import { CognitoUser } from '@aws-amplify/auth'
import { message } from 'antd'
import { Auth } from 'aws-amplify'
import axios from 'axios'
export const axiosInstance = axios.create()
let isRefreshing = false;
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error?.response?.status === 401) {
      if (isRefreshing) return;
      isRefreshing = true;

      localStorage.removeItem('token')
      try {
        const user = await Auth.currentAuthenticatedUser() as CognitoUser;
        const refreshToken = user?.getSignInUserSession()?.getRefreshToken();
        if (refreshToken) {
          message.loading('Login session expired. Refreshing token...')
          user.refreshSession(refreshToken, (err, result) => {
            if (result.idToken) {
              localStorage.setItem('token', result.idToken.jwtToken)
              message.destroy();
              message.success('Refresh token successfully, please try again!')
            } else {
              message.warning('Login session expired, please login again!')
              window.location.href = '/signin'
              isRefreshing = false;
              return message.warning('Login session expired, please login again!')
            }
          });
        } else {
          message.warning('Login session expired, please login again!')
          window.location.href = '/signin'
          isRefreshing = false;
          return message.warning('Login session expired, please login again!')
        }
      }
      catch (e) {
        window.location.href = '/signin'
        isRefreshing = false;
      }
    }
    return Promise.reject(error)
  }
)
