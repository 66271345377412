import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./main/index";

interface iProps {
  match: {
    url: string
  }
}

const App = ({ match }: iProps): JSX.Element => (
  <div className="main">
    <Switch>
      <Route path={`${match.url}main`} component={Main} />
    </Switch>
  </div>
);

export default App;
