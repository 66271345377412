import {
  CHECK_LIST_HEDGEFUND_NAV_DC_ERROR,
  CHECK_LIST_HEDGEFUND_NAV_DC_SUCCESS,
  GET_DOUBLE_CHECK_TRACK_ERROR,
  GET_DOUBLE_CHECK_TRACK_SUCCESS,
  GET_LIST_HEDGEFUND_NAV_DC_ERROR,
  GET_LIST_HEDGEFUND_NAV_DC_SUCCESS,
  GET_LIST_HEDGEFUND_NAV_ERROR,
  GET_LIST_HEDGEFUND_NAV_SUCCESS,
  GET_VALUE_ACCOUNT_HF_NAV,
  GET_VALUE_ACCOUNT_HF_NAV_DC,
  GET_VALUE_BASE_DATE_HF_NAV,
  GET_VALUE_BASE_DATE_HF_NAV_DC,
  GET_VALUE_PORTFOLIO_HF_NAV,
  GET_VALUE_PORTFOLIO_HF_NAV_DC,
  SAVE_LIST_HEDGEFUND_NAV_ERROR,
  SAVE_LIST_HEDGEFUND_NAV_SUCCESS,
} from '../../constants/ActionTypes'
import { HedgeFundNavDataDto } from './stateDtos'
interface ActionProps {
  type: string
  payload: any
  valueBasedate: string
  portfolioValue: any
  accountValue: any
  valueBasedateDC: string
  portfolioValueDC: any
  accountValueDC: any
  data: any[]
}

const INIT_STATE: HedgeFundNavDataDto = {
  hedgefundNAV_data: [],
  valueBasedate: '',
  portfolioValue: null,
  accountValue: null,
  valueBasedateDC: '',
  portfolioValueDC: null,
  accountValueDC: null,
  doubleCheckmounting: '',
}
const HedgeFundNavReducer = (
  state = INIT_STATE,
  action: ActionProps
): HedgeFundNavDataDto => {
  switch (action.type) {
    case GET_LIST_HEDGEFUND_NAV_SUCCESS: {
      return {
        ...state,
        hedgefundNAV_data: action.data,
      }
    }
    case GET_LIST_HEDGEFUND_NAV_ERROR: {
      return {
        ...state,
      }
    }
    case CHECK_LIST_HEDGEFUND_NAV_DC_SUCCESS: {
      return {
        ...state,
      }
    }
    case CHECK_LIST_HEDGEFUND_NAV_DC_ERROR: {
      return {
        ...state,
      }
    }
    case GET_LIST_HEDGEFUND_NAV_DC_SUCCESS: {
      return {
        ...state,
        hedgefundNAV_data: action.data,
      }
    }
    case GET_LIST_HEDGEFUND_NAV_DC_ERROR: {
      return {
        ...state,
      }
    }
    case GET_VALUE_ACCOUNT_HF_NAV: {
      return {
        ...state,
        accountValue: action.accountValue,
      }
    }
    case GET_VALUE_PORTFOLIO_HF_NAV: {
      return {
        ...state,
        portfolioValue: action.portfolioValue,
      }
    }
    case GET_DOUBLE_CHECK_TRACK_SUCCESS: {
      return {
        ...state,
        doubleCheckmounting: action.payload,
      }
    }
    case GET_DOUBLE_CHECK_TRACK_ERROR: {
      return {
        ...state,
      }
    }
    case GET_VALUE_BASE_DATE_HF_NAV: {
      return {
        ...state,
        valueBasedate: action.valueBasedate,
      }
    }
    case GET_VALUE_ACCOUNT_HF_NAV_DC: {
      return {
        ...state,
        accountValueDC: action.accountValueDC,
      }
    }
    case GET_VALUE_PORTFOLIO_HF_NAV_DC: {
      return {
        ...state,
        portfolioValueDC: action.portfolioValueDC,
      }
    }
    case GET_VALUE_BASE_DATE_HF_NAV_DC: {
      return {
        ...state,
        valueBasedateDC: action.valueBasedateDC,
      }
    }
    case SAVE_LIST_HEDGEFUND_NAV_SUCCESS: {
      const newList = [...state.hedgefundNAV_data]
      newList.push(action.payload)
      return {
        ...state,
        hedgefundNAV_data: newList,
      }
    }
    case SAVE_LIST_HEDGEFUND_NAV_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default HedgeFundNavReducer
