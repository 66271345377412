/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HIDE_MESSAGE,
  INIT_URL,
  LINK_SIGNIN_FALSE,
  LINK_SIGNIN_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SEND_CODE_SUCCESS,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  payload: '',
  loader: false,
  link: false,
  sendCode: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('token'),
}

const AuthReducer = (
  state = INIT_STATE,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      }
    }
    case LINK_SIGNIN_SUCCESS: {
      return {
        ...state,
        link: true,
      }
    }
    case LINK_SIGNIN_FALSE: {
      return {
        ...state,
        link: false,
      }
    }
    case SEND_CODE_SUCCESS: {
      return {
        ...state,
        sendCode: true,
      }
    }
    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      }
    }
    default:
      return state
  }
}

export default AuthReducer
