/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux'
import {
  GET_LiST_ACCOUNT_NAV,
  //   GET_LiST_ACCOUNT_NAV_FINAL_SUCCESS,
  GET_LiST_ACCOUNT_NAV_SUCCESS,
  GET_LiST_ACCOUNT_NAV_ERROR,
  BULK_SAVE_ACCOUNT_NAV_TOTAL_SUCCESS,
  //   BULK_SAVE_ACCOUNT_NAV_TOTAL_ERROR,
  BULK_SAVE_ACCOUNT_NAV_TOTAL,
  GET_EQ_CREDIT_ESTIMATE_SUCCESS,
  GET_EQ_CREDIT_ESTIMATE,
  GET_EQ_CREDIT_FINAL,
  GET_EQ_CREDIT_FINAL_SUCCESS,
  UPDATE_EQ_CREDIT_FINAL_SUCCESS,
  BULK_SAVE_ACCOUNT_NAV_TOTAL_ERROR,
  UPDATE_EQ_CREDIT_ESTIMATE_SUCCESS,
  UPDATE_EQ_CREDIT_ESTIMATE_ERROR,
  UPDATE_EQ_CREDIT_FINAL_ERROR,
  GET_VALUE_ACCOUNT_AC_NAV,
  GET_VALUE_BASE_DATE_AC_NAV,
  GET_VALUE_ACCOUNT_AC_DB_CHECK,
  GET_VALUE_BASE_DATE_AC_DB_CHECK,
  GET_LiST_DOUBLE_CHECK_NAV_ERROR,
  GET_LiST_DOUBLE_CHECK_NAV_SUCCESS,
  CHECK_LiST_DOUBLE_CHECK_NAV_SUCCESS,
  CHECK_LiST_DOUBLE_CHECK_NAV_ERROR,
  GET_DOUBLE_CHECK_TRACK_ERROR,
  GET_DOUBLE_CHECK_TRACK_SUCCESS,
  ALL_CHECK_DOUBLE_CHECK_TRACK_SUCCESS,
  ALL_CHECK_DOUBLE_CHECK_TRACK_ERROR,
} from '../../../constants/ActionTypes'
import {
  STATUS_BAD_REQUEST,
  STATUS_CODE_CREATE_SUCCESS,
  STATUS_CODE_SUCCESS,
  STATUS_CODE_UPDATE_SUCCESS,
} from '../../../constants/StatusCode'
import {
  AccountNAVEstimateApi,
  AccountNAVFinalApi,
  DoubleCheckTrackApi,
  NavAccountTotalApi,
} from '../../../client-axios/api'
import { axiosInstance } from '../../../core/axios'
import { configHeader } from '../../../core/token'
import { EqCreditUpdateItem } from '../../../dto'
import { message } from 'antd'

export const getListNavAccountTotal =
  (payload: { accountId: any; baseDate: string; isFinal: boolean }): unknown =>
  (dispatch: Dispatch) => {
    const navAccountTotalApi = new NavAccountTotalApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: GET_LiST_ACCOUNT_NAV })
    return navAccountTotalApi
      .findByAccountAndBasedateWithNavAccountTotal(
        payload.accountId,
        payload.baseDate,
        payload.isFinal
      )
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_LiST_ACCOUNT_NAV_SUCCESS, data: res.data })
        }
        return res.status
      })
      .catch((error) => {
        dispatch({
          type: GET_LiST_ACCOUNT_NAV_ERROR,
          error: error.response?.detail?.message,
        })
        message.error(error.response?.data?.detail?.message)
        return error?.response?.statusCode
      })
  }
export const doubleCheckMountingWithNav =
  (payload: { accountId: any; basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    const navAccountTotalApi = new NavAccountTotalApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return navAccountTotalApi
      .doubleCheckMountingWithNavAccountTotal(
        payload.accountId,
        payload.basedate
      )
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_LiST_DOUBLE_CHECK_NAV_SUCCESS, data: res.data })
        }
        return res.status
      })
      .catch((error) => {
        dispatch({
          type: GET_LiST_DOUBLE_CHECK_NAV_ERROR,
          error: error.response?.data?.message,
        })
      })
  }
export const mountingWithDoubleCheck =
  (payload: { accountId: any; basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    const doubleCheckTrackApi = new DoubleCheckTrackApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return doubleCheckTrackApi
      .mountingWithDoubleCheckTrack(payload.accountId, payload.basedate)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_DOUBLE_CHECK_TRACK_SUCCESS, payload: res.data })
        }
        return res.status
      })
      .catch((error) => {
        dispatch({
          type: GET_DOUBLE_CHECK_TRACK_ERROR,
          error: error.response?.data?.message,
        })
      })
  }
export const allCheckWithDC =
  (payload: { id: any; data: any }): unknown =>
  (dispatch: Dispatch) => {
    const doubleCheckTrackApi = new DoubleCheckTrackApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return doubleCheckTrackApi
      .allCheckedWithDoubleCheckTrack(payload.id, payload.data)
      .then((res) => {
        if (res.status === STATUS_CODE_UPDATE_SUCCESS) {
          dispatch({
            type: ALL_CHECK_DOUBLE_CHECK_TRACK_SUCCESS,
            payload: res.data,
          })
        }
        return res.status
      })
      .catch((error) => {
        dispatch({
          type: ALL_CHECK_DOUBLE_CHECK_TRACK_ERROR,
          error: error.response?.data?.message,
        })
      })
  }
export const doubleCheckWithNavAccountTotal =
  (payload: { data: any; fundId: number; basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    const navAccountTotalApi = new NavAccountTotalApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return navAccountTotalApi
      .doubleCheckWithNavAccountTotal(
        payload.fundId,
        payload.basedate,
        payload.data
      )
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({
            type: CHECK_LiST_DOUBLE_CHECK_NAV_SUCCESS,
            data: res.data,
          })
        }
        return res.status
      })
      .catch((error) => {
        dispatch({
          type: CHECK_LiST_DOUBLE_CHECK_NAV_ERROR,
          error: error.response?.data?.message,
        })
      })
  }
export const bulkSaveNavAccountTotal =
  (payload: { data: any }): unknown =>
  (dispatch: Dispatch) => {
    dispatch({ type: BULK_SAVE_ACCOUNT_NAV_TOTAL })
    const navAccountTotalApi = new NavAccountTotalApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return navAccountTotalApi
      .bulkSaveWithNavAccountTotal(payload.data)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({
            type: BULK_SAVE_ACCOUNT_NAV_TOTAL_SUCCESS,
            data: res.data,
          })
          return res?.status
        }
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          dispatch({
            type: BULK_SAVE_ACCOUNT_NAV_TOTAL_ERROR,
            Error: error.response?.data?.detail?.message,
          })
        }
        message.error(error.response?.data?.detail?.message)
        return error?.response?.statusCode
      })
  }
export const findEqCreditEstimate =
  (payload: { fundSeriesId: number; basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    dispatch({ type: GET_EQ_CREDIT_ESTIMATE })
    const accountNavEstimate = new AccountNAVEstimateApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    accountNavEstimate
      .findEqCreditWithAccountNavEstimate(
        payload.fundSeriesId,
        payload.basedate
      )
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: GET_EQ_CREDIT_ESTIMATE_SUCCESS,
            dataEqCreditEstimate: res.data,
          })
        }
      })
  }
export const updateEqCreditEstimate =
  (payload: any[]): unknown =>
  (dispatch: Dispatch) => {
    const accountNavEstimate = new AccountNAVEstimateApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return accountNavEstimate
      .updateEqCreditWithAccountNavEstimate(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({
            type: UPDATE_EQ_CREDIT_ESTIMATE_SUCCESS,
            payloadEq: res.data,
          })
          return res.status
        }
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: UPDATE_EQ_CREDIT_ESTIMATE_ERROR,
            Error: error.response?.data?.detail?.message,
          })
          message.error(error.response?.data?.detail?.message)
        }
      })
  }
export const findEqCreditFinal =
  (payload: { fundSeriesId: number; basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    dispatch({ type: GET_EQ_CREDIT_FINAL })
    const accountNavFinal = new AccountNAVFinalApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    accountNavFinal
      .findEqCreditWithAccountNavFinal(payload.fundSeriesId, payload.basedate)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: GET_EQ_CREDIT_FINAL_SUCCESS,
            dataEqCreditEstimate: res.data,
          })
        }
      })
  }
export const updateEqCreditFinal =
  (payload: EqCreditUpdateItem[]): unknown =>
  (dispatch: Dispatch) => {
    const accountNavFinal = new AccountNAVFinalApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return accountNavFinal
      .updateEqCreditWithAccountNavFinal(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({
            type: UPDATE_EQ_CREDIT_FINAL_SUCCESS,
            payloadEq: res.data,
          })
        }
        return res.status
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: UPDATE_EQ_CREDIT_FINAL_ERROR,
            Error: error.response?.data?.detail?.message,
          })
          message.error(error.response?.data?.detail?.message)
        }
      })
  }

export const getValueAccountAcNAV = (accountId: number): unknown => {
  return {
    type: GET_VALUE_ACCOUNT_AC_NAV,
    valueAccount: accountId,
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValueBasedateAcNAV = (basedate: any): unknown => {
  return {
    type: GET_VALUE_BASE_DATE_AC_NAV,
    valueBasedate: basedate,
  }
}
export const getValueAccountAcDoubleCheck = (accountId: number): unknown => {
  return {
    type: GET_VALUE_ACCOUNT_AC_DB_CHECK,
    valueAccountDc: accountId,
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValueBasedateAcDoubleCheck = (basedate: any): unknown => {
  return {
    type: GET_VALUE_BASE_DATE_AC_DB_CHECK,
    valueBasedateDc: basedate,
  }
}
