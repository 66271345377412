import {
  GET_LIST_INVESTOR,
  GET_LIST_INVESTOR_SUCCESS,
  GET_LIST_INVESTOR_DEFAULT,
  GET_BY_ID_INVESTOR_SUCCESS,
  CREATE_INVESTOR_SUCCESS,
  CREATE_INVESTOR_ERROR,
  UPDATE_INVESTOR_SUCCESS,
  UPDATE_INVESTOR_ERROR,
  UPDATE_INVESTOR,
  GET_BY_ID_INVESTOR,
  GET_LIST_INVESTOR_ACTIVE,
  PAGE_NUMBER_INV
} from '../../constants/ActionTypes'
import { MasterInvestorItem } from '../../dto'
import { MasterInvStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  loading: boolean
  data: MasterInvestorItem[]
  payload: MasterInvestorItem
  page: number
  tab: string
}
const INIT_STATE: MasterInvStateDto = {
  loading: false,
  CreateAlert: true,
  UpdateAlert: true,
  investorList: [],
  investorActiveList: [],
  dataDefault: [],
  id: 0,
  payload: null,
  tab: 'Active',
  page: 1
}
const MasterInvestorReducer = (
  state = INIT_STATE,
  action: ActionProps
): unknown => {
  switch (action.type) {
    case PAGE_NUMBER_INV: {
      return {
        ...state,
        page: action.page,
        tab: action.tab
      }
    }
    case GET_LIST_INVESTOR: {
      return {
        ...state,
        loading: true,
        investorList: [],
        investorActiveList: [],
      }
    }
    case GET_LIST_INVESTOR_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        investorList: action.data,
      }
    }
    case GET_LIST_INVESTOR_ACTIVE: {
      const activeList = action?.data.filter((x) => x.isActive == true && x?.asAccount == null)
      return {
        ...state,
        loading: false,
        investorActiveList: activeList,
      }
    }
    case GET_LIST_INVESTOR_DEFAULT: {
      return {
        ...state,
        loading: false,
        dataDefault: action.data,
      }
    }
    case CREATE_INVESTOR_SUCCESS: {
      const newList = [...state.investorList]

      newList.push(action.payload)
      return {
        ...state,
        investorList: newList,
      }
    }
    case CREATE_INVESTOR_ERROR: {
      return {
        ...state,
        CreateAlert: true,
      }
    }
    case UPDATE_INVESTOR: {
      return {
        ...state
      }
    }
    case UPDATE_INVESTOR_SUCCESS: {
      return {
        ...state,
        investorList: action.data,
        UpdateAlert: true,
      }
    }
    case UPDATE_INVESTOR_ERROR: {
      return {
        ...state,
        UpdateAlert: true,
      }
    }
    case GET_BY_ID_INVESTOR: {
      return {
        ...state,
        recordInvestor: null,
      }
    }
    case GET_BY_ID_INVESTOR_SUCCESS: {
      return {
        ...state,
        recordInvestor: action.payload,
        id: action.id,
      }
    }
    default:
      return state
  }
}
export default MasterInvestorReducer
