import {
  GET_BY_ID_ACCOUNT_CLASS,
  GET_BY_ID_ACCOUNT_CLASS_SUCCESS,
  GET_BY_ID_ACCOUNT_CLASS_ERROR,
  UPDATE_ACCOUNT_CLASS_SUCCESS,
  UPDATE_ACCOUNT_CLASS_ERROR,
  CREATE_ACCOUNT_CLASS_SUCCESS,
  CREATE_ACCOUNT_CLASS_ERROR,
  DELETE_ACCOUNT_CLASS,
  DELETE_ACCOUNT_CLASS_ERROR,
  DELETE_ACCOUNT_CLASS_SUCCESS,
} from '../../constants/ActionTypes'
import { ClassItem } from '../../dto'
import { AccountClassStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  data: ClassItem[]
  error: string
  loading: boolean
  payload: ClassItem
}
const INIT_STATE: AccountClassStateDto = {
  recordAcctClass: null,
  ClassList: [],
  CreateAlert: true,
  UpdateAlert: true,
  DeleteAlert: false,
  id: 0,
}
const AccountClassReducer = (
  state = INIT_STATE,
  action: ActionProps
): AccountClassStateDto => {
  switch (action.type) {
    case GET_BY_ID_ACCOUNT_CLASS: {
      return {
        ...state,
      }
    }
    case GET_BY_ID_ACCOUNT_CLASS_SUCCESS: {
      return {
        ...state,
        recordAcctClass: action.payload,
        id: action.id,
      }
    }
    case GET_BY_ID_ACCOUNT_CLASS_ERROR: {
      return {
        ...state,
      }
    }

    case CREATE_ACCOUNT_CLASS_SUCCESS: {
      const newList = [...state.ClassList]
      newList.push(action.payload)
      return {
        ...state,
        ClassList: newList,
        CreateAlert: true,
      }
    }
    case CREATE_ACCOUNT_CLASS_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_ACCOUNT_CLASS_SUCCESS: {
      const newList = state.ClassList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })


      return {
        ...state,
        ClassList: newList,
        UpdateAlert: true,
      }
    }
    case UPDATE_ACCOUNT_CLASS_ERROR: {
      return {
        ...state,
      }
    }
    case DELETE_ACCOUNT_CLASS: {
      return {
        ...state,
      }
    }
    case DELETE_ACCOUNT_CLASS_SUCCESS: {
      const newList = state.ClassList.filter(
        (item) => item.id !== action.payload
      )
      return {
        ...state,
        ClassList: newList,
        DeleteAlert: true,
      }
    }
    case DELETE_ACCOUNT_CLASS_ERROR: {
      return {
        ...state,
        DeleteAlert: false,
      }
    }
    default:
      return state
  }
}
export default AccountClassReducer
