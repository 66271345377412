import antJP from "antd/lib/locale-provider/ja_JP";
import enMessages from "../locales/jp_Kanji.json";

const JpLang = {
  messages: {
    ...enMessages
  },
  antd: antJP,
  locale: 'jp-Kanji',
};
export default JpLang;
