import {
  CREATE_WITH_FX_RATE,
  CREATE_WITH_FX_RATE_ERROR,
  CREATE_WITH_FX_RATE_SUCCESS,
  GET_ALL_FX_RATE,
  GET_ALL_FX_RATE_AUD_SUCCESS,
  GET_ALL_FX_RATE_ERROR,
  GET_ALL_FX_RATE_EUR_SUCCESS,
  GET_ALL_FX_RATE_GBP_SUCCESS,
  GET_ALL_FX_RATE_HKD_SUCCESS,
  GET_ALL_FX_RATE_JPY_SUCCESS,
  GET_ALL_FX_RATE_SUCCESS,
  GET_ALL_FX_RATE_USD_SUCCESS,
  UPDATE_WITH_FX_RATE_ERROR,
  UPDATE_WITH_FX_RATE_SUCCESS,
} from '../../constants/ActionTypes'
import { FXRateItem } from '../../dto'
import { FXRateDto } from './stateDtos'
interface ActionProps {
  type: string
  payload: FXRateItem
  data: FXRateItem[]
}

const INIT_STATE: FXRateDto = {
  FXRateList: [],
  FXRateListUSDJPY: [],
  FXRateListJPYEUR: [],
  FXRateListUSDEUR: [],
  FXRateListUSDAUD: [],
  FXRateListUSDGBP: [],
  FXRateListUSDHKD: [],
}
const FXRateReducer = (state = INIT_STATE, action: ActionProps): FXRateDto => {
  switch (action.type) {
    case CREATE_WITH_FX_RATE: {
      return {
        ...state,
      }
    }
    case CREATE_WITH_FX_RATE_SUCCESS: {
      const newList = [...state.FXRateList]
      newList.push(action.payload)
      return {
        ...state,
        FXRateList: newList,
      }
    }
    case CREATE_WITH_FX_RATE_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_WITH_FX_RATE_SUCCESS: {
      const newList = state.FXRateList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        FXRateList: newList,
      }
    }
    case UPDATE_WITH_FX_RATE_ERROR: {
      return {
        ...state,
      }
    }
    case GET_ALL_FX_RATE: {
      return {
        ...state,
      }
    }
    case GET_ALL_FX_RATE_SUCCESS: {
      return {
        ...state,
        FXRateList: action.data,
      }
    }
    case GET_ALL_FX_RATE_USD_SUCCESS: {
      return {
        ...state,
        FXRateListUSDJPY: action.data,
      }
    }
    case GET_ALL_FX_RATE_JPY_SUCCESS: {
      return {
        ...state,
        FXRateListJPYEUR: action.data,
      }
    }
    case GET_ALL_FX_RATE_EUR_SUCCESS: {
      return {
        ...state,
        FXRateListUSDEUR: action.data,
      }
    }
    case GET_ALL_FX_RATE_GBP_SUCCESS: {
      return {
        ...state,
        FXRateListUSDGBP: action.data,
      }
    }
    case GET_ALL_FX_RATE_AUD_SUCCESS: {
      return {
        ...state,
        FXRateListUSDAUD: action.data,
      }
    }
    case GET_ALL_FX_RATE_HKD_SUCCESS: {
      return {
        ...state,
        FXRateListUSDHKD: action.data,
      }
    }
    case GET_ALL_FX_RATE_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default FXRateReducer
