import { GET_LIST_ACCOUNT } from "../../constants/ActionTypes";
import { PortfolioCompositionStateDto } from './stateDtos';
interface ActionProps {
    type: string,
    payload: PortfolioCompositionStateDto
}

const INIT_STATE: PortfolioCompositionStateDto = {
    PortfolioCompositionList: [
        {
            id: 1,
            hedge_fund_name: "SCML Fund",
            children: [
                {
                    id: 11,
                    class_name: "class",
                    since: "2021-10-20",
                    end: "2021-10-21",
                },
                {
                    id: 12,
                    class_name: "class",
                    since: "2021-10-20",
                    end: "2021-10-21",
                }
            ]
        },
        {
            id: 2,
            hedge_fund_name: "ABCCC-CCCC",
            children: [
                {
                    id: 13,
                    class_name: "class",
                    since: "2021-10-20",
                    end: "2021-10-21",
                },
                {
                    id: 14,
                    class_name: "class",
                    since: "2021-10-20",
                    end: "2021-10-21",
                }
            ]
        },
    ],
};
const PortfolioReducer = (state = INIT_STATE, action: ActionProps): PortfolioCompositionStateDto => {
    switch (action.type) {
        case GET_LIST_ACCOUNT: {
            return {
                ...state,
            };
        }
        default:
            return state;
    }
};
export default PortfolioReducer;
