import { GET_DETAIL_CURRENCY_PAIR_FX, GET_DETAIL_CURRENCY_PAIR_FX_ERROR, GET_DETAIL_CURRENCY_PAIR_FX_SUCCESS, GET_LIST_FX_TICKER, GET_LIST_FX_TICKER_ERROR, GET_LIST_FX_TICKER_SUCCESS, GET_LIST_MARKET_INDEX_TICKER, GET_LIST_MARKET_INDEX_TICKER_ERROR, GET_LIST_MARKET_INDEX_TICKER_SUCCESS } from "../../../constants/ActionTypes"
import { FxTickerStateDto, IFXWithRawData } from "../stateDtos/MarketData/FxTickerStateDto"
import { MarketIndexStateDto } from "../stateDtos/MarketData/MarketIndexStateDto"

interface ActionProps {
    data: FxTickerStateDto[]
    detailCurPair: IFXWithRawData | null
    type: string
}
const INIT_STATE: FxTickerStateDto = {
    fxTickerList: [],
    detailCurPair: null,
}
const FxTickerReducer = (
    state = INIT_STATE,
    action: ActionProps
) => {
    switch (action.type) { 
        case GET_LIST_FX_TICKER: {
            return {
                ...state,
                fxTickerList: [],
            }
        }
        case GET_LIST_FX_TICKER_SUCCESS: {
            return {
                ...state,
                fxTickerList: action.data,
            }
        }
        case GET_LIST_FX_TICKER_ERROR: {
            return {
                ...state,
                fxTickerList: [],
            }
        }
        case GET_DETAIL_CURRENCY_PAIR_FX: {
            return {
                ...state,
                detailCurPair: null,
            }
        }
        case GET_DETAIL_CURRENCY_PAIR_FX_SUCCESS: {
            return {
                ...state,
                detailCurPair: action.detailCurPair,
            }
        }
        case GET_DETAIL_CURRENCY_PAIR_FX_ERROR: {
            return {
                ...state,
                detailCurPair: null,
            }
        }
        default:
            return state
    }
}   
export default FxTickerReducer