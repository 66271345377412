import { GET_LIST_MARKET_INDEX_TICKER, GET_LIST_MARKET_INDEX_TICKER_ERROR, GET_LIST_MARKET_INDEX_TICKER_SUCCESS } from "../../../constants/ActionTypes"
import { MarketIndexStateDto } from "../stateDtos/MarketData/MarketIndexStateDto"

interface ActionProps {
    data: MarketIndexStateDto[]
    type: string
}
const INIT_STATE: MarketIndexStateDto = {
    marketIndexList: [],
}
const MarketIndexReducer = (
    state = INIT_STATE,
    action: ActionProps
) => {
    switch (action.type) { 
        case GET_LIST_MARKET_INDEX_TICKER: {
            return {
                ...state,
                marketIndexList: [],
            }
        }
        case GET_LIST_MARKET_INDEX_TICKER_SUCCESS: {
            return {
                ...state,
                marketIndexList: action.data,
            }
        }
        case GET_LIST_MARKET_INDEX_TICKER_ERROR: {
            return {
                ...state,
                marketIndexList: [],
            }
        }
        default:
            return state
    }
}   
export default MarketIndexReducer