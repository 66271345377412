/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import {
  GET_BY_ID_CLASS_SUCCESS,
  GET_BY_ID_CLASS_ERROR,
  CREATE_CLASS_SUCCESS,
  CREATE_CLASS_ERROR,
  DELETE_CLASS_SUCCESS,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_ERROR,
  GET_LIST_HEDGEFUND_CLASS_SUCCESS,
  GET_LIST_HEDGEFUND_CLASS_ERROR,
  GET_SERIES_BY_HEDGEFUND_ID_ERROR,
  GET_SERIES_BY_HEDGEFUND_ID_SUCCESS,
} from '../../../constants/ActionTypes'
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
} from '../../../constants/StatusCode'
import { HedgeFundClassApi } from '../../../client-axios/api'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'
import { capitalizeFirstLetter } from '../../../util/function'
export const getListHedgeFundClass = (): unknown => (dispatch: Dispatch) => {
  const hedgeFundClassApi = new HedgeFundClassApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  return hedgeFundClassApi
    .findAllWithHedgefundClasses()
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_HEDGEFUND_CLASS_SUCCESS, data: res.data })
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch({
          type: GET_LIST_HEDGEFUND_CLASS_ERROR,
          error: error.response?.data?.detail?.message,
        })
        message.error(error.response?.data?.detail?.message)
      }
    })
}
export const createHedgeFundClass =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const hedgeFundClassApi = new HedgeFundClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return hedgeFundClassApi
      .createWithHedgefundClasses(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({ type: CREATE_CLASS_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: CREATE_CLASS_ERROR,
            error: error.response?.data?.detail?.message,
          })
          message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
        }
        return error?.response?.statusCode
      })
  }
export const updateHedgeFundClass =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const hedgeFundClassApi = new HedgeFundClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return hedgeFundClassApi
      .updateWithHedgefundClasses(payload.id, payload.data)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: UPDATE_CLASS_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: UPDATE_CLASS_ERROR,
            error: error.response?.data?.detail?.message,
          })
          message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
        }
        return error.response?.data?.statusCode
      })
  }
export const deleteHedgeFundClass = (payload: any) => (dispatch: Dispatch) => {
  const hedgeFundClassApi = new HedgeFundClassApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  return hedgeFundClassApi
    .removeWithHedgefundClasses(payload.id)
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: DELETE_CLASS_SUCCESS, data: res.data })
      }
    })
    .catch((error) => {
      if (error.response) {
        return message.error('Cannot delete!')
      }
    })
}
export const getHedgeFundClassById =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const hedgeFundClassApi = new HedgeFundClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    // dispatch({ type: GET_BY_ID_CLASS })
    return hedgeFundClassApi
      .findOneWithHedgefundClasses(payload.id)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_BY_ID_CLASS_SUCCESS, payload: res.data })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_BY_ID_CLASS_ERROR,
          error: error.response?.data?.detail?.message,
        })
      })
  }
export const getSeriesByHedgeFundClassId =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const hedgeFundClassApi = new HedgeFundClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return hedgeFundClassApi
      .getListSeriesWithHedgefundClasses(payload.id, payload.basedate)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_SERIES_BY_HEDGEFUND_ID_SUCCESS, data: res.data })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SERIES_BY_HEDGEFUND_ID_ERROR,
          error: error.response?.data?.detail?.message,
        })
      })

       return hedgeFundClassApi
      .findSeriesByHedgeFundClassWithHedgefundClasses(payload.id)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_SERIES_BY_HEDGEFUND_ID_SUCCESS, data: res.data })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SERIES_BY_HEDGEFUND_ID_ERROR,
          error: error.response?.data?.detail?.message,
        })
      })
  }
