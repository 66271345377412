import {
  GET_LIST_ORGANIZATION,
  GET_LIST_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_ERROR,
  GET_BY_ID_ORGANIZATION_SUCCESS,
  GET_BY_ID_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  GET_LIST_ORGANIZATION_DEFAULT,
  GET_LIST_ORGANIZATION_DEFAULT_SUCCESS,
  PAGE_NUMBER_ORGAN,
} from '../../constants/ActionTypes'
import { OrganizationItem } from '../../dto'
import { OrganizationStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  data: OrganizationItem[]
  error: string
  loading: boolean
  payload: OrganizationItem
  page: number
  tab: string
}
const INIT_STATE: OrganizationStateDto = {
  loading: false,
  error: '',
  organizationList: [],
  dataDefault: [],
  CreateAlert: false,
  UpdateAlert: null,
  id: 0,
  record: null,
  page: 1,
  tab: 'Active'
}
const OrganizationReducer = (
  state = INIT_STATE,
  action: ActionProps
): OrganizationStateDto => {
  switch (action.type) {
    case PAGE_NUMBER_ORGAN: {
      return {
        ...state,
        page: action.page,
        tab: action.tab
      }
    }
    case GET_LIST_ORGANIZATION: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_LIST_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        organizationList: action.data,
      }
    }
    case GET_LIST_ORGANIZATION_DEFAULT: {
      return {
        ...state,
      }
    }
    case GET_LIST_ORGANIZATION_DEFAULT_SUCCESS: {
      return {
        ...state,
        loading: false,
        dataDefault: action.data,
      }
    }
    case CREATE_ORGANIZATION_SUCCESS: {
      const newList = [...state.organizationList]
      newList.push(action.payload)
      return {
        ...state,
        CreateAlert: true,
        organizationList: newList,
      }
    }
    case CREATE_ORGANIZATION_ERROR: {
      return {
        ...state,
      }
    }
    case GET_BY_ID_ORGANIZATION: {
      return {
        ...state,
        record: null,
      }
    }
    case GET_BY_ID_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        record: action.payload,
        id: action.id,
      }
    }
    case UPDATE_ORGANIZATION: {
      return {
        ...state,
      }
    }
    case UPDATE_ORGANIZATION_SUCCESS: {
      // const newList = state.organizationList.map(function (item) {
      //   return item.id == action.payload.id ? action.payload : item
      // })
      return {
        ...state,
        // UpdateAlert: true,
        // organizationList: newList,
      }
    }
    case UPDATE_ORGANIZATION_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default OrganizationReducer
