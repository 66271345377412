import { DataViewAccountStateDto } from '../../reducers/stateDtos'
import { GET_LIST_DATA_VIEW_ACCOUNT, GET_LIST_DATA_VIEW_ACCOUNT_ACTIVE, GET_LIST_DATA_VIEW_ACCOUNT_DEFAULT, GET_LIST_DATA_VIEW_ACCOUNT_SUCCESS } from '../../../constants/ActionTypes'

interface ActionProps {
    data: any[]
    type: string
}
const INIT_STATE: DataViewAccountStateDto = {
    dataViewAcctList: [],
    dataViewAcctListDefault: [],
    dataViewAcctActive: [],
    loading: true,
}
const DataViewAccountsReducer = (
    state = INIT_STATE,
    action: ActionProps
): any => {
    switch (action.type) {
        case GET_LIST_DATA_VIEW_ACCOUNT: {
            return {
                ...state,
                loading: true,
                dataViewAcctList: [],
                dataViewAcctActive: []
            }
        }
        case GET_LIST_DATA_VIEW_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loading: false,
                dataViewAcctList: action.data,
            }
        }
        case GET_LIST_DATA_VIEW_ACCOUNT_DEFAULT: {
            return {
                ...state,
                loading: false,
                dataViewAcctListDefault: action.data,
            }
        }
        case GET_LIST_DATA_VIEW_ACCOUNT_ACTIVE: {
            const activeList = action.data.filter((x) => x.isActive == true)
            return {
                ...state,
                dataViewAcctActive: activeList,
            }
        }
        default:
            return state
    }
}
export default DataViewAccountsReducer