import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}

const HedgeFund = ({ match }: iProps): JSX.Element => (
  <Switch>
    {''}
    <Route
      exact
      path={`${match.url}/return`}
      component={asyncComponent(
        () => import('../../components/HF_HedgeFund/HF_ReturnHedgeFund/Return')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/return/dataEntry/:id/:basedate/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_ReturnHedgeFund/ReturnDataEntry'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/return/dataEntry/:id/:basedate/edit`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_ReturnHedgeFund/ReturnDataEntry'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/return/holdingLevel/:id/:basedate/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_ReturnHedgeFund/HoldingLevel'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/return/holdingLevel/:id/:basedate/edit`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_ReturnHedgeFund/HoldingLevel'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/nav`}
      component={asyncComponent(
        () =>
          import('../../components/HF_HedgeFund/HF_HedgeFundNAV/HedgeFundNAV')
      )}
    />
    <Route
      exact
      path={`${match.url}/port`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/PortfolioManager'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/portfolio-composition/:accountId/:portfolioId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioComposition/PortfolioComposition'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolio_id`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionView'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolio_id/:hedgeFundId/:currency/class-series-change/:basedate/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/ClassSeriesChange'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/class-series-change/:basedate/edit/:mergeTxnItemIds`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/ClassSeriesChange'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/class-series-change/:basedate/detail/:mergeTxnItemIds`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/ClassSeriesChange'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/allocation/:accountId/:portfolioId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_AllocationUpdate/AllocationUpdate'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/:checkAccounting/invest/:basedate/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionInvest'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/:fundTransId/:checkAccounting/invest/:basedate/edit/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionInvest'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/invest/:basedate/detail/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionInvest'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/:holdingId/redeem/:basedate/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditRedeem'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/:holdingId/redeem/:basedate/edit/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditRedeem'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/:holdingId/redeem/:basedate/detail/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditRedeem'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/redeem/:basedate/detail/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditRedeem'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/other/:basedate/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditOther'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/:fundTransactionId/other/:basedate/edit/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditOther'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/port/txn/:accountId/:portfolioId/:hedgeFundId/:fundClassId/other/:basedate/detail/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditOther'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/dataDuplication`}
      component={asyncComponent(
        () =>
          import('../../components/HF_HedgeFund/DataDuplicate/dataDuplication')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/doubleCheck`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_HedgeFundDoubleCheck/ListHedgeFundDoubleCheck'
          )
      )}
    />
       <Route
      exact
      path={`${match.url}/aum_exposure`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_AUM&Exposure/index'
          )
      )}
    />
    {/* <Route
      exact
      path={`${match.url}/portfolioManagement/portfolioView/create-update-other`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditOther'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/portfolioManagement/portfolioView/create-update-redeem`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionEditRedeem'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/portfolioManagement/portfolio-transaction/invest`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PortfolioTransactionInvest'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/portfolioManagement/portfolioView/create-update-rollup`}
      component={asyncComponent(
        () =>
          import(
            '../../components/HF_HedgeFund/HF_PortfolioManagerment/HF_PortfolioTransaction/PorfolioTransactionEditRollUp'
          )
      )}
    ></Route>  */}
  </Switch>
)
export default HedgeFund
