import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}

const MasterManagement = ({ match }: iProps): JSX.Element => (
  <Switch>
    {' '}
    {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/organization`}/> */}{' '}
    {/*M_Organization*/}
    <Route
      exact
      path={`${match.url}/organization`}
      component={asyncComponent(
        () => import('../../components/M_Organization/ListOrganization')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/organization/add`}
      component={asyncComponent(
        () => import('../../components/M_Organization/CreateUpdateOrganization')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/organization/edit/:id`}
      component={asyncComponent(
        () => import('../../components/M_Organization/CreateUpdateOrganization')
      )}
    />{' '}
    {/*M_Account*/}
    <Route
      exact
      path={`${match.url}/acct`}
      component={asyncComponent(
        () => import('../../components/M_Account/ListAccountMaster')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/acct/add`}
      component={asyncComponent(
        () => import('../../components/M_Account/CreateAndUpdateAccounts')
      )}
    />{' '}
    <Route
      path={`${match.url}/acct/edit/:accountId`}
      component={asyncComponent(
        () => import('../../components/M_Account/CreateAndUpdateAccounts')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/acct/view/:accountId`}
      component={asyncComponent(
        () => import('../../components/M_Account/CreateAndUpdateAccounts')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/acct/:fundId/portfolio/edit/:portfolioId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountPortfolio/CreateAndUpdatePortfolio'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/portfolio/view/:portfolioId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountPortfolio/CreateAndUpdatePortfolio'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/portfolio/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountPortfolio/CreateAndUpdatePortfolio'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/portfolio/:portfolioId/class/edit/:classId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountClasses/CreateAndUpdateClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/portfolio/:portfolioId/class/view/:classId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountClasses/CreateAndUpdateClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/class/edit/:classId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountClasses/CreateAndUpdateClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/class/view/:classId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountClasses/CreateAndUpdateClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/portfolio/:portfolioId/class/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountClasses/CreateAndUpdateClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:fundId/class/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_Account/M_AccountClasses/CreateAndUpdateClass'
          )
      )}
    />
    {/*M_HedgeFund*/}
    <Route
      exact
      path={`${match.url}/hf`}
      component={asyncComponent(
        () => import('../../components/M_HedgeFund/ListHedgeFund')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/hf/add`}
      component={asyncComponent(
        () => import('../../components/M_HedgeFund/CreateAndUpdateHedgeFund')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/hf/edit/:id`}
      component={asyncComponent(
        () => import('../../components/M_HedgeFund/CreateAndUpdateHedgeFund')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/hf/view/:id`}
      component={asyncComponent(
        () => import('../../components/M_HedgeFund/CreateAndUpdateHedgeFund')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/hf/:hedgefundId/class/edit/:classId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_HedgeFund/M_HedgeFundClasses/CreateAndUpdateHedgeFundClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/hf/:hedgefundId/class/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_HedgeFund/M_HedgeFundClasses/CreateAndUpdateHedgeFundClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/hf/:hedgefundId/class/view/:classId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_HedgeFund/M_HedgeFundClasses/CreateAndUpdateHedgeFundClass'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/hf/:hedgefundId/class/:classId/roll-up/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_HedgeFund/M_HedgeFundSeriesRollUp/CreateAndUpdateRollUp'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/hf/:hedgefundId/class/:classId/roll-up/edit/:rollUpId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_HedgeFund/M_HedgeFundSeriesRollUp/CreateAndUpdateRollUp'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/hf/:fundId/history/add`}
      component={asyncComponent(
        () =>
          import(
            '../../components/M_HedgeFund/M_HedgeFundHistorical/CreateAndUpdateHistorical'
          )
      )}
    />
    {/* Investor Master */}{' '}
    <Route
      exact
      path={`${match.url}/investor`}
      component={asyncComponent(
        () => import('../../components/M_Investor/ListInvestorMaster')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/investor/add/`}
      component={asyncComponent(
        () =>
          import('../../components/M_Investor/CreateAndUpdateInvestorMaster')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/investor/edit/:id`}
      component={asyncComponent(
        () =>
          import('../../components/M_Investor/CreateAndUpdateInvestorMaster')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/investor/view/:id`}
      component={asyncComponent(
        () =>
          import('../../components/M_Investor/CreateAndUpdateInvestorMaster')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/investor/:investorId/division/add`}
      component={asyncComponent(
        () => import('../../components/M_Investor/CreateAndUpdateDivision')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/investor/:investorId/division/edit/:divisionId`}
      component={asyncComponent(
        () => import('../../components/M_Investor/CreateAndUpdateDivision')
      )}
    />
    <Route
      exact
      path={`${match.url}/investor/:investorId/division/view/:divisionId`}
      component={asyncComponent(
        () => import('../../components/M_Investor/CreateAndUpdateDivision')
      )}
    />
  </Switch>
)

export default MasterManagement
