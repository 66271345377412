/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux'
import {
  GET_BY_ID_HF_HISTORICAL_SUCCESS,
  GET_BY_ID_HF_HISTORICAL_ERROR,
  UPDATE_BULK_HF_HISTORICAL_SUCCESS,
  UPDATE_BULK_HF_HISTORICAL_ERROR,
  GET_BY_ID_HF_HISTORICAL,
  UPDATE_BULK_HF_HISTORICAL,
} from '../../../constants/ActionTypes'
import { STATUS_CODE_SUCCESS } from '../../../constants/StatusCode'
import { HedgeFundHistoricalReturnsApi } from '../../../client-axios/api'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'

export const getHedgefundHistorical = (): unknown => {
  const HedgeFundHistoricalApi = new HedgeFundHistoricalReturnsApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  return async () => {
    try {
      return HedgeFundHistoricalApi.findAllWithHedgefundHistoricalReturn().then(
        (res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            return res
          } else {
            return res
          }
        }
      )
    } catch (error) {
      message.error((error as any)?.message)
    }
  }
}
export const getHedgefundHistoricalByHegdefundId =
  (payload: {
    fundId: any
    from: string
    to: string
    frequency: any
    currency: any
  }): unknown =>
  (dispatch: Dispatch) => {
    const HedgeFundHistoricalApi = new HedgeFundHistoricalReturnsApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: GET_BY_ID_HF_HISTORICAL })
    return HedgeFundHistoricalApi.findByHegdefundIdWithHedgefundHistoricalReturn(
      payload.fundId,
      payload.from,
      payload.to,
      payload.frequency,
      payload.currency
    )
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_BY_ID_HF_HISTORICAL_SUCCESS, data: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        dispatch({
          type: GET_BY_ID_HF_HISTORICAL_ERROR,
          error: error?.response?.data?.message,
        })
      })
  }
export const updateBulkHedgefundHistorical =
  (payload: { id: any; data: any }): unknown =>
  (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_BULK_HF_HISTORICAL })
    const HedgeFundHistoricalApi = new HedgeFundHistoricalReturnsApi(
      configHeader(),
      undefined,
      axiosInstance
    )

    return HedgeFundHistoricalApi.updateBulkWithHedgefundHistoricalReturn(
      payload.id,
      payload.data
    )
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: UPDATE_BULK_HF_HISTORICAL_SUCCESS,
            payload: res.data,
          })
          return res.status
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_BULK_HF_HISTORICAL_ERROR,
          error: error.detail?.message,
        })
        return message.error(error?.detail?.message)
      })
  }
