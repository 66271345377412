import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from './Settings'
import Auth from './Auth'
import Common from './Common'
import AccountReducer from './MasterAccount'
import AccountNAVReducer from './AccountNAV'
import MasterInvestor from './MasterInvestor'
import DoubleCheckReducer from './NAVDoubleCheck'
import InvestorTransactionReducer from './InvestorTransaction'
import FXDataReducer from './FXData'
import InterestRateReducer from './InterestRateData'
import HFIndexReducer from './HFIndexData'
import ReturnReducer from './HedgeFundReturn'
import HedgeFundNAVReducer from './HedgeFundNAV'
import AccountTransaction from './AccountTransaction'
import PortfolioComposition from './PortfolioComposition'
import Data from './DataAverage'
import DataNAV from './DataNAV'
import OrganizationReducer from './Organization'
import CityReducer from './City'
import HedgeFundReducer from './MasterHedgeFund'
import DivisionReducer from './MasterDivision'
import ClassReducer from './Class'
import AccountClassReducer from './AccountClass'
import HFHistoricalReducer from './HFHistorical'
import PortfolioReducer from './Portfolio'
import StrategyReducer from './Strategy'
import HedgeFundSeriesReducer from './HedgeFundSeries'
import SysuserReducer from './SysUser'
import MarketDataReducer from './MarketData'
import FXRateReducer from './FxRateData'
import PortTsReducer from './PortfolioTransaction'
import DataViewHedgeFundsReducer from './DataView/HedgeFunds'
import DataViewAccountsReducer from './DataView/Accounts'
import DataViewInvestorReducer from './DataView/Investor'
import InterestRatesTickerReducer from './MarketData/InterestRatesTicker'
import HedgefundIndexTickerReducer from './MarketData/HedgefundIndexTicker'
import { History } from 'history'
import SeriesReducer from './Series'
import MarketIndexReducer from './MarketData/MarketIndex'
import FxTickerReducer from './MarketData/FxTicker'

const createReducer = (history: History): unknown =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    m_account: AccountReducer,
    account_nav: AccountNAVReducer,
    master_investor: MasterInvestor,
    hedge_fund_nav: HedgeFundNAVReducer,
    fx_data: FXDataReducer,
    portfolio_composition: PortfolioComposition,
    hf_index_data: HFIndexReducer,
    interest_rate_data: InterestRateReducer,
    double_check: DoubleCheckReducer,
    investor_transaction: InvestorTransactionReducer,
    return_hedgefund: ReturnReducer,
    account_transaction: AccountTransaction,
    data_average: Data,
    data_nav: DataNAV,
    m_organization: OrganizationReducer,
    city: CityReducer,
    m_hedgefund: HedgeFundReducer,
    division: DivisionReducer,
    class: ClassReducer,
    acctClass: AccountClassReducer,
    hfHistorical: HFHistoricalReducer,
    portfolio: PortfolioReducer,
    Series: SeriesReducer,
    strategyReducer: StrategyReducer,
    hfSeriesReducer: HedgeFundSeriesReducer,
    sysUser: SysuserReducer,
    marketDataReducer: MarketDataReducer,
    fxRate: FXRateReducer,
    portTs: PortTsReducer,
    dataViewHf: DataViewHedgeFundsReducer,
    dataViewAccount: DataViewAccountsReducer,
    dataViewInvestor: DataViewInvestorReducer,
    interestRateTicker: InterestRatesTickerReducer,
    hedgefundIndexTicker: HedgefundIndexTickerReducer,
    marketIndex: MarketIndexReducer,
    fxTicker: FxTickerReducer,
  })
export default createReducer
