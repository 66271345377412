/* tslint:disable */
/* eslint-disable */
/**
 * Quark APIs
 * REST APIs for Quark
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountClassInvestorHoldingsDto
 */
export interface AccountClassInvestorHoldingsDto {
    /**
     * 
     * @type {Array<AccountSeriesInvestorHoldingsDto>}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'accountSeries': Array<AccountSeriesInvestorHoldingsDto>;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'fundClassName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'fundClassAbbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'isSeriesAccounting'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'bookValue': number;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'totalNavExEQ': number;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'totalNavInclEQ': number;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'reportShares'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'totalPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'unrealizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'realizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'totalDividend': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountClassInvestorHoldingsDto
     */
    'reportPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface AccountInvestorHoldingsDto
 */
export interface AccountInvestorHoldingsDto {
    /**
     * 
     * @type {Array<AccountClassInvestorHoldingsDto>}
     * @memberof AccountInvestorHoldingsDto
     */
    'accountClasses': Array<AccountClassInvestorHoldingsDto>;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'fundId': number;
    /**
     * 
     * @type {string}
     * @memberof AccountInvestorHoldingsDto
     */
    'fundName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountInvestorHoldingsDto
     */
    'fundAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'bookValue': number;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'totalNavExEQ': number;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'totalNavInclEQ': number;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'reportShares'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'totalPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'unrealizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'realizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'totalDividend': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInvestorHoldingsDto
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountInvestorHoldingsDto
     */
    'reportPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface AccountNavEstimateDuplicateDto
 */
export interface AccountNavEstimateDuplicateDto {
    /**
     * 
     * @type {string}
     * @memberof AccountNavEstimateDuplicateDto
     */
    'duplicateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof AccountNavEstimateDuplicateDto
     */
    'duplicateTo': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNavEstimateDuplicateDto
     */
    'toBeOverwrite': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNavEstimateDuplicateDto
     */
    'allAccounts': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccountNavEstimateDuplicateDto
     */
    'fundIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AccountPerformanceDto
 */
export interface AccountPerformanceDto {
    /**
     * 
     * @type {string}
     * @memberof AccountPerformanceDto
     */
    'basedate': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPerformanceDto
     */
    'isWeekly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPerformanceDto
     */
    'isMonthly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPerformanceDto
     */
    'isFinal': boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountPerformanceDto
     */
    'netMTD': number;
    /**
     * 
     * @type {number}
     * @memberof AccountPerformanceDto
     */
    'netFundingMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountPerformanceDto
     */
    'netWTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountPerformanceDto
     */
    'netFundingWTD'?: number;
}
/**
 * 
 * @export
 * @interface AccountSeriesInvestorHoldingsDto
 */
export interface AccountSeriesInvestorHoldingsDto {
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'fundSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'fundSeriesAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'bookValue': number;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'totalNavExEQ': number;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'totalNavInclEQ': number;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'reportShares'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'totalPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'unrealizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'realizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'totalDividend': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountSeriesInvestorHoldingsDto
     */
    'reportPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface AccountYTDDto
 */
export interface AccountYTDDto {
    /**
     * 
     * @type {string}
     * @memberof AccountYTDDto
     */
    'year': string;
    /**
     * 
     * @type {number}
     * @memberof AccountYTDDto
     */
    'ytd': number;
}
/**
 * 
 * @export
 * @interface AllocationFundClassDto
 */
export interface AllocationFundClassDto {
    /**
     * 
     * @type {Array<AllocationFundSeriesDto>}
     * @memberof AllocationFundClassDto
     */
    'fundSeries': Array<AllocationFundSeriesDto>;
    /**
     * 
     * @type {number}
     * @memberof AllocationFundClassDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof AllocationFundClassDto
     */
    'fundClassName': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationFundClassDto
     */
    'fundClassAbbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationFundClassDto
     */
    'isSeriesAccounting'?: boolean;
}
/**
 * 
 * @export
 * @interface AllocationFundSeriesDto
 */
export interface AllocationFundSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof AllocationFundSeriesDto
     */
    'endingNAV': number;
    /**
     * 
     * @type {number}
     * @memberof AllocationFundSeriesDto
     */
    'changedNavEnding': number;
    /**
     * 
     * @type {number}
     * @memberof AllocationFundSeriesDto
     */
    'allocationPercentage': number;
    /**
     * 
     * @type {object}
     * @memberof AllocationFundSeriesDto
     */
    'navFlag': object;
    /**
     * 
     * @type {number}
     * @memberof AllocationFundSeriesDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof AllocationFundSeriesDto
     */
    'fundSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationFundSeriesDto
     */
    'fundSeriesAbbreviation': string;
}
/**
 * 
 * @export
 * @interface AuthenticateRequestDto
 */
export interface AuthenticateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequestDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CalucalteHedgefundHistoricalReturnDto
 */
export interface CalucalteHedgefundHistoricalReturnDto {
    /**
     * 
     * @type {string}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'netMtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'netFundingMtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'netWtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'netFundingWtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'monthly'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'weekly'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CalucalteHedgefundHistoricalReturnDto
     */
    'currency'?: CalucalteHedgefundHistoricalReturnDtoCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CalucalteHedgefundHistoricalReturnDtoCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface ChangeFromDto
 */
export interface ChangeFromDto {
    /**
     * 
     * @type {number}
     * @memberof ChangeFromDto
     */
    'seriesId': number;
}
/**
 * 
 * @export
 * @interface ClassDto
 */
export interface ClassDto {
    /**
     * 
     * @type {Array<SeriesDto>}
     * @memberof ClassDto
     */
    'fundSeries': Array<SeriesDto>;
    /**
     * 
     * @type {number}
     * @memberof ClassDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    'fundClassName': string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    'fundClassAbbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassDto
     */
    'isSeriesAccounting'?: boolean;
}
/**
 * 
 * @export
 * @interface ClsFundTransactionItemDto
 */
export interface ClsFundTransactionItemDto {
    /**
     * 
     * @type {number}
     * @memberof ClsFundTransactionItemDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ClsFundTransactionItemDto
     */
    'redeemId': number;
    /**
     * 
     * @type {number}
     * @memberof ClsFundTransactionItemDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof ClsFundTransactionItemDto
     */
    'bookValueChange': number;
    /**
     * 
     * @type {string}
     * @memberof ClsFundTransactionItemDto
     */
    'transactionStatus': ClsFundTransactionItemDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClsFundTransactionItemDto
     */
    'transferType'?: ClsFundTransactionItemDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ClsFundTransactionItemDto
     */
    'comment': string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ClsFundTransactionItemDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum ClsFundTransactionItemDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}

/**
 * 
 * @export
 * @interface CreateAccountClassDto
 */
export interface CreateAccountClassDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountClassDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountClassDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountClassDto
     */
    'isSeriesAccounting': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountClassDto
     */
    'currency': CreateAccountClassDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountClassDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountClassDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountClassDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountClassDto
     */
    'managementFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountClassDto
     */
    'performanceFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountClassDto
     */
    'hurdleRate'?: number | null;
    /**
     * 
     * @type {Liquidity}
     * @memberof CreateAccountClassDto
     */
    'liquidity': Liquidity;
    /**
     * 
     * @type {Array<SoftLockDtoBase>}
     * @memberof CreateAccountClassDto
     */
    'softLock': Array<SoftLockDtoBase> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountClassDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountClassDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountClassDto
     */
    'portfolioId': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountClassDto
     */
    'legacyCode': string;
    /**
     * 
     * @type {Array<CreateAccountSeriesDto>}
     * @memberof CreateAccountClassDto
     */
    'fundSeries': Array<CreateAccountSeriesDto> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateAccountClassDtoCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface CreateAccountDto
 */
export interface CreateAccountDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountDto
     */
    'cityId': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Array<StrategyRelation>}
     * @memberof CreateAccountDto
     */
    'strategies': Array<StrategyRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof CreateAccountDto
     */
    'investmentManager': Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof CreateAccountDto
     */
    'investmentAdvisor': Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<ServiceProvider>}
     * @memberof CreateAccountDto
     */
    'serviceProviders': Array<ServiceProvider>;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'to': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'businessLocation': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountDto
     */
    'operatedByMCP': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'operationStyle': CreateAccountDtoOperationStyleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountDto
     */
    'isAccount': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountDto
     */
    'isHedgeFund': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    'accountStrategyType': CreateAccountDtoAccountStrategyTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateAccountDtoOperationStyleEnum {
    Self = 'Self',
    Delegate = 'Delegate'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateAccountDtoAccountStrategyTypeEnum {
    FoFs = 'FoFs',
    Single = 'Single'
}

/**
 * 
 * @export
 * @interface CreateAccountSeriesDto
 */
export interface CreateAccountSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof CreateAccountSeriesDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountSeriesDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountSeriesDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountSeriesDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountSeriesDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountSeriesDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountSeriesDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountSeriesDto
     */
    'managementFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountSeriesDto
     */
    'performanceFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountSeriesDto
     */
    'hurdleRate': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountSeriesDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountSeriesDto
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface CreateAllocationDto
 */
export interface CreateAllocationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {Array<CreateAllocationItemDto>}
     * @memberof CreateAllocationDto
     */
    'allocationItems': Array<CreateAllocationItemDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationDto
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @interface CreateAllocationItemDto
 */
export interface CreateAllocationItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationItemDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationItemDto
     */
    'holdingSeriesId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationItemDto
     */
    'navAfterTxn': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationItemDto
     */
    'navDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationItemDto
     */
    'navFlag': CreateAllocationItemDtoNavFlagEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationItemDto
     */
    'allocationPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationItemDto
     */
    'ytd': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationItemDto
     */
    'ltd': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationItemDto
     */
    'hwm': number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateAllocationItemDtoNavFlagEnum {
    Estimate = 'Estimate',
    Final = 'Final'
}

/**
 * 
 * @export
 * @interface CreateCityDto
 */
export interface CreateCityDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCityDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCityDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCityDto
     */
    'legacyCityCode'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateClassSeriesChangeDto
 */
export interface CreateClassSeriesChangeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateClassSeriesChangeDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateClassSeriesChangeDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateClassSeriesChangeDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateClassSeriesChangeDto
     */
    'changeToSeriesId': number;
    /**
     * 
     * @type {Array<ChangeFromDto>}
     * @memberof CreateClassSeriesChangeDto
     */
    'changeFrom': Array<ChangeFromDto>;
    /**
     * 
     * @type {CreateHedgefundTransactionDto}
     * @memberof CreateClassSeriesChangeDto
     */
    'fundTransaction': CreateHedgefundTransactionDto;
    /**
     * 
     * @type {Array<CreateHedgefundTransactionItemDto>}
     * @memberof CreateClassSeriesChangeDto
     */
    'redeemRecords': Array<CreateHedgefundTransactionItemDto>;
}
/**
 * 
 * @export
 * @interface CreateDividendDto
 */
export interface CreateDividendDto {
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'investorId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'divisionId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDividendDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'dividendAmount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'dividendPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDividendDto
     */
    'extraDivBvChange': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDividendDto
     */
    'transactionStatus': CreateDividendDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDividendDto
     */
    'comment': string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateDividendDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}

/**
 * 
 * @export
 * @interface CreateDivisionDto
 */
export interface CreateDivisionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDivisionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDivisionDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDivisionDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDivisionDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateDivisionDto
     */
    'investorId': number;
}
/**
 * 
 * @export
 * @interface CreateHFExposureDto
 */
export interface CreateHFExposureDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHFExposureDto
     */
    'basedate': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateHFExposureDto
     */
    'fundOrAbbreviation': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'fundAUM': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'strategyAUM': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'longExposure': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'shortExposure': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'netExposure': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'managerAUM': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'shortNum': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHFExposureDto
     */
    'longNum': number | null;
}
/**
 * 
 * @export
 * @interface CreateHedgefundClassDto
 */
export interface CreateHedgefundClassDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundClassDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundClassDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundClassDto
     */
    'isSeriesAccounting': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundClassDto
     */
    'currency': CreateHedgefundClassDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundClassDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundClassDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundClassDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundClassDto
     */
    'managementFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundClassDto
     */
    'performanceFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundClassDto
     */
    'hurdleRate'?: number | null;
    /**
     * 
     * @type {Liquidity}
     * @memberof CreateHedgefundClassDto
     */
    'liquidity': Liquidity;
    /**
     * 
     * @type {Array<SoftLockDtoBase>}
     * @memberof CreateHedgefundClassDto
     */
    'softLock': Array<SoftLockDtoBase> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundClassDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundClassDto
     */
    'fundId': number;
    /**
     * 
     * @type {Array<CreateHedgefundSeriesDto>}
     * @memberof CreateHedgefundClassDto
     */
    'fundSeries': Array<CreateHedgefundSeriesDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundClassDtoCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface CreateHedgefundDto
 */
export interface CreateHedgefundDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundDto
     */
    'cityId': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Array<StrategyRelation>}
     * @memberof CreateHedgefundDto
     */
    'strategies': Array<StrategyRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof CreateHedgefundDto
     */
    'investmentManager': Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof CreateHedgefundDto
     */
    'investmentAdvisor': Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<ServiceProvider>}
     * @memberof CreateHedgefundDto
     */
    'serviceProviders': Array<ServiceProvider>;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundDto
     */
    'from': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundDto
     */
    'isHedgeFund': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundDto
     */
    'businessLocation': string | null;
}
/**
 * 
 * @export
 * @interface CreateHedgefundHistoricalReturnDto
 */
export interface CreateHedgefundHistoricalReturnDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'netMtd': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'netFundingMtd': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'netWtd': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'netFundingWtd': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'fundId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'monthly': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'weekly': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundHistoricalReturnDto
     */
    'currency': CreateHedgefundHistoricalReturnDtoCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundHistoricalReturnDtoCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface CreateHedgefundSeriesDto
 */
export interface CreateHedgefundSeriesDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundSeriesDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundSeriesDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundSeriesDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundSeriesDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundSeriesDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundSeriesDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundSeriesDto
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundSeriesDto
     */
    'managementFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundSeriesDto
     */
    'performanceFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundSeriesDto
     */
    'hurdleRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundSeriesDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundSeriesDto
     */
    'fundId': number;
}
/**
 * 
 * @export
 * @interface CreateHedgefundTransactionDto
 */
export interface CreateHedgefundTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionDto
     */
    'fundId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionDto
     */
    'transactionStartType': CreateHedgefundTransactionDtoTransactionStartTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundTransactionDto
     */
    'hasSidePocket': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundTransactionDto
     */
    'isRolluped': boolean;
    /**
     * 
     * @type {Array<CreateHedgefundTransactionItemDto>}
     * @memberof CreateHedgefundTransactionDto
     */
    'fundTransactionItems': Array<CreateHedgefundTransactionItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionDto
     */
    'basedate': string | null;
    /**
     * 
     * @type {Liquidity}
     * @memberof CreateHedgefundTransactionDto
     */
    'liquidity': Liquidity;
    /**
     * 
     * @type {Array<SoftLockDtoBase>}
     * @memberof CreateHedgefundTransactionDto
     */
    'softLock': Array<SoftLockDtoBase> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundTransactionDtoTransactionStartTypeEnum {
    Invest = 'Invest',
    Transfer = 'Transfer',
    Opening = 'Opening',
    ClassSeriesChange = 'Class/Series Change',
    RollUp = 'Roll-up'
}

/**
 * 
 * @export
 * @interface CreateHedgefundTransactionItemDto
 */
export interface CreateHedgefundTransactionItemDto {
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'id': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'bookValueChange': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'accruedFee': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'transactionStatus': CreateHedgefundTransactionItemDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'transactionType': CreateHedgefundTransactionItemDtoTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'inputFrom': CreateHedgefundTransactionItemDtoInputFromEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'transferType'?: CreateHedgefundTransactionItemDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'redemptionMethod'?: CreateHedgefundTransactionItemDtoRedemptionMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'fundTransactionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'comment': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'isFullRedemption'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'navRedeemMoment'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateHedgefundTransactionItemDto
     */
    'bookValueRedeemMoment'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundTransactionItemDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundTransactionItemDtoTransactionTypeEnum {
    Invest = 'Invest',
    Redeem = 'Redeem',
    ClassSeriesChangeFrom = 'Class/Series Change (From)',
    ClassSeriesChangeTo = 'Class/Series Change (To)',
    Equalization = 'Equalization',
    Opening = 'Opening',
    Migration = 'Migration',
    Other = 'Other',
    AdjustmentForPerformanceFee = 'Adjustment for Performance Fee',
    ExtraDividend = 'Extra Dividend'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundTransactionItemDtoInputFromEnum {
    Amount = 'amount',
    Percentage = 'percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundTransactionItemDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateHedgefundTransactionItemDtoRedemptionMethodEnum {
    Fifo = 'FIFO',
    Lifo = 'LIFO',
    Prorata = 'Prorata',
    Manual = 'Manual'
}

/**
 * 
 * @export
 * @interface CreateHighWaterMarkDto
 */
export interface CreateHighWaterMarkDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHighWaterMarkDto
     */
    'year': string;
    /**
     * 
     * @type {number}
     * @memberof CreateHighWaterMarkDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHighWaterMarkDto
     */
    'hwm': number;
}
/**
 * 
 * @export
 * @interface CreateHoldingDto
 */
export interface CreateHoldingDto {
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingDto
     */
    'backstopId': number | null;
    /**
     * 
     * @type {CreateHoldingItemDto}
     * @memberof CreateHoldingDto
     */
    'holdingItem': CreateHoldingItemDto;
}
/**
 * 
 * @export
 * @interface CreateHoldingItemDto
 */
export interface CreateHoldingItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHoldingItemDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHoldingItemDto
     */
    'to': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingItemDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingItemDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingItemDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingItemDto
     */
    'holdingId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateHoldingItemDto
     */
    'fcode': string | null;
}
/**
 * 
 * @export
 * @interface CreateHoldingSettingDto
 */
export interface CreateHoldingSettingDto {
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingSettingDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingSettingDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateHoldingSettingDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateHoldingSettingDto
     */
    'from': string;
}
/**
 * 
 * @export
 * @interface CreateInputReturnDto
 */
export interface CreateInputReturnDto {
    /**
     * 
     * @type {string}
     * @memberof CreateInputReturnDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateInputReturnDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInputReturnDto
     */
    'fundClassId': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateInputReturnDto
     */
    'fundSeriesId': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateInputReturnDto
     */
    'portfolioId': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateInputReturnDto
     */
    'entryMethod': CreateInputReturnDtoEntryMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateInputReturnDto
     */
    'ratio': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateInputReturnDto
     */
    'inputMTD': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateInputReturnDto
     */
    'currency': CreateInputReturnDtoCurrencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInputReturnDto
     */
    'useStandardValue'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateInputReturnDtoEntryMethodEnum {
    Net = 'Net',
    GrossWithRatio = 'Gross With Ratio',
    GrossWithFee = 'Gross With Fee'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateInputReturnDtoCurrencyEnum {
    Cad = 'CAD',
    Krw = 'KRW',
    Twd = 'TWD',
    Inr = 'INR',
    Sgd = 'SGD',
    Myr = 'MYR',
    Idr = 'IDR',
    Vnd = 'VND',
    Thb = 'THB',
    Php = 'PHP',
    Pkr = 'PKR',
    Brl = 'BRL',
    Ars = 'ARS',
    Huf = 'HUF',
    Rub = 'RUB',
    Try = 'TRY',
    Zar = 'ZAR',
    Nok = 'NOK',
    Sek = 'SEK',
    Dkk = 'DKK',
    Nzd = 'NZD',
    Mxn = 'MXN',
    Aed = 'AED',
    Sar = 'SAR',
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface CreateInvestorDto
 */
export interface CreateInvestorDto {
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorDto
     */
    'investorType': CreateInvestorDtoInvestorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorDto
     */
    'investorSubType': CreateInvestorDtoInvestorSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorDto
     */
    'investorRegion': CreateInvestorDtoInvestorRegionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestorDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestorDto
     */
    'isDIM': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorDto
     */
    'comment': string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorDtoInvestorTypeEnum {
    Pension = 'Pension',
    FinancialInstitution = 'Financial Institution',
    Endowment = 'Endowment',
    Sovereign = 'Sovereign',
    Municipal = 'Municipal',
    Corporates = 'Corporates',
    FamilyOffice = 'Family Office',
    HighNetWorth = 'High Net Worth',
    Account = 'Account',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorDtoInvestorSubTypeEnum {
    Bank = 'Bank',
    RegionalBank = 'Regional Bank',
    InsuranceCompany = 'Insurance Company',
    SecurityFirm = 'Security Firm',
    Public = 'Public',
    Private = 'Private'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorDtoInvestorRegionEnum {
    Japan = 'Japan',
    AsiaExJapan = 'Asia ex Japan',
    Us = 'US',
    Europe = 'Europe',
    None = 'None',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface CreateInvestorTransactionDto
 */
export interface CreateInvestorTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionDto
     */
    'fundId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorTransactionDto
     */
    'transactionStartType': CreateInvestorTransactionDtoTransactionStartTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestorTransactionDto
     */
    'hasSidePocket': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionDto
     */
    'divisionId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionDto
     */
    'investorId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestorTransactionDto
     */
    'isInvestedByAccount': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionDto
     */
    'accountId': number;
    /**
     * 
     * @type {Array<CreateInvestorTransactionItemDto>}
     * @memberof CreateInvestorTransactionDto
     */
    'fundTransactionItems': Array<CreateInvestorTransactionItemDto> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorTransactionDtoTransactionStartTypeEnum {
    Invest = 'Invest',
    Transfer = 'Transfer',
    Opening = 'Opening',
    ClassSeriesChange = 'Class/Series Change',
    RollUp = 'Roll-up'
}

/**
 * 
 * @export
 * @interface CreateInvestorTransactionItemDto
 */
export interface CreateInvestorTransactionItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorTransactionItemDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionItemDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionItemDto
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionItemDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionItemDto
     */
    'bookValueChange': number;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorTransactionItemDto
     */
    'transactionStatus': CreateInvestorTransactionItemDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorTransactionItemDto
     */
    'transactionType': CreateInvestorTransactionItemDtoTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorTransactionItemDto
     */
    'inputFrom': CreateInvestorTransactionItemDtoInputFromEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestorTransactionItemDto
     */
    'isReinvest': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorTransactionItemDto
     */
    'transferType'?: CreateInvestorTransactionItemDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestorTransactionItemDto
     */
    'comment': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestorTransactionItemDto
     */
    'isRedeemAll': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionItemDto
     */
    'holdingId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestorTransactionItemDto
     */
    'holdingItemId': number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorTransactionItemDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorTransactionItemDtoTransactionTypeEnum {
    Invest = 'Invest',
    Redeem = 'Redeem',
    ClassSeriesChangeFrom = 'Class/Series Change (From)',
    ClassSeriesChangeTo = 'Class/Series Change (To)',
    Equalization = 'Equalization',
    Opening = 'Opening',
    Migration = 'Migration',
    Other = 'Other',
    AdjustmentForPerformanceFee = 'Adjustment for Performance Fee',
    ExtraDividend = 'Extra Dividend'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorTransactionItemDtoInputFromEnum {
    Amount = 'amount',
    Percentage = 'percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateInvestorTransactionItemDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}

/**
 * 
 * @export
 * @interface CreateOrganizationDto
 */
export interface CreateOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDto
     */
    'businessLocation': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDto
     */
    'establishDate': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationDto
     */
    'isInvAdvisorManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationDto
     */
    'isBroker': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationDto
     */
    'isAdministrator': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationDto
     */
    'isAuditor': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDto
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrganizationDto
     */
    'legacyCode': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDto
     */
    'legacyDataSource': CreateOrganizationDtoLegacyDataSourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOrganizationDtoLegacyDataSourceEnum {
    Auditor = 'AUDITOR',
    Manager = 'MANAGER',
    Broker = 'BROKER',
    Administrator = 'ADMINISTRATOR'
}

/**
 * 
 * @export
 * @interface CreatePermissionGroupDto
 */
export interface CreatePermissionGroupDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePermissionGroupDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePermissionGroupDto
     */
    'policyIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePermissionGroupDto
     */
    'sysUserIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CreatePolicyDto
 */
export interface CreatePolicyDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePolicyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePolicyDto
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface CreatePortfolioDto
 */
export interface CreatePortfolioDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePortfolioDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    'fiscalYearBeginningMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    'allocationCurrency': CreatePortfolioDtoAllocationCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    'legacyBkFcode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePortfolioDto
     */
    'accountId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePortfolioDto
     */
    'isInvestedFromFOFs': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePortfolioDto
     */
    'isInvestingIntoFOFsOrSingle': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreatePortfolioDtoAllocationCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface CreateRedeemInvTnxDto
 */
export interface CreateRedeemInvTnxDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRedeemInvTnxDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRedeemInvTnxDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRedeemInvTnxDto
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRedeemInvTnxDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRedeemInvTnxDto
     */
    'bookValueChange': number;
    /**
     * 
     * @type {string}
     * @memberof CreateRedeemInvTnxDto
     */
    'transactionStatus': CreateRedeemInvTnxDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateRedeemInvTnxDto
     */
    'transactionType': CreateRedeemInvTnxDtoTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateRedeemInvTnxDto
     */
    'inputFrom': CreateRedeemInvTnxDtoInputFromEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRedeemInvTnxDto
     */
    'isReinvest': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRedeemInvTnxDto
     */
    'transferType'?: CreateRedeemInvTnxDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateRedeemInvTnxDto
     */
    'comment': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRedeemInvTnxDto
     */
    'isRedeemAll': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CreateRedeemInvTnxDto
     */
    'holdingId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRedeemInvTnxDto
     */
    'holdingItemId': number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateRedeemInvTnxDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateRedeemInvTnxDtoTransactionTypeEnum {
    Invest = 'Invest',
    Redeem = 'Redeem',
    ClassSeriesChangeFrom = 'Class/Series Change (From)',
    ClassSeriesChangeTo = 'Class/Series Change (To)',
    Equalization = 'Equalization',
    Opening = 'Opening',
    Migration = 'Migration',
    Other = 'Other',
    AdjustmentForPerformanceFee = 'Adjustment for Performance Fee',
    ExtraDividend = 'Extra Dividend'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateRedeemInvTnxDtoInputFromEnum {
    Amount = 'amount',
    Percentage = 'percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateRedeemInvTnxDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}

/**
 * 
 * @export
 * @interface CreateRollupHistoryDto
 */
export interface CreateRollupHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof CreateRollupHistoryDto
     */
    'series_to_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateRollupHistoryDto
     */
    'fundSeriesId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRollupHistoryDto
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRollupHistoryDto
     */
    'basedate': string;
    /**
     * 
     * @type {Array<SeriesFromDto>}
     * @memberof CreateRollupHistoryDto
     */
    'seriesFrom': Array<SeriesFromDto>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof CreateRollupHistoryDto
     */
    'origins': Array<Array<number>>;
    /**
     * 
     * @type {CreateHedgefundSeriesDto}
     * @memberof CreateRollupHistoryDto
     */
    'fundSeries': CreateHedgefundSeriesDto;
}
/**
 * 
 * @export
 * @interface CreateStrategyDto
 */
export interface CreateStrategyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateStrategyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStrategyDto
     */
    'definition': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStrategyDto
     */
    'legacyCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateStrategyDto
     */
    'strategySource': CreateStrategyDtoStrategySourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateStrategyDtoStrategySourceEnum {
    Mcp = 'MCP',
    Hfr = 'HFR',
    CsTremont = 'CS/Tremont',
    Sp = 'S&P'
}

/**
 * 
 * @export
 * @interface CreateSystemInterestRateDto
 */
export interface CreateSystemInterestRateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSystemInterestRateDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSystemInterestRateDto
     */
    'currency': CreateSystemInterestRateDtoCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateSystemInterestRateDto
     */
    'tickerMasterId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSystemInterestRateDto
     */
    'indicatorType': CreateSystemInterestRateDtoIndicatorTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSystemInterestRateDtoCurrencyEnum {
    Cad = 'CAD',
    Krw = 'KRW',
    Twd = 'TWD',
    Inr = 'INR',
    Sgd = 'SGD',
    Myr = 'MYR',
    Idr = 'IDR',
    Vnd = 'VND',
    Thb = 'THB',
    Php = 'PHP',
    Pkr = 'PKR',
    Brl = 'BRL',
    Ars = 'ARS',
    Huf = 'HUF',
    Rub = 'RUB',
    Try = 'TRY',
    Zar = 'ZAR',
    Nok = 'NOK',
    Sek = 'SEK',
    Dkk = 'DKK',
    Nzd = 'NZD',
    Mxn = 'MXN',
    Aed = 'AED',
    Sar = 'SAR',
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateSystemInterestRateDtoIndicatorTypeEnum {
    HedgefundIndex = 'HedgefundIndex',
    MarketIndex = 'MarketIndex',
    InterestRate = 'InterestRate',
    ForeignExchange = 'ForeignExchange'
}

/**
 * 
 * @export
 * @interface CreateSysuserDto
 */
export interface CreateSysuserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'cognitoUserName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'sub': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'location': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSysuserDto
     */
    'isLocked': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'legacyUserCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'permissionGroups': CreateSysuserDtoPermissionGroupsEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateSysuserDto
     */
    'sysuserGroupIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserDto
     */
    'avatarOriginal': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSysuserDtoPermissionGroupsEnum {
    Administrators = 'Administrators',
    UserManager = 'User Manager',
    Operator = 'Operator',
    Reader = 'Reader',
    Analyst = 'Analyst',
    SeniorOperator = 'Senior Operator',
    Support = 'Support'
}

/**
 * 
 * @export
 * @interface CreateSysuserGroupDto
 */
export interface CreateSysuserGroupDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserGroupDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSysuserGroupDto
     */
    'sysLocation': CreateSysuserGroupDtoSysLocationEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateSysuserGroupDto
     */
    'sysUSersIds'?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSysuserGroupDtoSysLocationEnum {
    Tokyo = 'Tokyo',
    HongKong = 'Hong Kong',
    Chicago = 'Chicago',
    Seoul = 'Seoul'
}

/**
 * 
 * @export
 * @interface CurrencyAllocationGroupDto
 */
export interface CurrencyAllocationGroupDto {
    /**
     * 
     * @type {object}
     * @memberof CurrencyAllocationGroupDto
     */
    'currency': object;
    /**
     * 
     * @type {Array<AllocationFundClassDto>}
     * @memberof CurrencyAllocationGroupDto
     */
    'fundClasses': Array<AllocationFundClassDto>;
}
/**
 * 
 * @export
 * @interface CurrencyGroupDto
 */
export interface CurrencyGroupDto {
    /**
     * 
     * @type {object}
     * @memberof CurrencyGroupDto
     */
    'currency': object;
    /**
     * 
     * @type {Array<ClassDto>}
     * @memberof CurrencyGroupDto
     */
    'fundClasses': Array<ClassDto>;
}
/**
 * 
 * @export
 * @interface CurrencyGroupHWM
 */
export interface CurrencyGroupHWM {
    /**
     * 
     * @type {object}
     * @memberof CurrencyGroupHWM
     */
    'currency': object;
    /**
     * 
     * @type {Array<FundClassHWM>}
     * @memberof CurrencyGroupHWM
     */
    'fundClass': Array<FundClassHWM>;
}
/**
 * 
 * @export
 * @interface CurrencyGroupInvestorHoldingsDto
 */
export interface CurrencyGroupInvestorHoldingsDto {
    /**
     * 
     * @type {object}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'currency': object;
    /**
     * 
     * @type {Array<AccountInvestorHoldingsDto>}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'accounts': Array<AccountInvestorHoldingsDto>;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'reportShares'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'totalPL': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'unrealizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'realizedPL': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'totalDividend': number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'reportPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'bookValue': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'totalNavExEQ': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyGroupInvestorHoldingsDto
     */
    'totalNavInclEQ': number;
}
/**
 * 
 * @export
 * @interface CurrencyGroupInvestorNavBvDto
 */
export interface CurrencyGroupInvestorNavBvDto {
    /**
     * 
     * @type {object}
     * @memberof CurrencyGroupInvestorNavBvDto
     */
    'currency': object;
    /**
     * 
     * @type {Array<DataViewNavDto>}
     * @memberof CurrencyGroupInvestorNavBvDto
     */
    'navBvInvestorLevel': Array<DataViewNavDto>;
    /**
     * 
     * @type {Array<DivisionNavBvDto>}
     * @memberof CurrencyGroupInvestorNavBvDto
     */
    'divisions': Array<DivisionNavBvDto>;
}
/**
 * 
 * @export
 * @interface CurrencyGroupInvestorPerformanceDto
 */
export interface CurrencyGroupInvestorPerformanceDto {
    /**
     * 
     * @type {object}
     * @memberof CurrencyGroupInvestorPerformanceDto
     */
    'currency': object;
    /**
     * 
     * @type {Array<ReturnsDto>}
     * @memberof CurrencyGroupInvestorPerformanceDto
     */
    'perfInvestorLevel': Array<ReturnsDto>;
    /**
     * 
     * @type {Array<YtdDto>}
     * @memberof CurrencyGroupInvestorPerformanceDto
     */
    'ytdInvestorLevel': Array<YtdDto>;
    /**
     * 
     * @type {Array<DivisionPerformanceDto>}
     * @memberof CurrencyGroupInvestorPerformanceDto
     */
    'divisions': Array<DivisionPerformanceDto>;
}
/**
 * 
 * @export
 * @interface DataViewNavDto
 */
export interface DataViewNavDto {
    /**
     * 
     * @type {string}
     * @memberof DataViewNavDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof DataViewNavDto
     */
    'endingNAV': number;
    /**
     * 
     * @type {number}
     * @memberof DataViewNavDto
     */
    'endingBV'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DataViewNavDto
     */
    'isFinal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataViewNavDto
     */
    'isMonthly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataViewNavDto
     */
    'isWeekly': boolean;
}
/**
 * 
 * @export
 * @interface DataViewerAccountNavBvDto
 */
export interface DataViewerAccountNavBvDto {
    /**
     * 
     * @type {string}
     * @memberof DataViewerAccountNavBvDto
     */
    'basedate': string;
    /**
     * 
     * @type {boolean}
     * @memberof DataViewerAccountNavBvDto
     */
    'isWeekly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataViewerAccountNavBvDto
     */
    'isMonthly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataViewerAccountNavBvDto
     */
    'isFinal': boolean;
    /**
     * 
     * @type {number}
     * @memberof DataViewerAccountNavBvDto
     */
    'totalNavInclEQ': number;
    /**
     * 
     * @type {number}
     * @memberof DataViewerAccountNavBvDto
     */
    'bookValue': number;
}
/**
 * 
 * @export
 * @interface DetailPortTxnDto
 */
export interface DetailPortTxnDto {
    /**
     * 
     * @type {Liquidity}
     * @memberof DetailPortTxnDto
     */
    'liquidity': Liquidity;
    /**
     * 
     * @type {Array<SoftLockDtoBase>}
     * @memberof DetailPortTxnDto
     */
    'softLock': Array<SoftLockDtoBase>;
}
/**
 * 
 * @export
 * @interface DivisionNavBvDto
 */
export interface DivisionNavBvDto {
    /**
     * 
     * @type {Array<DataViewNavDto>}
     * @memberof DivisionNavBvDto
     */
    'navBvDivisionLevel': Array<DataViewNavDto>;
    /**
     * 
     * @type {number}
     * @memberof DivisionNavBvDto
     */
    'divisionId': number;
    /**
     * 
     * @type {string}
     * @memberof DivisionNavBvDto
     */
    'divisionName': string;
    /**
     * 
     * @type {string}
     * @memberof DivisionNavBvDto
     */
    'divisionAbbreviation': string;
}
/**
 * 
 * @export
 * @interface DivisionPerformanceDto
 */
export interface DivisionPerformanceDto {
    /**
     * 
     * @type {Array<YtdDto>}
     * @memberof DivisionPerformanceDto
     */
    'ytdDivisionLevel': Array<YtdDto>;
    /**
     * 
     * @type {Array<ReturnsDto>}
     * @memberof DivisionPerformanceDto
     */
    'perfDivisionLevel': Array<ReturnsDto>;
    /**
     * 
     * @type {number}
     * @memberof DivisionPerformanceDto
     */
    'divisionId': number;
    /**
     * 
     * @type {string}
     * @memberof DivisionPerformanceDto
     */
    'divisionName': string;
    /**
     * 
     * @type {string}
     * @memberof DivisionPerformanceDto
     */
    'divisionAbbreviation': string;
}
/**
 * 
 * @export
 * @interface ExposureBreakDownDto
 */
export interface ExposureBreakDownDto {
    /**
     * 
     * @type {string}
     * @memberof ExposureBreakDownDto
     */
    'basedate': string | null;
    /**
     * 
     * @type {string}
     * @memberof ExposureBreakDownDto
     */
    'fundOrAbbreviation': string | null;
    /**
     * 
     * @type {string}
     * @memberof ExposureBreakDownDto
     */
    'productName': ExposureBreakDownDtoProductNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ExposureBreakDownDto
     */
    'regionName': ExposureBreakDownDtoRegionNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ExposureBreakDownDto
     */
    'sectorName': ExposureBreakDownDtoSectorNameEnum;
    /**
     * 
     * @type {number}
     * @memberof ExposureBreakDownDto
     */
    'shortExposure': number | null;
    /**
     * 
     * @type {number}
     * @memberof ExposureBreakDownDto
     */
    'longExposure': number | null;
    /**
     * 
     * @type {number}
     * @memberof ExposureBreakDownDto
     */
    'capitalAllocation': number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ExposureBreakDownDtoProductNameEnum {
    Ir1bp = 'IR (/1bp)',
    Credit = 'Credit',
    Equity = 'Equity',
    Currency = 'Currency',
    Commodity = 'Commodity',
    Cb = 'CB',
    CreditSpread10 = 'Credit Spread (/10%)',
    EquityVol10 = 'Equity Vol (/10%)',
    Other = 'Other',
    General = 'General'
}
/**
    * @export
    * @enum {string}
    */
export enum ExposureBreakDownDtoRegionNameEnum {
    Us = 'US',
    Europe = 'Europe',
    Japan = 'Japan',
    Emerging = 'Emerging',
    Global = 'Global',
    Latin = 'Latin',
    Emea = 'EMEA',
    Asia = 'Asia',
    Australia = 'Australia',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum ExposureBreakDownDtoSectorNameEnum {
    Energy = 'Energy',
    Materials = 'Materials',
    Industrials = 'Industrials',
    ConsumerDiscretionary = 'Consumer Discretionary',
    ConsumerStaples = 'Consumer Staples',
    HealthCare = 'Health Care',
    Financials = 'Financials',
    InformationTechnology = 'Information Technology',
    TelecomService = 'Telecom Service',
    Utilities = 'Utilities',
    Market = 'Market',
    Other = 'Other',
    _1Y = '1Y',
    _2Y = '2Y',
    _5Y = '5Y',
    _10Y = '10Y',
    _20Y = '20Y',
    LoanIg = 'Loan IG',
    BondIg = 'Bond IG',
    CdsIg = 'CDS IG',
    LoanHy = 'Loan HY',
    BondHy = 'Bond HY',
    CdsHy = 'CDS HY',
    LoanDefaulted = 'Loan Defaulted',
    BondDefaulted = 'Bond Defaulted',
    PreciousMetal = 'Precious Metal',
    BaseMetal = 'Base Metal',
    OilGas = 'Oil Gas',
    Agricultural = 'Agricultural'
}

/**
 * 
 * @export
 * @interface FundClassHWM
 */
export interface FundClassHWM {
    /**
     * 
     * @type {Array<FundSeriesHWM>}
     * @memberof FundClassHWM
     */
    'fundSeries': Array<FundSeriesHWM>;
    /**
     * 
     * @type {number}
     * @memberof FundClassHWM
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof FundClassHWM
     */
    'fundClassName': string;
    /**
     * 
     * @type {string}
     * @memberof FundClassHWM
     */
    'fundClassAbbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof FundClassHWM
     */
    'isSeriesAccounting'?: boolean;
}
/**
 * 
 * @export
 * @interface FundSeriesHWM
 */
export interface FundSeriesHWM {
    /**
     * 
     * @type {number}
     * @memberof FundSeriesHWM
     */
    'hwm': number;
    /**
     * 
     * @type {number}
     * @memberof FundSeriesHWM
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof FundSeriesHWM
     */
    'fundSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof FundSeriesHWM
     */
    'fundSeriesAbbreviation': string;
}
/**
 * 
 * @export
 * @interface HedgefundHistoriccalReturnDto
 */
export interface HedgefundHistoriccalReturnDto {
    /**
     * 
     * @type {string}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'netMtd': number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'netFundingMtd': number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'netWtd': number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'netFundingWtd': number;
    /**
     * 
     * @type {boolean}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'monthly': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'weekly': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'hedgefundId'?: number;
    /**
     * 
     * @type {object}
     * @memberof HedgefundHistoriccalReturnDto
     */
    'currency': object;
}
/**
 * 
 * @export
 * @interface HedgefundRedeemDto
 */
export interface HedgefundRedeemDto {
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'bookValueChange'?: number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'share'?: number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'accruedFee'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'transactionStatus'?: HedgefundRedeemDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'transactionType'?: HedgefundRedeemDtoTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'inputFrom'?: HedgefundRedeemDtoInputFromEnum;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'transferType'?: HedgefundRedeemDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'redemptionMethod'?: HedgefundRedeemDtoRedemptionMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'fundTransactionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HedgefundRedeemDto
     */
    'isFullRedemption'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'navRedeemMoment'?: number;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'bookValueRedeemMoment'?: number;
    /**
     * 
     * @type {string}
     * @memberof HedgefundRedeemDto
     */
    'txnHash'?: string;
    /**
     * 
     * @type {number}
     * @memberof HedgefundRedeemDto
     */
    'holdingId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum HedgefundRedeemDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum HedgefundRedeemDtoTransactionTypeEnum {
    Invest = 'Invest',
    Redeem = 'Redeem',
    ClassSeriesChangeFrom = 'Class/Series Change (From)',
    ClassSeriesChangeTo = 'Class/Series Change (To)',
    Equalization = 'Equalization',
    Opening = 'Opening',
    Migration = 'Migration',
    Other = 'Other',
    AdjustmentForPerformanceFee = 'Adjustment for Performance Fee',
    ExtraDividend = 'Extra Dividend'
}
/**
    * @export
    * @enum {string}
    */
export enum HedgefundRedeemDtoInputFromEnum {
    Amount = 'amount',
    Percentage = 'percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum HedgefundRedeemDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}
/**
    * @export
    * @enum {string}
    */
export enum HedgefundRedeemDtoRedemptionMethodEnum {
    Fifo = 'FIFO',
    Lifo = 'LIFO',
    Prorata = 'Prorata',
    Manual = 'Manual'
}

/**
 * 
 * @export
 * @interface Liquidity
 */
export interface Liquidity {
    /**
     * 
     * @type {string}
     * @memberof Liquidity
     */
    'effectiveDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof Liquidity
     */
    'gateType': LiquidityGateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Liquidity
     */
    'gatePercentage': number;
    /**
     * 
     * @type {string}
     * @memberof Liquidity
     */
    'redemptionFrequency': LiquidityRedemptionFrequencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Liquidity
     */
    'isMonthEnd': boolean;
    /**
     * 
     * @type {string}
     * @memberof Liquidity
     */
    'noticePeriod': LiquidityNoticePeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof Liquidity
     */
    'noticeDays': number;
    /**
     * 
     * @type {boolean}
     * @memberof Liquidity
     */
    'hasGateType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Liquidity
     */
    'hasHardLockup': boolean;
    /**
     * 
     * @type {number}
     * @memberof Liquidity
     */
    'hardLockupMonths'?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum LiquidityGateTypeEnum {
    FundNav = 'Fund NAV',
    MasterFundNav = 'Master Fund NAV',
    InvestmentAmount = 'Investment Amount',
    OutstandingShare = 'Outstanding Share'
}
/**
    * @export
    * @enum {string}
    */
export enum LiquidityRedemptionFrequencyEnum {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Biweekly = 'Biweekly',
    Monthly = 'Monthly',
    Bimonthly = 'Bimonthly',
    Bimonthly24681012EndOnly = 'Bimonthly (2, 4, 6, 8, 10, 12 End Only)',
    Qa36912EndOnly = 'QA (3, 6, 9, 12 End Only)',
    QaAnyMonth = 'QA (Any Month)',
    Sa612EndOnly = 'SA (6, 12 End Only)',
    SaAnyMonth = 'SA (Any Month)',
    Pa12EndOnly = 'PA (12 End Only)',
    PaAnyMonth = 'PA (Any Month)',
    _25Y = '2.5 Y',
    MonExcQe = 'Mon exc QE'
}
/**
    * @export
    * @enum {string}
    */
export enum LiquidityNoticePeriodEnum {
    CalendarDays = 'Calendar Days',
    BusinessDay = 'Business Day'
}

/**
 * 
 * @export
 * @interface NavBvDto
 */
export interface NavBvDto {
    /**
     * 
     * @type {object}
     * @memberof NavBvDto
     */
    'currency': object;
    /**
     * 
     * @type {Array<DataViewNavDto>}
     * @memberof NavBvDto
     */
    'navBv': Array<DataViewNavDto>;
}
/**
 * 
 * @export
 * @interface OrganizationRelation
 */
export interface OrganizationRelation {
    /**
     * 
     * @type {number}
     * @memberof OrganizationRelation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRelation
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRelation
     */
    'to': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationRelation
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRelation
     */
    'organizationId': number;
}
/**
 * 
 * @export
 * @interface PerformanceDto
 */
export interface PerformanceDto {
    /**
     * 
     * @type {object}
     * @memberof PerformanceDto
     */
    'currency': object;
    /**
     * 
     * @type {Array<ReturnsDto>}
     * @memberof PerformanceDto
     */
    'returns': Array<ReturnsDto>;
    /**
     * 
     * @type {Array<YtdDto>}
     * @memberof PerformanceDto
     */
    'ytds'?: Array<YtdDto>;
    /**
     * 
     * @type {number}
     * @memberof PerformanceDto
     */
    'ltd': number;
}
/**
 * 
 * @export
 * @interface PortfolioDto
 */
export interface PortfolioDto {
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    'ytd': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    'netMtd': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    'hwm'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    'bookValue': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    'endingNAV': number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioDto
     */
    'isFinal': boolean;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioDto
     */
    'portfolioName': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioDto
     */
    'portfolioAbbreviation': string;
}
/**
 * 
 * @export
 * @interface RedeemCalcDto
 */
export interface RedeemCalcDto {
    /**
     * 
     * @type {Array<VirtualNavBvDto>}
     * @memberof RedeemCalcDto
     */
    'txnNavBv': Array<VirtualNavBvDto>;
    /**
     * 
     * @type {number}
     * @memberof RedeemCalcDto
     */
    'redeemAmount': number;
    /**
     * 
     * @type {string}
     * @memberof RedeemCalcDto
     */
    'redeemLogic': RedeemCalcDtoRedeemLogicEnum;
    /**
     * 
     * @type {number}
     * @memberof RedeemCalcDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {string}
     * @memberof RedeemCalcDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof RedeemCalcDto
     */
    'unitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof RedeemCalcDto
     */
    'averagePrice': number;
}

/**
    * @export
    * @enum {string}
    */
export enum RedeemCalcDtoRedeemLogicEnum {
    Fifo = 'FIFO',
    Lifo = 'LIFO',
    Prorata = 'Prorata',
    Manual = 'Manual'
}

/**
 * 
 * @export
 * @interface RegisterRequestDto
 */
export interface RegisterRequestDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'location': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterRequestDto
     */
    'isLocked': boolean;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'legacyUserCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'permissionGroups': RegisterRequestDtoPermissionGroupsEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'avatarOriginal': string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'avatarSmall': string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'avatarMedium': string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'avatarLarge': string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum RegisterRequestDtoPermissionGroupsEnum {
    Administrators = 'Administrators',
    UserManager = 'User Manager',
    Operator = 'Operator',
    Reader = 'Reader',
    Analyst = 'Analyst',
    SeniorOperator = 'Senior Operator',
    Support = 'Support'
}

/**
 * 
 * @export
 * @interface ResendRequestDto
 */
export interface ResendRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ResendRequestDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ResponseAccountInvestorTxnDto
 */
export interface ResponseAccountInvestorTxnDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'investorId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'investorName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'investorAbbreviaion': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'divisionId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'divisionName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'divisionAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'txnItemId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'txnHash'?: string;
    /**
     * 
     * @type {object}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'transactionStatus': object;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'transactionType': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'bookValueChange': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundClassName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundClassAbbreviation': string;
    /**
     * 
     * @type {object}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'currency': object;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountInvestorTxnDto
     */
    'fundSeriesAbbreviation': string;
}
/**
 * 
 * @export
 * @interface ResponseAccountListDto
 */
export interface ResponseAccountListDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountListDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountListDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseAccountListDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseAccountListDto
     */
    'accountStrategyType': object;
}
/**
 * 
 * @export
 * @interface ResponseAccountNavBvDto
 */
export interface ResponseAccountNavBvDto {
    /**
     * 
     * @type {Array<DataViewerAccountNavBvDto>}
     * @memberof ResponseAccountNavBvDto
     */
    'navbvs': Array<DataViewerAccountNavBvDto>;
}
/**
 * 
 * @export
 * @interface ResponseAccountPerformanceDto
 */
export interface ResponseAccountPerformanceDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPerformanceDto
     */
    'accountSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPerformanceDto
     */
    'accountSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPerformanceDto
     */
    'accountSeriesAbbreviation': string;
    /**
     * 
     * @type {Array<AccountPerformanceDto>}
     * @memberof ResponseAccountPerformanceDto
     */
    'performance': Array<AccountPerformanceDto>;
    /**
     * 
     * @type {Array<AccountYTDDto>}
     * @memberof ResponseAccountPerformanceDto
     */
    'ytd'?: Array<AccountYTDDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPerformanceDto
     */
    'ltd': number;
}
/**
 * 
 * @export
 * @interface ResponseAccountPortTxnDto
 */
export interface ResponseAccountPortTxnDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPortTxnDto
     */
    'txnItemId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'txnHash'?: string;
    /**
     * 
     * @type {object}
     * @memberof ResponseAccountPortTxnDto
     */
    'transactionStatus': object;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'transactionType': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPortTxnDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPortTxnDto
     */
    'bookValueChange': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPortTxnDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'portfolioName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'portfolioAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundClassName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundClassAbbreviation': string;
    /**
     * 
     * @type {object}
     * @memberof ResponseAccountPortTxnDto
     */
    'currency': object;
    /**
     * 
     * @type {number}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAccountPortTxnDto
     */
    'fundSeriesAbbreviation': string;
}
/**
 * 
 * @export
 * @interface ResponseAllocationDto
 */
export interface ResponseAllocationDto {
    /**
     * 
     * @type {ResponseAllocationDtoCash}
     * @memberof ResponseAllocationDto
     */
    'cash': ResponseAllocationDtoCash;
    /**
     * 
     * @type {Array<ResponseAllocationMountDto>}
     * @memberof ResponseAllocationDto
     */
    'allocations': Array<ResponseAllocationMountDto>;
}
/**
 * 
 * @export
 * @interface ResponseAllocationDtoCash
 */
export interface ResponseAllocationDtoCash {
    /**
     * 
     * @type {number}
     * @memberof ResponseAllocationDtoCash
     */
    'cashNav'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAllocationDtoCash
     */
    'cashAllocation'?: number;
}
/**
 * 
 * @export
 * @interface ResponseAllocationMountDto
 */
export interface ResponseAllocationMountDto {
    /**
     * 
     * @type {Array<CurrencyAllocationGroupDto>}
     * @memberof ResponseAllocationMountDto
     */
    'currencyGroup': Array<CurrencyAllocationGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponseAllocationMountDto
     */
    'fundId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAllocationMountDto
     */
    'fundName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAllocationMountDto
     */
    'fundAbbreviation': string;
}
/**
 * 
 * @export
 * @interface ResponseCreateAccountClassDto
 */
export interface ResponseCreateAccountClassDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof ResponseCreateAccountClassDto
     */
    'softLock': Array<object> | null;
    /**
     * 
     * @type {object}
     * @memberof ResponseCreateAccountClassDto
     */
    'fundClass': object;
    /**
     * 
     * @type {Array<object>}
     * @memberof ResponseCreateAccountClassDto
     */
    'fundSeries': Array<object> | null;
}
/**
 * 
 * @export
 * @interface ResponseCreateHedgefundClassDto
 */
export interface ResponseCreateHedgefundClassDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof ResponseCreateHedgefundClassDto
     */
    'softLock': Array<object> | null;
    /**
     * 
     * @type {object}
     * @memberof ResponseCreateHedgefundClassDto
     */
    'fundClass': object;
    /**
     * 
     * @type {Array<object>}
     * @memberof ResponseCreateHedgefundClassDto
     */
    'fundSeries': Array<object> | null;
}
/**
 * 
 * @export
 * @interface ResponseCreateRollupHistoryDto
 */
export interface ResponseCreateRollupHistoryDto {
    /**
     * 
     * @type {object}
     * @memberof ResponseCreateRollupHistoryDto
     */
    'fundSeries': object;
    /**
     * 
     * @type {object}
     * @memberof ResponseCreateRollupHistoryDto
     */
    'rollupHistory': object;
}
/**
 * 
 * @export
 * @interface ResponseHighWaterMarkDto
 */
export interface ResponseHighWaterMarkDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseHighWaterMarkDto
     */
    'baseYear': string;
    /**
     * 
     * @type {Array<CurrencyGroupHWM>}
     * @memberof ResponseHighWaterMarkDto
     */
    'groupByCurrency': Array<CurrencyGroupHWM>;
}
/**
 * 
 * @export
 * @interface ResponseHoldingsDto
 */
export interface ResponseHoldingsDto {
    /**
     * 
     * @type {Array<CurrencyGroupDto>}
     * @memberof ResponseHoldingsDto
     */
    'groupedByCcy': Array<CurrencyGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponseHoldingsDto
     */
    'fundId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseHoldingsDto
     */
    'fundName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseHoldingsDto
     */
    'fundAbbreviation': string;
}
/**
 * 
 * @export
 * @interface ResponseInvestorHoldingsDto
 */
export interface ResponseInvestorHoldingsDto {
    /**
     * 
     * @type {Array<CurrencyGroupInvestorHoldingsDto>}
     * @memberof ResponseInvestorHoldingsDto
     */
    'currencyGroup': Array<CurrencyGroupInvestorHoldingsDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponseInvestorHoldingsDto
     */
    'divisionId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorHoldingsDto
     */
    'divisionName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorHoldingsDto
     */
    'divisionAbbreviation': string;
}
/**
 * 
 * @export
 * @interface ResponseInvestorListDto
 */
export interface ResponseInvestorListDto {
    /**
     * 
     * @type {boolean}
     * @memberof ResponseInvestorListDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponseInvestorListDto
     */
    'investorId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorListDto
     */
    'investorName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorListDto
     */
    'investorAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseInvestorListDto
     */
    'asAccount': number;
}
/**
 * 
 * @export
 * @interface ResponseInvestorNavBvDto
 */
export interface ResponseInvestorNavBvDto {
    /**
     * 
     * @type {Array<CurrencyGroupInvestorNavBvDto>}
     * @memberof ResponseInvestorNavBvDto
     */
    'currencyGroup': Array<CurrencyGroupInvestorNavBvDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponseInvestorNavBvDto
     */
    'investorId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorNavBvDto
     */
    'investorName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorNavBvDto
     */
    'investorAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseInvestorNavBvDto
     */
    'asAccount': number;
}
/**
 * 
 * @export
 * @interface ResponseInvestorPerformanceDto
 */
export interface ResponseInvestorPerformanceDto {
    /**
     * 
     * @type {Array<CurrencyGroupInvestorPerformanceDto>}
     * @memberof ResponseInvestorPerformanceDto
     */
    'currencyGrouped': Array<CurrencyGroupInvestorPerformanceDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponseInvestorPerformanceDto
     */
    'investorId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorPerformanceDto
     */
    'investorName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvestorPerformanceDto
     */
    'investorAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseInvestorPerformanceDto
     */
    'asAccount': number;
}
/**
 * 
 * @export
 * @interface ResponseListDto
 */
export interface ResponseListDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseListDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseListDto
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface ResponseMountingHedgefundHistoricalDto
 */
export interface ResponseMountingHedgefundHistoricalDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseMountingHedgefundHistoricalDto
     */
    'fromDefaultValue': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof ResponseMountingHedgefundHistoricalDto
     */
    'currencies': Array<object>;
}
/**
 * 
 * @export
 * @interface ResponseNavBvDto
 */
export interface ResponseNavBvDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseNavBvDto
     */
    'accountId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseNavBvDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {Array<NavBvDto>}
     * @memberof ResponseNavBvDto
     */
    'navbvByCurrency': Array<NavBvDto>;
}
/**
 * 
 * @export
 * @interface ResponsePerformanceCommentDto
 */
export interface ResponsePerformanceCommentDto {
    /**
     * 
     * @type {number}
     * @memberof ResponsePerformanceCommentDto
     */
    'accountId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePerformanceCommentDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {Array<PerformanceDto>}
     * @memberof ResponsePerformanceCommentDto
     */
    'performance': Array<PerformanceDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortTxnDto
 */
export interface ResponsePortTxnDto {
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'id': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'bookValueChange': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'accruedFee': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'transactionStatus': ResponsePortTxnDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'transactionType': ResponsePortTxnDtoTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'inputFrom': ResponsePortTxnDtoInputFromEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'transferType'?: ResponsePortTxnDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'redemptionMethod'?: ResponsePortTxnDtoRedemptionMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'fundTransactionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'comment': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortTxnDto
     */
    'isFullRedemption'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'navRedeemMoment'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'bookValueRedeemMoment'?: number;
    /**
     * 
     * @type {DetailPortTxnDto}
     * @memberof ResponsePortTxnDto
     */
    'detail': DetailPortTxnDto;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'fundId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'txnHash': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'portfolioName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'portfolioAbbreviation': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortTxnDto
     */
    'isSeriesAccounting': boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponsePortTxnDto
     */
    'currency': object;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'fundClassId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'fundClassName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'fundClassAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'fundSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortTxnDto
     */
    'fundSeriesAbbreviation': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortTxnDto
     */
    'txnItemId': number;
}

/**
    * @export
    * @enum {string}
    */
export enum ResponsePortTxnDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum ResponsePortTxnDtoTransactionTypeEnum {
    Invest = 'Invest',
    Redeem = 'Redeem',
    ClassSeriesChangeFrom = 'Class/Series Change (From)',
    ClassSeriesChangeTo = 'Class/Series Change (To)',
    Equalization = 'Equalization',
    Opening = 'Opening',
    Migration = 'Migration',
    Other = 'Other',
    AdjustmentForPerformanceFee = 'Adjustment for Performance Fee',
    ExtraDividend = 'Extra Dividend'
}
/**
    * @export
    * @enum {string}
    */
export enum ResponsePortTxnDtoInputFromEnum {
    Amount = 'amount',
    Percentage = 'percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum ResponsePortTxnDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}
/**
    * @export
    * @enum {string}
    */
export enum ResponsePortTxnDtoRedemptionMethodEnum {
    Fifo = 'FIFO',
    Lifo = 'LIFO',
    Prorata = 'Prorata',
    Manual = 'Manual'
}

/**
 * 
 * @export
 * @interface ResponseUpdateHedgefundClassDto
 */
export interface ResponseUpdateHedgefundClassDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof ResponseUpdateHedgefundClassDto
     */
    'softLock': Array<object> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof ResponseUpdateHedgefundClassDto
     */
    'fundSeries': Array<object> | null;
    /**
     * 
     * @type {object}
     * @memberof ResponseUpdateHedgefundClassDto
     */
    'updateResult': object;
}
/**
 * 
 * @export
 * @interface ReturnDuplicateDto
 */
export interface ReturnDuplicateDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnDuplicateDto
     */
    'duplicateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDuplicateDto
     */
    'duplicateTo': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnDuplicateDto
     */
    'toBeOverwrite': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnDuplicateDto
     */
    'hedgefundLevel': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnDuplicateDto
     */
    'holdingLevel': boolean;
}
/**
 * 
 * @export
 * @interface ReturnsDto
 */
export interface ReturnsDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnsDto
     */
    'basedate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnsDto
     */
    'isMonthly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnsDto
     */
    'isWeekly': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReturnsDto
     */
    'netFundingMtd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReturnsDto
     */
    'netWtd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReturnsDto
     */
    'netMtd': number | null;
    /**
     * 
     * @type {number}
     * @memberof ReturnsDto
     */
    'netFundingWtd'?: number | null;
}
/**
 * 
 * @export
 * @interface SeriesDto
 */
export interface SeriesDto {
    /**
     * 
     * @type {Array<PortfolioDto>}
     * @memberof SeriesDto
     */
    'portfolios': Array<PortfolioDto>;
    /**
     * 
     * @type {number}
     * @memberof SeriesDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {string}
     * @memberof SeriesDto
     */
    'fundSeriesName': string;
    /**
     * 
     * @type {string}
     * @memberof SeriesDto
     */
    'fundSeriesAbbreviation': string;
}
/**
 * 
 * @export
 * @interface SeriesFromDto
 */
export interface SeriesFromDto {
    /**
     * 
     * @type {number}
     * @memberof SeriesFromDto
     */
    'seriesId': number;
}
/**
 * 
 * @export
 * @interface ServiceProvider
 */
export interface ServiceProvider {
    /**
     * 
     * @type {number}
     * @memberof ServiceProvider
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvider
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvider
     */
    'to': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProvider
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceProvider
     */
    'organizationId': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvider
     */
    'serviceType': ServiceProviderServiceTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceProviderServiceTypeEnum {
    Administrator = 'Administrator',
    Auditor = 'Auditor',
    Broker = 'Broker'
}

/**
 * 
 * @export
 * @interface SoftLockDtoBase
 */
export interface SoftLockDtoBase {
    /**
     * 
     * @type {number}
     * @memberof SoftLockDtoBase
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SoftLockDtoBase
     */
    'entityType': SoftLockDtoBaseEntityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SoftLockDtoBase
     */
    'fundClassId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SoftLockDtoBase
     */
    'fundTransactionId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SoftLockDtoBase
     */
    'months': number;
    /**
     * 
     * @type {number}
     * @memberof SoftLockDtoBase
     */
    'fee': number;
    /**
     * 
     * @type {string}
     * @memberof SoftLockDtoBase
     */
    'softLockEndDate': string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SoftLockDtoBaseEntityTypeEnum {
    FundTransaction = 'FundTransaction',
    FundClass = 'FundClass'
}

/**
 * 
 * @export
 * @interface StrategyRelation
 */
export interface StrategyRelation {
    /**
     * 
     * @type {number}
     * @memberof StrategyRelation
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StrategyRelation
     */
    'strategyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof StrategyRelation
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof StrategyRelation
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof StrategyRelation
     */
    'to': string | null;
}
/**
 * 
 * @export
 * @interface TickerRawdataDto
 */
export interface TickerRawdataDto {
    /**
     * 
     * @type {number}
     * @memberof TickerRawdataDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TickerRawdataDto
     */
    'tickerMasterId': number;
    /**
     * 
     * @type {string}
     * @memberof TickerRawdataDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof TickerRawdataDto
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface UpdateAccountClassDto
 */
export interface UpdateAccountClassDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountClassDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountClassDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountClassDto
     */
    'isSeriesAccounting'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountClassDto
     */
    'currency'?: UpdateAccountClassDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountClassDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountClassDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountClassDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountClassDto
     */
    'managementFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountClassDto
     */
    'performanceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountClassDto
     */
    'hurdleRate'?: number | null;
    /**
     * 
     * @type {Liquidity}
     * @memberof UpdateAccountClassDto
     */
    'liquidity'?: Liquidity;
    /**
     * 
     * @type {Array<SoftLockDtoBase>}
     * @memberof UpdateAccountClassDto
     */
    'softLock'?: Array<SoftLockDtoBase> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountClassDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountClassDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountClassDto
     */
    'portfolioId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountClassDto
     */
    'legacyCode'?: string;
    /**
     * 
     * @type {Array<CreateAccountSeriesDto>}
     * @memberof UpdateAccountClassDto
     */
    'fundSeries'?: Array<CreateAccountSeriesDto> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateAccountClassDtoCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface UpdateAccountDto
 */
export interface UpdateAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountDto
     */
    'cityId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Array<StrategyRelation>}
     * @memberof UpdateAccountDto
     */
    'strategies'?: Array<StrategyRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof UpdateAccountDto
     */
    'investmentManager'?: Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof UpdateAccountDto
     */
    'investmentAdvisor'?: Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<ServiceProvider>}
     * @memberof UpdateAccountDto
     */
    'serviceProviders'?: Array<ServiceProvider>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'businessLocation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'operatedByMCP'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'operationStyle'?: UpdateAccountDtoOperationStyleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'isAccount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'isHedgeFund'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'accountStrategyType'?: UpdateAccountDtoAccountStrategyTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateAccountDtoOperationStyleEnum {
    Self = 'Self',
    Delegate = 'Delegate'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateAccountDtoAccountStrategyTypeEnum {
    FoFs = 'FoFs',
    Single = 'Single'
}

/**
 * 
 * @export
 * @interface UpdateAccountNavEstimateDto
 */
export interface UpdateAccountNavEstimateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountNavEstimateDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'endingNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'reportNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'baseNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'lastMonthNavId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'portfolioId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'investorId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'divisionId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountNavEstimateDto
     */
    'isFinal'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'reportPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'reportShares'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'amountEstimate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'amountFinal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountNavEstimateDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'eqCreditId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'netMTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'netWTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'netFundingMTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavEstimateDto
     */
    'netFundingWTD'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateAccountNavFinalDto
 */
export interface UpdateAccountNavFinalDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountNavFinalDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'endingNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'reportNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'baseNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'lastMonthNavId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'portfolioId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'investorId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'divisionId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountNavFinalDto
     */
    'isFinal'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'reportPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'reportShares'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'amountEstimate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'amountFinal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountNavFinalDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'eqCreditId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'netMTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'netWTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'netFundingMTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountNavFinalDto
     */
    'netFundingWTD'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateAccountSeriesDto
 */
export interface UpdateAccountSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSeriesDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSeriesDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSeriesDto
     */
    'portfolioId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountSeriesDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountSeriesDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountSeriesDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountSeriesDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSeriesDto
     */
    'managementFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSeriesDto
     */
    'performanceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSeriesDto
     */
    'hurdleRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountSeriesDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountSeriesDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateAllocationDto
 */
export interface UpdateAllocationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationDto
     */
    'portfolioId'?: number;
    /**
     * 
     * @type {Array<CreateAllocationItemDto>}
     * @memberof UpdateAllocationDto
     */
    'allocationItems'?: Array<CreateAllocationItemDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationDto
     */
    'totalAmount'?: number;
}
/**
 * 
 * @export
 * @interface UpdateAllocationItemDto
 */
export interface UpdateAllocationItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationItemDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationItemDto
     */
    'holdingSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationItemDto
     */
    'navAfterTxn'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationItemDto
     */
    'navDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationItemDto
     */
    'navFlag'?: UpdateAllocationItemDtoNavFlagEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationItemDto
     */
    'allocationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationItemDto
     */
    'ytd'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationItemDto
     */
    'ltd'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAllocationItemDto
     */
    'hwm'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateAllocationItemDtoNavFlagEnum {
    Estimate = 'Estimate',
    Final = 'Final'
}

/**
 * 
 * @export
 * @interface UpdateBulkHedgefundHistoricalReturnDto
 */
export interface UpdateBulkHedgefundHistoricalReturnDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkHedgefundHistoricalReturnDto
     */
    'currency': string;
    /**
     * 
     * @type {Array<UpdateHedgefundHistoricalReturnDto>}
     * @memberof UpdateBulkHedgefundHistoricalReturnDto
     */
    'payload': Array<UpdateHedgefundHistoricalReturnDto>;
}
/**
 * 
 * @export
 * @interface UpdateCityDto
 */
export interface UpdateCityDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCityDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCityDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCityDto
     */
    'legacyCityCode'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateClassSeriesChangeDto
 */
export interface UpdateClassSeriesChangeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateClassSeriesChangeDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateClassSeriesChangeDto
     */
    'portfolioId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateClassSeriesChangeDto
     */
    'fundId': number;
    /**
     * 
     * @type {Array<ChangeFromDto>}
     * @memberof UpdateClassSeriesChangeDto
     */
    'changeFrom': Array<ChangeFromDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateClassSeriesChangeDto
     */
    'changeToSeriesId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateClassSeriesChangeDto
     */
    'fundTransactionId': number;
    /**
     * 
     * @type {Array<ClsFundTransactionItemDto>}
     * @memberof UpdateClassSeriesChangeDto
     */
    'fundTransactionItems': Array<ClsFundTransactionItemDto>;
    /**
     * 
     * @type {ClsFundTransactionItemDto}
     * @memberof UpdateClassSeriesChangeDto
     */
    'liquidity': ClsFundTransactionItemDto;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateClassSeriesChangeDto
     */
    'softLock': Array<object>;
}
/**
 * 
 * @export
 * @interface UpdateDividendDto
 */
export interface UpdateDividendDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'investorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'divisionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDividendDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'dividendAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'dividendPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDividendDto
     */
    'extraDivBvChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDividendDto
     */
    'transactionStatus'?: UpdateDividendDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDividendDto
     */
    'comment'?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateDividendDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}

/**
 * 
 * @export
 * @interface UpdateDivisionDto
 */
export interface UpdateDivisionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDivisionDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDivisionDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDivisionDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDivisionDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateDivisionDto
     */
    'investorId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDoubleCheckTrackDto
 */
export interface UpdateDoubleCheckTrackDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDoubleCheckTrackDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDoubleCheckTrackDto
     */
    'entityId'?: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateDoubleCheckTrackDto
     */
    'entityType'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateDoubleCheckTrackDto
     */
    'checkTarget'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDoubleCheckTrackDto
     */
    'allChecked'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateHedgefundClassDto
 */
export interface UpdateHedgefundClassDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundClassDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundClassDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundClassDto
     */
    'isSeriesAccounting'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundClassDto
     */
    'currency'?: UpdateHedgefundClassDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundClassDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundClassDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundClassDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundClassDto
     */
    'managementFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundClassDto
     */
    'performanceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundClassDto
     */
    'hurdleRate'?: number | null;
    /**
     * 
     * @type {Liquidity}
     * @memberof UpdateHedgefundClassDto
     */
    'liquidity'?: Liquidity;
    /**
     * 
     * @type {Array<SoftLockDtoBase>}
     * @memberof UpdateHedgefundClassDto
     */
    'softLock'?: Array<SoftLockDtoBase> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundClassDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundClassDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {Array<CreateHedgefundSeriesDto>}
     * @memberof UpdateHedgefundClassDto
     */
    'fundSeries'?: Array<CreateHedgefundSeriesDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundClassDtoCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface UpdateHedgefundDto
 */
export interface UpdateHedgefundDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundDto
     */
    'cityId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Array<StrategyRelation>}
     * @memberof UpdateHedgefundDto
     */
    'strategies'?: Array<StrategyRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof UpdateHedgefundDto
     */
    'investmentManager'?: Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<OrganizationRelation>}
     * @memberof UpdateHedgefundDto
     */
    'investmentAdvisor'?: Array<OrganizationRelation>;
    /**
     * 
     * @type {Array<ServiceProvider>}
     * @memberof UpdateHedgefundDto
     */
    'serviceProviders'?: Array<ServiceProvider>;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundDto
     */
    'from'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundDto
     */
    'isHedgeFund'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundDto
     */
    'businessLocation'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateHedgefundHistoricalReturnDto
 */
export interface UpdateHedgefundHistoricalReturnDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'netMtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'netFundingMtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'netWtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'netFundingWtd'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'monthly'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'weekly'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'currency'?: UpdateHedgefundHistoricalReturnDtoCurrencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundHistoricalReturnDto
     */
    'isDelete': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundHistoricalReturnDtoCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface UpdateHedgefundNavDto
 */
export interface UpdateHedgefundNavDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundNavDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'endingNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'reportNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'baseNAV'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'lastMonthNavId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'portfolioId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'investorId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'divisionId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundNavDto
     */
    'isFinal'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundNavDto
     */
    'id': number | null;
}
/**
 * 
 * @export
 * @interface UpdateHedgefundSeriesDto
 */
export interface UpdateHedgefundSeriesDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundSeriesDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundSeriesDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundSeriesDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundSeriesDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundSeriesDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundSeriesDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundSeriesDto
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundSeriesDto
     */
    'managementFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundSeriesDto
     */
    'performanceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundSeriesDto
     */
    'hurdleRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundSeriesDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundSeriesDto
     */
    'fundId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateHedgefundTransactionDto
 */
export interface UpdateHedgefundTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionDto
     */
    'transactionStartType'?: UpdateHedgefundTransactionDtoTransactionStartTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundTransactionDto
     */
    'hasSidePocket'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionDto
     */
    'portfolioId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundTransactionDto
     */
    'isRolluped'?: boolean;
    /**
     * 
     * @type {Array<UpdateHedgefundTransactionItemDto>}
     * @memberof UpdateHedgefundTransactionDto
     */
    'fundTransactionItems'?: Array<UpdateHedgefundTransactionItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionDto
     */
    'basedate'?: string | null;
    /**
     * 
     * @type {Liquidity}
     * @memberof UpdateHedgefundTransactionDto
     */
    'liquidity'?: Liquidity;
    /**
     * 
     * @type {Array<SoftLockDtoBase>}
     * @memberof UpdateHedgefundTransactionDto
     */
    'softLock'?: Array<SoftLockDtoBase> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundTransactionDtoTransactionStartTypeEnum {
    Invest = 'Invest',
    Transfer = 'Transfer',
    Opening = 'Opening',
    ClassSeriesChange = 'Class/Series Change',
    RollUp = 'Roll-up'
}

/**
 * 
 * @export
 * @interface UpdateHedgefundTransactionItemDto
 */
export interface UpdateHedgefundTransactionItemDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'id': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'basedate': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'bookValueChange': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'accruedFee': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'transactionStatus': UpdateHedgefundTransactionItemDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'transactionType': UpdateHedgefundTransactionItemDtoTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'inputFrom': UpdateHedgefundTransactionItemDtoInputFromEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'transferType'?: UpdateHedgefundTransactionItemDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'redemptionMethod'?: UpdateHedgefundTransactionItemDtoRedemptionMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'fundTransactionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'comment': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'isFullRedemption'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'navRedeemMoment'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHedgefundTransactionItemDto
     */
    'bookValueRedeemMoment'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundTransactionItemDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundTransactionItemDtoTransactionTypeEnum {
    Invest = 'Invest',
    Redeem = 'Redeem',
    ClassSeriesChangeFrom = 'Class/Series Change (From)',
    ClassSeriesChangeTo = 'Class/Series Change (To)',
    Equalization = 'Equalization',
    Opening = 'Opening',
    Migration = 'Migration',
    Other = 'Other',
    AdjustmentForPerformanceFee = 'Adjustment for Performance Fee',
    ExtraDividend = 'Extra Dividend'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundTransactionItemDtoInputFromEnum {
    Amount = 'amount',
    Percentage = 'percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundTransactionItemDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateHedgefundTransactionItemDtoRedemptionMethodEnum {
    Fifo = 'FIFO',
    Lifo = 'LIFO',
    Prorata = 'Prorata',
    Manual = 'Manual'
}

/**
 * 
 * @export
 * @interface UpdateHighWaterMarkDto
 */
export interface UpdateHighWaterMarkDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHighWaterMarkDto
     */
    'year'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateHighWaterMarkDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHighWaterMarkDto
     */
    'hwm'?: number;
}
/**
 * 
 * @export
 * @interface UpdateHoldingDto
 */
export interface UpdateHoldingDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateHoldingDto
     */
    'portfolioId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHoldingDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHoldingDto
     */
    'backstopId'?: number | null;
    /**
     * 
     * @type {CreateHoldingItemDto}
     * @memberof UpdateHoldingDto
     */
    'holdingItem'?: CreateHoldingItemDto;
}
/**
 * 
 * @export
 * @interface UpdateInputReturnDto
 */
export interface UpdateInputReturnDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInputReturnDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateInputReturnDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInputReturnDto
     */
    'fundClassId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateInputReturnDto
     */
    'fundSeriesId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateInputReturnDto
     */
    'portfolioId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateInputReturnDto
     */
    'entryMethod'?: UpdateInputReturnDtoEntryMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateInputReturnDto
     */
    'ratio'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateInputReturnDto
     */
    'inputMTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateInputReturnDto
     */
    'currency'?: UpdateInputReturnDtoCurrencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInputReturnDto
     */
    'useStandardValue'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateInputReturnDto
     */
    'id'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateInputReturnDtoEntryMethodEnum {
    Net = 'Net',
    GrossWithRatio = 'Gross With Ratio',
    GrossWithFee = 'Gross With Fee'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateInputReturnDtoCurrencyEnum {
    Cad = 'CAD',
    Krw = 'KRW',
    Twd = 'TWD',
    Inr = 'INR',
    Sgd = 'SGD',
    Myr = 'MYR',
    Idr = 'IDR',
    Vnd = 'VND',
    Thb = 'THB',
    Php = 'PHP',
    Pkr = 'PKR',
    Brl = 'BRL',
    Ars = 'ARS',
    Huf = 'HUF',
    Rub = 'RUB',
    Try = 'TRY',
    Zar = 'ZAR',
    Nok = 'NOK',
    Sek = 'SEK',
    Dkk = 'DKK',
    Nzd = 'NZD',
    Mxn = 'MXN',
    Aed = 'AED',
    Sar = 'SAR',
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface UpdateInvestorDto
 */
export interface UpdateInvestorDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorDto
     */
    'investorType'?: UpdateInvestorDtoInvestorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorDto
     */
    'investorSubType'?: UpdateInvestorDtoInvestorSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorDto
     */
    'investorRegion'?: UpdateInvestorDtoInvestorRegionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestorDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestorDto
     */
    'isDIM'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorDto
     */
    'comment'?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorDtoInvestorTypeEnum {
    Pension = 'Pension',
    FinancialInstitution = 'Financial Institution',
    Endowment = 'Endowment',
    Sovereign = 'Sovereign',
    Municipal = 'Municipal',
    Corporates = 'Corporates',
    FamilyOffice = 'Family Office',
    HighNetWorth = 'High Net Worth',
    Account = 'Account',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorDtoInvestorSubTypeEnum {
    Bank = 'Bank',
    RegionalBank = 'Regional Bank',
    InsuranceCompany = 'Insurance Company',
    SecurityFirm = 'Security Firm',
    Public = 'Public',
    Private = 'Private'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorDtoInvestorRegionEnum {
    Japan = 'Japan',
    AsiaExJapan = 'Asia ex Japan',
    Us = 'US',
    Europe = 'Europe',
    None = 'None',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface UpdateInvestorTransactionDto
 */
export interface UpdateInvestorTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionDto
     */
    'fundClassId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionDto
     */
    'fundId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorTransactionDto
     */
    'transactionStartType'?: UpdateInvestorTransactionDtoTransactionStartTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestorTransactionDto
     */
    'hasSidePocket'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionDto
     */
    'divisionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionDto
     */
    'investorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionDto
     */
    'portfolioId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestorTransactionDto
     */
    'isInvestedByAccount'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {Array<CreateInvestorTransactionItemDto>}
     * @memberof UpdateInvestorTransactionDto
     */
    'fundTransactionItems'?: Array<CreateInvestorTransactionItemDto> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorTransactionDtoTransactionStartTypeEnum {
    Invest = 'Invest',
    Transfer = 'Transfer',
    Opening = 'Opening',
    ClassSeriesChange = 'Class/Series Change',
    RollUp = 'Roll-up'
}

/**
 * 
 * @export
 * @interface UpdateInvestorTransactionItemDto
 */
export interface UpdateInvestorTransactionItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'share'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'bookValueChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'transactionStatus'?: UpdateInvestorTransactionItemDtoTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'transactionType'?: UpdateInvestorTransactionItemDtoTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'inputFrom'?: UpdateInvestorTransactionItemDtoInputFromEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'isReinvest'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'transferType'?: UpdateInvestorTransactionItemDtoTransferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'isRedeemAll'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'holdingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestorTransactionItemDto
     */
    'holdingItemId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorTransactionItemDtoTransactionStatusEnum {
    Tentative = 'Tentative',
    Final = 'Final'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorTransactionItemDtoTransactionTypeEnum {
    Invest = 'Invest',
    Redeem = 'Redeem',
    ClassSeriesChangeFrom = 'Class/Series Change (From)',
    ClassSeriesChangeTo = 'Class/Series Change (To)',
    Equalization = 'Equalization',
    Opening = 'Opening',
    Migration = 'Migration',
    Other = 'Other',
    AdjustmentForPerformanceFee = 'Adjustment for Performance Fee',
    ExtraDividend = 'Extra Dividend'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorTransactionItemDtoInputFromEnum {
    Amount = 'amount',
    Percentage = 'percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateInvestorTransactionItemDtoTransferTypeEnum {
    In = 'in',
    Out = 'out'
}

/**
 * 
 * @export
 * @interface UpdateNavAccountTotalDto
 */
export interface UpdateNavAccountTotalDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'fundSeriesId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateNavAccountTotalDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'totalSharesOutstanding'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'lastMonthNavId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'lastWeekNavId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'totalNavExEQ'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNavAccountTotalDto
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateNavAccountTotalDto
     */
    'updatedBy': number;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationDto
 */
export interface UpdateOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    'businessLocation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    'establishDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDto
     */
    'isInvAdvisorManager'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDto
     */
    'isBroker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDto
     */
    'isAdministrator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDto
     */
    'isAuditor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrganizationDto
     */
    'legacyCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    'legacyDataSource'?: UpdateOrganizationDtoLegacyDataSourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateOrganizationDtoLegacyDataSourceEnum {
    Auditor = 'AUDITOR',
    Manager = 'MANAGER',
    Broker = 'BROKER',
    Administrator = 'ADMINISTRATOR'
}

/**
 * 
 * @export
 * @interface UpdatePermissionGroupDto
 */
export interface UpdatePermissionGroupDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePermissionGroupDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatePermissionGroupDto
     */
    'policyIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatePermissionGroupDto
     */
    'sysUserIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdatePolicyDto
 */
export interface UpdatePolicyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePolicyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePolicyDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePortfolioDto
 */
export interface UpdatePortfolioDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePortfolioDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    'fiscalYearBeginningMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    'allocationCurrency'?: UpdatePortfolioDtoAllocationCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    'legacyBkFcode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatePortfolioDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePortfolioDto
     */
    'isInvestedFromFOFs'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePortfolioDto
     */
    'isInvestingIntoFOFsOrSingle'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdatePortfolioDtoAllocationCurrencyEnum {
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}

/**
 * 
 * @export
 * @interface UpdateRollupHistoryDto
 */
export interface UpdateRollupHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateRollupHistoryDto
     */
    'series_to_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRollupHistoryDto
     */
    'fundSeriesId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRollupHistoryDto
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRollupHistoryDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {Array<SeriesFromDto>}
     * @memberof UpdateRollupHistoryDto
     */
    'seriesFrom'?: Array<SeriesFromDto>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof UpdateRollupHistoryDto
     */
    'origins': Array<Array<number>>;
    /**
     * 
     * @type {CreateHedgefundSeriesDto}
     * @memberof UpdateRollupHistoryDto
     */
    'fundSeries'?: CreateHedgefundSeriesDto;
}
/**
 * 
 * @export
 * @interface UpdateStrategyDto
 */
export interface UpdateStrategyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateStrategyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStrategyDto
     */
    'definition'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStrategyDto
     */
    'legacyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateStrategyDto
     */
    'strategySource'?: UpdateStrategyDtoStrategySourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateStrategyDtoStrategySourceEnum {
    Mcp = 'MCP',
    Hfr = 'HFR',
    CsTremont = 'CS/Tremont',
    Sp = 'S&P'
}

/**
 * 
 * @export
 * @interface UpdateSystemInterestRateDto
 */
export interface UpdateSystemInterestRateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSystemInterestRateDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSystemInterestRateDto
     */
    'currency'?: UpdateSystemInterestRateDtoCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateSystemInterestRateDto
     */
    'tickerMasterId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSystemInterestRateDto
     */
    'indicatorType'?: UpdateSystemInterestRateDtoIndicatorTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateSystemInterestRateDtoCurrencyEnum {
    Cad = 'CAD',
    Krw = 'KRW',
    Twd = 'TWD',
    Inr = 'INR',
    Sgd = 'SGD',
    Myr = 'MYR',
    Idr = 'IDR',
    Vnd = 'VND',
    Thb = 'THB',
    Php = 'PHP',
    Pkr = 'PKR',
    Brl = 'BRL',
    Ars = 'ARS',
    Huf = 'HUF',
    Rub = 'RUB',
    Try = 'TRY',
    Zar = 'ZAR',
    Nok = 'NOK',
    Sek = 'SEK',
    Dkk = 'DKK',
    Nzd = 'NZD',
    Mxn = 'MXN',
    Aed = 'AED',
    Sar = 'SAR',
    Jpy = 'JPY',
    Usd = 'USD',
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Hkd = 'HKD',
    Cny = 'CNY',
    Cnh = 'CNH'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateSystemInterestRateDtoIndicatorTypeEnum {
    HedgefundIndex = 'HedgefundIndex',
    MarketIndex = 'MarketIndex',
    InterestRate = 'InterestRate',
    ForeignExchange = 'ForeignExchange'
}

/**
 * 
 * @export
 * @interface UpdateSysuserDto
 */
export interface UpdateSysuserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'cognitoUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'sub'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'location'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSysuserDto
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'legacyUserCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'permissionGroups'?: UpdateSysuserDtoPermissionGroupsEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSysuserDto
     */
    'sysuserGroupIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserDto
     */
    'avatarOriginal'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateSysuserDtoPermissionGroupsEnum {
    Administrators = 'Administrators',
    UserManager = 'User Manager',
    Operator = 'Operator',
    Reader = 'Reader',
    Analyst = 'Analyst',
    SeniorOperator = 'Senior Operator',
    Support = 'Support'
}

/**
 * 
 * @export
 * @interface UpdateSysuserGroupDto
 */
export interface UpdateSysuserGroupDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserGroupDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSysuserGroupDto
     */
    'sysLocation'?: UpdateSysuserGroupDtoSysLocationEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSysuserGroupDto
     */
    'sysUSersIds'?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateSysuserGroupDtoSysLocationEnum {
    Tokyo = 'Tokyo',
    HongKong = 'Hong Kong',
    Chicago = 'Chicago',
    Seoul = 'Seoul'
}

/**
 * 
 * @export
 * @interface UpsertDoubleCheckDto
 */
export interface UpsertDoubleCheckDto {
    /**
     * 
     * @type {number}
     * @memberof UpsertDoubleCheckDto
     */
    'fundNavId': number;
    /**
     * 
     * @type {number}
     * @memberof UpsertDoubleCheckDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertDoubleCheckDto
     */
    'isCheck': boolean;
}
/**
 * 
 * @export
 * @interface UpsertFundNavDoubleCheckDto
 */
export interface UpsertFundNavDoubleCheckDto {
    /**
     * 
     * @type {number}
     * @memberof UpsertFundNavDoubleCheckDto
     */
    'fundNavId': number;
    /**
     * 
     * @type {number}
     * @memberof UpsertFundNavDoubleCheckDto
     */
    'fundSeriesId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertFundNavDoubleCheckDto
     */
    'isCheck': boolean;
}
/**
 * 
 * @export
 * @interface VirtualNavBvDto
 */
export interface VirtualNavBvDto {
    /**
     * 
     * @type {number}
     * @memberof VirtualNavBvDto
     */
    'txnId': number;
    /**
     * 
     * @type {number}
     * @memberof VirtualNavBvDto
     */
    'txnItemId': number;
    /**
     * 
     * @type {number}
     * @memberof VirtualNavBvDto
     */
    'virtualBV': number;
    /**
     * 
     * @type {number}
     * @memberof VirtualNavBvDto
     */
    'virtualNav': number;
    /**
     * 
     * @type {string}
     * @memberof VirtualNavBvDto
     */
    'effectiveDate': string;
    /**
     * 
     * @type {number}
     * @memberof VirtualNavBvDto
     */
    'fundSeriesId': number;
}
/**
 * 
 * @export
 * @interface YtdDto
 */
export interface YtdDto {
    /**
     * 
     * @type {number}
     * @memberof YtdDto
     */
    'ytd': number;
    /**
     * 
     * @type {string}
     * @memberof YtdDto
     */
    'year': string;
}

/**
 * AccountClassApi - axios parameter creator
 * @export
 */
export const AccountClassApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAccountClassDto} createAccountClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAccountClasses: async (createAccountClassDto: CreateAccountClassDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountClassDto' is not null or undefined
            assertParamExists('createWithAccountClasses', 'createAccountClassDto', createAccountClassDto)
            const localVarPath = `/api/account-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountClasses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountClasses: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithAccountClasses', 'id', id)
            const localVarPath = `/api/account-classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesByAccountClassWithAccountClasses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSeriesByAccountClassWithAccountClasses', 'id', id)
            const localVarPath = `/api/account-classes/{id}/account-series`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountClasses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithAccountClasses', 'id', id)
            const localVarPath = `/api/account-classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAccountClassDto} updateAccountClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountClasses: async (id: number, updateAccountClassDto: UpdateAccountClassDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithAccountClasses', 'id', id)
            // verify required parameter 'updateAccountClassDto' is not null or undefined
            assertParamExists('updateWithAccountClasses', 'updateAccountClassDto', updateAccountClassDto)
            const localVarPath = `/api/account-classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountClassApi - functional programming interface
 * @export
 */
export const AccountClassApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountClassApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAccountClassDto} createAccountClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithAccountClasses(createAccountClassDto: CreateAccountClassDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateAccountClassDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithAccountClasses(createAccountClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithAccountClasses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithAccountClasses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithAccountClasses(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithAccountClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSeriesByAccountClassWithAccountClasses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSeriesByAccountClassWithAccountClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithAccountClasses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithAccountClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAccountClassDto} updateAccountClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithAccountClasses(id: number, updateAccountClassDto: UpdateAccountClassDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUpdateHedgefundClassDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithAccountClasses(id, updateAccountClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountClassApi - factory interface
 * @export
 */
export const AccountClassApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountClassApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAccountClassDto} createAccountClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAccountClasses(createAccountClassDto: CreateAccountClassDto, options?: any): AxiosPromise<ResponseCreateAccountClassDto> {
            return localVarFp.createWithAccountClasses(createAccountClassDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountClasses(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithAccountClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountClasses(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithAccountClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesByAccountClassWithAccountClasses(id: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findSeriesByAccountClassWithAccountClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountClasses(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithAccountClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAccountClassDto} updateAccountClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountClasses(id: number, updateAccountClassDto: UpdateAccountClassDto, options?: any): AxiosPromise<ResponseUpdateHedgefundClassDto> {
            return localVarFp.updateWithAccountClasses(id, updateAccountClassDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountClassApi - interface
 * @export
 * @interface AccountClassApi
 */
export interface AccountClassApiInterface {
    /**
     * 
     * @param {CreateAccountClassDto} createAccountClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApiInterface
     */
    createWithAccountClasses(createAccountClassDto: CreateAccountClassDto, options?: AxiosRequestConfig): AxiosPromise<ResponseCreateAccountClassDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApiInterface
     */
    findAllWithAccountClasses(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApiInterface
     */
    findOneWithAccountClasses(id: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApiInterface
     */
    findSeriesByAccountClassWithAccountClasses(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApiInterface
     */
    removeWithAccountClasses(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {UpdateAccountClassDto} updateAccountClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApiInterface
     */
    updateWithAccountClasses(id: number, updateAccountClassDto: UpdateAccountClassDto, options?: AxiosRequestConfig): AxiosPromise<ResponseUpdateHedgefundClassDto>;

}

/**
 * AccountClassApi - object-oriented interface
 * @export
 * @class AccountClassApi
 * @extends {BaseAPI}
 */
export class AccountClassApi extends BaseAPI implements AccountClassApiInterface {
    /**
     * 
     * @param {CreateAccountClassDto} createAccountClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApi
     */
    public createWithAccountClasses(createAccountClassDto: CreateAccountClassDto, options?: AxiosRequestConfig) {
        return AccountClassApiFp(this.configuration).createWithAccountClasses(createAccountClassDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApi
     */
    public findAllWithAccountClasses(options?: AxiosRequestConfig) {
        return AccountClassApiFp(this.configuration).findAllWithAccountClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApi
     */
    public findOneWithAccountClasses(id: number, options?: AxiosRequestConfig) {
        return AccountClassApiFp(this.configuration).findOneWithAccountClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApi
     */
    public findSeriesByAccountClassWithAccountClasses(id: string, options?: AxiosRequestConfig) {
        return AccountClassApiFp(this.configuration).findSeriesByAccountClassWithAccountClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApi
     */
    public removeWithAccountClasses(id: string, options?: AxiosRequestConfig) {
        return AccountClassApiFp(this.configuration).removeWithAccountClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateAccountClassDto} updateAccountClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountClassApi
     */
    public updateWithAccountClasses(id: number, updateAccountClassDto: UpdateAccountClassDto, options?: AxiosRequestConfig) {
        return AccountClassApiFp(this.configuration).updateWithAccountClasses(id, updateAccountClassDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountNAVEstimateApi - axios parameter creator
 * @export
 */
export const AccountNAVEstimateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithAccountNavEstimate: async (updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountNavEstimateDto' is not null or undefined
            assertParamExists('bulkSaveWithAccountNavEstimate', 'updateAccountNavEstimateDto', updateAccountNavEstimateDto)
            const localVarPath = `/api/account-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavEstimateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountNavEstimate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEqCreditWithAccountNavEstimate: async (fundSeriesId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundSeriesId' is not null or undefined
            assertParamExists('findEqCreditWithAccountNavEstimate', 'fundSeriesId', fundSeriesId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findEqCreditWithAccountNavEstimate', 'basedate', basedate)
            const localVarPath = `/api/account-return/eq-credit/{fundSeriesId}`
                .replace(`{${"fundSeriesId"}}`, encodeURIComponent(String(fundSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountNavEstimate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithAccountNavEstimate', 'id', id)
            const localVarPath = `/api/account-return/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountNavEstimate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithAccountNavEstimate', 'id', id)
            const localVarPath = `/api/account-return/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTableRowWithAccountNavEstimate: async (updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountNavEstimateDto' is not null or undefined
            assertParamExists('saveTableRowWithAccountNavEstimate', 'updateAccountNavEstimateDto', updateAccountNavEstimateDto)
            const localVarPath = `/api/account-return/save-row`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavEstimateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEqCreditWithAccountNavEstimate: async (updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountNavEstimateDto' is not null or undefined
            assertParamExists('updateEqCreditWithAccountNavEstimate', 'updateAccountNavEstimateDto', updateAccountNavEstimateDto)
            const localVarPath = `/api/account-return/update-eq-credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavEstimateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountNavEstimate: async (id: string, updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithAccountNavEstimate', 'id', id)
            // verify required parameter 'updateAccountNavEstimateDto' is not null or undefined
            assertParamExists('updateWithAccountNavEstimate', 'updateAccountNavEstimateDto', updateAccountNavEstimateDto)
            const localVarPath = `/api/account-return/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavEstimateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountNAVEstimateApi - functional programming interface
 * @export
 */
export const AccountNAVEstimateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountNAVEstimateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSaveWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSaveWithAccountNavEstimate(updateAccountNavEstimateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithAccountNavEstimate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithAccountNavEstimate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEqCreditWithAccountNavEstimate(fundSeriesId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEqCreditWithAccountNavEstimate(fundSeriesId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithAccountNavEstimate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithAccountNavEstimate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithAccountNavEstimate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithAccountNavEstimate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTableRowWithAccountNavEstimate(updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTableRowWithAccountNavEstimate(updateAccountNavEstimateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEqCreditWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEqCreditWithAccountNavEstimate(updateAccountNavEstimateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithAccountNavEstimate(id: string, updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithAccountNavEstimate(id, updateAccountNavEstimateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountNAVEstimateApi - factory interface
 * @export
 */
export const AccountNAVEstimateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountNAVEstimateApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: any): AxiosPromise<string> {
            return localVarFp.bulkSaveWithAccountNavEstimate(updateAccountNavEstimateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountNavEstimate(options?: any): AxiosPromise<string> {
            return localVarFp.findAllWithAccountNavEstimate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEqCreditWithAccountNavEstimate(fundSeriesId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findEqCreditWithAccountNavEstimate(fundSeriesId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountNavEstimate(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.findOneWithAccountNavEstimate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountNavEstimate(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeWithAccountNavEstimate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTableRowWithAccountNavEstimate(updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: any): AxiosPromise<object> {
            return localVarFp.saveTableRowWithAccountNavEstimate(updateAccountNavEstimateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEqCreditWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: any): AxiosPromise<object> {
            return localVarFp.updateEqCreditWithAccountNavEstimate(updateAccountNavEstimateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountNavEstimate(id: string, updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: any): AxiosPromise<string> {
            return localVarFp.updateWithAccountNavEstimate(id, updateAccountNavEstimateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountNAVEstimateApi - interface
 * @export
 * @interface AccountNAVEstimateApi
 */
export interface AccountNAVEstimateApiInterface {
    /**
     * 
     * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    bulkSaveWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    findAllWithAccountNavEstimate(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    findEqCreditWithAccountNavEstimate(fundSeriesId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    findOneWithAccountNavEstimate(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    removeWithAccountNavEstimate(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    saveTableRowWithAccountNavEstimate(updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    updateEqCreditWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApiInterface
     */
    updateWithAccountNavEstimate(id: string, updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * AccountNAVEstimateApi - object-oriented interface
 * @export
 * @class AccountNAVEstimateApi
 * @extends {BaseAPI}
 */
export class AccountNAVEstimateApi extends BaseAPI implements AccountNAVEstimateApiInterface {
    /**
     * 
     * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public bulkSaveWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).bulkSaveWithAccountNavEstimate(updateAccountNavEstimateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public findAllWithAccountNavEstimate(options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).findAllWithAccountNavEstimate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public findEqCreditWithAccountNavEstimate(fundSeriesId: number, basedate: string, options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).findEqCreditWithAccountNavEstimate(fundSeriesId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public findOneWithAccountNavEstimate(id: string, options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).findOneWithAccountNavEstimate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public removeWithAccountNavEstimate(id: string, options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).removeWithAccountNavEstimate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public saveTableRowWithAccountNavEstimate(updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).saveTableRowWithAccountNavEstimate(updateAccountNavEstimateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateAccountNavEstimateDto>} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public updateEqCreditWithAccountNavEstimate(updateAccountNavEstimateDto: Array<UpdateAccountNavEstimateDto>, options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).updateEqCreditWithAccountNavEstimate(updateAccountNavEstimateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountNavEstimateDto} updateAccountNavEstimateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVEstimateApi
     */
    public updateWithAccountNavEstimate(id: string, updateAccountNavEstimateDto: UpdateAccountNavEstimateDto, options?: AxiosRequestConfig) {
        return AccountNAVEstimateApiFp(this.configuration).updateWithAccountNavEstimate(id, updateAccountNavEstimateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountNAVFinalApi - axios parameter creator
 * @export
 */
export const AccountNAVFinalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithAccountNavFinal: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('bulkSaveWithAccountNavFinal', 'requestBody', requestBody)
            const localVarPath = `/api/account-nav`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountNavFinal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-nav`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEqCreditWithAccountNavFinal: async (fundSeriesId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundSeriesId' is not null or undefined
            assertParamExists('findEqCreditWithAccountNavFinal', 'fundSeriesId', fundSeriesId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findEqCreditWithAccountNavFinal', 'basedate', basedate)
            const localVarPath = `/api/account-nav/eq-credit/{fundSeriesId}`
                .replace(`{${"fundSeriesId"}}`, encodeURIComponent(String(fundSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountNavFinal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithAccountNavFinal', 'id', id)
            const localVarPath = `/api/account-nav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountNavFinal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithAccountNavFinal', 'id', id)
            const localVarPath = `/api/account-nav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTableRowWithAccountNavFinal: async (updateAccountNavFinalDto: UpdateAccountNavFinalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountNavFinalDto' is not null or undefined
            assertParamExists('saveTableRowWithAccountNavFinal', 'updateAccountNavFinalDto', updateAccountNavFinalDto)
            const localVarPath = `/api/account-nav/save-row`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavFinalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateAccountNavFinalDto>} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEqCreditWithAccountNavFinal: async (updateAccountNavFinalDto: Array<UpdateAccountNavFinalDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountNavFinalDto' is not null or undefined
            assertParamExists('updateEqCreditWithAccountNavFinal', 'updateAccountNavFinalDto', updateAccountNavFinalDto)
            const localVarPath = `/api/account-nav/update-eq-credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavFinalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountNavFinal: async (id: string, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithAccountNavFinal', 'id', id)
            // verify required parameter 'updateAccountNavFinalDto' is not null or undefined
            assertParamExists('updateWithAccountNavFinal', 'updateAccountNavFinalDto', updateAccountNavFinalDto)
            const localVarPath = `/api/account-nav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavFinalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountNAVFinalApi - functional programming interface
 * @export
 */
export const AccountNAVFinalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountNAVFinalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSaveWithAccountNavFinal(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSaveWithAccountNavFinal(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithAccountNavFinal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithAccountNavFinal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEqCreditWithAccountNavFinal(fundSeriesId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEqCreditWithAccountNavFinal(fundSeriesId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithAccountNavFinal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithAccountNavFinal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithAccountNavFinal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithAccountNavFinal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTableRowWithAccountNavFinal(updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTableRowWithAccountNavFinal(updateAccountNavFinalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<UpdateAccountNavFinalDto>} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEqCreditWithAccountNavFinal(updateAccountNavFinalDto: Array<UpdateAccountNavFinalDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEqCreditWithAccountNavFinal(updateAccountNavFinalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithAccountNavFinal(id: string, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithAccountNavFinal(id, updateAccountNavFinalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountNAVFinalApi - factory interface
 * @export
 */
export const AccountNAVFinalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountNAVFinalApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithAccountNavFinal(requestBody: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.bulkSaveWithAccountNavFinal(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountNavFinal(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithAccountNavFinal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEqCreditWithAccountNavFinal(fundSeriesId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findEqCreditWithAccountNavFinal(fundSeriesId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountNavFinal(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.findOneWithAccountNavFinal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountNavFinal(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeWithAccountNavFinal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTableRowWithAccountNavFinal(updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: any): AxiosPromise<object> {
            return localVarFp.saveTableRowWithAccountNavFinal(updateAccountNavFinalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateAccountNavFinalDto>} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEqCreditWithAccountNavFinal(updateAccountNavFinalDto: Array<UpdateAccountNavFinalDto>, options?: any): AxiosPromise<object> {
            return localVarFp.updateEqCreditWithAccountNavFinal(updateAccountNavFinalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountNavFinal(id: string, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: any): AxiosPromise<string> {
            return localVarFp.updateWithAccountNavFinal(id, updateAccountNavFinalDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountNAVFinalApi - interface
 * @export
 * @interface AccountNAVFinalApi
 */
export interface AccountNAVFinalApiInterface {
    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    bulkSaveWithAccountNavFinal(requestBody: Array<string>, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    findAllWithAccountNavFinal(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    findEqCreditWithAccountNavFinal(fundSeriesId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    findOneWithAccountNavFinal(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    removeWithAccountNavFinal(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    saveTableRowWithAccountNavFinal(updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {Array<UpdateAccountNavFinalDto>} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    updateEqCreditWithAccountNavFinal(updateAccountNavFinalDto: Array<UpdateAccountNavFinalDto>, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApiInterface
     */
    updateWithAccountNavFinal(id: string, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * AccountNAVFinalApi - object-oriented interface
 * @export
 * @class AccountNAVFinalApi
 * @extends {BaseAPI}
 */
export class AccountNAVFinalApi extends BaseAPI implements AccountNAVFinalApiInterface {
    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public bulkSaveWithAccountNavFinal(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).bulkSaveWithAccountNavFinal(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public findAllWithAccountNavFinal(options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).findAllWithAccountNavFinal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public findEqCreditWithAccountNavFinal(fundSeriesId: number, basedate: string, options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).findEqCreditWithAccountNavFinal(fundSeriesId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public findOneWithAccountNavFinal(id: string, options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).findOneWithAccountNavFinal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public removeWithAccountNavFinal(id: string, options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).removeWithAccountNavFinal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public saveTableRowWithAccountNavFinal(updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).saveTableRowWithAccountNavFinal(updateAccountNavFinalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateAccountNavFinalDto>} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public updateEqCreditWithAccountNavFinal(updateAccountNavFinalDto: Array<UpdateAccountNavFinalDto>, options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).updateEqCreditWithAccountNavFinal(updateAccountNavFinalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNAVFinalApi
     */
    public updateWithAccountNavFinal(id: string, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig) {
        return AccountNAVFinalApiFp(this.configuration).updateWithAccountNavFinal(id, updateAccountNavFinalDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountSeriesApi - axios parameter creator
 * @export
 */
export const AccountSeriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAccountSeriesDto} createAccountSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAccountSeries: async (createAccountSeriesDto: CreateAccountSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountSeriesDto' is not null or undefined
            assertParamExists('createWithAccountSeries', 'createAccountSeriesDto', createAccountSeriesDto)
            const localVarPath = `/api/account-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountNavId 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckWithAccountSeries: async (accountNavId: number, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountNavId' is not null or undefined
            assertParamExists('doubleCheckWithAccountSeries', 'accountNavId', accountNavId)
            // verify required parameter 'updateAccountNavFinalDto' is not null or undefined
            assertParamExists('doubleCheckWithAccountSeries', 'updateAccountNavFinalDto', updateAccountNavFinalDto)
            const localVarPath = `/api/account-series/double-check-account-final/{accountNavId}`
                .replace(`{${"accountNavId"}}`, encodeURIComponent(String(accountNavId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNavFinalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountSeries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountSeries: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithAccountSeries', 'id', id)
            const localVarPath = `/api/account-series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPerformanceWithAccountSeries: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPerformanceWithAccountSeries', 'id', id)
            const localVarPath = `/api/account-series/performance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountSeries: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithAccountSeries', 'id', id)
            const localVarPath = `/api/account-series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAccountSeriesDto} updateAccountSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountSeries: async (id: number, updateAccountSeriesDto: UpdateAccountSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithAccountSeries', 'id', id)
            // verify required parameter 'updateAccountSeriesDto' is not null or undefined
            assertParamExists('updateWithAccountSeries', 'updateAccountSeriesDto', updateAccountSeriesDto)
            const localVarPath = `/api/account-series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountSeriesApi - functional programming interface
 * @export
 */
export const AccountSeriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountSeriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAccountSeriesDto} createAccountSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithAccountSeries(createAccountSeriesDto: CreateAccountSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithAccountSeries(createAccountSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountNavId 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doubleCheckWithAccountSeries(accountNavId: number, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doubleCheckWithAccountSeries(accountNavId, updateAccountNavFinalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithAccountSeries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithAccountSeries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithAccountSeries(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithAccountSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPerformanceWithAccountSeries(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPerformanceWithAccountSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithAccountSeries(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithAccountSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAccountSeriesDto} updateAccountSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithAccountSeries(id: number, updateAccountSeriesDto: UpdateAccountSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithAccountSeries(id, updateAccountSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountSeriesApi - factory interface
 * @export
 */
export const AccountSeriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountSeriesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAccountSeriesDto} createAccountSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAccountSeries(createAccountSeriesDto: CreateAccountSeriesDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithAccountSeries(createAccountSeriesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountNavId 
         * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckWithAccountSeries(accountNavId: number, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: any): AxiosPromise<void> {
            return localVarFp.doubleCheckWithAccountSeries(accountNavId, updateAccountNavFinalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccountSeries(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithAccountSeries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccountSeries(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithAccountSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPerformanceWithAccountSeries(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.findPerformanceWithAccountSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccountSeries(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithAccountSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAccountSeriesDto} updateAccountSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccountSeries(id: number, updateAccountSeriesDto: UpdateAccountSeriesDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithAccountSeries(id, updateAccountSeriesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountSeriesApi - interface
 * @export
 * @interface AccountSeriesApi
 */
export interface AccountSeriesApiInterface {
    /**
     * 
     * @param {CreateAccountSeriesDto} createAccountSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApiInterface
     */
    createWithAccountSeries(createAccountSeriesDto: CreateAccountSeriesDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} accountNavId 
     * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApiInterface
     */
    doubleCheckWithAccountSeries(accountNavId: number, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApiInterface
     */
    findAllWithAccountSeries(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApiInterface
     */
    findOneWithAccountSeries(id: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApiInterface
     */
    findPerformanceWithAccountSeries(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApiInterface
     */
    removeWithAccountSeries(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {UpdateAccountSeriesDto} updateAccountSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApiInterface
     */
    updateWithAccountSeries(id: number, updateAccountSeriesDto: UpdateAccountSeriesDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AccountSeriesApi - object-oriented interface
 * @export
 * @class AccountSeriesApi
 * @extends {BaseAPI}
 */
export class AccountSeriesApi extends BaseAPI implements AccountSeriesApiInterface {
    /**
     * 
     * @param {CreateAccountSeriesDto} createAccountSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApi
     */
    public createWithAccountSeries(createAccountSeriesDto: CreateAccountSeriesDto, options?: AxiosRequestConfig) {
        return AccountSeriesApiFp(this.configuration).createWithAccountSeries(createAccountSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountNavId 
     * @param {UpdateAccountNavFinalDto} updateAccountNavFinalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApi
     */
    public doubleCheckWithAccountSeries(accountNavId: number, updateAccountNavFinalDto: UpdateAccountNavFinalDto, options?: AxiosRequestConfig) {
        return AccountSeriesApiFp(this.configuration).doubleCheckWithAccountSeries(accountNavId, updateAccountNavFinalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApi
     */
    public findAllWithAccountSeries(options?: AxiosRequestConfig) {
        return AccountSeriesApiFp(this.configuration).findAllWithAccountSeries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApi
     */
    public findOneWithAccountSeries(id: number, options?: AxiosRequestConfig) {
        return AccountSeriesApiFp(this.configuration).findOneWithAccountSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApi
     */
    public findPerformanceWithAccountSeries(id: number, options?: AxiosRequestConfig) {
        return AccountSeriesApiFp(this.configuration).findPerformanceWithAccountSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApi
     */
    public removeWithAccountSeries(id: string, options?: AxiosRequestConfig) {
        return AccountSeriesApiFp(this.configuration).removeWithAccountSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateAccountSeriesDto} updateAccountSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSeriesApi
     */
    public updateWithAccountSeries(id: number, updateAccountSeriesDto: UpdateAccountSeriesDto, options?: AxiosRequestConfig) {
        return AccountSeriesApiFp(this.configuration).updateWithAccountSeries(id, updateAccountSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountTransactionsApi - axios parameter creator
 * @export
 */
export const AccountTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} seriesId 
         * @param {number} divisionId 
         * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedeemBySeriesAndDivisionWithInvestorTransaction: async (seriesId: number, divisionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('createRedeemBySeriesAndDivisionWithInvestorTransaction', 'seriesId', seriesId)
            // verify required parameter 'divisionId' is not null or undefined
            assertParamExists('createRedeemBySeriesAndDivisionWithInvestorTransaction', 'divisionId', divisionId)
            // verify required parameter 'createRedeemInvTnxDto' is not null or undefined
            assertParamExists('createRedeemBySeriesAndDivisionWithInvestorTransaction', 'createRedeemInvTnxDto', createRedeemInvTnxDto)
            const localVarPath = `/api/account-transaction/redeem-by-series-division/{seriesId}/{divisionId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)))
                .replace(`{${"divisionId"}}`, encodeURIComponent(String(divisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRedeemInvTnxDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundTransactionId 
         * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedeemWithInvestorTransaction: async (fundTransactionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundTransactionId' is not null or undefined
            assertParamExists('createRedeemWithInvestorTransaction', 'fundTransactionId', fundTransactionId)
            // verify required parameter 'createRedeemInvTnxDto' is not null or undefined
            assertParamExists('createRedeemWithInvestorTransaction', 'createRedeemInvTnxDto', createRedeemInvTnxDto)
            const localVarPath = `/api/account-transaction/redeem/{fundTransactionId}`
                .replace(`{${"fundTransactionId"}}`, encodeURIComponent(String(fundTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRedeemInvTnxDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateInvestorTransactionDto} createInvestorTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithInvestorTransaction: async (createInvestorTransactionDto: CreateInvestorTransactionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvestorTransactionDto' is not null or undefined
            assertParamExists('createWithInvestorTransaction', 'createInvestorTransactionDto', createInvestorTransactionDto)
            const localVarPath = `/api/account-transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvestorTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountTransactionItemWithInvestorTransaction: async (txnItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txnItemId' is not null or undefined
            assertParamExists('findAccountTransactionItemWithInvestorTransaction', 'txnItemId', txnItemId)
            const localVarPath = `/api/account-transaction/transaction-item/{txnItemId}`
                .replace(`{${"txnItemId"}}`, encodeURIComponent(String(txnItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} lastDayOfMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTxnByAccountAndDateWithInvestorTransaction: async (accountId: number, lastDayOfMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findAllTxnByAccountAndDateWithInvestorTransaction', 'accountId', accountId)
            // verify required parameter 'lastDayOfMonth' is not null or undefined
            assertParamExists('findAllTxnByAccountAndDateWithInvestorTransaction', 'lastDayOfMonth', lastDayOfMonth)
            const localVarPath = `/api/account-transaction/investor-transaction/{accountId}/{lastDayOfMonth}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"lastDayOfMonth"}}`, encodeURIComponent(String(lastDayOfMonth)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithInvestorTransaction: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} effectiveDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByBasedayWithInvestorTransaction: async (id: number, effectiveDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findByBasedayWithInvestorTransaction', 'id', id)
            // verify required parameter 'effectiveDate' is not null or undefined
            assertParamExists('findByBasedayWithInvestorTransaction', 'effectiveDate', effectiveDate)
            const localVarPath = `/api/account-transaction/{id}/{effectiveDate}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"effectiveDate"}}`, encodeURIComponent(String(effectiveDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {string} effectiveDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForTxnDtailDividendWithInvestorTransaction: async (accountSeriesId: number, effectiveDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountSeriesId' is not null or undefined
            assertParamExists('findForTxnDtailDividendWithInvestorTransaction', 'accountSeriesId', accountSeriesId)
            // verify required parameter 'effectiveDate' is not null or undefined
            assertParamExists('findForTxnDtailDividendWithInvestorTransaction', 'effectiveDate', effectiveDate)
            const localVarPath = `/api/account-transaction/investor-transaction-detail-dividend/{accountSeriesId}/{effectiveDate}`
                .replace(`{${"accountSeriesId"}}`, encodeURIComponent(String(accountSeriesId)))
                .replace(`{${"effectiveDate"}}`, encodeURIComponent(String(effectiveDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} basedate 
         * @param {number} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNavFinalWithInvestorTransaction: async (basedate: string, accountSeriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findNavFinalWithInvestorTransaction', 'basedate', basedate)
            // verify required parameter 'accountSeriesId' is not null or undefined
            assertParamExists('findNavFinalWithInvestorTransaction', 'accountSeriesId', accountSeriesId)
            const localVarPath = `/api/account-transaction/navFinal/{basedate}/{accountSeriesId}`
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)))
                .replace(`{${"accountSeriesId"}}`, encodeURIComponent(String(accountSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seriesId 
         * @param {number} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneBySeriesDivisionWithInvestorTransaction: async (seriesId: number, divisionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('findOneBySeriesDivisionWithInvestorTransaction', 'seriesId', seriesId)
            // verify required parameter 'divisionId' is not null or undefined
            assertParamExists('findOneBySeriesDivisionWithInvestorTransaction', 'divisionId', divisionId)
            const localVarPath = `/api/account-transaction/find-one-by-series-division`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (seriesId !== undefined) {
                localVarQueryParameter['seriesId'] = seriesId;
            }

            if (divisionId !== undefined) {
                localVarQueryParameter['divisionId'] = divisionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithInvestorTransaction: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithInvestorTransaction', 'id', id)
            const localVarPath = `/api/account-transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} basedate 
         * @param {number} accountSeriesId 
         * @param {number} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemAllWithInvestorTransaction: async (basedate: string, accountSeriesId: number, divisionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('getRedeemAllWithInvestorTransaction', 'basedate', basedate)
            // verify required parameter 'accountSeriesId' is not null or undefined
            assertParamExists('getRedeemAllWithInvestorTransaction', 'accountSeriesId', accountSeriesId)
            // verify required parameter 'divisionId' is not null or undefined
            assertParamExists('getRedeemAllWithInvestorTransaction', 'divisionId', divisionId)
            const localVarPath = `/api/account-transaction/redeem-all/{basedate}/{accountSeriesId}/{divisionId}`
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)))
                .replace(`{${"accountSeriesId"}}`, encodeURIComponent(String(accountSeriesId)))
                .replace(`{${"divisionId"}}`, encodeURIComponent(String(divisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingEqCrystalWithInvestorTransaction: async (fundSeriesId: number, divisionId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundSeriesId' is not null or undefined
            assertParamExists('mountingEqCrystalWithInvestorTransaction', 'fundSeriesId', fundSeriesId)
            // verify required parameter 'divisionId' is not null or undefined
            assertParamExists('mountingEqCrystalWithInvestorTransaction', 'divisionId', divisionId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('mountingEqCrystalWithInvestorTransaction', 'basedate', basedate)
            const localVarPath = `/api/account-transaction/mounting-eq-crystal/{fundSeriesId}/{divisionId}`
                .replace(`{${"fundSeriesId"}}`, encodeURIComponent(String(fundSeriesId)))
                .replace(`{${"divisionId"}}`, encodeURIComponent(String(divisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionItemWithInvestorTransaction: async (txnItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txnItemId' is not null or undefined
            assertParamExists('removeTransactionItemWithInvestorTransaction', 'txnItemId', txnItemId)
            const localVarPath = `/api/account-transaction/transaction-item/{txnItemId}`
                .replace(`{${"txnItemId"}}`, encodeURIComponent(String(txnItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithInvestorTransaction: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithInvestorTransaction', 'id', id)
            const localVarPath = `/api/account-transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {UpdateInvestorTransactionItemDto} updateInvestorTransactionItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionItemWithInvestorTransaction: async (txnItemId: string, updateInvestorTransactionItemDto: UpdateInvestorTransactionItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txnItemId' is not null or undefined
            assertParamExists('updateTransactionItemWithInvestorTransaction', 'txnItemId', txnItemId)
            // verify required parameter 'updateInvestorTransactionItemDto' is not null or undefined
            assertParamExists('updateTransactionItemWithInvestorTransaction', 'updateInvestorTransactionItemDto', updateInvestorTransactionItemDto)
            const localVarPath = `/api/account-transaction/transaction-item/{txnItemId}`
                .replace(`{${"txnItemId"}}`, encodeURIComponent(String(txnItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvestorTransactionItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateInvestorTransactionDto} updateInvestorTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithInvestorTransaction: async (id: string, updateInvestorTransactionDto: UpdateInvestorTransactionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithInvestorTransaction', 'id', id)
            // verify required parameter 'updateInvestorTransactionDto' is not null or undefined
            assertParamExists('updateWithInvestorTransaction', 'updateInvestorTransactionDto', updateInvestorTransactionDto)
            const localVarPath = `/api/account-transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvestorTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountTransactionsApi - functional programming interface
 * @export
 */
export const AccountTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} seriesId 
         * @param {number} divisionId 
         * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRedeemBySeriesAndDivisionWithInvestorTransaction(seriesId: number, divisionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRedeemBySeriesAndDivisionWithInvestorTransaction(seriesId, divisionId, createRedeemInvTnxDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundTransactionId 
         * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRedeemWithInvestorTransaction(fundTransactionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRedeemWithInvestorTransaction(fundTransactionId, createRedeemInvTnxDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateInvestorTransactionDto} createInvestorTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithInvestorTransaction(createInvestorTransactionDto: CreateInvestorTransactionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithInvestorTransaction(createInvestorTransactionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAccountTransactionItemWithInvestorTransaction(txnItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAccountTransactionItemWithInvestorTransaction(txnItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} lastDayOfMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTxnByAccountAndDateWithInvestorTransaction(accountId: number, lastDayOfMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllTxnByAccountAndDateWithInvestorTransaction(accountId, lastDayOfMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithInvestorTransaction(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithInvestorTransaction(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} effectiveDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByBasedayWithInvestorTransaction(id: number, effectiveDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByBasedayWithInvestorTransaction(id, effectiveDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {string} effectiveDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findForTxnDtailDividendWithInvestorTransaction(accountSeriesId: number, effectiveDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findForTxnDtailDividendWithInvestorTransaction(accountSeriesId, effectiveDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} basedate 
         * @param {number} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNavFinalWithInvestorTransaction(basedate: string, accountSeriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNavFinalWithInvestorTransaction(basedate, accountSeriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seriesId 
         * @param {number} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneBySeriesDivisionWithInvestorTransaction(seriesId: number, divisionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneBySeriesDivisionWithInvestorTransaction(seriesId, divisionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithInvestorTransaction(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithInvestorTransaction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} basedate 
         * @param {number} accountSeriesId 
         * @param {number} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedeemAllWithInvestorTransaction(basedate: string, accountSeriesId: number, divisionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedeemAllWithInvestorTransaction(basedate, accountSeriesId, divisionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mountingEqCrystalWithInvestorTransaction(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mountingEqCrystalWithInvestorTransaction(fundSeriesId, divisionId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTransactionItemWithInvestorTransaction(txnItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTransactionItemWithInvestorTransaction(txnItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithInvestorTransaction(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithInvestorTransaction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {UpdateInvestorTransactionItemDto} updateInvestorTransactionItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionItemWithInvestorTransaction(txnItemId: string, updateInvestorTransactionItemDto: UpdateInvestorTransactionItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionItemWithInvestorTransaction(txnItemId, updateInvestorTransactionItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateInvestorTransactionDto} updateInvestorTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithInvestorTransaction(id: string, updateInvestorTransactionDto: UpdateInvestorTransactionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithInvestorTransaction(id, updateInvestorTransactionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountTransactionsApi - factory interface
 * @export
 */
export const AccountTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} seriesId 
         * @param {number} divisionId 
         * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedeemBySeriesAndDivisionWithInvestorTransaction(seriesId: number, divisionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: any): AxiosPromise<object> {
            return localVarFp.createRedeemBySeriesAndDivisionWithInvestorTransaction(seriesId, divisionId, createRedeemInvTnxDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundTransactionId 
         * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedeemWithInvestorTransaction(fundTransactionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: any): AxiosPromise<object> {
            return localVarFp.createRedeemWithInvestorTransaction(fundTransactionId, createRedeemInvTnxDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateInvestorTransactionDto} createInvestorTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithInvestorTransaction(createInvestorTransactionDto: CreateInvestorTransactionDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithInvestorTransaction(createInvestorTransactionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountTransactionItemWithInvestorTransaction(txnItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.findAccountTransactionItemWithInvestorTransaction(txnItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} lastDayOfMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTxnByAccountAndDateWithInvestorTransaction(accountId: number, lastDayOfMonth: string, options?: any): AxiosPromise<object> {
            return localVarFp.findAllTxnByAccountAndDateWithInvestorTransaction(accountId, lastDayOfMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithInvestorTransaction(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithInvestorTransaction(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} effectiveDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByBasedayWithInvestorTransaction(id: number, effectiveDate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findByBasedayWithInvestorTransaction(id, effectiveDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {string} effectiveDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForTxnDtailDividendWithInvestorTransaction(accountSeriesId: number, effectiveDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.findForTxnDtailDividendWithInvestorTransaction(accountSeriesId, effectiveDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} basedate 
         * @param {number} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNavFinalWithInvestorTransaction(basedate: string, accountSeriesId: number, options?: any): AxiosPromise<object> {
            return localVarFp.findNavFinalWithInvestorTransaction(basedate, accountSeriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seriesId 
         * @param {number} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneBySeriesDivisionWithInvestorTransaction(seriesId: number, divisionId: number, options?: any): AxiosPromise<object> {
            return localVarFp.findOneBySeriesDivisionWithInvestorTransaction(seriesId, divisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithInvestorTransaction(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithInvestorTransaction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} basedate 
         * @param {number} accountSeriesId 
         * @param {number} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemAllWithInvestorTransaction(basedate: string, accountSeriesId: number, divisionId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getRedeemAllWithInvestorTransaction(basedate, accountSeriesId, divisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingEqCrystalWithInvestorTransaction(fundSeriesId: number, divisionId: number, basedate: string, options?: any): AxiosPromise<object> {
            return localVarFp.mountingEqCrystalWithInvestorTransaction(fundSeriesId, divisionId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionItemWithInvestorTransaction(txnItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeTransactionItemWithInvestorTransaction(txnItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithInvestorTransaction(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithInvestorTransaction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {UpdateInvestorTransactionItemDto} updateInvestorTransactionItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionItemWithInvestorTransaction(txnItemId: string, updateInvestorTransactionItemDto: UpdateInvestorTransactionItemDto, options?: any): AxiosPromise<object> {
            return localVarFp.updateTransactionItemWithInvestorTransaction(txnItemId, updateInvestorTransactionItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateInvestorTransactionDto} updateInvestorTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithInvestorTransaction(id: string, updateInvestorTransactionDto: UpdateInvestorTransactionDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithInvestorTransaction(id, updateInvestorTransactionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountTransactionsApi - interface
 * @export
 * @interface AccountTransactionsApi
 */
export interface AccountTransactionsApiInterface {
    /**
     * 
     * @param {number} seriesId 
     * @param {number} divisionId 
     * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    createRedeemBySeriesAndDivisionWithInvestorTransaction(seriesId: number, divisionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} fundTransactionId 
     * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    createRedeemWithInvestorTransaction(fundTransactionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {CreateInvestorTransactionDto} createInvestorTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    createWithInvestorTransaction(createInvestorTransactionDto: CreateInvestorTransactionDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findAccountTransactionItemWithInvestorTransaction(txnItemId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} lastDayOfMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findAllTxnByAccountAndDateWithInvestorTransaction(accountId: number, lastDayOfMonth: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findAllWithInvestorTransaction(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} id 
     * @param {string} effectiveDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findByBasedayWithInvestorTransaction(id: number, effectiveDate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} accountSeriesId 
     * @param {string} effectiveDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findForTxnDtailDividendWithInvestorTransaction(accountSeriesId: number, effectiveDate: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} basedate 
     * @param {number} accountSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findNavFinalWithInvestorTransaction(basedate: string, accountSeriesId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} seriesId 
     * @param {number} divisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findOneBySeriesDivisionWithInvestorTransaction(seriesId: number, divisionId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    findOneWithInvestorTransaction(id: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} basedate 
     * @param {number} accountSeriesId 
     * @param {number} divisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    getRedeemAllWithInvestorTransaction(basedate: string, accountSeriesId: number, divisionId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} divisionId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    mountingEqCrystalWithInvestorTransaction(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    removeTransactionItemWithInvestorTransaction(txnItemId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    removeWithInvestorTransaction(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} txnItemId 
     * @param {UpdateInvestorTransactionItemDto} updateInvestorTransactionItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    updateTransactionItemWithInvestorTransaction(txnItemId: string, updateInvestorTransactionItemDto: UpdateInvestorTransactionItemDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateInvestorTransactionDto} updateInvestorTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApiInterface
     */
    updateWithInvestorTransaction(id: string, updateInvestorTransactionDto: UpdateInvestorTransactionDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AccountTransactionsApi - object-oriented interface
 * @export
 * @class AccountTransactionsApi
 * @extends {BaseAPI}
 */
export class AccountTransactionsApi extends BaseAPI implements AccountTransactionsApiInterface {
    /**
     * 
     * @param {number} seriesId 
     * @param {number} divisionId 
     * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public createRedeemBySeriesAndDivisionWithInvestorTransaction(seriesId: number, divisionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).createRedeemBySeriesAndDivisionWithInvestorTransaction(seriesId, divisionId, createRedeemInvTnxDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundTransactionId 
     * @param {CreateRedeemInvTnxDto} createRedeemInvTnxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public createRedeemWithInvestorTransaction(fundTransactionId: number, createRedeemInvTnxDto: CreateRedeemInvTnxDto, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).createRedeemWithInvestorTransaction(fundTransactionId, createRedeemInvTnxDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateInvestorTransactionDto} createInvestorTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public createWithInvestorTransaction(createInvestorTransactionDto: CreateInvestorTransactionDto, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).createWithInvestorTransaction(createInvestorTransactionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findAccountTransactionItemWithInvestorTransaction(txnItemId: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findAccountTransactionItemWithInvestorTransaction(txnItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} lastDayOfMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findAllTxnByAccountAndDateWithInvestorTransaction(accountId: number, lastDayOfMonth: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findAllTxnByAccountAndDateWithInvestorTransaction(accountId, lastDayOfMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findAllWithInvestorTransaction(options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findAllWithInvestorTransaction(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} effectiveDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findByBasedayWithInvestorTransaction(id: number, effectiveDate: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findByBasedayWithInvestorTransaction(id, effectiveDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountSeriesId 
     * @param {string} effectiveDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findForTxnDtailDividendWithInvestorTransaction(accountSeriesId: number, effectiveDate: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findForTxnDtailDividendWithInvestorTransaction(accountSeriesId, effectiveDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} basedate 
     * @param {number} accountSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findNavFinalWithInvestorTransaction(basedate: string, accountSeriesId: number, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findNavFinalWithInvestorTransaction(basedate, accountSeriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seriesId 
     * @param {number} divisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findOneBySeriesDivisionWithInvestorTransaction(seriesId: number, divisionId: number, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findOneBySeriesDivisionWithInvestorTransaction(seriesId, divisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public findOneWithInvestorTransaction(id: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).findOneWithInvestorTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} basedate 
     * @param {number} accountSeriesId 
     * @param {number} divisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public getRedeemAllWithInvestorTransaction(basedate: string, accountSeriesId: number, divisionId: number, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).getRedeemAllWithInvestorTransaction(basedate, accountSeriesId, divisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} divisionId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public mountingEqCrystalWithInvestorTransaction(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).mountingEqCrystalWithInvestorTransaction(fundSeriesId, divisionId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public removeTransactionItemWithInvestorTransaction(txnItemId: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).removeTransactionItemWithInvestorTransaction(txnItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public removeWithInvestorTransaction(id: string, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).removeWithInvestorTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} txnItemId 
     * @param {UpdateInvestorTransactionItemDto} updateInvestorTransactionItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public updateTransactionItemWithInvestorTransaction(txnItemId: string, updateInvestorTransactionItemDto: UpdateInvestorTransactionItemDto, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).updateTransactionItemWithInvestorTransaction(txnItemId, updateInvestorTransactionItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateInvestorTransactionDto} updateInvestorTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTransactionsApi
     */
    public updateWithInvestorTransaction(id: string, updateInvestorTransactionDto: UpdateInvestorTransactionDto, options?: AxiosRequestConfig) {
        return AccountTransactionsApiFp(this.configuration).updateWithInvestorTransaction(id, updateInvestorTransactionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAccountDto} createAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAccounts: async (createAccountDto: CreateAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountDto' is not null or undefined
            assertParamExists('createWithAccounts', 'createAccountDto', createAccountDto)
            const localVarPath = `/api/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckAccountNavFinalWithAccounts: async (accountId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('doubleCheckAccountNavFinalWithAccounts', 'accountId', accountId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('doubleCheckAccountNavFinalWithAccounts', 'basedate', basedate)
            const localVarPath = `/api/accounts/double-check-table/{accountId}/{basedate}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountEstimateNavWithAccounts: async (accountId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findAccountEstimateNavWithAccounts', 'accountId', accountId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findAccountEstimateNavWithAccounts', 'basedate', basedate)
            const localVarPath = `/api/accounts/estimate/{accountId}/{basedate}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountFinalNavWithAccounts: async (accountId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findAccountFinalNavWithAccounts', 'accountId', accountId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findAccountFinalNavWithAccounts', 'basedate', basedate)
            const localVarPath = `/api/accounts/final/{accountId}/{basedate}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} fields 
         * @param {Array<'id' | 'name' | 'abbreviation'>} eRRORUNKNOWN 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSpecifiedFieldsWithAccounts: async (fields: Array<string>, eRRORUNKNOWN: Array<'id' | 'name' | 'abbreviation'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fields' is not null or undefined
            assertParamExists('findAllSpecifiedFieldsWithAccounts', 'fields', fields)
            // verify required parameter 'eRRORUNKNOWN' is not null or undefined
            assertParamExists('findAllSpecifiedFieldsWithAccounts', 'eRRORUNKNOWN', eRRORUNKNOWN)
            const localVarPath = `/api/accounts/specified-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }

            if (eRRORUNKNOWN) {
                localVarQueryParameter[''] = eRRORUNKNOWN;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [basedate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClassesByAccountWithAccounts: async (id: string, basedate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findClassesByAccountWithAccounts', 'id', id)
            const localVarPath = `/api/accounts/{id}/account-classes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccounts: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithAccounts', 'id', id)
            const localVarPath = `/api/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [withPortfolio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveAccountsWithAccounts: async (withPortfolio?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/active-accounts-with-portfolio/incl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withPortfolio !== undefined) {
                localVarQueryParameter['withPortfolio'] = withPortfolio;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccounts: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithAccounts', 'id', id)
            const localVarPath = `/api/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccounts: async (id: string, updateAccountDto: UpdateAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithAccounts', 'id', id)
            // verify required parameter 'updateAccountDto' is not null or undefined
            assertParamExists('updateWithAccounts', 'updateAccountDto', updateAccountDto)
            const localVarPath = `/api/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAccountDto} createAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithAccounts(createAccountDto: CreateAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithAccounts(createAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doubleCheckAccountNavFinalWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doubleCheckAccountNavFinalWithAccounts(accountId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAccountEstimateNavWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAccountEstimateNavWithAccounts(accountId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAccountFinalNavWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAccountFinalNavWithAccounts(accountId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} fields 
         * @param {Array<'id' | 'name' | 'abbreviation'>} eRRORUNKNOWN 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSpecifiedFieldsWithAccounts(fields: Array<string>, eRRORUNKNOWN: Array<'id' | 'name' | 'abbreviation'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSpecifiedFieldsWithAccounts(fields, eRRORUNKNOWN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [basedate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findClassesByAccountWithAccounts(id: string, basedate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findClassesByAccountWithAccounts(id, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithAccounts(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithAccounts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [withPortfolio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveAccountsWithAccounts(withPortfolio?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveAccountsWithAccounts(withPortfolio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithAccounts(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithAccounts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithAccounts(id: string, updateAccountDto: UpdateAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithAccounts(id, updateAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAccountDto} createAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAccounts(createAccountDto: CreateAccountDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithAccounts(createAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckAccountNavFinalWithAccounts(accountId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.doubleCheckAccountNavFinalWithAccounts(accountId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountEstimateNavWithAccounts(accountId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAccountEstimateNavWithAccounts(accountId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountFinalNavWithAccounts(accountId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAccountFinalNavWithAccounts(accountId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} fields 
         * @param {Array<'id' | 'name' | 'abbreviation'>} eRRORUNKNOWN 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSpecifiedFieldsWithAccounts(fields: Array<string>, eRRORUNKNOWN: Array<'id' | 'name' | 'abbreviation'>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllSpecifiedFieldsWithAccounts(fields, eRRORUNKNOWN, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithAccounts(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [basedate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClassesByAccountWithAccounts(id: string, basedate?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findClassesByAccountWithAccounts(id, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAccounts(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithAccounts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [withPortfolio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveAccountsWithAccounts(withPortfolio?: boolean, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.listActiveAccountsWithAccounts(withPortfolio, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithAccounts(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.listWithAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAccounts(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithAccounts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAccounts(id: string, updateAccountDto: UpdateAccountDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithAccounts(id, updateAccountDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - interface
 * @export
 * @interface AccountsApi
 */
export interface AccountsApiInterface {
    /**
     * 
     * @param {CreateAccountDto} createAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    createWithAccounts(createAccountDto: CreateAccountDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    doubleCheckAccountNavFinalWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    findAccountEstimateNavWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    findAccountFinalNavWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {Array<string>} fields 
     * @param {Array<'id' | 'name' | 'abbreviation'>} eRRORUNKNOWN 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    findAllSpecifiedFieldsWithAccounts(fields: Array<string>, eRRORUNKNOWN: Array<'id' | 'name' | 'abbreviation'>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    findAllWithAccounts(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [basedate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    findClassesByAccountWithAccounts(id: string, basedate?: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    findOneWithAccounts(id: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {boolean} [withPortfolio] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    listActiveAccountsWithAccounts(withPortfolio?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    listWithAccounts(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    removeWithAccounts(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountDto} updateAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    updateWithAccounts(id: string, updateAccountDto: UpdateAccountDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI implements AccountsApiInterface {
    /**
     * 
     * @param {CreateAccountDto} createAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createWithAccounts(createAccountDto: CreateAccountDto, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).createWithAccounts(createAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public doubleCheckAccountNavFinalWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).doubleCheckAccountNavFinalWithAccounts(accountId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findAccountEstimateNavWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findAccountEstimateNavWithAccounts(accountId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findAccountFinalNavWithAccounts(accountId: number, basedate: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findAccountFinalNavWithAccounts(accountId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} fields 
     * @param {Array<'id' | 'name' | 'abbreviation'>} eRRORUNKNOWN 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findAllSpecifiedFieldsWithAccounts(fields: Array<string>, eRRORUNKNOWN: Array<'id' | 'name' | 'abbreviation'>, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findAllSpecifiedFieldsWithAccounts(fields, eRRORUNKNOWN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findAllWithAccounts(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findAllWithAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [basedate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findClassesByAccountWithAccounts(id: string, basedate?: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findClassesByAccountWithAccounts(id, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findOneWithAccounts(id: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findOneWithAccounts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [withPortfolio] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listActiveAccountsWithAccounts(withPortfolio?: boolean, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listActiveAccountsWithAccounts(withPortfolio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listWithAccounts(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listWithAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public removeWithAccounts(id: string, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).removeWithAccounts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountDto} updateAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateWithAccounts(id: string, updateAccountDto: UpdateAccountDto, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateWithAccounts(id, updateAccountDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationItemApi - axios parameter creator
 * @export
 */
export const AllocationItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAllocationItemDto} createAllocationItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAllocationItem: async (createAllocationItemDto: CreateAllocationItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAllocationItemDto' is not null or undefined
            assertParamExists('createWithAllocationItem', 'createAllocationItemDto', createAllocationItemDto)
            const localVarPath = `/api/allocation-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllocationItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} holdingSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByHoldingSeriesWithAllocationItem: async (holdingSeriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'holdingSeriesId' is not null or undefined
            assertParamExists('findAllByHoldingSeriesWithAllocationItem', 'holdingSeriesId', holdingSeriesId)
            const localVarPath = `/api/allocation-item/{holdingSeriesId}`
                .replace(`{${"holdingSeriesId"}}`, encodeURIComponent(String(holdingSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAllocationItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithAllocationItem', 'id', id)
            const localVarPath = `/api/allocation-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAllocationItemDto} updateAllocationItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAllocationItem: async (id: string, updateAllocationItemDto: UpdateAllocationItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithAllocationItem', 'id', id)
            // verify required parameter 'updateAllocationItemDto' is not null or undefined
            assertParamExists('updateWithAllocationItem', 'updateAllocationItemDto', updateAllocationItemDto)
            const localVarPath = `/api/allocation-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllocationItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationItemApi - functional programming interface
 * @export
 */
export const AllocationItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAllocationItemDto} createAllocationItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithAllocationItem(createAllocationItemDto: CreateAllocationItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithAllocationItem(createAllocationItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} holdingSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByHoldingSeriesWithAllocationItem(holdingSeriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByHoldingSeriesWithAllocationItem(holdingSeriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithAllocationItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithAllocationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAllocationItemDto} updateAllocationItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithAllocationItem(id: string, updateAllocationItemDto: UpdateAllocationItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithAllocationItem(id, updateAllocationItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationItemApi - factory interface
 * @export
 */
export const AllocationItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationItemApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAllocationItemDto} createAllocationItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAllocationItem(createAllocationItemDto: CreateAllocationItemDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithAllocationItem(createAllocationItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} holdingSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByHoldingSeriesWithAllocationItem(holdingSeriesId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllByHoldingSeriesWithAllocationItem(holdingSeriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAllocationItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithAllocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAllocationItemDto} updateAllocationItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAllocationItem(id: string, updateAllocationItemDto: UpdateAllocationItemDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithAllocationItem(id, updateAllocationItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationItemApi - interface
 * @export
 * @interface AllocationItemApi
 */
export interface AllocationItemApiInterface {
    /**
     * 
     * @param {CreateAllocationItemDto} createAllocationItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApiInterface
     */
    createWithAllocationItem(createAllocationItemDto: CreateAllocationItemDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} holdingSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApiInterface
     */
    findAllByHoldingSeriesWithAllocationItem(holdingSeriesId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApiInterface
     */
    removeWithAllocationItem(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateAllocationItemDto} updateAllocationItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApiInterface
     */
    updateWithAllocationItem(id: string, updateAllocationItemDto: UpdateAllocationItemDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AllocationItemApi - object-oriented interface
 * @export
 * @class AllocationItemApi
 * @extends {BaseAPI}
 */
export class AllocationItemApi extends BaseAPI implements AllocationItemApiInterface {
    /**
     * 
     * @param {CreateAllocationItemDto} createAllocationItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApi
     */
    public createWithAllocationItem(createAllocationItemDto: CreateAllocationItemDto, options?: AxiosRequestConfig) {
        return AllocationItemApiFp(this.configuration).createWithAllocationItem(createAllocationItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} holdingSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApi
     */
    public findAllByHoldingSeriesWithAllocationItem(holdingSeriesId: number, options?: AxiosRequestConfig) {
        return AllocationItemApiFp(this.configuration).findAllByHoldingSeriesWithAllocationItem(holdingSeriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApi
     */
    public removeWithAllocationItem(id: string, options?: AxiosRequestConfig) {
        return AllocationItemApiFp(this.configuration).removeWithAllocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAllocationItemDto} updateAllocationItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationItemApi
     */
    public updateWithAllocationItem(id: string, updateAllocationItemDto: UpdateAllocationItemDto, options?: AxiosRequestConfig) {
        return AllocationItemApiFp(this.configuration).updateWithAllocationItem(id, updateAllocationItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationsApi - axios parameter creator
 * @export
 */
export const AllocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAllocationDto} createAllocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAllocations: async (createAllocationDto: CreateAllocationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAllocationDto' is not null or undefined
            assertParamExists('createWithAllocations', 'createAllocationDto', createAllocationDto)
            const localVarPath = `/api/allocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUnderPortfolioByBaseDateWithAllocations: async (portfolioId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('findAllUnderPortfolioByBaseDateWithAllocations', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findAllUnderPortfolioByBaseDateWithAllocations', 'basedate', basedate)
            const localVarPath = `/api/allocations/under-portfolio/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAllocations: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithAllocations', 'id', id)
            const localVarPath = `/api/allocations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAllocations: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithAllocations', 'id', id)
            const localVarPath = `/api/allocations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAllocationDto} updateAllocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAllocations: async (id: string, updateAllocationDto: UpdateAllocationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithAllocations', 'id', id)
            // verify required parameter 'updateAllocationDto' is not null or undefined
            assertParamExists('updateWithAllocations', 'updateAllocationDto', updateAllocationDto)
            const localVarPath = `/api/allocations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationsApi - functional programming interface
 * @export
 */
export const AllocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAllocationDto} createAllocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithAllocations(createAllocationDto: CreateAllocationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithAllocations(createAllocationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllUnderPortfolioByBaseDateWithAllocations(portfolioId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAllocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllUnderPortfolioByBaseDateWithAllocations(portfolioId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithAllocations(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithAllocations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithAllocations(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithAllocations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAllocationDto} updateAllocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithAllocations(id: string, updateAllocationDto: UpdateAllocationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithAllocations(id, updateAllocationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationsApi - factory interface
 * @export
 */
export const AllocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAllocationDto} createAllocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithAllocations(createAllocationDto: CreateAllocationDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithAllocations(createAllocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUnderPortfolioByBaseDateWithAllocations(portfolioId: number, basedate: string, options?: any): AxiosPromise<ResponseAllocationDto> {
            return localVarFp.findAllUnderPortfolioByBaseDateWithAllocations(portfolioId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithAllocations(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithAllocations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithAllocations(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithAllocations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAllocationDto} updateAllocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithAllocations(id: string, updateAllocationDto: UpdateAllocationDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithAllocations(id, updateAllocationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationsApi - interface
 * @export
 * @interface AllocationsApi
 */
export interface AllocationsApiInterface {
    /**
     * 
     * @param {CreateAllocationDto} createAllocationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApiInterface
     */
    createWithAllocations(createAllocationDto: CreateAllocationDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApiInterface
     */
    findAllUnderPortfolioByBaseDateWithAllocations(portfolioId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<ResponseAllocationDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApiInterface
     */
    findOneWithAllocations(id: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApiInterface
     */
    removeWithAllocations(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateAllocationDto} updateAllocationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApiInterface
     */
    updateWithAllocations(id: string, updateAllocationDto: UpdateAllocationDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AllocationsApi - object-oriented interface
 * @export
 * @class AllocationsApi
 * @extends {BaseAPI}
 */
export class AllocationsApi extends BaseAPI implements AllocationsApiInterface {
    /**
     * 
     * @param {CreateAllocationDto} createAllocationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public createWithAllocations(createAllocationDto: CreateAllocationDto, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).createWithAllocations(createAllocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public findAllUnderPortfolioByBaseDateWithAllocations(portfolioId: number, basedate: string, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).findAllUnderPortfolioByBaseDateWithAllocations(portfolioId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public findOneWithAllocations(id: string, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).findOneWithAllocations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public removeWithAllocations(id: string, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).removeWithAllocations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAllocationDto} updateAllocationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public updateWithAllocations(id: string, updateAllocationDto: UpdateAllocationDto, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).updateWithAllocations(id, updateAllocationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticalToolsApi - axios parameter creator
 * @export
 */
export const AnalyticalToolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} from 
         * @param {string} to 
         * @param {'Bp' | 'AMT'} attributionType 
         * @param {string} currencyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceLoaderWithAnalytical: async (portfolioIds: Array<number>, from: string, to: string, attributionType: 'Bp' | 'AMT', currencyType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioIds' is not null or undefined
            assertParamExists('performanceLoaderWithAnalytical', 'portfolioIds', portfolioIds)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('performanceLoaderWithAnalytical', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('performanceLoaderWithAnalytical', 'to', to)
            // verify required parameter 'attributionType' is not null or undefined
            assertParamExists('performanceLoaderWithAnalytical', 'attributionType', attributionType)
            // verify required parameter 'currencyType' is not null or undefined
            assertParamExists('performanceLoaderWithAnalytical', 'currencyType', currencyType)
            const localVarPath = `/api/analytical/monthlyFund-performance-loader`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (portfolioIds) {
                localVarQueryParameter['portfolioIds'] = portfolioIds;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (attributionType !== undefined) {
                localVarQueryParameter['attributionType'] = attributionType;
            }

            if (currencyType !== undefined) {
                localVarQueryParameter['currencyType'] = currencyType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} basedate 
         * @param {'currency' | 'class'} groupBy 
         * @param {number} [benchmark] 
         * @param {boolean} [isAppliedAccountMTD] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceMTDWithAnalytical: async (portfolioIds: Array<number>, basedate: string, groupBy: 'currency' | 'class', benchmark?: number, isAppliedAccountMTD?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioIds' is not null or undefined
            assertParamExists('performanceMTDWithAnalytical', 'portfolioIds', portfolioIds)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('performanceMTDWithAnalytical', 'basedate', basedate)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('performanceMTDWithAnalytical', 'groupBy', groupBy)
            const localVarPath = `/api/analytical/performance-mtd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (portfolioIds) {
                localVarQueryParameter['portfolioIds'] = portfolioIds;
            }

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (benchmark !== undefined) {
                localVarQueryParameter['benchmark'] = benchmark;
            }

            if (isAppliedAccountMTD !== undefined) {
                localVarQueryParameter['isAppliedAccountMTD'] = isAppliedAccountMTD;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['groupBy'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} basedate 
         * @param {'NetFunding' | 'Return'} returnType 
         * @param {boolean} [consolidate] 
         * @param {boolean} [displayLm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        strategyAllocationWarWithAnalytical: async (portfolioIds: Array<number>, basedate: string, returnType: 'NetFunding' | 'Return', consolidate?: boolean, displayLm?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioIds' is not null or undefined
            assertParamExists('strategyAllocationWarWithAnalytical', 'portfolioIds', portfolioIds)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('strategyAllocationWarWithAnalytical', 'basedate', basedate)
            // verify required parameter 'returnType' is not null or undefined
            assertParamExists('strategyAllocationWarWithAnalytical', 'returnType', returnType)
            const localVarPath = `/api/analytical/strategy-allocation-war`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (portfolioIds) {
                localVarQueryParameter['portfolioIds'] = portfolioIds;
            }

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (returnType !== undefined) {
                localVarQueryParameter['returnType'] = returnType;
            }

            if (consolidate !== undefined) {
                localVarQueryParameter['consolidate'] = consolidate;
            }

            if (displayLm !== undefined) {
                localVarQueryParameter['displayLm'] = displayLm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticalToolsApi - functional programming interface
 * @export
 */
export const AnalyticalToolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticalToolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} from 
         * @param {string} to 
         * @param {'Bp' | 'AMT'} attributionType 
         * @param {string} currencyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceLoaderWithAnalytical(portfolioIds: Array<number>, from: string, to: string, attributionType: 'Bp' | 'AMT', currencyType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceLoaderWithAnalytical(portfolioIds, from, to, attributionType, currencyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} basedate 
         * @param {'currency' | 'class'} groupBy 
         * @param {number} [benchmark] 
         * @param {boolean} [isAppliedAccountMTD] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceMTDWithAnalytical(portfolioIds: Array<number>, basedate: string, groupBy: 'currency' | 'class', benchmark?: number, isAppliedAccountMTD?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceMTDWithAnalytical(portfolioIds, basedate, groupBy, benchmark, isAppliedAccountMTD, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} basedate 
         * @param {'NetFunding' | 'Return'} returnType 
         * @param {boolean} [consolidate] 
         * @param {boolean} [displayLm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async strategyAllocationWarWithAnalytical(portfolioIds: Array<number>, basedate: string, returnType: 'NetFunding' | 'Return', consolidate?: boolean, displayLm?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.strategyAllocationWarWithAnalytical(portfolioIds, basedate, returnType, consolidate, displayLm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticalToolsApi - factory interface
 * @export
 */
export const AnalyticalToolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticalToolsApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} from 
         * @param {string} to 
         * @param {'Bp' | 'AMT'} attributionType 
         * @param {string} currencyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceLoaderWithAnalytical(portfolioIds: Array<number>, from: string, to: string, attributionType: 'Bp' | 'AMT', currencyType: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.performanceLoaderWithAnalytical(portfolioIds, from, to, attributionType, currencyType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} basedate 
         * @param {'currency' | 'class'} groupBy 
         * @param {number} [benchmark] 
         * @param {boolean} [isAppliedAccountMTD] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceMTDWithAnalytical(portfolioIds: Array<number>, basedate: string, groupBy: 'currency' | 'class', benchmark?: number, isAppliedAccountMTD?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.performanceMTDWithAnalytical(portfolioIds, basedate, groupBy, benchmark, isAppliedAccountMTD, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} portfolioIds 
         * @param {string} basedate 
         * @param {'NetFunding' | 'Return'} returnType 
         * @param {boolean} [consolidate] 
         * @param {boolean} [displayLm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        strategyAllocationWarWithAnalytical(portfolioIds: Array<number>, basedate: string, returnType: 'NetFunding' | 'Return', consolidate?: boolean, displayLm?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.strategyAllocationWarWithAnalytical(portfolioIds, basedate, returnType, consolidate, displayLm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticalToolsApi - interface
 * @export
 * @interface AnalyticalToolsApi
 */
export interface AnalyticalToolsApiInterface {
    /**
     * 
     * @param {Array<number>} portfolioIds 
     * @param {string} from 
     * @param {string} to 
     * @param {'Bp' | 'AMT'} attributionType 
     * @param {string} currencyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticalToolsApiInterface
     */
    performanceLoaderWithAnalytical(portfolioIds: Array<number>, from: string, to: string, attributionType: 'Bp' | 'AMT', currencyType: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {Array<number>} portfolioIds 
     * @param {string} basedate 
     * @param {'currency' | 'class'} groupBy 
     * @param {number} [benchmark] 
     * @param {boolean} [isAppliedAccountMTD] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticalToolsApiInterface
     */
    performanceMTDWithAnalytical(portfolioIds: Array<number>, basedate: string, groupBy: 'currency' | 'class', benchmark?: number, isAppliedAccountMTD?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<number>} portfolioIds 
     * @param {string} basedate 
     * @param {'NetFunding' | 'Return'} returnType 
     * @param {boolean} [consolidate] 
     * @param {boolean} [displayLm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticalToolsApiInterface
     */
    strategyAllocationWarWithAnalytical(portfolioIds: Array<number>, basedate: string, returnType: 'NetFunding' | 'Return', consolidate?: boolean, displayLm?: boolean, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * AnalyticalToolsApi - object-oriented interface
 * @export
 * @class AnalyticalToolsApi
 * @extends {BaseAPI}
 */
export class AnalyticalToolsApi extends BaseAPI implements AnalyticalToolsApiInterface {
    /**
     * 
     * @param {Array<number>} portfolioIds 
     * @param {string} from 
     * @param {string} to 
     * @param {'Bp' | 'AMT'} attributionType 
     * @param {string} currencyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticalToolsApi
     */
    public performanceLoaderWithAnalytical(portfolioIds: Array<number>, from: string, to: string, attributionType: 'Bp' | 'AMT', currencyType: string, options?: AxiosRequestConfig) {
        return AnalyticalToolsApiFp(this.configuration).performanceLoaderWithAnalytical(portfolioIds, from, to, attributionType, currencyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} portfolioIds 
     * @param {string} basedate 
     * @param {'currency' | 'class'} groupBy 
     * @param {number} [benchmark] 
     * @param {boolean} [isAppliedAccountMTD] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticalToolsApi
     */
    public performanceMTDWithAnalytical(portfolioIds: Array<number>, basedate: string, groupBy: 'currency' | 'class', benchmark?: number, isAppliedAccountMTD?: boolean, options?: AxiosRequestConfig) {
        return AnalyticalToolsApiFp(this.configuration).performanceMTDWithAnalytical(portfolioIds, basedate, groupBy, benchmark, isAppliedAccountMTD, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} portfolioIds 
     * @param {string} basedate 
     * @param {'NetFunding' | 'Return'} returnType 
     * @param {boolean} [consolidate] 
     * @param {boolean} [displayLm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticalToolsApi
     */
    public strategyAllocationWarWithAnalytical(portfolioIds: Array<number>, basedate: string, returnType: 'NetFunding' | 'Return', consolidate?: boolean, displayLm?: boolean, options?: AxiosRequestConfig) {
        return AnalyticalToolsApiFp(this.configuration).strategyAllocationWarWithAnalytical(portfolioIds, basedate, returnType, consolidate, displayLm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateSysuserDto} updateSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoginWithAuth: async (id: number, updateSysuserDto: UpdateSysuserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createLoginWithAuth', 'id', id)
            // verify required parameter 'updateSysuserDto' is not null or undefined
            assertParamExists('createLoginWithAuth', 'updateSysuserDto', updateSysuserDto)
            const localVarPath = `/api/auth/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSysuserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticateRequestDto} authenticateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithAuth: async (authenticateRequestDto: AuthenticateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticateRequestDto' is not null or undefined
            assertParamExists('loginWithAuth', 'authenticateRequestDto', authenticateRequestDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterRequestDto} registerRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithAuth: async (registerRequestDto: RegisterRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequestDto' is not null or undefined
            assertParamExists('registerWithAuth', 'registerRequestDto', registerRequestDto)
            const localVarPath = `/api/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendRequestDto} resendRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendWithAuth: async (resendRequestDto: ResendRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendRequestDto' is not null or undefined
            assertParamExists('resendWithAuth', 'resendRequestDto', resendRequestDto)
            const localVarPath = `/api/auth/resend-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateSysuserDto} updateSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLoginWithAuth(id: number, updateSysuserDto: UpdateSysuserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLoginWithAuth(id, updateSysuserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthenticateRequestDto} authenticateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithAuth(authenticateRequestDto: AuthenticateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithAuth(authenticateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterRequestDto} registerRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerWithAuth(registerRequestDto: RegisterRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerWithAuth(registerRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResendRequestDto} resendRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendWithAuth(resendRequestDto: ResendRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendWithAuth(resendRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateSysuserDto} updateSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoginWithAuth(id: number, updateSysuserDto: UpdateSysuserDto, options?: any): AxiosPromise<object> {
            return localVarFp.createLoginWithAuth(id, updateSysuserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticateRequestDto} authenticateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithAuth(authenticateRequestDto: AuthenticateRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.loginWithAuth(authenticateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterRequestDto} registerRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithAuth(registerRequestDto: RegisterRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.registerWithAuth(registerRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendRequestDto} resendRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendWithAuth(resendRequestDto: ResendRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.resendWithAuth(resendRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {UpdateSysuserDto} updateSysuserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    createLoginWithAuth(id: number, updateSysuserDto: UpdateSysuserDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {AuthenticateRequestDto} authenticateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    loginWithAuth(authenticateRequestDto: AuthenticateRequestDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {RegisterRequestDto} registerRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    registerWithAuth(registerRequestDto: RegisterRequestDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {ResendRequestDto} resendRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    resendWithAuth(resendRequestDto: ResendRequestDto, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {UpdateSysuserDto} updateSysuserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createLoginWithAuth(id: number, updateSysuserDto: UpdateSysuserDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).createLoginWithAuth(id, updateSysuserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticateRequestDto} authenticateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginWithAuth(authenticateRequestDto: AuthenticateRequestDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginWithAuth(authenticateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterRequestDto} registerRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public registerWithAuth(registerRequestDto: RegisterRequestDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).registerWithAuth(registerRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResendRequestDto} resendRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resendWithAuth(resendRequestDto: ResendRequestDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resendWithAuth(resendRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CityApi - axios parameter creator
 * @export
 */
export const CityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCityDto} createCityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithCities: async (createCityDto: CreateCityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCityDto' is not null or undefined
            assertParamExists('createWithCities', 'createCityDto', createCityDto)
            const localVarPath = `/api/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithCities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithCities: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithCities', 'id', id)
            const localVarPath = `/api/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithCities: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithCities', 'id', id)
            const localVarPath = `/api/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCityDto} updateCityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithCities: async (id: number, updateCityDto: UpdateCityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithCities', 'id', id)
            // verify required parameter 'updateCityDto' is not null or undefined
            assertParamExists('updateWithCities', 'updateCityDto', updateCityDto)
            const localVarPath = `/api/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CityApi - functional programming interface
 * @export
 */
export const CityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCityDto} createCityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithCities(createCityDto: CreateCityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithCities(createCityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithCities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithCities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithCities(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithCities(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithCities(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithCities(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCityDto} updateCityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithCities(id: number, updateCityDto: UpdateCityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithCities(id, updateCityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CityApi - factory interface
 * @export
 */
export const CityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CityApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCityDto} createCityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithCities(createCityDto: CreateCityDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithCities(createCityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithCities(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithCities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithCities(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithCities(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithCities(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithCities(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCityDto} updateCityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithCities(id: number, updateCityDto: UpdateCityDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithCities(id, updateCityDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CityApi - interface
 * @export
 * @interface CityApi
 */
export interface CityApiInterface {
    /**
     * 
     * @param {CreateCityDto} createCityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApiInterface
     */
    createWithCities(createCityDto: CreateCityDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApiInterface
     */
    findAllWithCities(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApiInterface
     */
    findOneWithCities(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApiInterface
     */
    removeWithCities(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {UpdateCityDto} updateCityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApiInterface
     */
    updateWithCities(id: number, updateCityDto: UpdateCityDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CityApi - object-oriented interface
 * @export
 * @class CityApi
 * @extends {BaseAPI}
 */
export class CityApi extends BaseAPI implements CityApiInterface {
    /**
     * 
     * @param {CreateCityDto} createCityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public createWithCities(createCityDto: CreateCityDto, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).createWithCities(createCityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public findAllWithCities(options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).findAllWithCities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public findOneWithCities(id: string, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).findOneWithCities(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public removeWithCities(id: number, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).removeWithCities(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateCityDto} updateCityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public updateWithCities(id: number, updateCityDto: UpdateCityDto, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).updateWithCities(id, updateCityDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClassSeriesChangeApi - axios parameter creator
 * @export
 */
export const ClassSeriesChangeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateClassSeriesChangeDto} createClassSeriesChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithClassSeriesChange: async (createClassSeriesChangeDto: CreateClassSeriesChangeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClassSeriesChangeDto' is not null or undefined
            assertParamExists('createWithClassSeriesChange', 'createClassSeriesChangeDto', createClassSeriesChangeDto)
            const localVarPath = `/api/class-series-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClassSeriesChangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithClassSeriesChange: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/class-series-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithClassSeriesChange: async (seriesId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('findOneWithClassSeriesChange', 'seriesId', seriesId)
            const localVarPath = `/api/class-series-change/{seriesId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {string} currency 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingWithClassSeriesChange: async (fundId: number, portfolioId: number, currency: string, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('mountingWithClassSeriesChange', 'fundId', fundId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('mountingWithClassSeriesChange', 'portfolioId', portfolioId)
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('mountingWithClassSeriesChange', 'currency', currency)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('mountingWithClassSeriesChange', 'basedate', basedate)
            const localVarPath = `/api/class-series-change/mounting/{fundId}/{portfolioId}/{currency}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithClassSeriesChange: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithClassSeriesChange', 'id', id)
            const localVarPath = `/api/class-series-change/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateClassSeriesChangeDto} updateClassSeriesChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithClassSeriesChange: async (id: string, updateClassSeriesChangeDto: UpdateClassSeriesChangeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithClassSeriesChange', 'id', id)
            // verify required parameter 'updateClassSeriesChangeDto' is not null or undefined
            assertParamExists('updateWithClassSeriesChange', 'updateClassSeriesChangeDto', updateClassSeriesChangeDto)
            const localVarPath = `/api/class-series-change/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClassSeriesChangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassSeriesChangeApi - functional programming interface
 * @export
 */
export const ClassSeriesChangeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClassSeriesChangeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateClassSeriesChangeDto} createClassSeriesChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithClassSeriesChange(createClassSeriesChangeDto: CreateClassSeriesChangeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithClassSeriesChange(createClassSeriesChangeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithClassSeriesChange(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithClassSeriesChange(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithClassSeriesChange(seriesId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithClassSeriesChange(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {string} currency 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mountingWithClassSeriesChange(fundId: number, portfolioId: number, currency: string, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mountingWithClassSeriesChange(fundId, portfolioId, currency, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithClassSeriesChange(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithClassSeriesChange(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateClassSeriesChangeDto} updateClassSeriesChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithClassSeriesChange(id: string, updateClassSeriesChangeDto: UpdateClassSeriesChangeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithClassSeriesChange(id, updateClassSeriesChangeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClassSeriesChangeApi - factory interface
 * @export
 */
export const ClassSeriesChangeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClassSeriesChangeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateClassSeriesChangeDto} createClassSeriesChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithClassSeriesChange(createClassSeriesChangeDto: CreateClassSeriesChangeDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithClassSeriesChange(createClassSeriesChangeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithClassSeriesChange(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithClassSeriesChange(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithClassSeriesChange(seriesId: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithClassSeriesChange(seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {string} currency 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingWithClassSeriesChange(fundId: number, portfolioId: number, currency: string, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.mountingWithClassSeriesChange(fundId, portfolioId, currency, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithClassSeriesChange(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithClassSeriesChange(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateClassSeriesChangeDto} updateClassSeriesChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithClassSeriesChange(id: string, updateClassSeriesChangeDto: UpdateClassSeriesChangeDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.updateWithClassSeriesChange(id, updateClassSeriesChangeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassSeriesChangeApi - interface
 * @export
 * @interface ClassSeriesChangeApi
 */
export interface ClassSeriesChangeApiInterface {
    /**
     * 
     * @param {CreateClassSeriesChangeDto} createClassSeriesChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApiInterface
     */
    createWithClassSeriesChange(createClassSeriesChangeDto: CreateClassSeriesChangeDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApiInterface
     */
    findAllWithClassSeriesChange(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApiInterface
     */
    findOneWithClassSeriesChange(seriesId: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {string} currency 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApiInterface
     */
    mountingWithClassSeriesChange(fundId: number, portfolioId: number, currency: string, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApiInterface
     */
    removeWithClassSeriesChange(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateClassSeriesChangeDto} updateClassSeriesChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApiInterface
     */
    updateWithClassSeriesChange(id: string, updateClassSeriesChangeDto: UpdateClassSeriesChangeDto, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

}

/**
 * ClassSeriesChangeApi - object-oriented interface
 * @export
 * @class ClassSeriesChangeApi
 * @extends {BaseAPI}
 */
export class ClassSeriesChangeApi extends BaseAPI implements ClassSeriesChangeApiInterface {
    /**
     * 
     * @param {CreateClassSeriesChangeDto} createClassSeriesChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApi
     */
    public createWithClassSeriesChange(createClassSeriesChangeDto: CreateClassSeriesChangeDto, options?: AxiosRequestConfig) {
        return ClassSeriesChangeApiFp(this.configuration).createWithClassSeriesChange(createClassSeriesChangeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApi
     */
    public findAllWithClassSeriesChange(options?: AxiosRequestConfig) {
        return ClassSeriesChangeApiFp(this.configuration).findAllWithClassSeriesChange(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApi
     */
    public findOneWithClassSeriesChange(seriesId: string, options?: AxiosRequestConfig) {
        return ClassSeriesChangeApiFp(this.configuration).findOneWithClassSeriesChange(seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {string} currency 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApi
     */
    public mountingWithClassSeriesChange(fundId: number, portfolioId: number, currency: string, basedate: string, options?: AxiosRequestConfig) {
        return ClassSeriesChangeApiFp(this.configuration).mountingWithClassSeriesChange(fundId, portfolioId, currency, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApi
     */
    public removeWithClassSeriesChange(id: string, options?: AxiosRequestConfig) {
        return ClassSeriesChangeApiFp(this.configuration).removeWithClassSeriesChange(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateClassSeriesChangeDto} updateClassSeriesChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassSeriesChangeApi
     */
    public updateWithClassSeriesChange(id: string, updateClassSeriesChangeDto: UpdateClassSeriesChangeDto, options?: AxiosRequestConfig) {
        return ClassSeriesChangeApiFp(this.configuration).updateWithClassSeriesChange(id, updateClassSeriesChangeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataViewerAccountsApi - axios parameter creator
 * @export
 */
export const DataViewerAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationWithDataviewAccounts: async (portfolioId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('allocationWithDataviewAccounts', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('allocationWithDataviewAccounts', 'basedate', basedate)
            const localVarPath = `/api/dataview-accounts/allocation/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [fundId] 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorTxnWithDataviewAccounts: async (fundId?: number, investorView?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dataview-accounts/investor-transactions/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investorView !== undefined) {
                localVarQueryParameter['investorView'] = investorView;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithDataviewAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dataview-accounts/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterWithDataviewAccounts: async (accountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('masterWithDataviewAccounts', 'accountId', accountId)
            const localVarPath = `/api/dataview-accounts/master/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {'year_by_month' | 'list'} style 
         * @param {number} [fundClassId] 
         * @param {number} [fundSeriesId] 
         * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navbvWithDataviewAccounts: async (fundId: number, style: 'year_by_month' | 'list', fundClassId?: number, fundSeriesId?: number, currency?: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('navbvWithDataviewAccounts', 'fundId', fundId)
            // verify required parameter 'style' is not null or undefined
            assertParamExists('navbvWithDataviewAccounts', 'style', style)
            const localVarPath = `/api/dataview-accounts/navbv/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (style !== undefined) {
                localVarQueryParameter['style'] = style;
            }

            if (fundClassId !== undefined) {
                localVarQueryParameter['fundClassId'] = fundClassId;
            }

            if (fundSeriesId !== undefined) {
                localVarQueryParameter['fundSeriesId'] = fundSeriesId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceWithDataviewAccounts: async (fundSeriesId: number, style: 'year_by_month' | 'list', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundSeriesId' is not null or undefined
            assertParamExists('performanceWithDataviewAccounts', 'fundSeriesId', fundSeriesId)
            // verify required parameter 'style' is not null or undefined
            assertParamExists('performanceWithDataviewAccounts', 'style', style)
            const localVarPath = `/api/dataview-accounts/performance/{fundSeriesId}`
                .replace(`{${"fundSeriesId"}}`, encodeURIComponent(String(fundSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (style !== undefined) {
                localVarQueryParameter['style'] = style;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioTxnWithDataviewAccounts: async (accountId: number, investorView?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('portfolioTxnWithDataviewAccounts', 'accountId', accountId)
            const localVarPath = `/api/dataview-accounts/portfolio-transactions/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investorView !== undefined) {
                localVarQueryParameter['investorView'] = investorView;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareRegistryWithDataviewAccounts: async (accountId: number, basedate: string, investorView?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('shareRegistryWithDataviewAccounts', 'accountId', accountId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('shareRegistryWithDataviewAccounts', 'basedate', basedate)
            const localVarPath = `/api/dataview-accounts/share-registry/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (investorView !== undefined) {
                localVarQueryParameter['investorView'] = investorView;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataViewerAccountsApi - functional programming interface
 * @export
 */
export const DataViewerAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataViewerAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationWithDataviewAccounts(portfolioId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationWithDataviewAccounts(portfolioId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [fundId] 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorTxnWithDataviewAccounts(fundId?: number, investorView?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseAccountInvestorTxnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorTxnWithDataviewAccounts(fundId, investorView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithDataviewAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseAccountListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithDataviewAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterWithDataviewAccounts(accountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterWithDataviewAccounts(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {'year_by_month' | 'list'} style 
         * @param {number} [fundClassId] 
         * @param {number} [fundSeriesId] 
         * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navbvWithDataviewAccounts(fundId: number, style: 'year_by_month' | 'list', fundClassId?: number, fundSeriesId?: number, currency?: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.navbvWithDataviewAccounts(fundId, style, fundClassId, fundSeriesId, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceWithDataviewAccounts(fundSeriesId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceWithDataviewAccounts(fundSeriesId, style, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioTxnWithDataviewAccounts(accountId: number, investorView?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseAccountPortTxnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioTxnWithDataviewAccounts(accountId, investorView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareRegistryWithDataviewAccounts(accountId: number, basedate: string, investorView?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareRegistryWithDataviewAccounts(accountId, basedate, investorView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataViewerAccountsApi - factory interface
 * @export
 */
export const DataViewerAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataViewerAccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationWithDataviewAccounts(portfolioId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.allocationWithDataviewAccounts(portfolioId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [fundId] 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorTxnWithDataviewAccounts(fundId?: number, investorView?: boolean, options?: any): AxiosPromise<Array<ResponseAccountInvestorTxnDto>> {
            return localVarFp.investorTxnWithDataviewAccounts(fundId, investorView, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithDataviewAccounts(options?: any): AxiosPromise<Array<ResponseAccountListDto>> {
            return localVarFp.listWithDataviewAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterWithDataviewAccounts(accountId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.masterWithDataviewAccounts(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {'year_by_month' | 'list'} style 
         * @param {number} [fundClassId] 
         * @param {number} [fundSeriesId] 
         * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navbvWithDataviewAccounts(fundId: number, style: 'year_by_month' | 'list', fundClassId?: number, fundSeriesId?: number, currency?: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: any): AxiosPromise<object> {
            return localVarFp.navbvWithDataviewAccounts(fundId, style, fundClassId, fundSeriesId, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceWithDataviewAccounts(fundSeriesId: number, style: 'year_by_month' | 'list', options?: any): AxiosPromise<object> {
            return localVarFp.performanceWithDataviewAccounts(fundSeriesId, style, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioTxnWithDataviewAccounts(accountId: number, investorView?: boolean, options?: any): AxiosPromise<Array<ResponseAccountPortTxnDto>> {
            return localVarFp.portfolioTxnWithDataviewAccounts(accountId, investorView, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareRegistryWithDataviewAccounts(accountId: number, basedate: string, investorView?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.shareRegistryWithDataviewAccounts(accountId, basedate, investorView, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataViewerAccountsApi - interface
 * @export
 * @interface DataViewerAccountsApi
 */
export interface DataViewerAccountsApiInterface {
    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    allocationWithDataviewAccounts(portfolioId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} [fundId] 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    investorTxnWithDataviewAccounts(fundId?: number, investorView?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<ResponseAccountInvestorTxnDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    listWithDataviewAccounts(options?: AxiosRequestConfig): AxiosPromise<Array<ResponseAccountListDto>>;

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    masterWithDataviewAccounts(accountId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundId 
     * @param {'year_by_month' | 'list'} style 
     * @param {number} [fundClassId] 
     * @param {number} [fundSeriesId] 
     * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    navbvWithDataviewAccounts(fundId: number, style: 'year_by_month' | 'list', fundClassId?: number, fundSeriesId?: number, currency?: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    performanceWithDataviewAccounts(fundSeriesId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} accountId 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    portfolioTxnWithDataviewAccounts(accountId: number, investorView?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<ResponseAccountPortTxnDto>>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApiInterface
     */
    shareRegistryWithDataviewAccounts(accountId: number, basedate: string, investorView?: boolean, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * DataViewerAccountsApi - object-oriented interface
 * @export
 * @class DataViewerAccountsApi
 * @extends {BaseAPI}
 */
export class DataViewerAccountsApi extends BaseAPI implements DataViewerAccountsApiInterface {
    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public allocationWithDataviewAccounts(portfolioId: number, basedate: string, options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).allocationWithDataviewAccounts(portfolioId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [fundId] 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public investorTxnWithDataviewAccounts(fundId?: number, investorView?: boolean, options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).investorTxnWithDataviewAccounts(fundId, investorView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public listWithDataviewAccounts(options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).listWithDataviewAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public masterWithDataviewAccounts(accountId: number, options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).masterWithDataviewAccounts(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {'year_by_month' | 'list'} style 
     * @param {number} [fundClassId] 
     * @param {number} [fundSeriesId] 
     * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public navbvWithDataviewAccounts(fundId: number, style: 'year_by_month' | 'list', fundClassId?: number, fundSeriesId?: number, currency?: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).navbvWithDataviewAccounts(fundId, style, fundClassId, fundSeriesId, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public performanceWithDataviewAccounts(fundSeriesId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).performanceWithDataviewAccounts(fundSeriesId, style, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public portfolioTxnWithDataviewAccounts(accountId: number, investorView?: boolean, options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).portfolioTxnWithDataviewAccounts(accountId, investorView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerAccountsApi
     */
    public shareRegistryWithDataviewAccounts(accountId: number, basedate: string, investorView?: boolean, options?: AxiosRequestConfig) {
        return DataViewerAccountsApiFp(this.configuration).shareRegistryWithDataviewAccounts(accountId, basedate, investorView, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataViewerHedgeFundsApi - axios parameter creator
 * @export
 */
export const DataViewerHedgeFundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hfMasterListWithDataviewHedgefunds: async (fundId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('hfMasterListWithDataviewHedgefunds', 'fundId', fundId)
            const localVarPath = `/api/dataview-hedgefunds/list-hf-master/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingsWithDataviewHedgefunds: async (fundId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('holdingsWithDataviewHedgefunds', 'fundId', fundId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('holdingsWithDataviewHedgefunds', 'basedate', basedate)
            const localVarPath = `/api/dataview-hedgefunds/holdings/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hwmWithDataviewHedgefunds: async (fundId: number, portfolioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('hwmWithDataviewHedgefunds', 'fundId', fundId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('hwmWithDataviewHedgefunds', 'portfolioId', portfolioId)
            const localVarPath = `/api/dataview-hedgefunds/hwm/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithDataviewHedgefunds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dataview-hedgefunds/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navBvWithDataviewHedgefunds: async (fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('navBvWithDataviewHedgefunds', 'fundId', fundId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('navBvWithDataviewHedgefunds', 'portfolioId', portfolioId)
            // verify required parameter 'style' is not null or undefined
            assertParamExists('navBvWithDataviewHedgefunds', 'style', style)
            const localVarPath = `/api/dataview-hedgefunds/nav-bv/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }

            if (style !== undefined) {
                localVarQueryParameter['style'] = style;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceCommentWithDataviewHedgefunds: async (fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('performanceCommentWithDataviewHedgefunds', 'fundId', fundId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('performanceCommentWithDataviewHedgefunds', 'portfolioId', portfolioId)
            // verify required parameter 'style' is not null or undefined
            assertParamExists('performanceCommentWithDataviewHedgefunds', 'style', style)
            const localVarPath = `/api/dataview-hedgefunds/performance-comment/{fundId}/{portfolioId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (style !== undefined) {
                localVarQueryParameter['style'] = style;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} [portfolioId] 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portTxnWithDataviewHedgefunds: async (fundId: number, portfolioId?: number, investorView?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('portTxnWithDataviewHedgefunds', 'fundId', fundId)
            const localVarPath = `/api/dataview-hedgefunds/portfolio-txn/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }

            if (investorView !== undefined) {
                localVarQueryParameter['investorView'] = investorView;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataViewerHedgeFundsApi - functional programming interface
 * @export
 */
export const DataViewerHedgeFundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataViewerHedgeFundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hfMasterListWithDataviewHedgefunds(fundId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hfMasterListWithDataviewHedgefunds(fundId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingsWithDataviewHedgefunds(fundId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingsWithDataviewHedgefunds(fundId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hwmWithDataviewHedgefunds(fundId: number, portfolioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hwmWithDataviewHedgefunds(fundId, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithDataviewHedgefunds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithDataviewHedgefunds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navBvWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseNavBvDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.navBvWithDataviewHedgefunds(fundId, portfolioId, style, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceCommentWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceCommentWithDataviewHedgefunds(fundId, portfolioId, style, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} [portfolioId] 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portTxnWithDataviewHedgefunds(fundId: number, portfolioId?: number, investorView?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponsePortTxnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portTxnWithDataviewHedgefunds(fundId, portfolioId, investorView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataViewerHedgeFundsApi - factory interface
 * @export
 */
export const DataViewerHedgeFundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataViewerHedgeFundsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hfMasterListWithDataviewHedgefunds(fundId: number, options?: any): AxiosPromise<object> {
            return localVarFp.hfMasterListWithDataviewHedgefunds(fundId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingsWithDataviewHedgefunds(fundId: number, basedate: string, options?: any): AxiosPromise<void> {
            return localVarFp.holdingsWithDataviewHedgefunds(fundId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hwmWithDataviewHedgefunds(fundId: number, portfolioId: number, options?: any): AxiosPromise<void> {
            return localVarFp.hwmWithDataviewHedgefunds(fundId, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithDataviewHedgefunds(options?: any): AxiosPromise<Array<ResponseListDto>> {
            return localVarFp.listWithDataviewHedgefunds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navBvWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: any): AxiosPromise<ResponseNavBvDto> {
            return localVarFp.navBvWithDataviewHedgefunds(fundId, portfolioId, style, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} portfolioId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceCommentWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: any): AxiosPromise<object> {
            return localVarFp.performanceCommentWithDataviewHedgefunds(fundId, portfolioId, style, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {number} [portfolioId] 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portTxnWithDataviewHedgefunds(fundId: number, portfolioId?: number, investorView?: boolean, options?: any): AxiosPromise<Array<ResponsePortTxnDto>> {
            return localVarFp.portTxnWithDataviewHedgefunds(fundId, portfolioId, investorView, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataViewerHedgeFundsApi - interface
 * @export
 * @interface DataViewerHedgeFundsApi
 */
export interface DataViewerHedgeFundsApiInterface {
    /**
     * 
     * @param {number} fundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApiInterface
     */
    hfMasterListWithDataviewHedgefunds(fundId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApiInterface
     */
    holdingsWithDataviewHedgefunds(fundId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApiInterface
     */
    hwmWithDataviewHedgefunds(fundId: number, portfolioId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApiInterface
     */
    listWithDataviewHedgefunds(options?: AxiosRequestConfig): AxiosPromise<Array<ResponseListDto>>;

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApiInterface
     */
    navBvWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): AxiosPromise<ResponseNavBvDto>;

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApiInterface
     */
    performanceCommentWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} fundId 
     * @param {number} [portfolioId] 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApiInterface
     */
    portTxnWithDataviewHedgefunds(fundId: number, portfolioId?: number, investorView?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<ResponsePortTxnDto>>;

}

/**
 * DataViewerHedgeFundsApi - object-oriented interface
 * @export
 * @class DataViewerHedgeFundsApi
 * @extends {BaseAPI}
 */
export class DataViewerHedgeFundsApi extends BaseAPI implements DataViewerHedgeFundsApiInterface {
    /**
     * 
     * @param {number} fundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApi
     */
    public hfMasterListWithDataviewHedgefunds(fundId: number, options?: AxiosRequestConfig) {
        return DataViewerHedgeFundsApiFp(this.configuration).hfMasterListWithDataviewHedgefunds(fundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApi
     */
    public holdingsWithDataviewHedgefunds(fundId: number, basedate: string, options?: AxiosRequestConfig) {
        return DataViewerHedgeFundsApiFp(this.configuration).holdingsWithDataviewHedgefunds(fundId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApi
     */
    public hwmWithDataviewHedgefunds(fundId: number, portfolioId: number, options?: AxiosRequestConfig) {
        return DataViewerHedgeFundsApiFp(this.configuration).hwmWithDataviewHedgefunds(fundId, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApi
     */
    public listWithDataviewHedgefunds(options?: AxiosRequestConfig) {
        return DataViewerHedgeFundsApiFp(this.configuration).listWithDataviewHedgefunds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApi
     */
    public navBvWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig) {
        return DataViewerHedgeFundsApiFp(this.configuration).navBvWithDataviewHedgefunds(fundId, portfolioId, style, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {number} portfolioId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApi
     */
    public performanceCommentWithDataviewHedgefunds(fundId: number, portfolioId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig) {
        return DataViewerHedgeFundsApiFp(this.configuration).performanceCommentWithDataviewHedgefunds(fundId, portfolioId, style, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {number} [portfolioId] 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerHedgeFundsApi
     */
    public portTxnWithDataviewHedgefunds(fundId: number, portfolioId?: number, investorView?: boolean, options?: AxiosRequestConfig) {
        return DataViewerHedgeFundsApiFp(this.configuration).portTxnWithDataviewHedgefunds(fundId, portfolioId, investorView, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataViewerInvestorsApi - axios parameter creator
 * @export
 */
export const DataViewerInvestorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} investorId 
         * @param {string} basedate 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingsWithDataviewInvestors: async (investorId: number, basedate: string, investorView?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investorId' is not null or undefined
            assertParamExists('holdingsWithDataviewInvestors', 'investorId', investorId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('holdingsWithDataviewInvestors', 'basedate', basedate)
            const localVarPath = `/api/dataview-investors/holdings/{investorId}`
                .replace(`{${"investorId"}}`, encodeURIComponent(String(investorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (investorView !== undefined) {
                localVarQueryParameter['investorView'] = investorView;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithDataviewInvestors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dataview-investors/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} investorId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navBvWithDataviewInvestors: async (investorId: number, style: 'year_by_month' | 'list', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investorId' is not null or undefined
            assertParamExists('navBvWithDataviewInvestors', 'investorId', investorId)
            // verify required parameter 'style' is not null or undefined
            assertParamExists('navBvWithDataviewInvestors', 'style', style)
            const localVarPath = `/api/dataview-investors/navbv/{investorId}`
                .replace(`{${"investorId"}}`, encodeURIComponent(String(investorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (style !== undefined) {
                localVarQueryParameter['style'] = style;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} investorId 
         * @param {'year_by_month' | 'list'} style 
         * @param {number} [divisionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceWithDataviewInvestors: async (investorId: number, style: 'year_by_month' | 'list', divisionId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investorId' is not null or undefined
            assertParamExists('performanceWithDataviewInvestors', 'investorId', investorId)
            // verify required parameter 'style' is not null or undefined
            assertParamExists('performanceWithDataviewInvestors', 'style', style)
            const localVarPath = `/api/dataview-investors/performance/{investorId}`
                .replace(`{${"investorId"}}`, encodeURIComponent(String(investorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (style !== undefined) {
                localVarQueryParameter['style'] = style;
            }

            if (divisionId !== undefined) {
                localVarQueryParameter['divisionId'] = divisionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} investorId 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsWithDataviewInvestors: async (investorId: number, investorView?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investorId' is not null or undefined
            assertParamExists('transactionsWithDataviewInvestors', 'investorId', investorId)
            const localVarPath = `/api/dataview-investors/transactionis/{investorId}`
                .replace(`{${"investorId"}}`, encodeURIComponent(String(investorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investorView !== undefined) {
                localVarQueryParameter['investorView'] = investorView;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataViewerInvestorsApi - functional programming interface
 * @export
 */
export const DataViewerInvestorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataViewerInvestorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} investorId 
         * @param {string} basedate 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingsWithDataviewInvestors(investorId: number, basedate: string, investorView?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseInvestorHoldingsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingsWithDataviewInvestors(investorId, basedate, investorView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithDataviewInvestors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseInvestorListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithDataviewInvestors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} investorId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navBvWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInvestorNavBvDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.navBvWithDataviewInvestors(investorId, style, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} investorId 
         * @param {'year_by_month' | 'list'} style 
         * @param {number} [divisionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', divisionId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceWithDataviewInvestors(investorId, style, divisionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} investorId 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsWithDataviewInvestors(investorId: number, investorView?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseAccountInvestorTxnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsWithDataviewInvestors(investorId, investorView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataViewerInvestorsApi - factory interface
 * @export
 */
export const DataViewerInvestorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataViewerInvestorsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} investorId 
         * @param {string} basedate 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingsWithDataviewInvestors(investorId: number, basedate: string, investorView?: boolean, options?: any): AxiosPromise<Array<ResponseInvestorHoldingsDto>> {
            return localVarFp.holdingsWithDataviewInvestors(investorId, basedate, investorView, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithDataviewInvestors(options?: any): AxiosPromise<Array<ResponseInvestorListDto>> {
            return localVarFp.listWithDataviewInvestors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} investorId 
         * @param {'year_by_month' | 'list'} style 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navBvWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', options?: any): AxiosPromise<ResponseInvestorNavBvDto> {
            return localVarFp.navBvWithDataviewInvestors(investorId, style, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} investorId 
         * @param {'year_by_month' | 'list'} style 
         * @param {number} [divisionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', divisionId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.performanceWithDataviewInvestors(investorId, style, divisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} investorId 
         * @param {boolean} [investorView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsWithDataviewInvestors(investorId: number, investorView?: boolean, options?: any): AxiosPromise<Array<ResponseAccountInvestorTxnDto>> {
            return localVarFp.transactionsWithDataviewInvestors(investorId, investorView, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataViewerInvestorsApi - interface
 * @export
 * @interface DataViewerInvestorsApi
 */
export interface DataViewerInvestorsApiInterface {
    /**
     * 
     * @param {number} investorId 
     * @param {string} basedate 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApiInterface
     */
    holdingsWithDataviewInvestors(investorId: number, basedate: string, investorView?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<ResponseInvestorHoldingsDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApiInterface
     */
    listWithDataviewInvestors(options?: AxiosRequestConfig): AxiosPromise<Array<ResponseInvestorListDto>>;

    /**
     * 
     * @param {number} investorId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApiInterface
     */
    navBvWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig): AxiosPromise<ResponseInvestorNavBvDto>;

    /**
     * 
     * @param {number} investorId 
     * @param {'year_by_month' | 'list'} style 
     * @param {number} [divisionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApiInterface
     */
    performanceWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', divisionId?: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} investorId 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApiInterface
     */
    transactionsWithDataviewInvestors(investorId: number, investorView?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<ResponseAccountInvestorTxnDto>>;

}

/**
 * DataViewerInvestorsApi - object-oriented interface
 * @export
 * @class DataViewerInvestorsApi
 * @extends {BaseAPI}
 */
export class DataViewerInvestorsApi extends BaseAPI implements DataViewerInvestorsApiInterface {
    /**
     * 
     * @param {number} investorId 
     * @param {string} basedate 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApi
     */
    public holdingsWithDataviewInvestors(investorId: number, basedate: string, investorView?: boolean, options?: AxiosRequestConfig) {
        return DataViewerInvestorsApiFp(this.configuration).holdingsWithDataviewInvestors(investorId, basedate, investorView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApi
     */
    public listWithDataviewInvestors(options?: AxiosRequestConfig) {
        return DataViewerInvestorsApiFp(this.configuration).listWithDataviewInvestors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} investorId 
     * @param {'year_by_month' | 'list'} style 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApi
     */
    public navBvWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', options?: AxiosRequestConfig) {
        return DataViewerInvestorsApiFp(this.configuration).navBvWithDataviewInvestors(investorId, style, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} investorId 
     * @param {'year_by_month' | 'list'} style 
     * @param {number} [divisionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApi
     */
    public performanceWithDataviewInvestors(investorId: number, style: 'year_by_month' | 'list', divisionId?: number, options?: AxiosRequestConfig) {
        return DataViewerInvestorsApiFp(this.configuration).performanceWithDataviewInvestors(investorId, style, divisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} investorId 
     * @param {boolean} [investorView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataViewerInvestorsApi
     */
    public transactionsWithDataviewInvestors(investorId: number, investorView?: boolean, options?: AxiosRequestConfig) {
        return DataViewerInvestorsApiFp(this.configuration).transactionsWithDataviewInvestors(investorId, investorView, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateWithSystemInterestRate: async (currency: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('candidateWithSystemInterestRate', 'currency', currency)
            const localVarPath = `/api/system-interest-rate/candidates/{currency}`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateHighWaterMarkDto} createHighWaterMarkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHighWaterMark: async (createHighWaterMarkDto: CreateHighWaterMarkDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHighWaterMarkDto' is not null or undefined
            assertParamExists('createWithHighWaterMark', 'createHighWaterMarkDto', createHighWaterMarkDto)
            const localVarPath = `/api/high-water-mark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHighWaterMarkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSystemInterestRateDto} createSystemInterestRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithSystemInterestRate: async (createSystemInterestRateDto: CreateSystemInterestRateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSystemInterestRateDto' is not null or undefined
            assertParamExists('createWithSystemInterestRate', 'createSystemInterestRateDto', createSystemInterestRateDto)
            const localVarPath = `/api/system-interest-rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSystemInterestRateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithTickerMaster: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createWithTickerMaster', 'body', body)
            const localVarPath = `/api/ticker-master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHighWaterMark: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/high-water-mark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithSystemIndicator: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system-indicator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithTickerMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ticker-master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHighWaterMark: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHighWaterMark', 'id', id)
            const localVarPath = `/api/high-water-mark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithTickerMaster: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithTickerMaster', 'id', id)
            const localVarPath = `/api/ticker-master/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelloWithApp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {Array<string>} currencies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateByDateRangeWithSystemInterestRate: async (start: string, end: string, currencies: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getInterestRateByDateRangeWithSystemInterestRate', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getInterestRateByDateRangeWithSystemInterestRate', 'end', end)
            // verify required parameter 'currencies' is not null or undefined
            assertParamExists('getInterestRateByDateRangeWithSystemInterestRate', 'currencies', currencies)
            const localVarPath = `/api/system-interest-rate/ir-by-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (currencies) {
                localVarQueryParameter['currencies'] = currencies;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} basedate 
         * @param {Array<string>} currencies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateOnBasedateWithSystemInterestRate: async (basedate: string, currencies: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('getInterestRateOnBasedateWithSystemInterestRate', 'basedate', basedate)
            // verify required parameter 'currencies' is not null or undefined
            assertParamExists('getInterestRateOnBasedateWithSystemInterestRate', 'currencies', currencies)
            const localVarPath = `/api/system-interest-rate/ir-on-basedate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (currencies) {
                localVarQueryParameter['currencies'] = currencies;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} year 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCompositionWithCacheStore: async (portfolioId: string, year: string, month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioCompositionWithCacheStore', 'portfolioId', portfolioId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getPortfolioCompositionWithCacheStore', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getPortfolioCompositionWithCacheStore', 'month', month)
            const localVarPath = `/api/cache-store/portfolio-composition/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSysInterestRateWithSystemInterestRate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system-interest-rate/list-sys-ir`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHighWaterMark: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithHighWaterMark', 'id', id)
            const localVarPath = `/api/high-water-mark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithTickerMaster: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithTickerMaster', 'id', id)
            const localVarPath = `/api/ticker-master/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [basedate] 
         * @param {number} [monthRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seedPortfolioCompositionWithCacheStore: async (basedate?: string, monthRange?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cache-store/portfolio-composition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (monthRange !== undefined) {
                localVarQueryParameter['monthRange'] = monthRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHighWaterMarkDto} updateHighWaterMarkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHighWaterMark: async (id: string, updateHighWaterMarkDto: UpdateHighWaterMarkDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithHighWaterMark', 'id', id)
            // verify required parameter 'updateHighWaterMarkDto' is not null or undefined
            assertParamExists('updateWithHighWaterMark', 'updateHighWaterMarkDto', updateHighWaterMarkDto)
            const localVarPath = `/api/high-water-mark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHighWaterMarkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSystemInterestRateDto} updateSystemInterestRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithSystemInterestRate: async (id: string, updateSystemInterestRateDto: UpdateSystemInterestRateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithSystemInterestRate', 'id', id)
            // verify required parameter 'updateSystemInterestRateDto' is not null or undefined
            assertParamExists('updateWithSystemInterestRate', 'updateSystemInterestRateDto', updateSystemInterestRateDto)
            const localVarPath = `/api/system-interest-rate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSystemInterestRateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithTickerMaster: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithTickerMaster', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateWithTickerMaster', 'body', body)
            const localVarPath = `/api/ticker-master/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateWithSystemInterestRate(currency: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateWithSystemInterestRate(currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateHighWaterMarkDto} createHighWaterMarkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithHighWaterMark(createHighWaterMarkDto: CreateHighWaterMarkDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithHighWaterMark(createHighWaterMarkDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSystemInterestRateDto} createSystemInterestRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithSystemInterestRate(createSystemInterestRateDto: CreateSystemInterestRateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithSystemInterestRate(createSystemInterestRateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithTickerMaster(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithTickerMaster(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHighWaterMark(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHighWaterMark(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithSystemIndicator(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithSystemIndicator(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithTickerMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithTickerMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHighWaterMark(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHighWaterMark(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithTickerMaster(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithTickerMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHelloWithApp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHelloWithApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {Array<string>} currencies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestRateByDateRangeWithSystemInterestRate(start: string, end: string, currencies: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterestRateByDateRangeWithSystemInterestRate(start, end, currencies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} basedate 
         * @param {Array<string>} currencies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestRateOnBasedateWithSystemInterestRate(basedate: string, currencies: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterestRateOnBasedateWithSystemInterestRate(basedate, currencies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} year 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioCompositionWithCacheStore(portfolioId: string, year: string, month: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioCompositionWithCacheStore(portfolioId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSysInterestRateWithSystemInterestRate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSysInterestRateWithSystemInterestRate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithHighWaterMark(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithHighWaterMark(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithTickerMaster(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithTickerMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [basedate] 
         * @param {number} [monthRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seedPortfolioCompositionWithCacheStore(basedate?: string, monthRange?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.seedPortfolioCompositionWithCacheStore(basedate, monthRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHighWaterMarkDto} updateHighWaterMarkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithHighWaterMark(id: string, updateHighWaterMarkDto: UpdateHighWaterMarkDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithHighWaterMark(id, updateHighWaterMarkDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSystemInterestRateDto} updateSystemInterestRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithSystemInterestRate(id: string, updateSystemInterestRateDto: UpdateSystemInterestRateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithSystemInterestRate(id, updateSystemInterestRateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithTickerMaster(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithTickerMaster(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateWithSystemInterestRate(currency: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: any): AxiosPromise<Array<object>> {
            return localVarFp.candidateWithSystemInterestRate(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateHighWaterMarkDto} createHighWaterMarkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHighWaterMark(createHighWaterMarkDto: CreateHighWaterMarkDto, options?: any): AxiosPromise<string> {
            return localVarFp.createWithHighWaterMark(createHighWaterMarkDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSystemInterestRateDto} createSystemInterestRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithSystemInterestRate(createSystemInterestRateDto: CreateSystemInterestRateDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithSystemInterestRate(createSystemInterestRateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithTickerMaster(body: object, options?: any): AxiosPromise<string> {
            return localVarFp.createWithTickerMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHighWaterMark(options?: any): AxiosPromise<string> {
            return localVarFp.findAllWithHighWaterMark(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithSystemIndicator(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithSystemIndicator(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithTickerMaster(options?: any): AxiosPromise<string> {
            return localVarFp.findAllWithTickerMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHighWaterMark(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.findOneWithHighWaterMark(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithTickerMaster(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.findOneWithTickerMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelloWithApp(options?: any): AxiosPromise<string> {
            return localVarFp.getHelloWithApp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {Array<string>} currencies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateByDateRangeWithSystemInterestRate(start: string, end: string, currencies: Array<string>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getInterestRateByDateRangeWithSystemInterestRate(start, end, currencies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} basedate 
         * @param {Array<string>} currencies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateOnBasedateWithSystemInterestRate(basedate: string, currencies: Array<string>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getInterestRateOnBasedateWithSystemInterestRate(basedate, currencies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} year 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCompositionWithCacheStore(portfolioId: string, year: string, month: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getPortfolioCompositionWithCacheStore(portfolioId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSysInterestRateWithSystemInterestRate(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.listSysInterestRateWithSystemInterestRate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHighWaterMark(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeWithHighWaterMark(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithTickerMaster(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeWithTickerMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [basedate] 
         * @param {number} [monthRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seedPortfolioCompositionWithCacheStore(basedate?: string, monthRange?: number, options?: any): AxiosPromise<string> {
            return localVarFp.seedPortfolioCompositionWithCacheStore(basedate, monthRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHighWaterMarkDto} updateHighWaterMarkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHighWaterMark(id: string, updateHighWaterMarkDto: UpdateHighWaterMarkDto, options?: any): AxiosPromise<string> {
            return localVarFp.updateWithHighWaterMark(id, updateHighWaterMarkDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSystemInterestRateDto} updateSystemInterestRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithSystemInterestRate(id: string, updateSystemInterestRateDto: UpdateSystemInterestRateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithSystemInterestRate(id, updateSystemInterestRateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithTickerMaster(id: string, body: object, options?: any): AxiosPromise<string> {
            return localVarFp.updateWithTickerMaster(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    candidateWithSystemInterestRate(currency: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {CreateHighWaterMarkDto} createHighWaterMarkDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createWithHighWaterMark(createHighWaterMarkDto: CreateHighWaterMarkDto, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {CreateSystemInterestRateDto} createSystemInterestRateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createWithSystemInterestRate(createSystemInterestRateDto: CreateSystemInterestRateDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createWithTickerMaster(body: object, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    findAllWithHighWaterMark(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    findAllWithSystemIndicator(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    findAllWithTickerMaster(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    findOneWithHighWaterMark(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    findOneWithTickerMaster(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getHelloWithApp(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} start 
     * @param {string} end 
     * @param {Array<string>} currencies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getInterestRateByDateRangeWithSystemInterestRate(start: string, end: string, currencies: Array<string>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} basedate 
     * @param {Array<string>} currencies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getInterestRateOnBasedateWithSystemInterestRate(basedate: string, currencies: Array<string>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} year 
     * @param {string} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getPortfolioCompositionWithCacheStore(portfolioId: string, year: string, month: string, options?: AxiosRequestConfig): AxiosPromise<Array<number>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    listSysInterestRateWithSystemInterestRate(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    removeWithHighWaterMark(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    removeWithTickerMaster(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} [basedate] 
     * @param {number} [monthRange] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    seedPortfolioCompositionWithCacheStore(basedate?: string, monthRange?: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateHighWaterMarkDto} updateHighWaterMarkDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateWithHighWaterMark(id: string, updateHighWaterMarkDto: UpdateHighWaterMarkDto, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateSystemInterestRateDto} updateSystemInterestRateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateWithSystemInterestRate(id: string, updateSystemInterestRateDto: UpdateSystemInterestRateDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateWithTickerMaster(id: string, body: object, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @param {'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public candidateWithSystemInterestRate(currency: 'CAD' | 'KRW' | 'TWD' | 'INR' | 'SGD' | 'MYR' | 'IDR' | 'VND' | 'THB' | 'PHP' | 'PKR' | 'BRL' | 'ARS' | 'HUF' | 'RUB' | 'TRY' | 'ZAR' | 'NOK' | 'SEK' | 'DKK' | 'NZD' | 'MXN' | 'AED' | 'SAR' | 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).candidateWithSystemInterestRate(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateHighWaterMarkDto} createHighWaterMarkDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createWithHighWaterMark(createHighWaterMarkDto: CreateHighWaterMarkDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createWithHighWaterMark(createHighWaterMarkDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSystemInterestRateDto} createSystemInterestRateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createWithSystemInterestRate(createSystemInterestRateDto: CreateSystemInterestRateDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createWithSystemInterestRate(createSystemInterestRateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createWithTickerMaster(body: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createWithTickerMaster(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findAllWithHighWaterMark(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findAllWithHighWaterMark(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findAllWithSystemIndicator(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findAllWithSystemIndicator(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findAllWithTickerMaster(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findAllWithTickerMaster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findOneWithHighWaterMark(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findOneWithHighWaterMark(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findOneWithTickerMaster(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findOneWithTickerMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHelloWithApp(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHelloWithApp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} start 
     * @param {string} end 
     * @param {Array<string>} currencies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInterestRateByDateRangeWithSystemInterestRate(start: string, end: string, currencies: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getInterestRateByDateRangeWithSystemInterestRate(start, end, currencies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} basedate 
     * @param {Array<string>} currencies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInterestRateOnBasedateWithSystemInterestRate(basedate: string, currencies: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getInterestRateOnBasedateWithSystemInterestRate(basedate, currencies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} year 
     * @param {string} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPortfolioCompositionWithCacheStore(portfolioId: string, year: string, month: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPortfolioCompositionWithCacheStore(portfolioId, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSysInterestRateWithSystemInterestRate(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSysInterestRateWithSystemInterestRate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeWithHighWaterMark(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeWithHighWaterMark(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeWithTickerMaster(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeWithTickerMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [basedate] 
     * @param {number} [monthRange] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public seedPortfolioCompositionWithCacheStore(basedate?: string, monthRange?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).seedPortfolioCompositionWithCacheStore(basedate, monthRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateHighWaterMarkDto} updateHighWaterMarkDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWithHighWaterMark(id: string, updateHighWaterMarkDto: UpdateHighWaterMarkDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWithHighWaterMark(id, updateHighWaterMarkDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateSystemInterestRateDto} updateSystemInterestRateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWithSystemInterestRate(id: string, updateSystemInterestRateDto: UpdateSystemInterestRateDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWithSystemInterestRate(id, updateSystemInterestRateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWithTickerMaster(id: string, body: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWithTickerMaster(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DividendApi - axios parameter creator
 * @export
 */
export const DividendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CreateDividendDto>} createDividendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithDividend: async (createDividendDto: Array<CreateDividendDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDividendDto' is not null or undefined
            assertParamExists('createWithDividend', 'createDividendDto', createDividendDto)
            const localVarPath = `/api/dividend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDividendDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendMountingWithDividend: async (fundSeriesId: number, divisionId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundSeriesId' is not null or undefined
            assertParamExists('dividendMountingWithDividend', 'fundSeriesId', fundSeriesId)
            // verify required parameter 'divisionId' is not null or undefined
            assertParamExists('dividendMountingWithDividend', 'divisionId', divisionId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('dividendMountingWithDividend', 'basedate', basedate)
            const localVarPath = `/api/dividend/dividend-mounting/{fundSeriesId}`
                .replace(`{${"fundSeriesId"}}`, encodeURIComponent(String(fundSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (divisionId !== undefined) {
                localVarQueryParameter['divisionId'] = divisionId;
            }

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountTsFundTxnWithDividend: async (accountSeriesId: string, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountSeriesId' is not null or undefined
            assertParamExists('findAccountTsFundTxnWithDividend', 'accountSeriesId', accountSeriesId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findAccountTsFundTxnWithDividend', 'basedate', basedate)
            const localVarPath = `/api/dividend/account-ts-fund-txn/{accountSeriesId}/{basedate}`
                .replace(`{${"accountSeriesId"}}`, encodeURIComponent(String(accountSeriesId)))
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAccountSeriesWithDividend: async (accountSeriesId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountSeriesId' is not null or undefined
            assertParamExists('findAllByAccountSeriesWithDividend', 'accountSeriesId', accountSeriesId)
            const localVarPath = `/api/dividend/by-account-series/{accountSeriesId}`
                .replace(`{${"accountSeriesId"}}`, encodeURIComponent(String(accountSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAccountWithDividend: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findAllByAccountWithDividend', 'accountId', accountId)
            const localVarPath = `/api/dividend/account/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithDividend: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithDividend', 'id', id)
            const localVarPath = `/api/dividend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookValueWithDividend: async (fundSeriesId: number, divisionId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundSeriesId' is not null or undefined
            assertParamExists('getBookValueWithDividend', 'fundSeriesId', fundSeriesId)
            // verify required parameter 'divisionId' is not null or undefined
            assertParamExists('getBookValueWithDividend', 'divisionId', divisionId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('getBookValueWithDividend', 'basedate', basedate)
            const localVarPath = `/api/dividend/bookValue/{fundSeriesId}`
                .replace(`{${"fundSeriesId"}}`, encodeURIComponent(String(fundSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (divisionId !== undefined) {
                localVarQueryParameter['divisionId'] = divisionId;
            }

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithDividend: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithDividend', 'id', id)
            const localVarPath = `/api/dividend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateDividendDto>} updateDividendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithDividend: async (updateDividendDto: Array<UpdateDividendDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDividendDto' is not null or undefined
            assertParamExists('updateWithDividend', 'updateDividendDto', updateDividendDto)
            const localVarPath = `/api/dividend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDividendDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DividendApi - functional programming interface
 * @export
 */
export const DividendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DividendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<CreateDividendDto>} createDividendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithDividend(createDividendDto: Array<CreateDividendDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithDividend(createDividendDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendMountingWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendMountingWithDividend(fundSeriesId, divisionId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAccountTsFundTxnWithDividend(accountSeriesId: string, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAccountTsFundTxnWithDividend(accountSeriesId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByAccountSeriesWithDividend(accountSeriesId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByAccountSeriesWithDividend(accountSeriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByAccountWithDividend(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByAccountWithDividend(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithDividend(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithDividend(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookValueWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookValueWithDividend(fundSeriesId, divisionId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithDividend(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithDividend(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<UpdateDividendDto>} updateDividendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithDividend(updateDividendDto: Array<UpdateDividendDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithDividend(updateDividendDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DividendApi - factory interface
 * @export
 */
export const DividendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DividendApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<CreateDividendDto>} createDividendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithDividend(createDividendDto: Array<CreateDividendDto>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.createWithDividend(createDividendDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendMountingWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: any): AxiosPromise<object> {
            return localVarFp.dividendMountingWithDividend(fundSeriesId, divisionId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountSeriesId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountTsFundTxnWithDividend(accountSeriesId: string, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAccountTsFundTxnWithDividend(accountSeriesId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAccountSeriesWithDividend(accountSeriesId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllByAccountSeriesWithDividend(accountSeriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAccountWithDividend(accountId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllByAccountWithDividend(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithDividend(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithDividend(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} divisionId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookValueWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getBookValueWithDividend(fundSeriesId, divisionId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithDividend(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithDividend(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateDividendDto>} updateDividendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithDividend(updateDividendDto: Array<UpdateDividendDto>, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithDividend(updateDividendDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DividendApi - interface
 * @export
 * @interface DividendApi
 */
export interface DividendApiInterface {
    /**
     * 
     * @param {Array<CreateDividendDto>} createDividendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    createWithDividend(createDividendDto: Array<CreateDividendDto>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} divisionId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    dividendMountingWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} accountSeriesId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    findAccountTsFundTxnWithDividend(accountSeriesId: string, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} accountSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    findAllByAccountSeriesWithDividend(accountSeriesId: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    findAllByAccountWithDividend(accountId: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    findOneWithDividend(id: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} divisionId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    getBookValueWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    removeWithDividend(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<UpdateDividendDto>} updateDividendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApiInterface
     */
    updateWithDividend(updateDividendDto: Array<UpdateDividendDto>, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DividendApi - object-oriented interface
 * @export
 * @class DividendApi
 * @extends {BaseAPI}
 */
export class DividendApi extends BaseAPI implements DividendApiInterface {
    /**
     * 
     * @param {Array<CreateDividendDto>} createDividendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public createWithDividend(createDividendDto: Array<CreateDividendDto>, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).createWithDividend(createDividendDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} divisionId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public dividendMountingWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).dividendMountingWithDividend(fundSeriesId, divisionId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountSeriesId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public findAccountTsFundTxnWithDividend(accountSeriesId: string, basedate: string, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).findAccountTsFundTxnWithDividend(accountSeriesId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public findAllByAccountSeriesWithDividend(accountSeriesId: string, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).findAllByAccountSeriesWithDividend(accountSeriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public findAllByAccountWithDividend(accountId: string, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).findAllByAccountWithDividend(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public findOneWithDividend(id: string, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).findOneWithDividend(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} divisionId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public getBookValueWithDividend(fundSeriesId: number, divisionId: number, basedate: string, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).getBookValueWithDividend(fundSeriesId, divisionId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public removeWithDividend(id: string, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).removeWithDividend(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateDividendDto>} updateDividendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendApi
     */
    public updateWithDividend(updateDividendDto: Array<UpdateDividendDto>, options?: AxiosRequestConfig) {
        return DividendApiFp(this.configuration).updateWithDividend(updateDividendDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DivisionsApi - axios parameter creator
 * @export
 */
export const DivisionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDivisionDto} createDivisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithDivisions: async (createDivisionDto: CreateDivisionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDivisionDto' is not null or undefined
            assertParamExists('createWithDivisions', 'createDivisionDto', createDivisionDto)
            const localVarPath = `/api/divisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDivisionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithDivisions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/divisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithDivisions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithDivisions', 'id', id)
            const localVarPath = `/api/divisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithDivisions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithDivisions', 'id', id)
            const localVarPath = `/api/divisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDivisionDto} updateDivisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithDivisions: async (id: string, updateDivisionDto: UpdateDivisionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithDivisions', 'id', id)
            // verify required parameter 'updateDivisionDto' is not null or undefined
            assertParamExists('updateWithDivisions', 'updateDivisionDto', updateDivisionDto)
            const localVarPath = `/api/divisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDivisionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DivisionsApi - functional programming interface
 * @export
 */
export const DivisionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DivisionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDivisionDto} createDivisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithDivisions(createDivisionDto: CreateDivisionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithDivisions(createDivisionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithDivisions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithDivisions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithDivisions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithDivisions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithDivisions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithDivisions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDivisionDto} updateDivisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithDivisions(id: string, updateDivisionDto: UpdateDivisionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithDivisions(id, updateDivisionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DivisionsApi - factory interface
 * @export
 */
export const DivisionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DivisionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDivisionDto} createDivisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithDivisions(createDivisionDto: CreateDivisionDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithDivisions(createDivisionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithDivisions(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithDivisions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithDivisions(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithDivisions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithDivisions(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithDivisions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDivisionDto} updateDivisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithDivisions(id: string, updateDivisionDto: UpdateDivisionDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithDivisions(id, updateDivisionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DivisionsApi - interface
 * @export
 * @interface DivisionsApi
 */
export interface DivisionsApiInterface {
    /**
     * 
     * @param {CreateDivisionDto} createDivisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApiInterface
     */
    createWithDivisions(createDivisionDto: CreateDivisionDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApiInterface
     */
    findAllWithDivisions(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApiInterface
     */
    findOneWithDivisions(id: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApiInterface
     */
    removeWithDivisions(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateDivisionDto} updateDivisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApiInterface
     */
    updateWithDivisions(id: string, updateDivisionDto: UpdateDivisionDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DivisionsApi - object-oriented interface
 * @export
 * @class DivisionsApi
 * @extends {BaseAPI}
 */
export class DivisionsApi extends BaseAPI implements DivisionsApiInterface {
    /**
     * 
     * @param {CreateDivisionDto} createDivisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApi
     */
    public createWithDivisions(createDivisionDto: CreateDivisionDto, options?: AxiosRequestConfig) {
        return DivisionsApiFp(this.configuration).createWithDivisions(createDivisionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApi
     */
    public findAllWithDivisions(options?: AxiosRequestConfig) {
        return DivisionsApiFp(this.configuration).findAllWithDivisions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApi
     */
    public findOneWithDivisions(id: string, options?: AxiosRequestConfig) {
        return DivisionsApiFp(this.configuration).findOneWithDivisions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApi
     */
    public removeWithDivisions(id: string, options?: AxiosRequestConfig) {
        return DivisionsApiFp(this.configuration).removeWithDivisions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDivisionDto} updateDivisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionsApi
     */
    public updateWithDivisions(id: string, updateDivisionDto: UpdateDivisionDto, options?: AxiosRequestConfig) {
        return DivisionsApiFp(this.configuration).updateWithDivisions(id, updateDivisionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DoubleCheckTrackApi - axios parameter creator
 * @export
 */
export const DoubleCheckTrackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateDoubleCheckTrackDto} updateDoubleCheckTrackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCheckedWithDoubleCheckTrack: async (id: number, updateDoubleCheckTrackDto: UpdateDoubleCheckTrackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allCheckedWithDoubleCheckTrack', 'id', id)
            // verify required parameter 'updateDoubleCheckTrackDto' is not null or undefined
            assertParamExists('allCheckedWithDoubleCheckTrack', 'updateDoubleCheckTrackDto', updateDoubleCheckTrackDto)
            const localVarPath = `/api/double-check-track/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDoubleCheckTrackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingHedgeFundWithDoubleCheckTrack: async (portfolioId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('mountingHedgeFundWithDoubleCheckTrack', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('mountingHedgeFundWithDoubleCheckTrack', 'basedate', basedate)
            const localVarPath = `/api/double-check-track/hedgefund-mounting/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingWithDoubleCheckTrack: async (accountId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('mountingWithDoubleCheckTrack', 'accountId', accountId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('mountingWithDoubleCheckTrack', 'basedate', basedate)
            const localVarPath = `/api/double-check-track/account-mounting/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoubleCheckTrackApi - functional programming interface
 * @export
 */
export const DoubleCheckTrackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoubleCheckTrackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateDoubleCheckTrackDto} updateDoubleCheckTrackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCheckedWithDoubleCheckTrack(id: number, updateDoubleCheckTrackDto: UpdateDoubleCheckTrackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCheckedWithDoubleCheckTrack(id, updateDoubleCheckTrackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mountingHedgeFundWithDoubleCheckTrack(portfolioId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mountingHedgeFundWithDoubleCheckTrack(portfolioId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mountingWithDoubleCheckTrack(accountId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mountingWithDoubleCheckTrack(accountId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoubleCheckTrackApi - factory interface
 * @export
 */
export const DoubleCheckTrackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoubleCheckTrackApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateDoubleCheckTrackDto} updateDoubleCheckTrackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCheckedWithDoubleCheckTrack(id: number, updateDoubleCheckTrackDto: UpdateDoubleCheckTrackDto, options?: any): AxiosPromise<void> {
            return localVarFp.allCheckedWithDoubleCheckTrack(id, updateDoubleCheckTrackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingHedgeFundWithDoubleCheckTrack(portfolioId: number, basedate: string, options?: any): AxiosPromise<object> {
            return localVarFp.mountingHedgeFundWithDoubleCheckTrack(portfolioId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingWithDoubleCheckTrack(accountId: number, basedate: string, options?: any): AxiosPromise<object> {
            return localVarFp.mountingWithDoubleCheckTrack(accountId, basedate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoubleCheckTrackApi - interface
 * @export
 * @interface DoubleCheckTrackApi
 */
export interface DoubleCheckTrackApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {UpdateDoubleCheckTrackDto} updateDoubleCheckTrackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleCheckTrackApiInterface
     */
    allCheckedWithDoubleCheckTrack(id: number, updateDoubleCheckTrackDto: UpdateDoubleCheckTrackDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleCheckTrackApiInterface
     */
    mountingHedgeFundWithDoubleCheckTrack(portfolioId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleCheckTrackApiInterface
     */
    mountingWithDoubleCheckTrack(accountId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * DoubleCheckTrackApi - object-oriented interface
 * @export
 * @class DoubleCheckTrackApi
 * @extends {BaseAPI}
 */
export class DoubleCheckTrackApi extends BaseAPI implements DoubleCheckTrackApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {UpdateDoubleCheckTrackDto} updateDoubleCheckTrackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleCheckTrackApi
     */
    public allCheckedWithDoubleCheckTrack(id: number, updateDoubleCheckTrackDto: UpdateDoubleCheckTrackDto, options?: AxiosRequestConfig) {
        return DoubleCheckTrackApiFp(this.configuration).allCheckedWithDoubleCheckTrack(id, updateDoubleCheckTrackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleCheckTrackApi
     */
    public mountingHedgeFundWithDoubleCheckTrack(portfolioId: number, basedate: string, options?: AxiosRequestConfig) {
        return DoubleCheckTrackApiFp(this.configuration).mountingHedgeFundWithDoubleCheckTrack(portfolioId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleCheckTrackApi
     */
    public mountingWithDoubleCheckTrack(accountId: number, basedate: string, options?: AxiosRequestConfig) {
        return DoubleCheckTrackApiFp(this.configuration).mountingWithDoubleCheckTrack(accountId, basedate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportFileApi - axios parameter creator
 * @export
 */
export const ExportFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {string} frequency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceListWithExportFile: async (accountSeriesId: number, frequency: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountSeriesId' is not null or undefined
            assertParamExists('performanceListWithExportFile', 'accountSeriesId', accountSeriesId)
            // verify required parameter 'frequency' is not null or undefined
            assertParamExists('performanceListWithExportFile', 'frequency', frequency)
            const localVarPath = `/api/export-file/account-data-view/performance-list/{accountSeriesId}/query`
                .replace(`{${"accountSeriesId"}}`, encodeURIComponent(String(accountSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceYearByMonthWithExportFile: async (accountSeriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountSeriesId' is not null or undefined
            assertParamExists('performanceYearByMonthWithExportFile', 'accountSeriesId', accountSeriesId)
            const localVarPath = `/api/export-file/account-data-view/performance-year-by-month/{accountSeriesId}`
                .replace(`{${"accountSeriesId"}}`, encodeURIComponent(String(accountSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportFileApi - functional programming interface
 * @export
 */
export const ExportFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {string} frequency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceListWithExportFile(accountSeriesId: number, frequency: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceListWithExportFile(accountSeriesId, frequency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceYearByMonthWithExportFile(accountSeriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceYearByMonthWithExportFile(accountSeriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportFileApi - factory interface
 * @export
 */
export const ExportFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportFileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {string} frequency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceListWithExportFile(accountSeriesId: number, frequency: string, options?: any): AxiosPromise<void> {
            return localVarFp.performanceListWithExportFile(accountSeriesId, frequency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceYearByMonthWithExportFile(accountSeriesId: number, options?: any): AxiosPromise<object> {
            return localVarFp.performanceYearByMonthWithExportFile(accountSeriesId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportFileApi - interface
 * @export
 * @interface ExportFileApi
 */
export interface ExportFileApiInterface {
    /**
     * 
     * @param {number} accountSeriesId 
     * @param {string} frequency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportFileApiInterface
     */
    performanceListWithExportFile(accountSeriesId: number, frequency: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} accountSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportFileApiInterface
     */
    performanceYearByMonthWithExportFile(accountSeriesId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * ExportFileApi - object-oriented interface
 * @export
 * @class ExportFileApi
 * @extends {BaseAPI}
 */
export class ExportFileApi extends BaseAPI implements ExportFileApiInterface {
    /**
     * 
     * @param {number} accountSeriesId 
     * @param {string} frequency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportFileApi
     */
    public performanceListWithExportFile(accountSeriesId: number, frequency: string, options?: AxiosRequestConfig) {
        return ExportFileApiFp(this.configuration).performanceListWithExportFile(accountSeriesId, frequency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportFileApi
     */
    public performanceYearByMonthWithExportFile(accountSeriesId: number, options?: AxiosRequestConfig) {
        return ExportFileApiFp(this.configuration).performanceYearByMonthWithExportFile(accountSeriesId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ForeignExchangeTickerApi - axios parameter creator
 * @export
 */
export const ForeignExchangeTickerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithForeignExchangeTicker: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fx-ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} quoteCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCurrencyPairWithForeignExchangeTicker: async (currency: string, quoteCurrency: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('findByCurrencyPairWithForeignExchangeTicker', 'currency', currency)
            // verify required parameter 'quoteCurrency' is not null or undefined
            assertParamExists('findByCurrencyPairWithForeignExchangeTicker', 'quoteCurrency', quoteCurrency)
            const localVarPath = `/api/fx-ticker/by-currency-pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (quoteCurrency !== undefined) {
                localVarQueryParameter['quoteCurrency'] = quoteCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTickerWithForeignExchangeTicker: async (ticker: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('findByTickerWithForeignExchangeTicker', 'ticker', ticker)
            const localVarPath = `/api/fx-ticker/by-ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticker !== undefined) {
                localVarQueryParameter['ticker'] = ticker;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForeignExchangeTickerApi - functional programming interface
 * @export
 */
export const ForeignExchangeTickerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForeignExchangeTickerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithForeignExchangeTicker(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithForeignExchangeTicker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} quoteCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByCurrencyPairWithForeignExchangeTicker(currency: string, quoteCurrency: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByCurrencyPairWithForeignExchangeTicker(currency, quoteCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByTickerWithForeignExchangeTicker(ticker: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByTickerWithForeignExchangeTicker(ticker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForeignExchangeTickerApi - factory interface
 * @export
 */
export const ForeignExchangeTickerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForeignExchangeTickerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithForeignExchangeTicker(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithForeignExchangeTicker(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} quoteCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCurrencyPairWithForeignExchangeTicker(currency: string, quoteCurrency: string, options?: any): AxiosPromise<object> {
            return localVarFp.findByCurrencyPairWithForeignExchangeTicker(currency, quoteCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTickerWithForeignExchangeTicker(ticker: string, options?: any): AxiosPromise<object> {
            return localVarFp.findByTickerWithForeignExchangeTicker(ticker, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForeignExchangeTickerApi - interface
 * @export
 * @interface ForeignExchangeTickerApi
 */
export interface ForeignExchangeTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignExchangeTickerApiInterface
     */
    findAllWithForeignExchangeTicker(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} currency 
     * @param {string} quoteCurrency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignExchangeTickerApiInterface
     */
    findByCurrencyPairWithForeignExchangeTicker(currency: string, quoteCurrency: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} ticker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignExchangeTickerApiInterface
     */
    findByTickerWithForeignExchangeTicker(ticker: string, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * ForeignExchangeTickerApi - object-oriented interface
 * @export
 * @class ForeignExchangeTickerApi
 * @extends {BaseAPI}
 */
export class ForeignExchangeTickerApi extends BaseAPI implements ForeignExchangeTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignExchangeTickerApi
     */
    public findAllWithForeignExchangeTicker(options?: AxiosRequestConfig) {
        return ForeignExchangeTickerApiFp(this.configuration).findAllWithForeignExchangeTicker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {string} quoteCurrency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignExchangeTickerApi
     */
    public findByCurrencyPairWithForeignExchangeTicker(currency: string, quoteCurrency: string, options?: AxiosRequestConfig) {
        return ForeignExchangeTickerApiFp(this.configuration).findByCurrencyPairWithForeignExchangeTicker(currency, quoteCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignExchangeTickerApi
     */
    public findByTickerWithForeignExchangeTicker(ticker: string, options?: AxiosRequestConfig) {
        return ForeignExchangeTickerApiFp(this.configuration).findByTickerWithForeignExchangeTicker(ticker, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgeFundClassApi - axios parameter creator
 * @export
 */
export const HedgeFundClassApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHedgefundClassDto} createHedgefundClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundClasses: async (createHedgefundClassDto: CreateHedgefundClassDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHedgefundClassDto' is not null or undefined
            assertParamExists('createWithHedgefundClasses', 'createHedgefundClassDto', createHedgefundClassDto)
            const localVarPath = `/api/hedgefund-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHedgefundClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundClasses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefund-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} hedgefundClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLiquidityForConsolidationWithHedgefundClasses: async (hedgefundClassId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hedgefundClassId' is not null or undefined
            assertParamExists('findLiquidityForConsolidationWithHedgefundClasses', 'hedgefundClassId', hedgefundClassId)
            const localVarPath = `/api/hedgefund-classes/consolidation-liquidity/{hedgefundClassId}`
                .replace(`{${"hedgefundClassId"}}`, encodeURIComponent(String(hedgefundClassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundClasses: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHedgefundClasses', 'id', id)
            const localVarPath = `/api/hedgefund-classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHoldingClassLevelWithHedgefundClasses: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHoldingClassLevelWithHedgefundClasses', 'id', id)
            const localVarPath = `/api/hedgefund-classes/with-holding-class-level/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesByHedgeFundClassWithHedgefundClasses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSeriesByHedgeFundClassWithHedgefundClasses', 'id', id)
            const localVarPath = `/api/hedgefund-classes/{id}/hedgefund-series`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundClassId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSeriesWithHedgefundClasses: async (fundClassId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundClassId' is not null or undefined
            assertParamExists('getListSeriesWithHedgefundClasses', 'fundClassId', fundClassId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('getListSeriesWithHedgefundClasses', 'basedate', basedate)
            const localVarPath = `/api/hedgefund-classes/{fundClassId}/list-series-available`
                .replace(`{${"fundClassId"}}`, encodeURIComponent(String(fundClassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSoftLockWithHedgefundClasses: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeSoftLockWithHedgefundClasses', 'id', id)
            const localVarPath = `/api/hedgefund-classes/soft-lock/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundClasses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithHedgefundClasses', 'id', id)
            const localVarPath = `/api/hedgefund-classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollupListWithHedgefundClasses: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rollupListWithHedgefundClasses', 'id', id)
            const localVarPath = `/api/hedgefund-classes/rollup-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundClassDto} updateHedgefundClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundClasses: async (id: string, updateHedgefundClassDto: UpdateHedgefundClassDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithHedgefundClasses', 'id', id)
            // verify required parameter 'updateHedgefundClassDto' is not null or undefined
            assertParamExists('updateWithHedgefundClasses', 'updateHedgefundClassDto', updateHedgefundClassDto)
            const localVarPath = `/api/hedgefund-classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHedgefundClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgeFundClassApi - functional programming interface
 * @export
 */
export const HedgeFundClassApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgeFundClassApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHedgefundClassDto} createHedgefundClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithHedgefundClasses(createHedgefundClassDto: CreateHedgefundClassDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithHedgefundClasses(createHedgefundClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHedgefundClasses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHedgefundClasses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} hedgefundClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLiquidityForConsolidationWithHedgefundClasses(hedgefundClassId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLiquidityForConsolidationWithHedgefundClasses(hedgefundClassId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHedgefundClasses(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHedgefundClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHoldingClassLevelWithHedgefundClasses(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHoldingClassLevelWithHedgefundClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSeriesByHedgeFundClassWithHedgefundClasses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSeriesByHedgeFundClassWithHedgefundClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundClassId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListSeriesWithHedgefundClasses(fundClassId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListSeriesWithHedgefundClasses(fundClassId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSoftLockWithHedgefundClasses(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSoftLockWithHedgefundClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithHedgefundClasses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithHedgefundClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rollupListWithHedgefundClasses(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rollupListWithHedgefundClasses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundClassDto} updateHedgefundClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithHedgefundClasses(id: string, updateHedgefundClassDto: UpdateHedgefundClassDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUpdateHedgefundClassDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithHedgefundClasses(id, updateHedgefundClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgeFundClassApi - factory interface
 * @export
 */
export const HedgeFundClassApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgeFundClassApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHedgefundClassDto} createHedgefundClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundClasses(createHedgefundClassDto: CreateHedgefundClassDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithHedgefundClasses(createHedgefundClassDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundClasses(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithHedgefundClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} hedgefundClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLiquidityForConsolidationWithHedgefundClasses(hedgefundClassId: number, options?: any): AxiosPromise<object> {
            return localVarFp.findLiquidityForConsolidationWithHedgefundClasses(hedgefundClassId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundClasses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithHedgefundClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHoldingClassLevelWithHedgefundClasses(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithHoldingClassLevelWithHedgefundClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesByHedgeFundClassWithHedgefundClasses(id: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findSeriesByHedgeFundClassWithHedgefundClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundClassId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSeriesWithHedgefundClasses(fundClassId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getListSeriesWithHedgefundClasses(fundClassId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSoftLockWithHedgefundClasses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeSoftLockWithHedgefundClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundClasses(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithHedgefundClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollupListWithHedgefundClasses(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.rollupListWithHedgefundClasses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundClassDto} updateHedgefundClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundClasses(id: string, updateHedgefundClassDto: UpdateHedgefundClassDto, options?: any): AxiosPromise<ResponseUpdateHedgefundClassDto> {
            return localVarFp.updateWithHedgefundClasses(id, updateHedgefundClassDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgeFundClassApi - interface
 * @export
 * @interface HedgeFundClassApi
 */
export interface HedgeFundClassApiInterface {
    /**
     * 
     * @param {CreateHedgefundClassDto} createHedgefundClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    createWithHedgefundClasses(createHedgefundClassDto: CreateHedgefundClassDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    findAllWithHedgefundClasses(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} hedgefundClassId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    findLiquidityForConsolidationWithHedgefundClasses(hedgefundClassId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    findOneWithHedgefundClasses(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    findOneWithHoldingClassLevelWithHedgefundClasses(id: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    findSeriesByHedgeFundClassWithHedgefundClasses(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundClassId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    getListSeriesWithHedgefundClasses(fundClassId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    removeSoftLockWithHedgefundClasses(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    removeWithHedgefundClasses(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    rollupListWithHedgefundClasses(id: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundClassDto} updateHedgefundClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApiInterface
     */
    updateWithHedgefundClasses(id: string, updateHedgefundClassDto: UpdateHedgefundClassDto, options?: AxiosRequestConfig): AxiosPromise<ResponseUpdateHedgefundClassDto>;

}

/**
 * HedgeFundClassApi - object-oriented interface
 * @export
 * @class HedgeFundClassApi
 * @extends {BaseAPI}
 */
export class HedgeFundClassApi extends BaseAPI implements HedgeFundClassApiInterface {
    /**
     * 
     * @param {CreateHedgefundClassDto} createHedgefundClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public createWithHedgefundClasses(createHedgefundClassDto: CreateHedgefundClassDto, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).createWithHedgefundClasses(createHedgefundClassDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public findAllWithHedgefundClasses(options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).findAllWithHedgefundClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} hedgefundClassId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public findLiquidityForConsolidationWithHedgefundClasses(hedgefundClassId: number, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).findLiquidityForConsolidationWithHedgefundClasses(hedgefundClassId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public findOneWithHedgefundClasses(id: number, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).findOneWithHedgefundClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public findOneWithHoldingClassLevelWithHedgefundClasses(id: number, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).findOneWithHoldingClassLevelWithHedgefundClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public findSeriesByHedgeFundClassWithHedgefundClasses(id: string, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).findSeriesByHedgeFundClassWithHedgefundClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundClassId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public getListSeriesWithHedgefundClasses(fundClassId: number, basedate: string, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).getListSeriesWithHedgefundClasses(fundClassId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public removeSoftLockWithHedgefundClasses(id: number, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).removeSoftLockWithHedgefundClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public removeWithHedgefundClasses(id: string, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).removeWithHedgefundClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public rollupListWithHedgefundClasses(id: number, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).rollupListWithHedgefundClasses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundClassDto} updateHedgefundClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundClassApi
     */
    public updateWithHedgefundClasses(id: string, updateHedgefundClassDto: UpdateHedgefundClassDto, options?: AxiosRequestConfig) {
        return HedgeFundClassApiFp(this.configuration).updateWithHedgefundClasses(id, updateHedgefundClassDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgeFundHistoricalReturnsApi - axios parameter creator
 * @export
 */
export const HedgeFundHistoricalReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} hedgefundId 
         * @param {CalucalteHedgefundHistoricalReturnDto} calucalteHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateWithHedgefundHistoricalReturn: async (hedgefundId: string, calucalteHedgefundHistoricalReturnDto: CalucalteHedgefundHistoricalReturnDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hedgefundId' is not null or undefined
            assertParamExists('calculateWithHedgefundHistoricalReturn', 'hedgefundId', hedgefundId)
            // verify required parameter 'calucalteHedgefundHistoricalReturnDto' is not null or undefined
            assertParamExists('calculateWithHedgefundHistoricalReturn', 'calucalteHedgefundHistoricalReturnDto', calucalteHedgefundHistoricalReturnDto)
            const localVarPath = `/api/hedgefund-historical-return/{hedgefundId}/calculate`
                .replace(`{${"hedgefundId"}}`, encodeURIComponent(String(hedgefundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calucalteHedgefundHistoricalReturnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateHedgefundHistoricalReturnDto} createHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundHistoricalReturn: async (createHedgefundHistoricalReturnDto: CreateHedgefundHistoricalReturnDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHedgefundHistoricalReturnDto' is not null or undefined
            assertParamExists('createWithHedgefundHistoricalReturn', 'createHedgefundHistoricalReturnDto', createHedgefundHistoricalReturnDto)
            const localVarPath = `/api/hedgefund-historical-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHedgefundHistoricalReturnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundHistoricalReturn: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefund-historical-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hedgefundId 
         * @param {string} from 
         * @param {string} to 
         * @param {'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly'} frequency 
         * @param {'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByHegdefundIdWithHedgefundHistoricalReturn: async (hedgefundId: string, from: string, to: string, frequency: 'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly', currency: 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hedgefundId' is not null or undefined
            assertParamExists('findByHegdefundIdWithHedgefundHistoricalReturn', 'hedgefundId', hedgefundId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('findByHegdefundIdWithHedgefundHistoricalReturn', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('findByHegdefundIdWithHedgefundHistoricalReturn', 'to', to)
            // verify required parameter 'frequency' is not null or undefined
            assertParamExists('findByHegdefundIdWithHedgefundHistoricalReturn', 'frequency', frequency)
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('findByHegdefundIdWithHedgefundHistoricalReturn', 'currency', currency)
            const localVarPath = `/api/hedgefund-historical-return/{hedgefundId}/hedgefund`
                .replace(`{${"hedgefundId"}}`, encodeURIComponent(String(hedgefundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundHistoricalReturn: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHedgefundHistoricalReturn', 'id', id)
            const localVarPath = `/api/hedgefund-historical-return/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingWithHedgefundHistoricalReturn: async (fundId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('mountingWithHedgefundHistoricalReturn', 'fundId', fundId)
            const localVarPath = `/api/hedgefund-historical-return/mounting/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundHistoricalReturn: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithHedgefundHistoricalReturn', 'id', id)
            const localVarPath = `/api/hedgefund-historical-return/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hedgefundId 
         * @param {UpdateBulkHedgefundHistoricalReturnDto} updateBulkHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBulkWithHedgefundHistoricalReturn: async (hedgefundId: string, updateBulkHedgefundHistoricalReturnDto: UpdateBulkHedgefundHistoricalReturnDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hedgefundId' is not null or undefined
            assertParamExists('updateBulkWithHedgefundHistoricalReturn', 'hedgefundId', hedgefundId)
            // verify required parameter 'updateBulkHedgefundHistoricalReturnDto' is not null or undefined
            assertParamExists('updateBulkWithHedgefundHistoricalReturn', 'updateBulkHedgefundHistoricalReturnDto', updateBulkHedgefundHistoricalReturnDto)
            const localVarPath = `/api/hedgefund-historical-return/{hedgefundId}/hedgefund`
                .replace(`{${"hedgefundId"}}`, encodeURIComponent(String(hedgefundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBulkHedgefundHistoricalReturnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundHistoricalReturnDto} updateHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundHistoricalReturn: async (id: string, updateHedgefundHistoricalReturnDto: UpdateHedgefundHistoricalReturnDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithHedgefundHistoricalReturn', 'id', id)
            // verify required parameter 'updateHedgefundHistoricalReturnDto' is not null or undefined
            assertParamExists('updateWithHedgefundHistoricalReturn', 'updateHedgefundHistoricalReturnDto', updateHedgefundHistoricalReturnDto)
            const localVarPath = `/api/hedgefund-historical-return/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHedgefundHistoricalReturnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgeFundHistoricalReturnsApi - functional programming interface
 * @export
 */
export const HedgeFundHistoricalReturnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgeFundHistoricalReturnsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} hedgefundId 
         * @param {CalucalteHedgefundHistoricalReturnDto} calucalteHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateWithHedgefundHistoricalReturn(hedgefundId: string, calucalteHedgefundHistoricalReturnDto: CalucalteHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalucalteHedgefundHistoricalReturnDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateWithHedgefundHistoricalReturn(hedgefundId, calucalteHedgefundHistoricalReturnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateHedgefundHistoricalReturnDto} createHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithHedgefundHistoricalReturn(createHedgefundHistoricalReturnDto: CreateHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithHedgefundHistoricalReturn(createHedgefundHistoricalReturnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHedgefundHistoricalReturn(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHedgefundHistoricalReturn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hedgefundId 
         * @param {string} from 
         * @param {string} to 
         * @param {'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly'} frequency 
         * @param {'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByHegdefundIdWithHedgefundHistoricalReturn(hedgefundId: string, from: string, to: string, frequency: 'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly', currency: 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HedgefundHistoriccalReturnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByHegdefundIdWithHedgefundHistoricalReturn(hedgefundId, from, to, frequency, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHedgefundHistoricalReturn(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHedgefundHistoricalReturn(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mountingWithHedgefundHistoricalReturn(fundId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMountingHedgefundHistoricalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mountingWithHedgefundHistoricalReturn(fundId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithHedgefundHistoricalReturn(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithHedgefundHistoricalReturn(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hedgefundId 
         * @param {UpdateBulkHedgefundHistoricalReturnDto} updateBulkHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBulkWithHedgefundHistoricalReturn(hedgefundId: string, updateBulkHedgefundHistoricalReturnDto: UpdateBulkHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBulkWithHedgefundHistoricalReturn(hedgefundId, updateBulkHedgefundHistoricalReturnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundHistoricalReturnDto} updateHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithHedgefundHistoricalReturn(id: string, updateHedgefundHistoricalReturnDto: UpdateHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithHedgefundHistoricalReturn(id, updateHedgefundHistoricalReturnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgeFundHistoricalReturnsApi - factory interface
 * @export
 */
export const HedgeFundHistoricalReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgeFundHistoricalReturnsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} hedgefundId 
         * @param {CalucalteHedgefundHistoricalReturnDto} calucalteHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateWithHedgefundHistoricalReturn(hedgefundId: string, calucalteHedgefundHistoricalReturnDto: CalucalteHedgefundHistoricalReturnDto, options?: any): AxiosPromise<CalucalteHedgefundHistoricalReturnDto> {
            return localVarFp.calculateWithHedgefundHistoricalReturn(hedgefundId, calucalteHedgefundHistoricalReturnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateHedgefundHistoricalReturnDto} createHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundHistoricalReturn(createHedgefundHistoricalReturnDto: CreateHedgefundHistoricalReturnDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithHedgefundHistoricalReturn(createHedgefundHistoricalReturnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundHistoricalReturn(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithHedgefundHistoricalReturn(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hedgefundId 
         * @param {string} from 
         * @param {string} to 
         * @param {'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly'} frequency 
         * @param {'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByHegdefundIdWithHedgefundHistoricalReturn(hedgefundId: string, from: string, to: string, frequency: 'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly', currency: 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: any): AxiosPromise<Array<HedgefundHistoriccalReturnDto>> {
            return localVarFp.findByHegdefundIdWithHedgefundHistoricalReturn(hedgefundId, from, to, frequency, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundHistoricalReturn(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithHedgefundHistoricalReturn(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mountingWithHedgefundHistoricalReturn(fundId: number, options?: any): AxiosPromise<ResponseMountingHedgefundHistoricalDto> {
            return localVarFp.mountingWithHedgefundHistoricalReturn(fundId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundHistoricalReturn(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithHedgefundHistoricalReturn(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hedgefundId 
         * @param {UpdateBulkHedgefundHistoricalReturnDto} updateBulkHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBulkWithHedgefundHistoricalReturn(hedgefundId: string, updateBulkHedgefundHistoricalReturnDto: UpdateBulkHedgefundHistoricalReturnDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.updateBulkWithHedgefundHistoricalReturn(hedgefundId, updateBulkHedgefundHistoricalReturnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundHistoricalReturnDto} updateHedgefundHistoricalReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundHistoricalReturn(id: string, updateHedgefundHistoricalReturnDto: UpdateHedgefundHistoricalReturnDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithHedgefundHistoricalReturn(id, updateHedgefundHistoricalReturnDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgeFundHistoricalReturnsApi - interface
 * @export
 * @interface HedgeFundHistoricalReturnsApi
 */
export interface HedgeFundHistoricalReturnsApiInterface {
    /**
     * 
     * @param {string} hedgefundId 
     * @param {CalucalteHedgefundHistoricalReturnDto} calucalteHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    calculateWithHedgefundHistoricalReturn(hedgefundId: string, calucalteHedgefundHistoricalReturnDto: CalucalteHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): AxiosPromise<CalucalteHedgefundHistoricalReturnDto>;

    /**
     * 
     * @param {CreateHedgefundHistoricalReturnDto} createHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    createWithHedgefundHistoricalReturn(createHedgefundHistoricalReturnDto: CreateHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    findAllWithHedgefundHistoricalReturn(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} hedgefundId 
     * @param {string} from 
     * @param {string} to 
     * @param {'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly'} frequency 
     * @param {'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    findByHegdefundIdWithHedgefundHistoricalReturn(hedgefundId: string, from: string, to: string, frequency: 'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly', currency: 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig): AxiosPromise<Array<HedgefundHistoriccalReturnDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    findOneWithHedgefundHistoricalReturn(id: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} fundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    mountingWithHedgefundHistoricalReturn(fundId: number, options?: AxiosRequestConfig): AxiosPromise<ResponseMountingHedgefundHistoricalDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    removeWithHedgefundHistoricalReturn(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} hedgefundId 
     * @param {UpdateBulkHedgefundHistoricalReturnDto} updateBulkHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    updateBulkWithHedgefundHistoricalReturn(hedgefundId: string, updateBulkHedgefundHistoricalReturnDto: UpdateBulkHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundHistoricalReturnDto} updateHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApiInterface
     */
    updateWithHedgefundHistoricalReturn(id: string, updateHedgefundHistoricalReturnDto: UpdateHedgefundHistoricalReturnDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * HedgeFundHistoricalReturnsApi - object-oriented interface
 * @export
 * @class HedgeFundHistoricalReturnsApi
 * @extends {BaseAPI}
 */
export class HedgeFundHistoricalReturnsApi extends BaseAPI implements HedgeFundHistoricalReturnsApiInterface {
    /**
     * 
     * @param {string} hedgefundId 
     * @param {CalucalteHedgefundHistoricalReturnDto} calucalteHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public calculateWithHedgefundHistoricalReturn(hedgefundId: string, calucalteHedgefundHistoricalReturnDto: CalucalteHedgefundHistoricalReturnDto, options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).calculateWithHedgefundHistoricalReturn(hedgefundId, calucalteHedgefundHistoricalReturnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateHedgefundHistoricalReturnDto} createHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public createWithHedgefundHistoricalReturn(createHedgefundHistoricalReturnDto: CreateHedgefundHistoricalReturnDto, options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).createWithHedgefundHistoricalReturn(createHedgefundHistoricalReturnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public findAllWithHedgefundHistoricalReturn(options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).findAllWithHedgefundHistoricalReturn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hedgefundId 
     * @param {string} from 
     * @param {string} to 
     * @param {'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly'} frequency 
     * @param {'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH'} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public findByHegdefundIdWithHedgefundHistoricalReturn(hedgefundId: string, from: string, to: string, frequency: 'AllData' | 'Weekly' | 'Monthly' | 'WeeklyMonthly', currency: 'JPY' | 'USD' | 'EUR' | 'GBP' | 'AUD' | 'HKD' | 'CNY' | 'CNH', options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).findByHegdefundIdWithHedgefundHistoricalReturn(hedgefundId, from, to, frequency, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public findOneWithHedgefundHistoricalReturn(id: string, options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).findOneWithHedgefundHistoricalReturn(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public mountingWithHedgefundHistoricalReturn(fundId: number, options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).mountingWithHedgefundHistoricalReturn(fundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public removeWithHedgefundHistoricalReturn(id: string, options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).removeWithHedgefundHistoricalReturn(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hedgefundId 
     * @param {UpdateBulkHedgefundHistoricalReturnDto} updateBulkHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public updateBulkWithHedgefundHistoricalReturn(hedgefundId: string, updateBulkHedgefundHistoricalReturnDto: UpdateBulkHedgefundHistoricalReturnDto, options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).updateBulkWithHedgefundHistoricalReturn(hedgefundId, updateBulkHedgefundHistoricalReturnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundHistoricalReturnDto} updateHedgefundHistoricalReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundHistoricalReturnsApi
     */
    public updateWithHedgefundHistoricalReturn(id: string, updateHedgefundHistoricalReturnDto: UpdateHedgefundHistoricalReturnDto, options?: AxiosRequestConfig) {
        return HedgeFundHistoricalReturnsApiFp(this.configuration).updateWithHedgefundHistoricalReturn(id, updateHedgefundHistoricalReturnDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgeFundSeriesApi - axios parameter creator
 * @export
 */
export const HedgeFundSeriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHedgefundSeriesDto} createHedgefundSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundSeries: async (createHedgefundSeriesDto: CreateHedgefundSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHedgefundSeriesDto' is not null or undefined
            assertParamExists('createWithHedgefundSeries', 'createHedgefundSeriesDto', createHedgefundSeriesDto)
            const localVarPath = `/api/hedgefund-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHedgefundSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundSeries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefund-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundSeries: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHedgefundSeries', 'id', id)
            const localVarPath = `/api/hedgefund-series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundSeries: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithHedgefundSeries', 'id', id)
            const localVarPath = `/api/hedgefund-series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundSeriesDto} updateHedgefundSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundSeries: async (id: string, updateHedgefundSeriesDto: UpdateHedgefundSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithHedgefundSeries', 'id', id)
            // verify required parameter 'updateHedgefundSeriesDto' is not null or undefined
            assertParamExists('updateWithHedgefundSeries', 'updateHedgefundSeriesDto', updateHedgefundSeriesDto)
            const localVarPath = `/api/hedgefund-series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHedgefundSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgeFundSeriesApi - functional programming interface
 * @export
 */
export const HedgeFundSeriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgeFundSeriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHedgefundSeriesDto} createHedgefundSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithHedgefundSeries(createHedgefundSeriesDto: CreateHedgefundSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithHedgefundSeries(createHedgefundSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHedgefundSeries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHedgefundSeries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHedgefundSeries(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHedgefundSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithHedgefundSeries(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithHedgefundSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundSeriesDto} updateHedgefundSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithHedgefundSeries(id: string, updateHedgefundSeriesDto: UpdateHedgefundSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithHedgefundSeries(id, updateHedgefundSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgeFundSeriesApi - factory interface
 * @export
 */
export const HedgeFundSeriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgeFundSeriesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHedgefundSeriesDto} createHedgefundSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundSeries(createHedgefundSeriesDto: CreateHedgefundSeriesDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithHedgefundSeries(createHedgefundSeriesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundSeries(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithHedgefundSeries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundSeries(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithHedgefundSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundSeries(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithHedgefundSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundSeriesDto} updateHedgefundSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundSeries(id: string, updateHedgefundSeriesDto: UpdateHedgefundSeriesDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithHedgefundSeries(id, updateHedgefundSeriesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgeFundSeriesApi - interface
 * @export
 * @interface HedgeFundSeriesApi
 */
export interface HedgeFundSeriesApiInterface {
    /**
     * 
     * @param {CreateHedgefundSeriesDto} createHedgefundSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApiInterface
     */
    createWithHedgefundSeries(createHedgefundSeriesDto: CreateHedgefundSeriesDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApiInterface
     */
    findAllWithHedgefundSeries(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApiInterface
     */
    findOneWithHedgefundSeries(id: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApiInterface
     */
    removeWithHedgefundSeries(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundSeriesDto} updateHedgefundSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApiInterface
     */
    updateWithHedgefundSeries(id: string, updateHedgefundSeriesDto: UpdateHedgefundSeriesDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * HedgeFundSeriesApi - object-oriented interface
 * @export
 * @class HedgeFundSeriesApi
 * @extends {BaseAPI}
 */
export class HedgeFundSeriesApi extends BaseAPI implements HedgeFundSeriesApiInterface {
    /**
     * 
     * @param {CreateHedgefundSeriesDto} createHedgefundSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApi
     */
    public createWithHedgefundSeries(createHedgefundSeriesDto: CreateHedgefundSeriesDto, options?: AxiosRequestConfig) {
        return HedgeFundSeriesApiFp(this.configuration).createWithHedgefundSeries(createHedgefundSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApi
     */
    public findAllWithHedgefundSeries(options?: AxiosRequestConfig) {
        return HedgeFundSeriesApiFp(this.configuration).findAllWithHedgefundSeries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApi
     */
    public findOneWithHedgefundSeries(id: number, options?: AxiosRequestConfig) {
        return HedgeFundSeriesApiFp(this.configuration).findOneWithHedgefundSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApi
     */
    public removeWithHedgefundSeries(id: string, options?: AxiosRequestConfig) {
        return HedgeFundSeriesApiFp(this.configuration).removeWithHedgefundSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundSeriesDto} updateHedgefundSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundSeriesApi
     */
    public updateWithHedgefundSeries(id: string, updateHedgefundSeriesDto: UpdateHedgefundSeriesDto, options?: AxiosRequestConfig) {
        return HedgeFundSeriesApiFp(this.configuration).updateWithHedgefundSeries(id, updateHedgefundSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgeFundsApi - axios parameter creator
 * @export
 */
export const HedgeFundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHedgefundDto} createHedgefundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefunds: async (createHedgefundDto: CreateHedgefundDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHedgefundDto' is not null or undefined
            assertParamExists('createWithHedgefunds', 'createHedgefundDto', createHedgefundDto)
            const localVarPath = `/api/hedgefunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHedgefundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'id' | 'name' | 'abbreviation'>} fields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSpecifiedFieldsWithHedgefunds: async (fields: Array<'id' | 'name' | 'abbreviation'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fields' is not null or undefined
            assertParamExists('findAllSpecifiedFieldsWithHedgefunds', 'fields', fields)
            const localVarPath = `/api/hedgefunds/specified-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefunds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClassesByHedgeFundForHoldingSettingsWithHedgefunds: async (id: string, portfolioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findClassesByHedgeFundForHoldingSettingsWithHedgefunds', 'id', id)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('findClassesByHedgeFundForHoldingSettingsWithHedgefunds', 'portfolioId', portfolioId)
            const localVarPath = `/api/hedgefunds/{id}/{portfolioId}/hedgefund-classes-holding-settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClassesByHedgeFundWithHedgefunds: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findClassesByHedgeFundWithHedgefunds', 'id', id)
            const localVarPath = `/api/hedgefunds/{id}/hedgefund-classes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} hedgefundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForCheckResultWithHedgefunds: async (hedgefundId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hedgefundId' is not null or undefined
            assertParamExists('findForCheckResultWithHedgefunds', 'hedgefundId', hedgefundId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findForCheckResultWithHedgefunds', 'basedate', basedate)
            const localVarPath = `/api/hedgefunds/check-results/{hedgefundId}/{basedate}`
                .replace(`{${"hedgefundId"}}`, encodeURIComponent(String(hedgefundId)))
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefunds: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHedgefunds', 'id', id)
            const localVarPath = `/api/hedgefunds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithHedgefunds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefunds/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefunds: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithHedgefunds', 'id', id)
            const localVarPath = `/api/hedgefunds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {UpdateHedgefundDto} updateHedgefundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefunds: async (fundId: number, updateHedgefundDto: UpdateHedgefundDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('updateWithHedgefunds', 'fundId', fundId)
            // verify required parameter 'updateHedgefundDto' is not null or undefined
            assertParamExists('updateWithHedgefunds', 'updateHedgefundDto', updateHedgefundDto)
            const localVarPath = `/api/hedgefunds/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHedgefundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgeFundsApi - functional programming interface
 * @export
 */
export const HedgeFundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgeFundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHedgefundDto} createHedgefundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithHedgefunds(createHedgefundDto: CreateHedgefundDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithHedgefunds(createHedgefundDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'id' | 'name' | 'abbreviation'>} fields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSpecifiedFieldsWithHedgefunds(fields: Array<'id' | 'name' | 'abbreviation'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSpecifiedFieldsWithHedgefunds(fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHedgefunds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHedgefunds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findClassesByHedgeFundForHoldingSettingsWithHedgefunds(id: string, portfolioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findClassesByHedgeFundForHoldingSettingsWithHedgefunds(id, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findClassesByHedgeFundWithHedgefunds(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findClassesByHedgeFundWithHedgefunds(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} hedgefundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findForCheckResultWithHedgefunds(hedgefundId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findForCheckResultWithHedgefunds(hedgefundId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHedgefunds(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHedgefunds(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithHedgefunds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithHedgefunds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithHedgefunds(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithHedgefunds(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {UpdateHedgefundDto} updateHedgefundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithHedgefunds(fundId: number, updateHedgefundDto: UpdateHedgefundDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithHedgefunds(fundId, updateHedgefundDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgeFundsApi - factory interface
 * @export
 */
export const HedgeFundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgeFundsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHedgefundDto} createHedgefundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefunds(createHedgefundDto: CreateHedgefundDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithHedgefunds(createHedgefundDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<'id' | 'name' | 'abbreviation'>} fields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSpecifiedFieldsWithHedgefunds(fields: Array<'id' | 'name' | 'abbreviation'>, options?: any): AxiosPromise<void> {
            return localVarFp.findAllSpecifiedFieldsWithHedgefunds(fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefunds(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithHedgefunds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClassesByHedgeFundForHoldingSettingsWithHedgefunds(id: string, portfolioId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findClassesByHedgeFundForHoldingSettingsWithHedgefunds(id, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClassesByHedgeFundWithHedgefunds(id: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findClassesByHedgeFundWithHedgefunds(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} hedgefundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForCheckResultWithHedgefunds(hedgefundId: number, basedate: string, options?: any): AxiosPromise<object> {
            return localVarFp.findForCheckResultWithHedgefunds(hedgefundId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefunds(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithHedgefunds(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithHedgefunds(options?: any): AxiosPromise<object> {
            return localVarFp.listWithHedgefunds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefunds(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithHedgefunds(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {UpdateHedgefundDto} updateHedgefundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefunds(fundId: number, updateHedgefundDto: UpdateHedgefundDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithHedgefunds(fundId, updateHedgefundDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgeFundsApi - interface
 * @export
 * @interface HedgeFundsApi
 */
export interface HedgeFundsApiInterface {
    /**
     * 
     * @param {CreateHedgefundDto} createHedgefundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    createWithHedgefunds(createHedgefundDto: CreateHedgefundDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<'id' | 'name' | 'abbreviation'>} fields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    findAllSpecifiedFieldsWithHedgefunds(fields: Array<'id' | 'name' | 'abbreviation'>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    findAllWithHedgefunds(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {number} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    findClassesByHedgeFundForHoldingSettingsWithHedgefunds(id: string, portfolioId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    findClassesByHedgeFundWithHedgefunds(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} hedgefundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    findForCheckResultWithHedgefunds(hedgefundId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    findOneWithHedgefunds(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    listWithHedgefunds(options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    removeWithHedgefunds(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} fundId 
     * @param {UpdateHedgefundDto} updateHedgefundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApiInterface
     */
    updateWithHedgefunds(fundId: number, updateHedgefundDto: UpdateHedgefundDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * HedgeFundsApi - object-oriented interface
 * @export
 * @class HedgeFundsApi
 * @extends {BaseAPI}
 */
export class HedgeFundsApi extends BaseAPI implements HedgeFundsApiInterface {
    /**
     * 
     * @param {CreateHedgefundDto} createHedgefundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public createWithHedgefunds(createHedgefundDto: CreateHedgefundDto, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).createWithHedgefunds(createHedgefundDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<'id' | 'name' | 'abbreviation'>} fields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public findAllSpecifiedFieldsWithHedgefunds(fields: Array<'id' | 'name' | 'abbreviation'>, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).findAllSpecifiedFieldsWithHedgefunds(fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public findAllWithHedgefunds(options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).findAllWithHedgefunds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public findClassesByHedgeFundForHoldingSettingsWithHedgefunds(id: string, portfolioId: number, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).findClassesByHedgeFundForHoldingSettingsWithHedgefunds(id, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public findClassesByHedgeFundWithHedgefunds(id: string, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).findClassesByHedgeFundWithHedgefunds(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} hedgefundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public findForCheckResultWithHedgefunds(hedgefundId: number, basedate: string, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).findForCheckResultWithHedgefunds(hedgefundId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public findOneWithHedgefunds(id: number, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).findOneWithHedgefunds(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public listWithHedgefunds(options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).listWithHedgefunds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public removeWithHedgefunds(id: string, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).removeWithHedgefunds(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {UpdateHedgefundDto} updateHedgefundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgeFundsApi
     */
    public updateWithHedgefunds(fundId: number, updateHedgefundDto: UpdateHedgefundDto, options?: AxiosRequestConfig) {
        return HedgeFundsApiFp(this.configuration).updateWithHedgefunds(fundId, updateHedgefundDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgefundExposureApi - axios parameter creator
 * @export
 */
export const HedgefundExposureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByFundWithHedgefundExposure: async (fundId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('findAllByFundWithHedgefundExposure', 'fundId', fundId)
            const localVarPath = `/api/hedgefund-exposure/by-fund/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundExposure: async (fundId: string, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('findOneWithHedgefundExposure', 'fundId', fundId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findOneWithHedgefundExposure', 'basedate', basedate)
            const localVarPath = `/api/hedgefund-exposure/by-fund-and-basedate/{fundId}/{basedate}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)))
                .replace(`{${"basedate"}}`, encodeURIComponent(String(basedate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWithHedgefundExposure: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadWithHedgefundExposure', 'file', file)
            const localVarPath = `/api/hedgefund-exposure/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ExposureBreakDownDto>} exposureBreakDownDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertExposureBreakdownWithHedgefundExposure: async (exposureBreakDownDto: Array<ExposureBreakDownDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exposureBreakDownDto' is not null or undefined
            assertParamExists('upsertExposureBreakdownWithHedgefundExposure', 'exposureBreakDownDto', exposureBreakDownDto)
            const localVarPath = `/api/hedgefund-exposure/upsert-exposure-breakdown`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exposureBreakDownDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateHFExposureDto>} createHFExposureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertHFExposureWithHedgefundExposure: async (createHFExposureDto: Array<CreateHFExposureDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHFExposureDto' is not null or undefined
            assertParamExists('upsertHFExposureWithHedgefundExposure', 'createHFExposureDto', createHFExposureDto)
            const localVarPath = `/api/hedgefund-exposure/upsert-hf-exposure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHFExposureDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgefundExposureApi - functional programming interface
 * @export
 */
export const HedgefundExposureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgefundExposureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByFundWithHedgefundExposure(fundId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByFundWithHedgefundExposure(fundId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHedgefundExposure(fundId: string, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHedgefundExposure(fundId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadWithHedgefundExposure(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadWithHedgefundExposure(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ExposureBreakDownDto>} exposureBreakDownDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertExposureBreakdownWithHedgefundExposure(exposureBreakDownDto: Array<ExposureBreakDownDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertExposureBreakdownWithHedgefundExposure(exposureBreakDownDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateHFExposureDto>} createHFExposureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertHFExposureWithHedgefundExposure(createHFExposureDto: Array<CreateHFExposureDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertHFExposureWithHedgefundExposure(createHFExposureDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgefundExposureApi - factory interface
 * @export
 */
export const HedgefundExposureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgefundExposureApiFp(configuration)
    return {
        /**
         * 
         * @param {number} fundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByFundWithHedgefundExposure(fundId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllByFundWithHedgefundExposure(fundId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundExposure(fundId: string, basedate: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithHedgefundExposure(fundId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWithHedgefundExposure(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadWithHedgefundExposure(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ExposureBreakDownDto>} exposureBreakDownDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertExposureBreakdownWithHedgefundExposure(exposureBreakDownDto: Array<ExposureBreakDownDto>, options?: any): AxiosPromise<void> {
            return localVarFp.upsertExposureBreakdownWithHedgefundExposure(exposureBreakDownDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateHFExposureDto>} createHFExposureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertHFExposureWithHedgefundExposure(createHFExposureDto: Array<CreateHFExposureDto>, options?: any): AxiosPromise<void> {
            return localVarFp.upsertHFExposureWithHedgefundExposure(createHFExposureDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgefundExposureApi - interface
 * @export
 * @interface HedgefundExposureApi
 */
export interface HedgefundExposureApiInterface {
    /**
     * 
     * @param {number} fundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApiInterface
     */
    findAllByFundWithHedgefundExposure(fundId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApiInterface
     */
    findOneWithHedgefundExposure(fundId: string, basedate: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApiInterface
     */
    uploadWithHedgefundExposure(file: any, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<ExposureBreakDownDto>} exposureBreakDownDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApiInterface
     */
    upsertExposureBreakdownWithHedgefundExposure(exposureBreakDownDto: Array<ExposureBreakDownDto>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<CreateHFExposureDto>} createHFExposureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApiInterface
     */
    upsertHFExposureWithHedgefundExposure(createHFExposureDto: Array<CreateHFExposureDto>, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * HedgefundExposureApi - object-oriented interface
 * @export
 * @class HedgefundExposureApi
 * @extends {BaseAPI}
 */
export class HedgefundExposureApi extends BaseAPI implements HedgefundExposureApiInterface {
    /**
     * 
     * @param {number} fundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApi
     */
    public findAllByFundWithHedgefundExposure(fundId: number, options?: AxiosRequestConfig) {
        return HedgefundExposureApiFp(this.configuration).findAllByFundWithHedgefundExposure(fundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApi
     */
    public findOneWithHedgefundExposure(fundId: string, basedate: string, options?: AxiosRequestConfig) {
        return HedgefundExposureApiFp(this.configuration).findOneWithHedgefundExposure(fundId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApi
     */
    public uploadWithHedgefundExposure(file: any, options?: AxiosRequestConfig) {
        return HedgefundExposureApiFp(this.configuration).uploadWithHedgefundExposure(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ExposureBreakDownDto>} exposureBreakDownDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApi
     */
    public upsertExposureBreakdownWithHedgefundExposure(exposureBreakDownDto: Array<ExposureBreakDownDto>, options?: AxiosRequestConfig) {
        return HedgefundExposureApiFp(this.configuration).upsertExposureBreakdownWithHedgefundExposure(exposureBreakDownDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateHFExposureDto>} createHFExposureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundExposureApi
     */
    public upsertHFExposureWithHedgefundExposure(createHFExposureDto: Array<CreateHFExposureDto>, options?: AxiosRequestConfig) {
        return HedgefundExposureApiFp(this.configuration).upsertHFExposureWithHedgefundExposure(createHFExposureDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgefundIndexTickerApi - axios parameter creator
 * @export
 */
export const HedgefundIndexTickerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundIndexTicker: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefund-index-ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTickerWithHedgefundIndexTicker: async (ticker: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('findByTickerWithHedgefundIndexTicker', 'ticker', ticker)
            const localVarPath = `/api/hedgefund-index-ticker/by-ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticker !== undefined) {
                localVarQueryParameter['ticker'] = ticker;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgefundIndexTickerApi - functional programming interface
 * @export
 */
export const HedgefundIndexTickerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgefundIndexTickerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHedgefundIndexTicker(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHedgefundIndexTicker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByTickerWithHedgefundIndexTicker(ticker: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByTickerWithHedgefundIndexTicker(ticker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgefundIndexTickerApi - factory interface
 * @export
 */
export const HedgefundIndexTickerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgefundIndexTickerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundIndexTicker(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithHedgefundIndexTicker(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTickerWithHedgefundIndexTicker(ticker: string, options?: any): AxiosPromise<object> {
            return localVarFp.findByTickerWithHedgefundIndexTicker(ticker, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgefundIndexTickerApi - interface
 * @export
 * @interface HedgefundIndexTickerApi
 */
export interface HedgefundIndexTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundIndexTickerApiInterface
     */
    findAllWithHedgefundIndexTicker(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} ticker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundIndexTickerApiInterface
     */
    findByTickerWithHedgefundIndexTicker(ticker: string, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * HedgefundIndexTickerApi - object-oriented interface
 * @export
 * @class HedgefundIndexTickerApi
 * @extends {BaseAPI}
 */
export class HedgefundIndexTickerApi extends BaseAPI implements HedgefundIndexTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundIndexTickerApi
     */
    public findAllWithHedgefundIndexTicker(options?: AxiosRequestConfig) {
        return HedgefundIndexTickerApiFp(this.configuration).findAllWithHedgefundIndexTicker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundIndexTickerApi
     */
    public findByTickerWithHedgefundIndexTicker(ticker: string, options?: AxiosRequestConfig) {
        return HedgefundIndexTickerApiFp(this.configuration).findByTickerWithHedgefundIndexTicker(ticker, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgefundNavApi - axios parameter creator
 * @export
 */
export const HedgefundNavApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<UpdateHedgefundNavDto>} updateHedgefundNavDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithHedgefundNav: async (updateHedgefundNavDto: Array<UpdateHedgefundNavDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateHedgefundNavDto' is not null or undefined
            assertParamExists('bulkSaveWithHedgefundNav', 'updateHedgefundNavDto', updateHedgefundNavDto)
            const localVarPath = `/api/hedgefund-nav/bulk-save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHedgefundNavDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckMountingWithHedgefundNav: async (portfolioId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('doubleCheckMountingWithHedgefundNav', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('doubleCheckMountingWithHedgefundNav', 'basedate', basedate)
            const localVarPath = `/api/hedgefund-nav/double-check-mounting/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {Array<UpsertFundNavDoubleCheckDto>} upsertFundNavDoubleCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckWithHedgefundNav: async (portfolioId: number, basedate: string, upsertFundNavDoubleCheckDto: Array<UpsertFundNavDoubleCheckDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('doubleCheckWithHedgefundNav', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('doubleCheckWithHedgefundNav', 'basedate', basedate)
            // verify required parameter 'upsertFundNavDoubleCheckDto' is not null or undefined
            assertParamExists('doubleCheckWithHedgefundNav', 'upsertFundNavDoubleCheckDto', upsertFundNavDoubleCheckDto)
            const localVarPath = `/api/hedgefund-nav/double-check/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertFundNavDoubleCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundNav: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefund-nav`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundNav: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHedgefundNav', 'id', id)
            const localVarPath = `/api/hedgefund-nav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} basedate 
         * @param {Array<string>} portfolioIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFundNavByPortfolioWithHedgefundNav: async (basedate: string, portfolioIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('listFundNavByPortfolioWithHedgefundNav', 'basedate', basedate)
            // verify required parameter 'portfolioIds' is not null or undefined
            assertParamExists('listFundNavByPortfolioWithHedgefundNav', 'portfolioIds', portfolioIds)
            const localVarPath = `/api/hedgefund-nav/list-fund-nav`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (portfolioIds) {
                localVarQueryParameter['portfolioIds'] = portfolioIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundNav: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithHedgefundNav', 'id', id)
            const localVarPath = `/api/hedgefund-nav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgefundNavApi - functional programming interface
 * @export
 */
export const HedgefundNavApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgefundNavApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateHedgefundNavDto>} updateHedgefundNavDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSaveWithHedgefundNav(updateHedgefundNavDto: Array<UpdateHedgefundNavDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UpdateHedgefundNavDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSaveWithHedgefundNav(updateHedgefundNavDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doubleCheckMountingWithHedgefundNav(portfolioId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doubleCheckMountingWithHedgefundNav(portfolioId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {Array<UpsertFundNavDoubleCheckDto>} upsertFundNavDoubleCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doubleCheckWithHedgefundNav(portfolioId: number, basedate: string, upsertFundNavDoubleCheckDto: Array<UpsertFundNavDoubleCheckDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doubleCheckWithHedgefundNav(portfolioId, basedate, upsertFundNavDoubleCheckDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHedgefundNav(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHedgefundNav(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHedgefundNav(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHedgefundNav(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} basedate 
         * @param {Array<string>} portfolioIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFundNavByPortfolioWithHedgefundNav(basedate: string, portfolioIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFundNavByPortfolioWithHedgefundNav(basedate, portfolioIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithHedgefundNav(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithHedgefundNav(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgefundNavApi - factory interface
 * @export
 */
export const HedgefundNavApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgefundNavApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateHedgefundNavDto>} updateHedgefundNavDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithHedgefundNav(updateHedgefundNavDto: Array<UpdateHedgefundNavDto>, options?: any): AxiosPromise<Array<UpdateHedgefundNavDto>> {
            return localVarFp.bulkSaveWithHedgefundNav(updateHedgefundNavDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckMountingWithHedgefundNav(portfolioId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.doubleCheckMountingWithHedgefundNav(portfolioId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {Array<UpsertFundNavDoubleCheckDto>} upsertFundNavDoubleCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckWithHedgefundNav(portfolioId: number, basedate: string, upsertFundNavDoubleCheckDto: Array<UpsertFundNavDoubleCheckDto>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.doubleCheckWithHedgefundNav(portfolioId, basedate, upsertFundNavDoubleCheckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundNav(options?: any): AxiosPromise<string> {
            return localVarFp.findAllWithHedgefundNav(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundNav(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithHedgefundNav(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} basedate 
         * @param {Array<string>} portfolioIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFundNavByPortfolioWithHedgefundNav(basedate: string, portfolioIds: Array<string>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.listFundNavByPortfolioWithHedgefundNav(basedate, portfolioIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundNav(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeWithHedgefundNav(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgefundNavApi - interface
 * @export
 * @interface HedgefundNavApi
 */
export interface HedgefundNavApiInterface {
    /**
     * 
     * @param {Array<UpdateHedgefundNavDto>} updateHedgefundNavDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApiInterface
     */
    bulkSaveWithHedgefundNav(updateHedgefundNavDto: Array<UpdateHedgefundNavDto>, options?: AxiosRequestConfig): AxiosPromise<Array<UpdateHedgefundNavDto>>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApiInterface
     */
    doubleCheckMountingWithHedgefundNav(portfolioId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {Array<UpsertFundNavDoubleCheckDto>} upsertFundNavDoubleCheckDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApiInterface
     */
    doubleCheckWithHedgefundNav(portfolioId: number, basedate: string, upsertFundNavDoubleCheckDto: Array<UpsertFundNavDoubleCheckDto>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApiInterface
     */
    findAllWithHedgefundNav(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApiInterface
     */
    findOneWithHedgefundNav(id: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} basedate 
     * @param {Array<string>} portfolioIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApiInterface
     */
    listFundNavByPortfolioWithHedgefundNav(basedate: string, portfolioIds: Array<string>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApiInterface
     */
    removeWithHedgefundNav(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * HedgefundNavApi - object-oriented interface
 * @export
 * @class HedgefundNavApi
 * @extends {BaseAPI}
 */
export class HedgefundNavApi extends BaseAPI implements HedgefundNavApiInterface {
    /**
     * 
     * @param {Array<UpdateHedgefundNavDto>} updateHedgefundNavDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApi
     */
    public bulkSaveWithHedgefundNav(updateHedgefundNavDto: Array<UpdateHedgefundNavDto>, options?: AxiosRequestConfig) {
        return HedgefundNavApiFp(this.configuration).bulkSaveWithHedgefundNav(updateHedgefundNavDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApi
     */
    public doubleCheckMountingWithHedgefundNav(portfolioId: number, basedate: string, options?: AxiosRequestConfig) {
        return HedgefundNavApiFp(this.configuration).doubleCheckMountingWithHedgefundNav(portfolioId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {Array<UpsertFundNavDoubleCheckDto>} upsertFundNavDoubleCheckDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApi
     */
    public doubleCheckWithHedgefundNav(portfolioId: number, basedate: string, upsertFundNavDoubleCheckDto: Array<UpsertFundNavDoubleCheckDto>, options?: AxiosRequestConfig) {
        return HedgefundNavApiFp(this.configuration).doubleCheckWithHedgefundNav(portfolioId, basedate, upsertFundNavDoubleCheckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApi
     */
    public findAllWithHedgefundNav(options?: AxiosRequestConfig) {
        return HedgefundNavApiFp(this.configuration).findAllWithHedgefundNav(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApi
     */
    public findOneWithHedgefundNav(id: string, options?: AxiosRequestConfig) {
        return HedgefundNavApiFp(this.configuration).findOneWithHedgefundNav(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} basedate 
     * @param {Array<string>} portfolioIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApi
     */
    public listFundNavByPortfolioWithHedgefundNav(basedate: string, portfolioIds: Array<string>, options?: AxiosRequestConfig) {
        return HedgefundNavApiFp(this.configuration).listFundNavByPortfolioWithHedgefundNav(basedate, portfolioIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundNavApi
     */
    public removeWithHedgefundNav(id: string, options?: AxiosRequestConfig) {
        return HedgefundNavApiFp(this.configuration).removeWithHedgefundNav(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HedgefundTransactionsApi - axios parameter creator
 * @export
 */
export const HedgefundTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} fundTxnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clsMountingWithHedgefundTransactions: async (fundTxnItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundTxnItemId' is not null or undefined
            assertParamExists('clsMountingWithHedgefundTransactions', 'fundTxnItemId', fundTxnItemId)
            const localVarPath = `/api/hedgefund-transactions/cls-mounting/{fundTxnItemId}`
                .replace(`{${"fundTxnItemId"}}`, encodeURIComponent(String(fundTxnItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateHoldingSettingDto} createHoldingSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoldingWithHedgefundTransactions: async (createHoldingSettingDto: CreateHoldingSettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHoldingSettingDto' is not null or undefined
            assertParamExists('createHoldingWithHedgefundTransactions', 'createHoldingSettingDto', createHoldingSettingDto)
            const localVarPath = `/api/hedgefund-transactions/holdings-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHoldingSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateHedgefundTransactionDto} createHedgefundTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundTransactions: async (createHedgefundTransactionDto: CreateHedgefundTransactionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHedgefundTransactionDto' is not null or undefined
            assertParamExists('createWithHedgefundTransactions', 'createHedgefundTransactionDto', createHedgefundTransactionDto)
            const localVarPath = `/api/hedgefund-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHedgefundTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefund-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPortfolioAndBasedateWithHedgefundTransactions: async (portfolioId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('findByPortfolioAndBasedateWithHedgefundTransactions', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findByPortfolioAndBasedateWithHedgefundTransactions', 'basedate', basedate)
            const localVarPath = `/api/hedgefund-transactions/list-txn/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInitTransactionWithHedgefundTransactions: async (fundSeriesId: number, portfolioId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundSeriesId' is not null or undefined
            assertParamExists('findInitTransactionWithHedgefundTransactions', 'fundSeriesId', fundSeriesId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('findInitTransactionWithHedgefundTransactions', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findInitTransactionWithHedgefundTransactions', 'basedate', basedate)
            const localVarPath = `/api/hedgefund-transactions/find-init-txn/{fundSeriesId}`
                .replace(`{${"fundSeriesId"}}`, encodeURIComponent(String(fundSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {number} [fundClassId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNavFinalWithHedgefundTransactions: async (portfolioId: number, basedate: string, fundClassId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('findNavFinalWithHedgefundTransactions', 'portfolioId', portfolioId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findNavFinalWithHedgefundTransactions', 'basedate', basedate)
            const localVarPath = `/api/hedgefund-transactions/navFinal/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (fundClassId !== undefined) {
                localVarQueryParameter['fundClassId'] = fundClassId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundTransactions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithHedgefundTransactions', 'id', id)
            const localVarPath = `/api/hedgefund-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hedgeFundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccountInvestInHedgeFundWithHedgefundTransactions: async (hedgeFundId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hedgeFundId' is not null or undefined
            assertParamExists('getAllAccountInvestInHedgeFundWithHedgefundTransactions', 'hedgeFundId', hedgeFundId)
            const localVarPath = `/api/hedgefund-transactions/get-account-list/{hedgeFundId}`
                .replace(`{${"hedgeFundId"}}`, encodeURIComponent(String(hedgeFundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClsFundTxnWithHedgefundTransactions: async (txnItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txnItemId' is not null or undefined
            assertParamExists('getClsFundTxnWithHedgefundTransactions', 'txnItemId', txnItemId)
            const localVarPath = `/api/hedgefund-transactions/get-cls-fund-txn/{txnItemId}`
                .replace(`{${"txnItemId"}}`, encodeURIComponent(String(txnItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RedeemCalcDto} redeemCalcDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemCalcWithHedgefundTransactions: async (redeemCalcDto: RedeemCalcDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redeemCalcDto' is not null or undefined
            assertParamExists('redeemCalcWithHedgefundTransactions', 'redeemCalcDto', redeemCalcDto)
            const localVarPath = `/api/hedgefund-transactions/redeem-calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redeemCalcDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {number} [fundClassId] 
         * @param {string} [basedate] 
         * @param {string} [isClassSeriesChange] 
         * @param {number} [hedgefundId] 
         * @param {string} [currency] 
         * @param {number} [editFundTxnItemId] 
         * @param {string} [txnHash] 
         * @param {Array<string>} [fundSeriesIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemMountingWithHedgefundTransactions: async (portfolioId: number, fundClassId?: number, basedate?: string, isClassSeriesChange?: string, hedgefundId?: number, currency?: string, editFundTxnItemId?: number, txnHash?: string, fundSeriesIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('redeemMountingWithHedgefundTransactions', 'portfolioId', portfolioId)
            const localVarPath = `/api/hedgefund-transactions/redeem-mounting/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fundClassId !== undefined) {
                localVarQueryParameter['fundClassId'] = fundClassId;
            }

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (isClassSeriesChange !== undefined) {
                localVarQueryParameter['isClassSeriesChange'] = isClassSeriesChange;
            }

            if (hedgefundId !== undefined) {
                localVarQueryParameter['hedgefundId'] = hedgefundId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (editFundTxnItemId !== undefined) {
                localVarQueryParameter['editFundTxnItemId'] = editFundTxnItemId;
            }

            if (txnHash !== undefined) {
                localVarQueryParameter['txnHash'] = txnHash;
            }

            if (fundSeriesIds) {
                localVarQueryParameter['fundSeriesIds'] = fundSeriesIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<HedgefundRedeemDto>} hedgefundRedeemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemWithHedgefundTransactions: async (hedgefundRedeemDto: Array<HedgefundRedeemDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hedgefundRedeemDto' is not null or undefined
            assertParamExists('redeemWithHedgefundTransactions', 'hedgefundRedeemDto', hedgefundRedeemDto)
            const localVarPath = `/api/hedgefund-transactions/redeem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hedgefundRedeemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionClsItemWithHedgefundTransactions: async (txnItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txnItemId' is not null or undefined
            assertParamExists('removeTransactionClsItemWithHedgefundTransactions', 'txnItemId', txnItemId)
            const localVarPath = `/api/hedgefund-transactions/transaction-cls-item/{txnItemId}`
                .replace(`{${"txnItemId"}}`, encodeURIComponent(String(txnItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionItemWithHedgefundTransactions: async (txnItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txnItemId' is not null or undefined
            assertParamExists('removeTransactionItemWithHedgefundTransactions', 'txnItemId', txnItemId)
            const localVarPath = `/api/hedgefund-transactions/transaction-item/{txnItemId}`
                .replace(`{${"txnItemId"}}`, encodeURIComponent(String(txnItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionItemsWithHedgefundTransactions: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('removeTransactionItemsWithHedgefundTransactions', 'requestBody', requestBody)
            const localVarPath = `/api/hedgefund-transactions/transaction-item/bulk-remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundTransactions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithHedgefundTransactions', 'id', id)
            const localVarPath = `/api/hedgefund-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seedOriginsWithHedgefundTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hedgefund-transactions/seed-origins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundTransactionDto} updateHedgefundTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundTransactions: async (id: string, updateHedgefundTransactionDto: UpdateHedgefundTransactionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithHedgefundTransactions', 'id', id)
            // verify required parameter 'updateHedgefundTransactionDto' is not null or undefined
            assertParamExists('updateWithHedgefundTransactions', 'updateHedgefundTransactionDto', updateHedgefundTransactionDto)
            const localVarPath = `/api/hedgefund-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHedgefundTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HedgefundTransactionsApi - functional programming interface
 * @export
 */
export const HedgefundTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HedgefundTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} fundTxnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clsMountingWithHedgefundTransactions(fundTxnItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clsMountingWithHedgefundTransactions(fundTxnItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateHoldingSettingDto} createHoldingSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoldingWithHedgefundTransactions(createHoldingSettingDto: CreateHoldingSettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoldingWithHedgefundTransactions(createHoldingSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateHedgefundTransactionDto} createHedgefundTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithHedgefundTransactions(createHedgefundTransactionDto: CreateHedgefundTransactionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithHedgefundTransactions(createHedgefundTransactionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithHedgefundTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithHedgefundTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByPortfolioAndBasedateWithHedgefundTransactions(portfolioId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByPortfolioAndBasedateWithHedgefundTransactions(portfolioId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findInitTransactionWithHedgefundTransactions(fundSeriesId: number, portfolioId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findInitTransactionWithHedgefundTransactions(fundSeriesId, portfolioId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {number} [fundClassId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNavFinalWithHedgefundTransactions(portfolioId: number, basedate: string, fundClassId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNavFinalWithHedgefundTransactions(portfolioId, basedate, fundClassId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithHedgefundTransactions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithHedgefundTransactions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hedgeFundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAccountInvestInHedgeFundWithHedgefundTransactions(hedgeFundId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAccountInvestInHedgeFundWithHedgefundTransactions(hedgeFundId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClsFundTxnWithHedgefundTransactions(txnItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClsFundTxnWithHedgefundTransactions(txnItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RedeemCalcDto} redeemCalcDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemCalcWithHedgefundTransactions(redeemCalcDto: RedeemCalcDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemCalcWithHedgefundTransactions(redeemCalcDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {number} [fundClassId] 
         * @param {string} [basedate] 
         * @param {string} [isClassSeriesChange] 
         * @param {number} [hedgefundId] 
         * @param {string} [currency] 
         * @param {number} [editFundTxnItemId] 
         * @param {string} [txnHash] 
         * @param {Array<string>} [fundSeriesIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemMountingWithHedgefundTransactions(portfolioId: number, fundClassId?: number, basedate?: string, isClassSeriesChange?: string, hedgefundId?: number, currency?: string, editFundTxnItemId?: number, txnHash?: string, fundSeriesIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemMountingWithHedgefundTransactions(portfolioId, fundClassId, basedate, isClassSeriesChange, hedgefundId, currency, editFundTxnItemId, txnHash, fundSeriesIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<HedgefundRedeemDto>} hedgefundRedeemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemWithHedgefundTransactions(hedgefundRedeemDto: Array<HedgefundRedeemDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemWithHedgefundTransactions(hedgefundRedeemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTransactionClsItemWithHedgefundTransactions(txnItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTransactionClsItemWithHedgefundTransactions(txnItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTransactionItemWithHedgefundTransactions(txnItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTransactionItemWithHedgefundTransactions(txnItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTransactionItemsWithHedgefundTransactions(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTransactionItemsWithHedgefundTransactions(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithHedgefundTransactions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithHedgefundTransactions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seedOriginsWithHedgefundTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.seedOriginsWithHedgefundTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundTransactionDto} updateHedgefundTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithHedgefundTransactions(id: string, updateHedgefundTransactionDto: UpdateHedgefundTransactionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithHedgefundTransactions(id, updateHedgefundTransactionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HedgefundTransactionsApi - factory interface
 * @export
 */
export const HedgefundTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HedgefundTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} fundTxnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clsMountingWithHedgefundTransactions(fundTxnItemId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.clsMountingWithHedgefundTransactions(fundTxnItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateHoldingSettingDto} createHoldingSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoldingWithHedgefundTransactions(createHoldingSettingDto: CreateHoldingSettingDto, options?: any): AxiosPromise<object> {
            return localVarFp.createHoldingWithHedgefundTransactions(createHoldingSettingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateHedgefundTransactionDto} createHedgefundTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithHedgefundTransactions(createHedgefundTransactionDto: CreateHedgefundTransactionDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.createWithHedgefundTransactions(createHedgefundTransactionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithHedgefundTransactions(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithHedgefundTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPortfolioAndBasedateWithHedgefundTransactions(portfolioId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findByPortfolioAndBasedateWithHedgefundTransactions(portfolioId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundSeriesId 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInitTransactionWithHedgefundTransactions(fundSeriesId: number, portfolioId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findInitTransactionWithHedgefundTransactions(fundSeriesId, portfolioId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {string} basedate 
         * @param {number} [fundClassId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNavFinalWithHedgefundTransactions(portfolioId: number, basedate: string, fundClassId?: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findNavFinalWithHedgefundTransactions(portfolioId, basedate, fundClassId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithHedgefundTransactions(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithHedgefundTransactions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hedgeFundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccountInvestInHedgeFundWithHedgefundTransactions(hedgeFundId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getAllAccountInvestInHedgeFundWithHedgefundTransactions(hedgeFundId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClsFundTxnWithHedgefundTransactions(txnItemId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getClsFundTxnWithHedgefundTransactions(txnItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RedeemCalcDto} redeemCalcDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemCalcWithHedgefundTransactions(redeemCalcDto: RedeemCalcDto, options?: any): AxiosPromise<void> {
            return localVarFp.redeemCalcWithHedgefundTransactions(redeemCalcDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {number} [fundClassId] 
         * @param {string} [basedate] 
         * @param {string} [isClassSeriesChange] 
         * @param {number} [hedgefundId] 
         * @param {string} [currency] 
         * @param {number} [editFundTxnItemId] 
         * @param {string} [txnHash] 
         * @param {Array<string>} [fundSeriesIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemMountingWithHedgefundTransactions(portfolioId: number, fundClassId?: number, basedate?: string, isClassSeriesChange?: string, hedgefundId?: number, currency?: string, editFundTxnItemId?: number, txnHash?: string, fundSeriesIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.redeemMountingWithHedgefundTransactions(portfolioId, fundClassId, basedate, isClassSeriesChange, hedgefundId, currency, editFundTxnItemId, txnHash, fundSeriesIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<HedgefundRedeemDto>} hedgefundRedeemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemWithHedgefundTransactions(hedgefundRedeemDto: Array<HedgefundRedeemDto>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.redeemWithHedgefundTransactions(hedgefundRedeemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionClsItemWithHedgefundTransactions(txnItemId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.removeTransactionClsItemWithHedgefundTransactions(txnItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} txnItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionItemWithHedgefundTransactions(txnItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeTransactionItemWithHedgefundTransactions(txnItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTransactionItemsWithHedgefundTransactions(requestBody: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.removeTransactionItemsWithHedgefundTransactions(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithHedgefundTransactions(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithHedgefundTransactions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seedOriginsWithHedgefundTransactions(options?: any): AxiosPromise<void> {
            return localVarFp.seedOriginsWithHedgefundTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHedgefundTransactionDto} updateHedgefundTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithHedgefundTransactions(id: string, updateHedgefundTransactionDto: UpdateHedgefundTransactionDto, options?: any): AxiosPromise<object> {
            return localVarFp.updateWithHedgefundTransactions(id, updateHedgefundTransactionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HedgefundTransactionsApi - interface
 * @export
 * @interface HedgefundTransactionsApi
 */
export interface HedgefundTransactionsApiInterface {
    /**
     * 
     * @param {number} fundTxnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    clsMountingWithHedgefundTransactions(fundTxnItemId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {CreateHoldingSettingDto} createHoldingSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    createHoldingWithHedgefundTransactions(createHoldingSettingDto: CreateHoldingSettingDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {CreateHedgefundTransactionDto} createHedgefundTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    createWithHedgefundTransactions(createHedgefundTransactionDto: CreateHedgefundTransactionDto, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    findAllWithHedgefundTransactions(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    findByPortfolioAndBasedateWithHedgefundTransactions(portfolioId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    findInitTransactionWithHedgefundTransactions(fundSeriesId: number, portfolioId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {number} [fundClassId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    findNavFinalWithHedgefundTransactions(portfolioId: number, basedate: string, fundClassId?: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    findOneWithHedgefundTransactions(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} hedgeFundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    getAllAccountInvestInHedgeFundWithHedgefundTransactions(hedgeFundId: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    getClsFundTxnWithHedgefundTransactions(txnItemId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {RedeemCalcDto} redeemCalcDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    redeemCalcWithHedgefundTransactions(redeemCalcDto: RedeemCalcDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {number} [fundClassId] 
     * @param {string} [basedate] 
     * @param {string} [isClassSeriesChange] 
     * @param {number} [hedgefundId] 
     * @param {string} [currency] 
     * @param {number} [editFundTxnItemId] 
     * @param {string} [txnHash] 
     * @param {Array<string>} [fundSeriesIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    redeemMountingWithHedgefundTransactions(portfolioId: number, fundClassId?: number, basedate?: string, isClassSeriesChange?: string, hedgefundId?: number, currency?: string, editFundTxnItemId?: number, txnHash?: string, fundSeriesIds?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<HedgefundRedeemDto>} hedgefundRedeemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    redeemWithHedgefundTransactions(hedgefundRedeemDto: Array<HedgefundRedeemDto>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    removeTransactionClsItemWithHedgefundTransactions(txnItemId: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    removeTransactionItemWithHedgefundTransactions(txnItemId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    removeTransactionItemsWithHedgefundTransactions(requestBody: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<number>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    removeWithHedgefundTransactions(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    seedOriginsWithHedgefundTransactions(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundTransactionDto} updateHedgefundTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApiInterface
     */
    updateWithHedgefundTransactions(id: string, updateHedgefundTransactionDto: UpdateHedgefundTransactionDto, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * HedgefundTransactionsApi - object-oriented interface
 * @export
 * @class HedgefundTransactionsApi
 * @extends {BaseAPI}
 */
export class HedgefundTransactionsApi extends BaseAPI implements HedgefundTransactionsApiInterface {
    /**
     * 
     * @param {number} fundTxnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public clsMountingWithHedgefundTransactions(fundTxnItemId: number, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).clsMountingWithHedgefundTransactions(fundTxnItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateHoldingSettingDto} createHoldingSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public createHoldingWithHedgefundTransactions(createHoldingSettingDto: CreateHoldingSettingDto, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).createHoldingWithHedgefundTransactions(createHoldingSettingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateHedgefundTransactionDto} createHedgefundTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public createWithHedgefundTransactions(createHedgefundTransactionDto: CreateHedgefundTransactionDto, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).createWithHedgefundTransactions(createHedgefundTransactionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public findAllWithHedgefundTransactions(options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).findAllWithHedgefundTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public findByPortfolioAndBasedateWithHedgefundTransactions(portfolioId: number, basedate: string, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).findByPortfolioAndBasedateWithHedgefundTransactions(portfolioId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundSeriesId 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public findInitTransactionWithHedgefundTransactions(fundSeriesId: number, portfolioId: number, basedate: string, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).findInitTransactionWithHedgefundTransactions(fundSeriesId, portfolioId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {string} basedate 
     * @param {number} [fundClassId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public findNavFinalWithHedgefundTransactions(portfolioId: number, basedate: string, fundClassId?: number, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).findNavFinalWithHedgefundTransactions(portfolioId, basedate, fundClassId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public findOneWithHedgefundTransactions(id: string, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).findOneWithHedgefundTransactions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hedgeFundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public getAllAccountInvestInHedgeFundWithHedgefundTransactions(hedgeFundId: string, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).getAllAccountInvestInHedgeFundWithHedgefundTransactions(hedgeFundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public getClsFundTxnWithHedgefundTransactions(txnItemId: number, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).getClsFundTxnWithHedgefundTransactions(txnItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RedeemCalcDto} redeemCalcDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public redeemCalcWithHedgefundTransactions(redeemCalcDto: RedeemCalcDto, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).redeemCalcWithHedgefundTransactions(redeemCalcDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {number} [fundClassId] 
     * @param {string} [basedate] 
     * @param {string} [isClassSeriesChange] 
     * @param {number} [hedgefundId] 
     * @param {string} [currency] 
     * @param {number} [editFundTxnItemId] 
     * @param {string} [txnHash] 
     * @param {Array<string>} [fundSeriesIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public redeemMountingWithHedgefundTransactions(portfolioId: number, fundClassId?: number, basedate?: string, isClassSeriesChange?: string, hedgefundId?: number, currency?: string, editFundTxnItemId?: number, txnHash?: string, fundSeriesIds?: Array<string>, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).redeemMountingWithHedgefundTransactions(portfolioId, fundClassId, basedate, isClassSeriesChange, hedgefundId, currency, editFundTxnItemId, txnHash, fundSeriesIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<HedgefundRedeemDto>} hedgefundRedeemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public redeemWithHedgefundTransactions(hedgefundRedeemDto: Array<HedgefundRedeemDto>, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).redeemWithHedgefundTransactions(hedgefundRedeemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public removeTransactionClsItemWithHedgefundTransactions(txnItemId: string, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).removeTransactionClsItemWithHedgefundTransactions(txnItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} txnItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public removeTransactionItemWithHedgefundTransactions(txnItemId: string, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).removeTransactionItemWithHedgefundTransactions(txnItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public removeTransactionItemsWithHedgefundTransactions(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).removeTransactionItemsWithHedgefundTransactions(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public removeWithHedgefundTransactions(id: string, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).removeWithHedgefundTransactions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public seedOriginsWithHedgefundTransactions(options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).seedOriginsWithHedgefundTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateHedgefundTransactionDto} updateHedgefundTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HedgefundTransactionsApi
     */
    public updateWithHedgefundTransactions(id: string, updateHedgefundTransactionDto: UpdateHedgefundTransactionDto, options?: AxiosRequestConfig) {
        return HedgefundTransactionsApiFp(this.configuration).updateWithHedgefundTransactions(id, updateHedgefundTransactionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InputReturnApi - axios parameter creator
 * @export
 */
export const InputReturnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<UpdateInputReturnDto>} updateInputReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithInputReturn: async (updateInputReturnDto: Array<UpdateInputReturnDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateInputReturnDto' is not null or undefined
            assertParamExists('bulkSaveWithInputReturn', 'updateInputReturnDto', updateInputReturnDto)
            const localVarPath = `/api/input-return/bulk-save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInputReturnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByHedgefundWithInputReturn: async (basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('countByHedgefundWithInputReturn', 'basedate', basedate)
            const localVarPath = `/api/input-return/count-by-hedgefund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateInputReturnDto>} createInputReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithInputReturn: async (createInputReturnDto: Array<CreateInputReturnDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInputReturnDto' is not null or undefined
            assertParamExists('createWithInputReturn', 'createInputReturnDto', createInputReturnDto)
            const localVarPath = `/api/input-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInputReturnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByFundAndBasedateWithInputReturn: async (fundId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('deleteByFundAndBasedateWithInputReturn', 'fundId', fundId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('deleteByFundAndBasedateWithInputReturn', 'basedate', basedate)
            const localVarPath = `/api/input-return/delete-by-fund-bd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fundId !== undefined) {
                localVarQueryParameter['fundId'] = fundId;
            }

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReturnDuplicateDto} returnDuplicateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataWithInputReturn: async (returnDuplicateDto: ReturnDuplicateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'returnDuplicateDto' is not null or undefined
            assertParamExists('duplicateDataWithInputReturn', 'returnDuplicateDto', returnDuplicateDto)
            const localVarPath = `/api/input-return/data-duplication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnDuplicateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFundAndBasedateWithInputReturn: async (fundId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('findByFundAndBasedateWithInputReturn', 'fundId', fundId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findByFundAndBasedateWithInputReturn', 'basedate', basedate)
            const localVarPath = `/api/input-return/input-return-hf/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findHoldingsByFundAndBasedateWithInputReturn: async (fundId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('findHoldingsByFundAndBasedateWithInputReturn', 'fundId', fundId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findHoldingsByFundAndBasedateWithInputReturn', 'basedate', basedate)
            const localVarPath = `/api/input-return/input-return-holdings/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} duplicateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isExistingDataWithInputReturn: async (duplicateTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'duplicateTo' is not null or undefined
            assertParamExists('isExistingDataWithInputReturn', 'duplicateTo', duplicateTo)
            const localVarPath = `/api/input-return/is-exsiting-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (duplicateTo !== undefined) {
                localVarQueryParameter['duplicateTo'] = duplicateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithInputReturn: async (basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('listWithInputReturn', 'basedate', basedate)
            const localVarPath = `/api/input-return/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InputReturnApi - functional programming interface
 * @export
 */
export const InputReturnApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InputReturnApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateInputReturnDto>} updateInputReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSaveWithInputReturn(updateInputReturnDto: Array<UpdateInputReturnDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSaveWithInputReturn(updateInputReturnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countByHedgefundWithInputReturn(basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countByHedgefundWithInputReturn(basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateInputReturnDto>} createInputReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithInputReturn(createInputReturnDto: Array<CreateInputReturnDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithInputReturn(createInputReturnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByFundAndBasedateWithInputReturn(fundId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReturnDuplicateDto} returnDuplicateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateDataWithInputReturn(returnDuplicateDto: ReturnDuplicateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateDataWithInputReturn(returnDuplicateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByFundAndBasedateWithInputReturn(fundId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findHoldingsByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findHoldingsByFundAndBasedateWithInputReturn(fundId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} duplicateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isExistingDataWithInputReturn(duplicateTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isExistingDataWithInputReturn(duplicateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithInputReturn(basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithInputReturn(basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InputReturnApi - factory interface
 * @export
 */
export const InputReturnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InputReturnApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateInputReturnDto>} updateInputReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithInputReturn(updateInputReturnDto: Array<UpdateInputReturnDto>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.bulkSaveWithInputReturn(updateInputReturnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByHedgefundWithInputReturn(basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.countByHedgefundWithInputReturn(basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateInputReturnDto>} createInputReturnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithInputReturn(createInputReturnDto: Array<CreateInputReturnDto>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.createWithInputReturn(createInputReturnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteByFundAndBasedateWithInputReturn(fundId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReturnDuplicateDto} returnDuplicateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataWithInputReturn(returnDuplicateDto: ReturnDuplicateDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.duplicateDataWithInputReturn(returnDuplicateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: any): AxiosPromise<void> {
            return localVarFp.findByFundAndBasedateWithInputReturn(fundId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findHoldingsByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findHoldingsByFundAndBasedateWithInputReturn(fundId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} duplicateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isExistingDataWithInputReturn(duplicateTo: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isExistingDataWithInputReturn(duplicateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithInputReturn(basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.listWithInputReturn(basedate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InputReturnApi - interface
 * @export
 * @interface InputReturnApi
 */
export interface InputReturnApiInterface {
    /**
     * 
     * @param {Array<UpdateInputReturnDto>} updateInputReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    bulkSaveWithInputReturn(updateInputReturnDto: Array<UpdateInputReturnDto>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    countByHedgefundWithInputReturn(basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {Array<CreateInputReturnDto>} createInputReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    createWithInputReturn(createInputReturnDto: Array<CreateInputReturnDto>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    deleteByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {ReturnDuplicateDto} returnDuplicateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    duplicateDataWithInputReturn(returnDuplicateDto: ReturnDuplicateDto, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    findByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    findHoldingsByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} duplicateTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    isExistingDataWithInputReturn(duplicateTo: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApiInterface
     */
    listWithInputReturn(basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

}

/**
 * InputReturnApi - object-oriented interface
 * @export
 * @class InputReturnApi
 * @extends {BaseAPI}
 */
export class InputReturnApi extends BaseAPI implements InputReturnApiInterface {
    /**
     * 
     * @param {Array<UpdateInputReturnDto>} updateInputReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public bulkSaveWithInputReturn(updateInputReturnDto: Array<UpdateInputReturnDto>, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).bulkSaveWithInputReturn(updateInputReturnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public countByHedgefundWithInputReturn(basedate: string, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).countByHedgefundWithInputReturn(basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateInputReturnDto>} createInputReturnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public createWithInputReturn(createInputReturnDto: Array<CreateInputReturnDto>, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).createWithInputReturn(createInputReturnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public deleteByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).deleteByFundAndBasedateWithInputReturn(fundId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReturnDuplicateDto} returnDuplicateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public duplicateDataWithInputReturn(returnDuplicateDto: ReturnDuplicateDto, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).duplicateDataWithInputReturn(returnDuplicateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public findByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).findByFundAndBasedateWithInputReturn(fundId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public findHoldingsByFundAndBasedateWithInputReturn(fundId: number, basedate: string, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).findHoldingsByFundAndBasedateWithInputReturn(fundId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} duplicateTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public isExistingDataWithInputReturn(duplicateTo: string, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).isExistingDataWithInputReturn(duplicateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputReturnApi
     */
    public listWithInputReturn(basedate: string, options?: AxiosRequestConfig) {
        return InputReturnApiFp(this.configuration).listWithInputReturn(basedate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InterestRatesTickerApi - axios parameter creator
 * @export
 */
export const InterestRatesTickerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithInterestRateTicker: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ir-ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTickerWithInterestRateTicker: async (ticker: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('findByTickerWithInterestRateTicker', 'ticker', ticker)
            const localVarPath = `/api/ir-ticker/by-ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticker !== undefined) {
                localVarQueryParameter['ticker'] = ticker;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterestRatesTickerApi - functional programming interface
 * @export
 */
export const InterestRatesTickerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterestRatesTickerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithInterestRateTicker(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithInterestRateTicker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByTickerWithInterestRateTicker(ticker: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByTickerWithInterestRateTicker(ticker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InterestRatesTickerApi - factory interface
 * @export
 */
export const InterestRatesTickerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterestRatesTickerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithInterestRateTicker(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithInterestRateTicker(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTickerWithInterestRateTicker(ticker: string, options?: any): AxiosPromise<object> {
            return localVarFp.findByTickerWithInterestRateTicker(ticker, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterestRatesTickerApi - interface
 * @export
 * @interface InterestRatesTickerApi
 */
export interface InterestRatesTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestRatesTickerApiInterface
     */
    findAllWithInterestRateTicker(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} ticker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestRatesTickerApiInterface
     */
    findByTickerWithInterestRateTicker(ticker: string, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * InterestRatesTickerApi - object-oriented interface
 * @export
 * @class InterestRatesTickerApi
 * @extends {BaseAPI}
 */
export class InterestRatesTickerApi extends BaseAPI implements InterestRatesTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestRatesTickerApi
     */
    public findAllWithInterestRateTicker(options?: AxiosRequestConfig) {
        return InterestRatesTickerApiFp(this.configuration).findAllWithInterestRateTicker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestRatesTickerApi
     */
    public findByTickerWithInterestRateTicker(ticker: string, options?: AxiosRequestConfig) {
        return InterestRatesTickerApiFp(this.configuration).findByTickerWithInterestRateTicker(ticker, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestorsApi - axios parameter creator
 * @export
 */
export const InvestorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateInvestorDto} createInvestorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithInvestors: async (createInvestorDto: CreateInvestorDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvestorDto' is not null or undefined
            assertParamExists('createWithInvestors', 'createInvestorDto', createInvestorDto)
            const localVarPath = `/api/investors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvestorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithInvestors: async (withDivision?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/investors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withDivision !== undefined) {
                localVarQueryParameter['withDivision'] = withDivision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDivisionsByInvestorWithInvestors: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findDivisionsByInvestorWithInvestors', 'id', id)
            const localVarPath = `/api/investors/{id}/division`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithInvestors: async (id: number, withDivision?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithInvestors', 'id', id)
            const localVarPath = `/api/investors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withDivision !== undefined) {
                localVarQueryParameter['withDivision'] = withDivision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveInvestorWithInvestors: async (withDivision?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/investors/active-investors-with-division`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withDivision !== undefined) {
                localVarQueryParameter['withDivision'] = withDivision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithInvestors: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithInvestors', 'id', id)
            const localVarPath = `/api/investors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateInvestorDto} updateInvestorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithInvestors: async (id: number, updateInvestorDto: UpdateInvestorDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithInvestors', 'id', id)
            // verify required parameter 'updateInvestorDto' is not null or undefined
            assertParamExists('updateWithInvestors', 'updateInvestorDto', updateInvestorDto)
            const localVarPath = `/api/investors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvestorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestorsApi - functional programming interface
 * @export
 */
export const InvestorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateInvestorDto} createInvestorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithInvestors(createInvestorDto: CreateInvestorDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithInvestors(createInvestorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithInvestors(withDivision?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithInvestors(withDivision, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDivisionsByInvestorWithInvestors(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDivisionsByInvestorWithInvestors(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithInvestors(id: number, withDivision?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithInvestors(id, withDivision, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveInvestorWithInvestors(withDivision?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveInvestorWithInvestors(withDivision, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithInvestors(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithInvestors(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateInvestorDto} updateInvestorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithInvestors(id: number, updateInvestorDto: UpdateInvestorDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithInvestors(id, updateInvestorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestorsApi - factory interface
 * @export
 */
export const InvestorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestorsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateInvestorDto} createInvestorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithInvestors(createInvestorDto: CreateInvestorDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWithInvestors(createInvestorDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithInvestors(withDivision?: boolean, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithInvestors(withDivision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDivisionsByInvestorWithInvestors(id: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findDivisionsByInvestorWithInvestors(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithInvestors(id: number, withDivision?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithInvestors(id, withDivision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [withDivision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveInvestorWithInvestors(withDivision?: boolean, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.listActiveInvestorWithInvestors(withDivision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithInvestors(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithInvestors(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateInvestorDto} updateInvestorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithInvestors(id: number, updateInvestorDto: UpdateInvestorDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithInvestors(id, updateInvestorDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestorsApi - interface
 * @export
 * @interface InvestorsApi
 */
export interface InvestorsApiInterface {
    /**
     * 
     * @param {CreateInvestorDto} createInvestorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApiInterface
     */
    createWithInvestors(createInvestorDto: CreateInvestorDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {boolean} [withDivision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApiInterface
     */
    findAllWithInvestors(withDivision?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApiInterface
     */
    findDivisionsByInvestorWithInvestors(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} id 
     * @param {boolean} [withDivision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApiInterface
     */
    findOneWithInvestors(id: number, withDivision?: boolean, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {boolean} [withDivision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApiInterface
     */
    listActiveInvestorWithInvestors(withDivision?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApiInterface
     */
    removeWithInvestors(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {UpdateInvestorDto} updateInvestorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApiInterface
     */
    updateWithInvestors(id: number, updateInvestorDto: UpdateInvestorDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * InvestorsApi - object-oriented interface
 * @export
 * @class InvestorsApi
 * @extends {BaseAPI}
 */
export class InvestorsApi extends BaseAPI implements InvestorsApiInterface {
    /**
     * 
     * @param {CreateInvestorDto} createInvestorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public createWithInvestors(createInvestorDto: CreateInvestorDto, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).createWithInvestors(createInvestorDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [withDivision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public findAllWithInvestors(withDivision?: boolean, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).findAllWithInvestors(withDivision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public findDivisionsByInvestorWithInvestors(id: string, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).findDivisionsByInvestorWithInvestors(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [withDivision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public findOneWithInvestors(id: number, withDivision?: boolean, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).findOneWithInvestors(id, withDivision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [withDivision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public listActiveInvestorWithInvestors(withDivision?: boolean, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).listActiveInvestorWithInvestors(withDivision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public removeWithInvestors(id: string, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).removeWithInvestors(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateInvestorDto} updateInvestorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public updateWithInvestors(id: number, updateInvestorDto: UpdateInvestorDto, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).updateWithInvestors(id, updateInvestorDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LegacyStrategyApi - axios parameter creator
 * @export
 */
export const LegacyStrategyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStrategyDto} createStrategyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithStrategies: async (createStrategyDto: CreateStrategyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStrategyDto' is not null or undefined
            assertParamExists('createWithStrategies', 'createStrategyDto', createStrategyDto)
            const localVarPath = `/api/legacy/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStrategyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithStrategies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithStrategies: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithStrategies', 'id', id)
            const localVarPath = `/api/legacy/strategies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithStrategies: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithStrategies', 'id', id)
            const localVarPath = `/api/legacy/strategies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStrategyDto} updateStrategyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithStrategies: async (id: string, updateStrategyDto: UpdateStrategyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithStrategies', 'id', id)
            // verify required parameter 'updateStrategyDto' is not null or undefined
            assertParamExists('updateWithStrategies', 'updateStrategyDto', updateStrategyDto)
            const localVarPath = `/api/legacy/strategies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStrategyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegacyStrategyApi - functional programming interface
 * @export
 */
export const LegacyStrategyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegacyStrategyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateStrategyDto} createStrategyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithStrategies(createStrategyDto: CreateStrategyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithStrategies(createStrategyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithStrategies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithStrategies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithStrategies(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithStrategies(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithStrategies(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithStrategies(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStrategyDto} updateStrategyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithStrategies(id: string, updateStrategyDto: UpdateStrategyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithStrategies(id, updateStrategyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegacyStrategyApi - factory interface
 * @export
 */
export const LegacyStrategyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegacyStrategyApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateStrategyDto} createStrategyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithStrategies(createStrategyDto: CreateStrategyDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithStrategies(createStrategyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithStrategies(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithStrategies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithStrategies(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithStrategies(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithStrategies(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithStrategies(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStrategyDto} updateStrategyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithStrategies(id: string, updateStrategyDto: UpdateStrategyDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithStrategies(id, updateStrategyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LegacyStrategyApi - interface
 * @export
 * @interface LegacyStrategyApi
 */
export interface LegacyStrategyApiInterface {
    /**
     * 
     * @param {CreateStrategyDto} createStrategyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApiInterface
     */
    createWithStrategies(createStrategyDto: CreateStrategyDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApiInterface
     */
    findAllWithStrategies(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApiInterface
     */
    findOneWithStrategies(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApiInterface
     */
    removeWithStrategies(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateStrategyDto} updateStrategyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApiInterface
     */
    updateWithStrategies(id: string, updateStrategyDto: UpdateStrategyDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * LegacyStrategyApi - object-oriented interface
 * @export
 * @class LegacyStrategyApi
 * @extends {BaseAPI}
 */
export class LegacyStrategyApi extends BaseAPI implements LegacyStrategyApiInterface {
    /**
     * 
     * @param {CreateStrategyDto} createStrategyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApi
     */
    public createWithStrategies(createStrategyDto: CreateStrategyDto, options?: AxiosRequestConfig) {
        return LegacyStrategyApiFp(this.configuration).createWithStrategies(createStrategyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApi
     */
    public findAllWithStrategies(options?: AxiosRequestConfig) {
        return LegacyStrategyApiFp(this.configuration).findAllWithStrategies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApi
     */
    public findOneWithStrategies(id: string, options?: AxiosRequestConfig) {
        return LegacyStrategyApiFp(this.configuration).findOneWithStrategies(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApi
     */
    public removeWithStrategies(id: string, options?: AxiosRequestConfig) {
        return LegacyStrategyApiFp(this.configuration).removeWithStrategies(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateStrategyDto} updateStrategyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyStrategyApi
     */
    public updateWithStrategies(id: string, updateStrategyDto: UpdateStrategyDto, options?: AxiosRequestConfig) {
        return LegacyStrategyApiFp(this.configuration).updateWithStrategies(id, updateStrategyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketIndexTickerApi - axios parameter creator
 * @export
 */
export const MarketIndexTickerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithMarketIndexTicker: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/market-index-ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketIndexTickerApi - functional programming interface
 * @export
 */
export const MarketIndexTickerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketIndexTickerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithMarketIndexTicker(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithMarketIndexTicker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketIndexTickerApi - factory interface
 * @export
 */
export const MarketIndexTickerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketIndexTickerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithMarketIndexTicker(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithMarketIndexTicker(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketIndexTickerApi - interface
 * @export
 * @interface MarketIndexTickerApi
 */
export interface MarketIndexTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIndexTickerApiInterface
     */
    findAllWithMarketIndexTicker(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

}

/**
 * MarketIndexTickerApi - object-oriented interface
 * @export
 * @class MarketIndexTickerApi
 * @extends {BaseAPI}
 */
export class MarketIndexTickerApi extends BaseAPI implements MarketIndexTickerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIndexTickerApi
     */
    public findAllWithMarketIndexTicker(options?: AxiosRequestConfig) {
        return MarketIndexTickerApiFp(this.configuration).findAllWithMarketIndexTicker(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NavAccountTotalApi - axios parameter creator
 * @export
 */
export const NavAccountTotalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<UpdateNavAccountTotalDto>} updateNavAccountTotalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithNavAccountTotal: async (updateNavAccountTotalDto: Array<UpdateNavAccountTotalDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNavAccountTotalDto' is not null or undefined
            assertParamExists('bulkSaveWithNavAccountTotal', 'updateNavAccountTotalDto', updateNavAccountTotalDto)
            const localVarPath = `/api/nav-account-total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNavAccountTotalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckMountingWithNavAccountTotal: async (accountId: number, basedate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('doubleCheckMountingWithNavAccountTotal', 'accountId', accountId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('doubleCheckMountingWithNavAccountTotal', 'basedate', basedate)
            const localVarPath = `/api/nav-account-total/double-check-mouting/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {Array<UpsertDoubleCheckDto>} upsertDoubleCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckWithNavAccountTotal: async (fundId: number, basedate: string, upsertDoubleCheckDto: Array<UpsertDoubleCheckDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('doubleCheckWithNavAccountTotal', 'fundId', fundId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('doubleCheckWithNavAccountTotal', 'basedate', basedate)
            // verify required parameter 'upsertDoubleCheckDto' is not null or undefined
            assertParamExists('doubleCheckWithNavAccountTotal', 'upsertDoubleCheckDto', upsertDoubleCheckDto)
            const localVarPath = `/api/nav-account-total/double-check/{fundId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertDoubleCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountNavEstimateDuplicateDto} accountNavEstimateDuplicateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataWithNavAccountTotal: async (accountNavEstimateDuplicateDto: AccountNavEstimateDuplicateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountNavEstimateDuplicateDto' is not null or undefined
            assertParamExists('duplicateDataWithNavAccountTotal', 'accountNavEstimateDuplicateDto', accountNavEstimateDuplicateDto)
            const localVarPath = `/api/nav-account-total/data-duplication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountNavEstimateDuplicateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} duplicateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existingDataWithNavAccountTotal: async (duplicateTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'duplicateTo' is not null or undefined
            assertParamExists('existingDataWithNavAccountTotal', 'duplicateTo', duplicateTo)
            const localVarPath = `/api/nav-account-total/existing-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (duplicateTo !== undefined) {
                localVarQueryParameter['duplicateTo'] = duplicateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {boolean} [isFinal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByAccountAndBasedateWithNavAccountTotal: async (accountId: number, basedate: string, isFinal?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findByAccountAndBasedateWithNavAccountTotal', 'accountId', accountId)
            // verify required parameter 'basedate' is not null or undefined
            assertParamExists('findByAccountAndBasedateWithNavAccountTotal', 'basedate', basedate)
            const localVarPath = `/api/nav-account-total/find-by-account/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (basedate !== undefined) {
                localVarQueryParameter['basedate'] = basedate;
            }

            if (isFinal !== undefined) {
                localVarQueryParameter['isFinal'] = isFinal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NavAccountTotalApi - functional programming interface
 * @export
 */
export const NavAccountTotalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NavAccountTotalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateNavAccountTotalDto>} updateNavAccountTotalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSaveWithNavAccountTotal(updateNavAccountTotalDto: Array<UpdateNavAccountTotalDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UpdateNavAccountTotalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSaveWithNavAccountTotal(updateNavAccountTotalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doubleCheckMountingWithNavAccountTotal(accountId: number, basedate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doubleCheckMountingWithNavAccountTotal(accountId, basedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {Array<UpsertDoubleCheckDto>} upsertDoubleCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doubleCheckWithNavAccountTotal(fundId: number, basedate: string, upsertDoubleCheckDto: Array<UpsertDoubleCheckDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doubleCheckWithNavAccountTotal(fundId, basedate, upsertDoubleCheckDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccountNavEstimateDuplicateDto} accountNavEstimateDuplicateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateDataWithNavAccountTotal(accountNavEstimateDuplicateDto: AccountNavEstimateDuplicateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UpdateNavAccountTotalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateDataWithNavAccountTotal(accountNavEstimateDuplicateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} duplicateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existingDataWithNavAccountTotal(duplicateTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existingDataWithNavAccountTotal(duplicateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {boolean} [isFinal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByAccountAndBasedateWithNavAccountTotal(accountId: number, basedate: string, isFinal?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByAccountAndBasedateWithNavAccountTotal(accountId, basedate, isFinal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NavAccountTotalApi - factory interface
 * @export
 */
export const NavAccountTotalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NavAccountTotalApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<UpdateNavAccountTotalDto>} updateNavAccountTotalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSaveWithNavAccountTotal(updateNavAccountTotalDto: Array<UpdateNavAccountTotalDto>, options?: any): AxiosPromise<Array<UpdateNavAccountTotalDto>> {
            return localVarFp.bulkSaveWithNavAccountTotal(updateNavAccountTotalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckMountingWithNavAccountTotal(accountId: number, basedate: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.doubleCheckMountingWithNavAccountTotal(accountId, basedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fundId 
         * @param {string} basedate 
         * @param {Array<UpsertDoubleCheckDto>} upsertDoubleCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleCheckWithNavAccountTotal(fundId: number, basedate: string, upsertDoubleCheckDto: Array<UpsertDoubleCheckDto>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.doubleCheckWithNavAccountTotal(fundId, basedate, upsertDoubleCheckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountNavEstimateDuplicateDto} accountNavEstimateDuplicateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataWithNavAccountTotal(accountNavEstimateDuplicateDto: AccountNavEstimateDuplicateDto, options?: any): AxiosPromise<Array<UpdateNavAccountTotalDto>> {
            return localVarFp.duplicateDataWithNavAccountTotal(accountNavEstimateDuplicateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} duplicateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existingDataWithNavAccountTotal(duplicateTo: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.existingDataWithNavAccountTotal(duplicateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {string} basedate 
         * @param {boolean} [isFinal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByAccountAndBasedateWithNavAccountTotal(accountId: number, basedate: string, isFinal?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.findByAccountAndBasedateWithNavAccountTotal(accountId, basedate, isFinal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NavAccountTotalApi - interface
 * @export
 * @interface NavAccountTotalApi
 */
export interface NavAccountTotalApiInterface {
    /**
     * 
     * @param {Array<UpdateNavAccountTotalDto>} updateNavAccountTotalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApiInterface
     */
    bulkSaveWithNavAccountTotal(updateNavAccountTotalDto: Array<UpdateNavAccountTotalDto>, options?: AxiosRequestConfig): AxiosPromise<Array<UpdateNavAccountTotalDto>>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApiInterface
     */
    doubleCheckMountingWithNavAccountTotal(accountId: number, basedate: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {Array<UpsertDoubleCheckDto>} upsertDoubleCheckDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApiInterface
     */
    doubleCheckWithNavAccountTotal(fundId: number, basedate: string, upsertDoubleCheckDto: Array<UpsertDoubleCheckDto>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {AccountNavEstimateDuplicateDto} accountNavEstimateDuplicateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApiInterface
     */
    duplicateDataWithNavAccountTotal(accountNavEstimateDuplicateDto: AccountNavEstimateDuplicateDto, options?: AxiosRequestConfig): AxiosPromise<Array<UpdateNavAccountTotalDto>>;

    /**
     * 
     * @param {string} duplicateTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApiInterface
     */
    existingDataWithNavAccountTotal(duplicateTo: string, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {boolean} [isFinal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApiInterface
     */
    findByAccountAndBasedateWithNavAccountTotal(accountId: number, basedate: string, isFinal?: boolean, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * NavAccountTotalApi - object-oriented interface
 * @export
 * @class NavAccountTotalApi
 * @extends {BaseAPI}
 */
export class NavAccountTotalApi extends BaseAPI implements NavAccountTotalApiInterface {
    /**
     * 
     * @param {Array<UpdateNavAccountTotalDto>} updateNavAccountTotalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApi
     */
    public bulkSaveWithNavAccountTotal(updateNavAccountTotalDto: Array<UpdateNavAccountTotalDto>, options?: AxiosRequestConfig) {
        return NavAccountTotalApiFp(this.configuration).bulkSaveWithNavAccountTotal(updateNavAccountTotalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApi
     */
    public doubleCheckMountingWithNavAccountTotal(accountId: number, basedate: string, options?: AxiosRequestConfig) {
        return NavAccountTotalApiFp(this.configuration).doubleCheckMountingWithNavAccountTotal(accountId, basedate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fundId 
     * @param {string} basedate 
     * @param {Array<UpsertDoubleCheckDto>} upsertDoubleCheckDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApi
     */
    public doubleCheckWithNavAccountTotal(fundId: number, basedate: string, upsertDoubleCheckDto: Array<UpsertDoubleCheckDto>, options?: AxiosRequestConfig) {
        return NavAccountTotalApiFp(this.configuration).doubleCheckWithNavAccountTotal(fundId, basedate, upsertDoubleCheckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountNavEstimateDuplicateDto} accountNavEstimateDuplicateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApi
     */
    public duplicateDataWithNavAccountTotal(accountNavEstimateDuplicateDto: AccountNavEstimateDuplicateDto, options?: AxiosRequestConfig) {
        return NavAccountTotalApiFp(this.configuration).duplicateDataWithNavAccountTotal(accountNavEstimateDuplicateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} duplicateTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApi
     */
    public existingDataWithNavAccountTotal(duplicateTo: string, options?: AxiosRequestConfig) {
        return NavAccountTotalApiFp(this.configuration).existingDataWithNavAccountTotal(duplicateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {string} basedate 
     * @param {boolean} [isFinal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavAccountTotalApi
     */
    public findByAccountAndBasedateWithNavAccountTotal(accountId: number, basedate: string, isFinal?: boolean, options?: AxiosRequestConfig) {
        return NavAccountTotalApiFp(this.configuration).findByAccountAndBasedateWithNavAccountTotal(accountId, basedate, isFinal, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrganizationDto} createOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithOrganizations: async (createOrganizationDto: CreateOrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationDto' is not null or undefined
            assertParamExists('createWithOrganizations', 'createOrganizationDto', createOrganizationDto)
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [isHFManagementFirm] 
         * @param {boolean} [isInvAdvisorManager] 
         * @param {boolean} [isBroker] 
         * @param {boolean} [isAdministrator] 
         * @param {boolean} [isAuditor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithOrganizations: async (name?: string, isHFManagementFirm?: boolean, isInvAdvisorManager?: boolean, isBroker?: boolean, isAdministrator?: boolean, isAuditor?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isHFManagementFirm !== undefined) {
                localVarQueryParameter['isHFManagementFirm'] = isHFManagementFirm;
            }

            if (isInvAdvisorManager !== undefined) {
                localVarQueryParameter['isInvAdvisorManager'] = isInvAdvisorManager;
            }

            if (isBroker !== undefined) {
                localVarQueryParameter['isBroker'] = isBroker;
            }

            if (isAdministrator !== undefined) {
                localVarQueryParameter['isAdministrator'] = isAdministrator;
            }

            if (isAuditor !== undefined) {
                localVarQueryParameter['isAuditor'] = isAuditor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithOrganizations: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithOrganizations', 'id', id)
            const localVarPath = `/api/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithOrganizations: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithOrganizations', 'id', id)
            const localVarPath = `/api/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithOrganizations: async (id: number, updateOrganizationDto: UpdateOrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithOrganizations', 'id', id)
            // verify required parameter 'updateOrganizationDto' is not null or undefined
            assertParamExists('updateWithOrganizations', 'updateOrganizationDto', updateOrganizationDto)
            const localVarPath = `/api/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrganizationDto} createOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithOrganizations(createOrganizationDto: CreateOrganizationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithOrganizations(createOrganizationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [isHFManagementFirm] 
         * @param {boolean} [isInvAdvisorManager] 
         * @param {boolean} [isBroker] 
         * @param {boolean} [isAdministrator] 
         * @param {boolean} [isAuditor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithOrganizations(name?: string, isHFManagementFirm?: boolean, isInvAdvisorManager?: boolean, isBroker?: boolean, isAdministrator?: boolean, isAuditor?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithOrganizations(name, isHFManagementFirm, isInvAdvisorManager, isBroker, isAdministrator, isAuditor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithOrganizations(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithOrganizations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithOrganizations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithOrganizations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithOrganizations(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithOrganizations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithOrganizations(id: number, updateOrganizationDto: UpdateOrganizationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithOrganizations(id, updateOrganizationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrganizationDto} createOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithOrganizations(createOrganizationDto: CreateOrganizationDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithOrganizations(createOrganizationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [isHFManagementFirm] 
         * @param {boolean} [isInvAdvisorManager] 
         * @param {boolean} [isBroker] 
         * @param {boolean} [isAdministrator] 
         * @param {boolean} [isAuditor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithOrganizations(name?: string, isHFManagementFirm?: boolean, isInvAdvisorManager?: boolean, isBroker?: boolean, isAdministrator?: boolean, isAuditor?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithOrganizations(name, isHFManagementFirm, isInvAdvisorManager, isBroker, isAdministrator, isAuditor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithOrganizations(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithOrganizations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithOrganizations(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.listWithOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithOrganizations(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithOrganizations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithOrganizations(id: number, updateOrganizationDto: UpdateOrganizationDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithOrganizations(id, updateOrganizationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - interface
 * @export
 * @interface OrganizationsApi
 */
export interface OrganizationsApiInterface {
    /**
     * 
     * @param {CreateOrganizationDto} createOrganizationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    createWithOrganizations(createOrganizationDto: CreateOrganizationDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [name] 
     * @param {boolean} [isHFManagementFirm] 
     * @param {boolean} [isInvAdvisorManager] 
     * @param {boolean} [isBroker] 
     * @param {boolean} [isAdministrator] 
     * @param {boolean} [isAuditor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    findAllWithOrganizations(name?: string, isHFManagementFirm?: boolean, isInvAdvisorManager?: boolean, isBroker?: boolean, isAdministrator?: boolean, isAuditor?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    findOneWithOrganizations(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    listWithOrganizations(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    removeWithOrganizations(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {UpdateOrganizationDto} updateOrganizationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    updateWithOrganizations(id: number, updateOrganizationDto: UpdateOrganizationDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI implements OrganizationsApiInterface {
    /**
     * 
     * @param {CreateOrganizationDto} createOrganizationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createWithOrganizations(createOrganizationDto: CreateOrganizationDto, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).createWithOrganizations(createOrganizationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {boolean} [isHFManagementFirm] 
     * @param {boolean} [isInvAdvisorManager] 
     * @param {boolean} [isBroker] 
     * @param {boolean} [isAdministrator] 
     * @param {boolean} [isAuditor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public findAllWithOrganizations(name?: string, isHFManagementFirm?: boolean, isInvAdvisorManager?: boolean, isBroker?: boolean, isAdministrator?: boolean, isAuditor?: boolean, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).findAllWithOrganizations(name, isHFManagementFirm, isInvAdvisorManager, isBroker, isAdministrator, isAuditor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public findOneWithOrganizations(id: number, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).findOneWithOrganizations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listWithOrganizations(options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).listWithOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public removeWithOrganizations(id: number, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).removeWithOrganizations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateOrganizationDto} updateOrganizationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateWithOrganizations(id: number, updateOrganizationDto: UpdateOrganizationDto, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateWithOrganizations(id, updateOrganizationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionGroupApi - axios parameter creator
 * @export
 */
export const PermissionGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePermissionGroupDto} createPermissionGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithPermissionGroup: async (createPermissionGroupDto: CreatePermissionGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPermissionGroupDto' is not null or undefined
            assertParamExists('createWithPermissionGroup', 'createPermissionGroupDto', createPermissionGroupDto)
            const localVarPath = `/api/permission-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPermissionGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithPermissionGroup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/permission-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithPermissionGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithPermissionGroup', 'id', id)
            const localVarPath = `/api/permission-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithPermissionGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithPermissionGroup', 'id', id)
            const localVarPath = `/api/permission-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePermissionGroupDto} updatePermissionGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithPermissionGroup: async (id: string, updatePermissionGroupDto: UpdatePermissionGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithPermissionGroup', 'id', id)
            // verify required parameter 'updatePermissionGroupDto' is not null or undefined
            assertParamExists('updateWithPermissionGroup', 'updatePermissionGroupDto', updatePermissionGroupDto)
            const localVarPath = `/api/permission-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePermissionGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionGroupApi - functional programming interface
 * @export
 */
export const PermissionGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePermissionGroupDto} createPermissionGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithPermissionGroup(createPermissionGroupDto: CreatePermissionGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithPermissionGroup(createPermissionGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithPermissionGroup(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithPermissionGroup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithPermissionGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithPermissionGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithPermissionGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithPermissionGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePermissionGroupDto} updatePermissionGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithPermissionGroup(id: string, updatePermissionGroupDto: UpdatePermissionGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithPermissionGroup(id, updatePermissionGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionGroupApi - factory interface
 * @export
 */
export const PermissionGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePermissionGroupDto} createPermissionGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithPermissionGroup(createPermissionGroupDto: CreatePermissionGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithPermissionGroup(createPermissionGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithPermissionGroup(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithPermissionGroup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithPermissionGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithPermissionGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithPermissionGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithPermissionGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePermissionGroupDto} updatePermissionGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithPermissionGroup(id: string, updatePermissionGroupDto: UpdatePermissionGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithPermissionGroup(id, updatePermissionGroupDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionGroupApi - interface
 * @export
 * @interface PermissionGroupApi
 */
export interface PermissionGroupApiInterface {
    /**
     * 
     * @param {CreatePermissionGroupDto} createPermissionGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApiInterface
     */
    createWithPermissionGroup(createPermissionGroupDto: CreatePermissionGroupDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApiInterface
     */
    findAllWithPermissionGroup(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApiInterface
     */
    findOneWithPermissionGroup(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApiInterface
     */
    removeWithPermissionGroup(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdatePermissionGroupDto} updatePermissionGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApiInterface
     */
    updateWithPermissionGroup(id: string, updatePermissionGroupDto: UpdatePermissionGroupDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * PermissionGroupApi - object-oriented interface
 * @export
 * @class PermissionGroupApi
 * @extends {BaseAPI}
 */
export class PermissionGroupApi extends BaseAPI implements PermissionGroupApiInterface {
    /**
     * 
     * @param {CreatePermissionGroupDto} createPermissionGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApi
     */
    public createWithPermissionGroup(createPermissionGroupDto: CreatePermissionGroupDto, options?: AxiosRequestConfig) {
        return PermissionGroupApiFp(this.configuration).createWithPermissionGroup(createPermissionGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApi
     */
    public findAllWithPermissionGroup(options?: AxiosRequestConfig) {
        return PermissionGroupApiFp(this.configuration).findAllWithPermissionGroup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApi
     */
    public findOneWithPermissionGroup(id: string, options?: AxiosRequestConfig) {
        return PermissionGroupApiFp(this.configuration).findOneWithPermissionGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApi
     */
    public removeWithPermissionGroup(id: string, options?: AxiosRequestConfig) {
        return PermissionGroupApiFp(this.configuration).removeWithPermissionGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdatePermissionGroupDto} updatePermissionGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionGroupApi
     */
    public updateWithPermissionGroup(id: string, updatePermissionGroupDto: UpdatePermissionGroupDto, options?: AxiosRequestConfig) {
        return PermissionGroupApiFp(this.configuration).updateWithPermissionGroup(id, updatePermissionGroupDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PolicyApi - axios parameter creator
 * @export
 */
export const PolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePolicyDto} createPolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithPolicy: async (createPolicyDto: CreatePolicyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPolicyDto' is not null or undefined
            assertParamExists('createWithPolicy', 'createPolicyDto', createPolicyDto)
            const localVarPath = `/api/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPolicyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithPolicy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithPolicy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithPolicy', 'id', id)
            const localVarPath = `/api/policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithPolicy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithPolicy', 'id', id)
            const localVarPath = `/api/policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePolicyDto} updatePolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithPolicy: async (id: string, updatePolicyDto: UpdatePolicyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithPolicy', 'id', id)
            // verify required parameter 'updatePolicyDto' is not null or undefined
            assertParamExists('updateWithPolicy', 'updatePolicyDto', updatePolicyDto)
            const localVarPath = `/api/policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePolicyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolicyApi - functional programming interface
 * @export
 */
export const PolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePolicyDto} createPolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithPolicy(createPolicyDto: CreatePolicyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithPolicy(createPolicyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithPolicy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithPolicy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithPolicy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithPolicy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithPolicy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithPolicy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePolicyDto} updatePolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithPolicy(id: string, updatePolicyDto: UpdatePolicyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithPolicy(id, updatePolicyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PolicyApi - factory interface
 * @export
 */
export const PolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolicyApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePolicyDto} createPolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithPolicy(createPolicyDto: CreatePolicyDto, options?: any): AxiosPromise<string> {
            return localVarFp.createWithPolicy(createPolicyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithPolicy(options?: any): AxiosPromise<string> {
            return localVarFp.findAllWithPolicy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithPolicy(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.findOneWithPolicy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithPolicy(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeWithPolicy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePolicyDto} updatePolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithPolicy(id: string, updatePolicyDto: UpdatePolicyDto, options?: any): AxiosPromise<string> {
            return localVarFp.updateWithPolicy(id, updatePolicyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PolicyApi - interface
 * @export
 * @interface PolicyApi
 */
export interface PolicyApiInterface {
    /**
     * 
     * @param {CreatePolicyDto} createPolicyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    createWithPolicy(createPolicyDto: CreatePolicyDto, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    findAllWithPolicy(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    findOneWithPolicy(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    removeWithPolicy(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {UpdatePolicyDto} updatePolicyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    updateWithPolicy(id: string, updatePolicyDto: UpdatePolicyDto, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * PolicyApi - object-oriented interface
 * @export
 * @class PolicyApi
 * @extends {BaseAPI}
 */
export class PolicyApi extends BaseAPI implements PolicyApiInterface {
    /**
     * 
     * @param {CreatePolicyDto} createPolicyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public createWithPolicy(createPolicyDto: CreatePolicyDto, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).createWithPolicy(createPolicyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public findAllWithPolicy(options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).findAllWithPolicy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public findOneWithPolicy(id: string, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).findOneWithPolicy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public removeWithPolicy(id: string, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).removeWithPolicy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdatePolicyDto} updatePolicyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public updateWithPolicy(id: string, updatePolicyDto: UpdatePolicyDto, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).updateWithPolicy(id, updatePolicyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortfoliosApi - axios parameter creator
 * @export
 */
export const PortfoliosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHoldingDto} createHoldingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoldingWithPortfolios: async (createHoldingDto: CreateHoldingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHoldingDto' is not null or undefined
            assertParamExists('createHoldingWithPortfolios', 'createHoldingDto', createHoldingDto)
            const localVarPath = `/api/portfolios/create-holdings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHoldingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePortfolioDto} createPortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithPortfolios: async (createPortfolioDto: CreatePortfolioDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPortfolioDto' is not null or undefined
            assertParamExists('createWithPortfolios', 'createPortfolioDto', createPortfolioDto)
            const localVarPath = `/api/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPortfolioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAccountIdWithPortfolios: async (accountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findAllByAccountIdWithPortfolios', 'accountId', accountId)
            const localVarPath = `/api/portfolios/{accountId}/account`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllForPortfolioCompositionWithPortfolios: async (portfolioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('findAllForPortfolioCompositionWithPortfolios', 'portfolioId', portfolioId)
            const localVarPath = `/api/portfolios/portfolio-composition-list/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllNameByAccountWithPortfolios: async (accountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findAllNameByAccountWithPortfolios', 'accountId', accountId)
            const localVarPath = `/api/portfolios/name/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithPortfolios: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithPortfolios: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithPortfolios', 'id', id)
            const localVarPath = `/api/portfolios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWithHoldingHoldingItemWithPortfolios: async (ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('findWithHoldingHoldingItemWithPortfolios', 'ids', ids)
            const localVarPath = `/api/portfolios/port/holding/holding-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithPortfolios: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithPortfolios', 'id', id)
            const localVarPath = `/api/portfolios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} holdingId 
         * @param {UpdateHoldingDto} updateHoldingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoldingWithPortfolios: async (holdingId: number, updateHoldingDto: UpdateHoldingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'holdingId' is not null or undefined
            assertParamExists('updateHoldingWithPortfolios', 'holdingId', holdingId)
            // verify required parameter 'updateHoldingDto' is not null or undefined
            assertParamExists('updateHoldingWithPortfolios', 'updateHoldingDto', updateHoldingDto)
            const localVarPath = `/api/portfolios/update-holdings/{holdingId}`
                .replace(`{${"holdingId"}}`, encodeURIComponent(String(holdingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHoldingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePortfolioDto} updatePortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithPortfolios: async (id: number, updatePortfolioDto: UpdatePortfolioDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithPortfolios', 'id', id)
            // verify required parameter 'updatePortfolioDto' is not null or undefined
            assertParamExists('updateWithPortfolios', 'updatePortfolioDto', updatePortfolioDto)
            const localVarPath = `/api/portfolios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePortfolioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfoliosApi - functional programming interface
 * @export
 */
export const PortfoliosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfoliosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHoldingDto} createHoldingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoldingWithPortfolios(createHoldingDto: CreateHoldingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoldingWithPortfolios(createHoldingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePortfolioDto} createPortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithPortfolios(createPortfolioDto: CreatePortfolioDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithPortfolios(createPortfolioDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByAccountIdWithPortfolios(accountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByAccountIdWithPortfolios(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllForPortfolioCompositionWithPortfolios(portfolioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllForPortfolioCompositionWithPortfolios(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllNameByAccountWithPortfolios(accountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllNameByAccountWithPortfolios(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithPortfolios(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithPortfolios(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithPortfolios(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithPortfolios(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findWithHoldingHoldingItemWithPortfolios(ids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findWithHoldingHoldingItemWithPortfolios(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithPortfolios(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithPortfolios(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} holdingId 
         * @param {UpdateHoldingDto} updateHoldingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHoldingWithPortfolios(holdingId: number, updateHoldingDto: UpdateHoldingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHoldingWithPortfolios(holdingId, updateHoldingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePortfolioDto} updatePortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithPortfolios(id: number, updatePortfolioDto: UpdatePortfolioDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithPortfolios(id, updatePortfolioDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfoliosApi - factory interface
 * @export
 */
export const PortfoliosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfoliosApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHoldingDto} createHoldingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoldingWithPortfolios(createHoldingDto: CreateHoldingDto, options?: any): AxiosPromise<object> {
            return localVarFp.createHoldingWithPortfolios(createHoldingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePortfolioDto} createPortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithPortfolios(createPortfolioDto: CreatePortfolioDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithPortfolios(createPortfolioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAccountIdWithPortfolios(accountId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllByAccountIdWithPortfolios(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllForPortfolioCompositionWithPortfolios(portfolioId: number, options?: any): AxiosPromise<object> {
            return localVarFp.findAllForPortfolioCompositionWithPortfolios(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllNameByAccountWithPortfolios(accountId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllNameByAccountWithPortfolios(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithPortfolios(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithPortfolios(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithPortfolios(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithPortfolios(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWithHoldingHoldingItemWithPortfolios(ids: Array<number>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findWithHoldingHoldingItemWithPortfolios(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithPortfolios(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithPortfolios(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} holdingId 
         * @param {UpdateHoldingDto} updateHoldingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoldingWithPortfolios(holdingId: number, updateHoldingDto: UpdateHoldingDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateHoldingWithPortfolios(holdingId, updateHoldingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePortfolioDto} updatePortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithPortfolios(id: number, updatePortfolioDto: UpdatePortfolioDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithPortfolios(id, updatePortfolioDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfoliosApi - interface
 * @export
 * @interface PortfoliosApi
 */
export interface PortfoliosApiInterface {
    /**
     * 
     * @param {CreateHoldingDto} createHoldingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    createHoldingWithPortfolios(createHoldingDto: CreateHoldingDto, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {CreatePortfolioDto} createPortfolioDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    createWithPortfolios(createPortfolioDto: CreatePortfolioDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    findAllByAccountIdWithPortfolios(accountId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {number} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    findAllForPortfolioCompositionWithPortfolios(portfolioId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    findAllNameByAccountWithPortfolios(accountId: number, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    findAllWithPortfolios(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    findOneWithPortfolios(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<number>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    findWithHoldingHoldingItemWithPortfolios(ids: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    removeWithPortfolios(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} holdingId 
     * @param {UpdateHoldingDto} updateHoldingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    updateHoldingWithPortfolios(holdingId: number, updateHoldingDto: UpdateHoldingDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {UpdatePortfolioDto} updatePortfolioDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApiInterface
     */
    updateWithPortfolios(id: number, updatePortfolioDto: UpdatePortfolioDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * PortfoliosApi - object-oriented interface
 * @export
 * @class PortfoliosApi
 * @extends {BaseAPI}
 */
export class PortfoliosApi extends BaseAPI implements PortfoliosApiInterface {
    /**
     * 
     * @param {CreateHoldingDto} createHoldingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public createHoldingWithPortfolios(createHoldingDto: CreateHoldingDto, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).createHoldingWithPortfolios(createHoldingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePortfolioDto} createPortfolioDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public createWithPortfolios(createPortfolioDto: CreatePortfolioDto, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).createWithPortfolios(createPortfolioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public findAllByAccountIdWithPortfolios(accountId: number, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).findAllByAccountIdWithPortfolios(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public findAllForPortfolioCompositionWithPortfolios(portfolioId: number, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).findAllForPortfolioCompositionWithPortfolios(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public findAllNameByAccountWithPortfolios(accountId: number, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).findAllNameByAccountWithPortfolios(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public findAllWithPortfolios(options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).findAllWithPortfolios(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public findOneWithPortfolios(id: number, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).findOneWithPortfolios(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public findWithHoldingHoldingItemWithPortfolios(ids: Array<number>, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).findWithHoldingHoldingItemWithPortfolios(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public removeWithPortfolios(id: string, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).removeWithPortfolios(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} holdingId 
     * @param {UpdateHoldingDto} updateHoldingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public updateHoldingWithPortfolios(holdingId: number, updateHoldingDto: UpdateHoldingDto, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).updateHoldingWithPortfolios(holdingId, updateHoldingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdatePortfolioDto} updatePortfolioDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfoliosApi
     */
    public updateWithPortfolios(id: number, updatePortfolioDto: UpdatePortfolioDto, options?: AxiosRequestConfig) {
        return PortfoliosApiFp(this.configuration).updateWithPortfolios(id, updatePortfolioDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RollupHistoryApi - axios parameter creator
 * @export
 */
export const RollupHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRollupHistoryDto} createRollupHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithRollupHistory: async (createRollupHistoryDto: CreateRollupHistoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRollupHistoryDto' is not null or undefined
            assertParamExists('createWithRollupHistory', 'createRollupHistoryDto', createRollupHistoryDto)
            const localVarPath = `/api/rollup-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRollupHistoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithRollupHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/rollup-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithRollupHistory: async (seriesId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('findOneWithRollupHistory', 'seriesId', seriesId)
            const localVarPath = `/api/rollup-history/{seriesId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSeriesFromRollupWithRollupHistory: async (id: number, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeSeriesFromRollupWithRollupHistory', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('removeSeriesFromRollupWithRollupHistory', 'requestBody', requestBody)
            const localVarPath = `/api/rollup-history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRollupHistoryDto} updateRollupHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithRollupHistory: async (id: string, updateRollupHistoryDto: UpdateRollupHistoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithRollupHistory', 'id', id)
            // verify required parameter 'updateRollupHistoryDto' is not null or undefined
            assertParamExists('updateWithRollupHistory', 'updateRollupHistoryDto', updateRollupHistoryDto)
            const localVarPath = `/api/rollup-history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRollupHistoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RollupHistoryApi - functional programming interface
 * @export
 */
export const RollupHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RollupHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRollupHistoryDto} createRollupHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithRollupHistory(createRollupHistoryDto: CreateRollupHistoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateRollupHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithRollupHistory(createRollupHistoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithRollupHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithRollupHistory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithRollupHistory(seriesId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithRollupHistory(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSeriesFromRollupWithRollupHistory(id: number, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSeriesFromRollupWithRollupHistory(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRollupHistoryDto} updateRollupHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithRollupHistory(id: string, updateRollupHistoryDto: UpdateRollupHistoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithRollupHistory(id, updateRollupHistoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RollupHistoryApi - factory interface
 * @export
 */
export const RollupHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RollupHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRollupHistoryDto} createRollupHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithRollupHistory(createRollupHistoryDto: CreateRollupHistoryDto, options?: any): AxiosPromise<ResponseCreateRollupHistoryDto> {
            return localVarFp.createWithRollupHistory(createRollupHistoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithRollupHistory(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllWithRollupHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithRollupHistory(seriesId: string, options?: any): AxiosPromise<object> {
            return localVarFp.findOneWithRollupHistory(seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSeriesFromRollupWithRollupHistory(id: number, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.removeSeriesFromRollupWithRollupHistory(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRollupHistoryDto} updateRollupHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithRollupHistory(id: string, updateRollupHistoryDto: UpdateRollupHistoryDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithRollupHistory(id, updateRollupHistoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RollupHistoryApi - interface
 * @export
 * @interface RollupHistoryApi
 */
export interface RollupHistoryApiInterface {
    /**
     * 
     * @param {CreateRollupHistoryDto} createRollupHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApiInterface
     */
    createWithRollupHistory(createRollupHistoryDto: CreateRollupHistoryDto, options?: AxiosRequestConfig): AxiosPromise<ResponseCreateRollupHistoryDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApiInterface
     */
    findAllWithRollupHistory(options?: AxiosRequestConfig): AxiosPromise<Array<object>>;

    /**
     * 
     * @param {string} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApiInterface
     */
    findOneWithRollupHistory(seriesId: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApiInterface
     */
    removeSeriesFromRollupWithRollupHistory(id: number, requestBody: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateRollupHistoryDto} updateRollupHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApiInterface
     */
    updateWithRollupHistory(id: string, updateRollupHistoryDto: UpdateRollupHistoryDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * RollupHistoryApi - object-oriented interface
 * @export
 * @class RollupHistoryApi
 * @extends {BaseAPI}
 */
export class RollupHistoryApi extends BaseAPI implements RollupHistoryApiInterface {
    /**
     * 
     * @param {CreateRollupHistoryDto} createRollupHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApi
     */
    public createWithRollupHistory(createRollupHistoryDto: CreateRollupHistoryDto, options?: AxiosRequestConfig) {
        return RollupHistoryApiFp(this.configuration).createWithRollupHistory(createRollupHistoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApi
     */
    public findAllWithRollupHistory(options?: AxiosRequestConfig) {
        return RollupHistoryApiFp(this.configuration).findAllWithRollupHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApi
     */
    public findOneWithRollupHistory(seriesId: string, options?: AxiosRequestConfig) {
        return RollupHistoryApiFp(this.configuration).findOneWithRollupHistory(seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApi
     */
    public removeSeriesFromRollupWithRollupHistory(id: number, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return RollupHistoryApiFp(this.configuration).removeSeriesFromRollupWithRollupHistory(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRollupHistoryDto} updateRollupHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RollupHistoryApi
     */
    public updateWithRollupHistory(id: string, updateRollupHistoryDto: UpdateRollupHistoryDto, options?: AxiosRequestConfig) {
        return RollupHistoryApiFp(this.configuration).updateWithRollupHistory(id, updateRollupHistoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemUserGroupsApi - axios parameter creator
 * @export
 */
export const SystemUserGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSysuserGroupDto} createSysuserGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithSysuserGroups: async (createSysuserGroupDto: CreateSysuserGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSysuserGroupDto' is not null or undefined
            assertParamExists('createWithSysuserGroups', 'createSysuserGroupDto', createSysuserGroupDto)
            const localVarPath = `/api/sysuser-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSysuserGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithSysuserGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sysuser-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithSysuserGroups: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithSysuserGroups', 'id', id)
            const localVarPath = `/api/sysuser-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithSysuserGroups: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithSysuserGroups', 'id', id)
            const localVarPath = `/api/sysuser-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSysuserGroupDto} updateSysuserGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithSysuserGroups: async (id: string, updateSysuserGroupDto: UpdateSysuserGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithSysuserGroups', 'id', id)
            // verify required parameter 'updateSysuserGroupDto' is not null or undefined
            assertParamExists('updateWithSysuserGroups', 'updateSysuserGroupDto', updateSysuserGroupDto)
            const localVarPath = `/api/sysuser-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSysuserGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemUserGroupsApi - functional programming interface
 * @export
 */
export const SystemUserGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemUserGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSysuserGroupDto} createSysuserGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithSysuserGroups(createSysuserGroupDto: CreateSysuserGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithSysuserGroups(createSysuserGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithSysuserGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithSysuserGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithSysuserGroups(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithSysuserGroups(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithSysuserGroups(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithSysuserGroups(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSysuserGroupDto} updateSysuserGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithSysuserGroups(id: string, updateSysuserGroupDto: UpdateSysuserGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithSysuserGroups(id, updateSysuserGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemUserGroupsApi - factory interface
 * @export
 */
export const SystemUserGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemUserGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSysuserGroupDto} createSysuserGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithSysuserGroups(createSysuserGroupDto: CreateSysuserGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithSysuserGroups(createSysuserGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithSysuserGroups(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithSysuserGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithSysuserGroups(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.findOneWithSysuserGroups(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithSysuserGroups(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithSysuserGroups(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSysuserGroupDto} updateSysuserGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithSysuserGroups(id: string, updateSysuserGroupDto: UpdateSysuserGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithSysuserGroups(id, updateSysuserGroupDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemUserGroupsApi - interface
 * @export
 * @interface SystemUserGroupsApi
 */
export interface SystemUserGroupsApiInterface {
    /**
     * 
     * @param {CreateSysuserGroupDto} createSysuserGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApiInterface
     */
    createWithSysuserGroups(createSysuserGroupDto: CreateSysuserGroupDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApiInterface
     */
    findAllWithSysuserGroups(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApiInterface
     */
    findOneWithSysuserGroups(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApiInterface
     */
    removeWithSysuserGroups(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateSysuserGroupDto} updateSysuserGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApiInterface
     */
    updateWithSysuserGroups(id: string, updateSysuserGroupDto: UpdateSysuserGroupDto, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * SystemUserGroupsApi - object-oriented interface
 * @export
 * @class SystemUserGroupsApi
 * @extends {BaseAPI}
 */
export class SystemUserGroupsApi extends BaseAPI implements SystemUserGroupsApiInterface {
    /**
     * 
     * @param {CreateSysuserGroupDto} createSysuserGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApi
     */
    public createWithSysuserGroups(createSysuserGroupDto: CreateSysuserGroupDto, options?: AxiosRequestConfig) {
        return SystemUserGroupsApiFp(this.configuration).createWithSysuserGroups(createSysuserGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApi
     */
    public findAllWithSysuserGroups(options?: AxiosRequestConfig) {
        return SystemUserGroupsApiFp(this.configuration).findAllWithSysuserGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApi
     */
    public findOneWithSysuserGroups(id: string, options?: AxiosRequestConfig) {
        return SystemUserGroupsApiFp(this.configuration).findOneWithSysuserGroups(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApi
     */
    public removeWithSysuserGroups(id: string, options?: AxiosRequestConfig) {
        return SystemUserGroupsApiFp(this.configuration).removeWithSysuserGroups(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateSysuserGroupDto} updateSysuserGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUserGroupsApi
     */
    public updateWithSysuserGroups(id: string, updateSysuserGroupDto: UpdateSysuserGroupDto, options?: AxiosRequestConfig) {
        return SystemUserGroupsApiFp(this.configuration).updateWithSysuserGroups(id, updateSysuserGroupDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemUsersApi - axios parameter creator
 * @export
 */
export const SystemUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSysuserDto} createSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithSysusers: async (createSysuserDto: CreateSysuserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSysuserDto' is not null or undefined
            assertParamExists('createWithSysusers', 'createSysuserDto', createSysuserDto)
            const localVarPath = `/api/sysusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSysuserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithSysusers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sysusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNameWithSysusers: async (sub: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sub' is not null or undefined
            assertParamExists('findNameWithSysusers', 'sub', sub)
            const localVarPath = `/api/sysusers/{sub}`
                .replace(`{${"sub"}}`, encodeURIComponent(String(sub)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneAvatarWithSysusers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneAvatarWithSysusers', 'id', id)
            const localVarPath = `/api/sysusers/profile-avatar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByIdWithSysusers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneByIdWithSysusers', 'id', id)
            const localVarPath = `/api/sysusers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithSysusers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWithSysusers', 'id', id)
            const localVarPath = `/api/sysusers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSysuserDto} updateSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithSysusers: async (id: number, updateSysuserDto: UpdateSysuserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithSysusers', 'id', id)
            // verify required parameter 'updateSysuserDto' is not null or undefined
            assertParamExists('updateWithSysusers', 'updateSysuserDto', updateSysuserDto)
            const localVarPath = `/api/sysusers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSysuserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileWithSysusers: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadedFileWithSysusers', 'image', image)
            const localVarPath = `/api/sysusers/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemUsersApi - functional programming interface
 * @export
 */
export const SystemUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSysuserDto} createSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithSysusers(createSysuserDto: CreateSysuserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithSysusers(createSysuserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithSysusers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithSysusers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNameWithSysusers(sub: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNameWithSysusers(sub, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneAvatarWithSysusers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneAvatarWithSysusers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneByIdWithSysusers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneByIdWithSysusers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWithSysusers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWithSysusers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSysuserDto} updateSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithSysusers(id: number, updateSysuserDto: UpdateSysuserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithSysusers(id, updateSysuserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadedFileWithSysusers(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadedFileWithSysusers(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemUsersApi - factory interface
 * @export
 */
export const SystemUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSysuserDto} createSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithSysusers(createSysuserDto: CreateSysuserDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWithSysusers(createSysuserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithSysusers(options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithSysusers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNameWithSysusers(sub: string, options?: any): AxiosPromise<void> {
            return localVarFp.findNameWithSysusers(sub, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneAvatarWithSysusers(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.findOneAvatarWithSysusers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByIdWithSysusers(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.findOneByIdWithSysusers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWithSysusers(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeWithSysusers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSysuserDto} updateSysuserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithSysusers(id: number, updateSysuserDto: UpdateSysuserDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithSysusers(id, updateSysuserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileWithSysusers(image: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadedFileWithSysusers(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemUsersApi - interface
 * @export
 * @interface SystemUsersApi
 */
export interface SystemUsersApiInterface {
    /**
     * 
     * @param {CreateSysuserDto} createSysuserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    createWithSysusers(createSysuserDto: CreateSysuserDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    findAllWithSysusers(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} sub 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    findNameWithSysusers(sub: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    findOneAvatarWithSysusers(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    findOneByIdWithSysusers(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    removeWithSysusers(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {UpdateSysuserDto} updateSysuserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    updateWithSysusers(id: number, updateSysuserDto: UpdateSysuserDto, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApiInterface
     */
    uploadedFileWithSysusers(image: any, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * SystemUsersApi - object-oriented interface
 * @export
 * @class SystemUsersApi
 * @extends {BaseAPI}
 */
export class SystemUsersApi extends BaseAPI implements SystemUsersApiInterface {
    /**
     * 
     * @param {CreateSysuserDto} createSysuserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public createWithSysusers(createSysuserDto: CreateSysuserDto, options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).createWithSysusers(createSysuserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public findAllWithSysusers(options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).findAllWithSysusers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sub 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public findNameWithSysusers(sub: string, options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).findNameWithSysusers(sub, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public findOneAvatarWithSysusers(id: number, options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).findOneAvatarWithSysusers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public findOneByIdWithSysusers(id: number, options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).findOneByIdWithSysusers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public removeWithSysusers(id: number, options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).removeWithSysusers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateSysuserDto} updateSysuserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public updateWithSysusers(id: number, updateSysuserDto: UpdateSysuserDto, options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).updateWithSysusers(id, updateSysuserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemUsersApi
     */
    public uploadedFileWithSysusers(image: any, options?: AxiosRequestConfig) {
        return SystemUsersApiFp(this.configuration).uploadedFileWithSysusers(image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TickerRawdataApi - axios parameter creator
 * @export
 */
export const TickerRawdataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<TickerRawdataDto>} tickerRawdataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpsertWithTickerRawdata: async (tickerRawdataDto: Array<TickerRawdataDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tickerRawdataDto' is not null or undefined
            assertParamExists('bulkUpsertWithTickerRawdata', 'tickerRawdataDto', tickerRawdataDto)
            const localVarPath = `/api/ticker-rawdata/bulk-upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tickerRawdataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TickerRawdataApi - functional programming interface
 * @export
 */
export const TickerRawdataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TickerRawdataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<TickerRawdataDto>} tickerRawdataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpsertWithTickerRawdata(tickerRawdataDto: Array<TickerRawdataDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpsertWithTickerRawdata(tickerRawdataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TickerRawdataApi - factory interface
 * @export
 */
export const TickerRawdataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TickerRawdataApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<TickerRawdataDto>} tickerRawdataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpsertWithTickerRawdata(tickerRawdataDto: Array<TickerRawdataDto>, options?: any): AxiosPromise<void> {
            return localVarFp.bulkUpsertWithTickerRawdata(tickerRawdataDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TickerRawdataApi - interface
 * @export
 * @interface TickerRawdataApi
 */
export interface TickerRawdataApiInterface {
    /**
     * 
     * @param {Array<TickerRawdataDto>} tickerRawdataDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TickerRawdataApiInterface
     */
    bulkUpsertWithTickerRawdata(tickerRawdataDto: Array<TickerRawdataDto>, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TickerRawdataApi - object-oriented interface
 * @export
 * @class TickerRawdataApi
 * @extends {BaseAPI}
 */
export class TickerRawdataApi extends BaseAPI implements TickerRawdataApiInterface {
    /**
     * 
     * @param {Array<TickerRawdataDto>} tickerRawdataDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TickerRawdataApi
     */
    public bulkUpsertWithTickerRawdata(tickerRawdataDto: Array<TickerRawdataDto>, options?: AxiosRequestConfig) {
        return TickerRawdataApiFp(this.configuration).bulkUpsertWithTickerRawdata(tickerRawdataDto, options).then((request) => request(this.axios, this.basePath));
    }
}


