import {
  CREATE_WITH_TICKER,
  CREATE_WITH_TICKER_ERROR,
  CREATE_WITH_TICKER_SUCCESS,
  FIND_SYSTEM_CANDIDATE,
  FIND_SYSTEM_CANDIDATE_SUCCESS_JPY,
  FIND_SYSTEM_CANDIDATE_SUCCESS_USD,
  FIND_SYSTEM_CANDIDATE_SUCCESS_AUD,
  FIND_SYSTEM_CANDIDATE_SUCCESS_HKD,
  FIND_SYSTEM_CANDIDATE_SUCCESS_GBP,
  FIND_SYSTEM_CANDIDATE_SUCCESS_EUR,
  GET_LIST_WITH_TICKER,
  GET_LIST_WITH_TICKER_SUCCESS,
  UPDATE_WITH_TICKER,
  UPDATE_WITH_TICKER_ERROR,
  UPDATE_WITH_TICKER_SUCCESS,
} from '../../constants/ActionTypes'
import { DataCandidateItem, MarketDataItem } from '../../dto'
import { MarketDataStateDto } from './stateDtos/MarketDataStateDto'
interface ActionProps {
  data: MarketDataItem[]
  dataCandidateUSD: DataCandidateItem[],
  dataCandidateJPY: DataCandidateItem[],
  dataCandidateEUR: DataCandidateItem[],
  dataCandidateGBP: DataCandidateItem[],
  dataCandidateAUD: DataCandidateItem[],
  dataCandidateHKD: DataCandidateItem[],
  type: string
  payload: MarketDataItem
}
const INIT_STATE: MarketDataStateDto = {
  dataListFX: [],
  dataListInterestRate: [],
  dataCandidateUSD: [],
  dataCandidateJPY: [],
  dataCandidateEUR: [],
  dataCandidateGBP: [],
  dataCandidateAUD: [],
  dataCandidateHKD: [],

}
const MarketDataReducer = (
  state = INIT_STATE,
  action: ActionProps
): MarketDataStateDto => {
  switch (action.type) {
    case GET_LIST_WITH_TICKER: {
      return {
        ...state,
      }
    }
    case GET_LIST_WITH_TICKER_SUCCESS: {
      return {
        ...state,
        dataListFX: action.data,
        dataListInterestRate: action.data
      }
    }
    case CREATE_WITH_TICKER: {
      return {
        ...state,
      }
    }
    case CREATE_WITH_TICKER_SUCCESS: {
      const newListFX = [...state.dataListFX]
      newListFX.push(action.payload)
      const newListInterest = [...state.dataListInterestRate]
      newListInterest.push(action.payload)
      return {
        ...state,
        dataListFX: newListFX,
        dataListInterestRate: newListInterest
      }
    }
    case CREATE_WITH_TICKER_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_WITH_TICKER: {
      return {
        ...state,
      }
    }
    case UPDATE_WITH_TICKER_SUCCESS: {
      const newListFX = state.dataListFX.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      const newListInterest = state.dataListInterestRate.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        dataListFX: newListFX,
        dataListInterestRate: newListInterest
      }
    }
    case UPDATE_WITH_TICKER_ERROR: {
      return {
        ...state,
      }
    }
    case FIND_SYSTEM_CANDIDATE: {
      return {
        ...state,
      }
    }
    case FIND_SYSTEM_CANDIDATE_SUCCESS_USD: {
      return {
        ...state,
        dataCandidateUSD: action.dataCandidateUSD,
      }
    }
    case FIND_SYSTEM_CANDIDATE_SUCCESS_JPY: {
      return {
        ...state,
        dataCandidateJPY: action.dataCandidateJPY,
      }
    }
    case FIND_SYSTEM_CANDIDATE_SUCCESS_EUR: {
      return {
        ...state,
        dataCandidateEUR: action.dataCandidateEUR,
      }
    }
    case FIND_SYSTEM_CANDIDATE_SUCCESS_AUD: {
      return {
        ...state,
        dataCandidateAUD: action.dataCandidateAUD,
      }
    }
    case FIND_SYSTEM_CANDIDATE_SUCCESS_GBP: {
      return {
        ...state,
        dataCandidateGBP: action.dataCandidateGBP,
      }
    }
    case FIND_SYSTEM_CANDIDATE_SUCCESS_HKD: {
      return {
        ...state,
        dataCandidateHKD: action.dataCandidateHKD,
      }
    }
    default:
      return state
  }
}
export default MarketDataReducer
