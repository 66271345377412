import { GET_WEIGHTEDAVERAGEDATA } from "../../constants/ActionTypes";
import { DataItemStateDto} from './stateDtos';
interface ActionProps {
    type: string,
    payload: ""
}

const INIT_STATE : DataItemStateDto = {
    dataList :[{
        id:1,
        jpy_class:{
            year: 2020,
            jan:"0.14%",
            feb:"1.74%",
            mar:"1.15%",
            apr:"0.57%",
            may:"-0.28",
            jun:"0.48%",
            jul:"1.62%",
            aug:"0.54%",
            sep:"0.27%",
            oct:"0.56%",
            nov:"1.18%",
            dec:"0.01%",
            ytd:"7.93%",
        },
    },{
        id:2,
        jpy_class:{
            year: 2019,
            jan:"0.14%",
            feb:"1.74%",
            mar:"1.15%",
            apr:"0.57%",
            may:"-0.28",
            jun:"0.48%",
            jul:"1.62%",
            aug:"0.54%",
            sep:"0.27%",
            oct:"0.56%",
            nov:"1.18%",
            dec:"0.01%",
            ytd:"7.93%",
        },
    },{
        id:3,
        jpy_class:{
            year: 2018,
            jan:"0.14%",
            feb:"1.74%",
            mar:"1.15%",
            apr:"0.57%",
            may:"-0.28",
            jun:"0.48%",
            jul:"1.62%",
            aug:"0.54%",
            sep:"0.27%",
            oct:"0.56%",
            nov:"1.18%",
            dec:"0.01%",
            ytd:"7.93%",
        },
    }]
}
const dataReducer = (state = INIT_STATE, action: ActionProps ):DataItemStateDto => {
    switch (action.type) {
        case GET_WEIGHTEDAVERAGEDATA:
          {
            return {
              ...state
            }
          }
        default:
          return state;
      }
}
export default dataReducer