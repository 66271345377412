import { GET_LIST_ACCOUNT } from "../../constants/ActionTypes";
import { FXDataDto } from './stateDtos';
interface ActionProps {
    type: string,
    payload: FXDataDto
}

const INIT_STATE: FXDataDto = {
    fx_data: [
        {
            id: 1,
            ticker: "JPY",
            market_sector: "Currency",
            base_currency: "USD",
            quote_currency: "JPY",
            discontinuedAt: "",
        },
        {
            id: 2,
            ticker: "EUR",
            market_sector: "Currency",
            base_currency: "USD",
            quote_currency: "EUR",
            discontinuedAt: "",
        },
        {
            id: 3,
            ticker: "AUD",
            market_sector: "Currency",
            base_currency: "USD",
            quote_currency: "AUD",
            discontinuedAt: "",
        },
        {
            id: 4,
            ticker: "HKD",
            market_sector: "Currency",
            base_currency: "USD",
            quote_currency: "HKD",
            discontinuedAt: "",
        },
    ],
};
const FXDataReducer = (state = INIT_STATE, action: ActionProps): FXDataDto => {
    switch (action.type) {
        case GET_LIST_ACCOUNT: {
            return {
                ...state,
            };
        }
        default:
            return state;
    }
};
export default FXDataReducer;
