import { GET_DETAIL_DATA_WITH_TICKER, GET_DETAIL_DATA_WITH_TICKER_ERROR, GET_DETAIL_DATA_WITH_TICKER_SUCCESS, GET_LIST_INTEREST_RATES_TICKER, GET_LIST_INTEREST_RATES_TICKER_ERROR, GET_LIST_INTEREST_RATES_TICKER_SUCCESS } from "../../../constants/ActionTypes"
import { IInterestRatesTickerWithRawData, InterestRatesTickerStateDto } from "../stateDtos/MarketData/InterestRatesTickerStateDto"

interface ActionProps {
    data: InterestRatesTickerStateDto[]
    detailData: IInterestRatesTickerWithRawData | null
    type: string
}
const INIT_STATE: InterestRatesTickerStateDto = {
    interestRatesTickerList: [],
    detailData: null
}
const InterestRatesTickerReducer = (
    state = INIT_STATE,
    action: ActionProps
) => {
    switch (action.type) {
        case GET_LIST_INTEREST_RATES_TICKER: {
            return {
                ...state,
                interestRatesTickerList: [],
            }
        }
        case GET_LIST_INTEREST_RATES_TICKER_SUCCESS: {
            return {
                ...state,
                interestRatesTickerList: action.data,
            }
        }
        case GET_LIST_INTEREST_RATES_TICKER_ERROR: {
            return {
                ...state,
                interestRatesTickerList: [],
            }
        }
        case GET_DETAIL_DATA_WITH_TICKER: {
            return {
                ...state,
                detailData: null,
            }
        }
        case GET_DETAIL_DATA_WITH_TICKER_SUCCESS: {
            return {
                ...state,
                detailData: action.detailData,
            }
        }
        case GET_DETAIL_DATA_WITH_TICKER_ERROR: {
            return {
                ...state,
                detailData: null,
            }
        }
        default:
            return state
    }
}
export default InterestRatesTickerReducer