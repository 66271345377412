/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  STATUS_CODE_CREATE_SUCCESS,
  STATUS_CODE_SUCCESS,
  STATUS_CODE_UPDATE_SUCCESS,
} from '../../../constants/StatusCode'
import { DividendApi, AccountTransactionsApi } from '../../../client-axios/api'
import { axiosInstance } from '../../../core/axios'
import { configHeader } from '../../../core/token'
import { Dispatch } from 'redux'
import {
  CREATE_AC_DIVIDEND_TRANSACTION_ERROR,
  CREATE_AC_DIVIDEND_TRANSACTION_SUCCESS,
  CREATE_AC_INVESTOR_TRANSACTION_ERROR,
  CREATE_AC_INVESTOR_TRANSACTION_SUCCESS,
  CREATE_AC_REDEEM_TRANSACTION_ERROR,
  CREATE_AC_REDEEM_TRANSACTION_SUCCESS,
  DELETE_AC_DIVIDEND_TRANSACTION_ERROR,
  DELETE_AC_DIVIDEND_TRANSACTION_SUCCESS,
  DELETE_AC_INVESTOR_TRANSACTION_ERROR,
  DELETE_AC_INVESTOR_TRANSACTION_SUCCESS,
  GET_ACCOUNT_TS_FUND_TXN,
  GET_ACCOUNT_TS_FUND_TXN_ERROR,
  GET_ACCOUNT_TS_FUND_TXN_SUCCESS,
  GET_BY_ID_AC_DIVIDEND_TRANSACTION_ERROR,
  GET_BY_ID_AC_DIVIDEND_TRANSACTION_SUCCESS,
  GET_INVESTOR_WITH_TRANSACTION_ID,
  GET_INVESTOR_WITH_TRANSACTION_ID_ERROR,
  GET_INVESTOR_WITH_TRANSACTION_ID_SUCCESS,
  GET_LIST_INVESTOR_TRANSACTION_WITH_ID,
  GET_LIST_INVESTOR_TRANSACTION_WITH_ID_DEFAULT,
  GET_LIST_INVESTOR_TRANSACTION_WITH_ID_ERROR,
  GET_NAV_FINAL_INVESTTXN,
  GET_NAV_FINAL_INVESTTXN_SUCCESS,
  GET_VALUE_ACCOUNT_INVESTOR,
  GET_VALUE_BASE_DATE_INVESTOR,
  UPDATE_AC_DIVIDEND_TRANSACTION_ERROR,
  UPDATE_AC_DIVIDEND_TRANSACTION_SUCCESS,
  UPDATE_AC_INVESTOR_TRANSACTION_ERROR,
  UPDATE_AC_INVESTOR_TRANSACTION_SUCCESS,
} from '../../../constants/ActionTypes'
import { message } from 'antd'
import { Moment } from 'moment'
import { capitalizeFirstLetter } from '../../../util/function'

export const createAcInvestorTransaction =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const accountTransaction = new AccountTransactionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return accountTransaction
        .createWithInvestorTransaction(payload)
        .then((res) => {
          if (res.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({
              type: CREATE_AC_INVESTOR_TRANSACTION_SUCCESS,
              payload: res.data,
            })
            return res.status
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: CREATE_AC_INVESTOR_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(error.response?.data?.detail?.message)
          }
          return error?.response?.statusCode
        })
    }
export const updateAcInvestorTransaction =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const accountTransaction = new AccountTransactionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return accountTransaction
        .updateTransactionItemWithInvestorTransaction(
          payload.txnItemId,
          payload.data
        )
        .then((res) => {
          if (res.status === STATUS_CODE_UPDATE_SUCCESS) {
            dispatch({
              type: UPDATE_AC_INVESTOR_TRANSACTION_SUCCESS,
              payload: res.data,
            })
            return res.status
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: UPDATE_AC_INVESTOR_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
          }
          return error?.response?.statusCode
        })
    }
export const deleteAcInvestorTransaction =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const accountTransaction = new AccountTransactionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return accountTransaction
        .removeTransactionItemWithInvestorTransaction(payload.txnItemId)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: DELETE_AC_INVESTOR_TRANSACTION_SUCCESS,
              payload: res.data,
            })
            return res.status
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: DELETE_AC_INVESTOR_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(error.response?.data?.detail?.message, 10)
          }
          return error?.response?.statusCode
        })
    }
export const getListInvestorTransactionWithAccountId =
  (payload: { accountId: any; lastDayOfMonth: string }): unknown =>
    (dispatch: Dispatch) => {
      const accountTransaction = new AccountTransactionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      // dispatch({ type: GET_LIST_INVESTOR_TRANSACTION })
      return accountTransaction
        .findAllTxnByAccountAndDateWithInvestorTransaction(
          payload.accountId,
          payload.lastDayOfMonth
        )
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: GET_LIST_INVESTOR_TRANSACTION_WITH_ID,
              payload: res.data,
            })
            dispatch({
              type: GET_LIST_INVESTOR_TRANSACTION_WITH_ID_DEFAULT,
              payload: res.data,
            })
            return res.status
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: GET_LIST_INVESTOR_TRANSACTION_WITH_ID_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(error.response?.data?.detail?.message)
            return error?.response?.statusCode
          }
        })
    }

export const getInvestByTransactionId =
  (payload: { id: string }): unknown =>
    (dispatch: Dispatch) => {
      const accountTransaction = new AccountTransactionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      dispatch({ type: GET_INVESTOR_WITH_TRANSACTION_ID })
      return accountTransaction
        .findAccountTransactionItemWithInvestorTransaction(payload.id)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: GET_INVESTOR_WITH_TRANSACTION_ID_SUCCESS,
              payload: res.data,
            })
          }
          return res.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: GET_INVESTOR_WITH_TRANSACTION_ID_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(error.response?.data?.detail?.message)
          }
          return error?.response?.statusCode
        })
    }
export const createRedeemTransaction =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const accountTransaction = new AccountTransactionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return accountTransaction
        .createRedeemWithInvestorTransaction(
          payload.fundTransactionId,
          payload.data
        )
        .then((res) => {
          if (res.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({
              type: CREATE_AC_REDEEM_TRANSACTION_SUCCESS,
              payload: res.data,
            })
            return res.status
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: CREATE_AC_REDEEM_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
          }
          return error?.response?.statusCode
        })
    }
export const createDividendTransaction =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const dividendApi = new DividendApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return dividendApi
        .createWithDividend(payload)
        .then((res) => {
          if (res.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({
              type: CREATE_AC_DIVIDEND_TRANSACTION_SUCCESS,
              payload: res.data,
            })
          }
          return res.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: CREATE_AC_DIVIDEND_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
          }
          return error?.response?.statusCode
        })
    }
export const updateDividendTransaction =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const dividendApi = new DividendApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return dividendApi
        .updateWithDividend(payload)
        .then((res) => {
          if (res.status === STATUS_CODE_UPDATE_SUCCESS) {
            dispatch({
              type: UPDATE_AC_DIVIDEND_TRANSACTION_SUCCESS,
              payload: res.data,
            })
          }
          return res.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: UPDATE_AC_DIVIDEND_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(error.response?.data?.detail?.message)
          }
          return error?.response?.statusCode
        })
    }
export const deleteDividendTransaction =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const dividendApi = new DividendApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return dividendApi
        .removeWithDividend(payload.dividendId)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: DELETE_AC_DIVIDEND_TRANSACTION_SUCCESS,
              payload: res.data,
            })
          }
          return res.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: DELETE_AC_DIVIDEND_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(error.response?.data?.detail?.message)
          }
          return error?.response?.statusCode
        })
    }
export const getAccountTsFundTxn =
  (payload: { accountSeriesId: string; basedate: string }): unknown =>
    (dispatch: Dispatch) => {
      const dividendApi = new DividendApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      dispatch({ type: GET_ACCOUNT_TS_FUND_TXN })
      return dividendApi
        .findAccountTsFundTxnWithDividend(
          payload.accountSeriesId,
          payload.basedate
        )
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: GET_ACCOUNT_TS_FUND_TXN_SUCCESS,
              payload: res.data,
            })
          }
          return res.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: GET_ACCOUNT_TS_FUND_TXN_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(
              capitalizeFirstLetter(
                error.response?.data?.detail?.message.replace(/_/g, ' ')
              ),
              10
            )
          }
          return error?.response?.statusCode
        })
    }
export const getByIdDividend =
  (payload: { dividendId: string }): unknown =>
    (dispatch: Dispatch) => {
      const dividendApi = new DividendApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return dividendApi
        .findOneWithDividend(payload.dividendId)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: GET_BY_ID_AC_DIVIDEND_TRANSACTION_SUCCESS,
              payload: res.data,
            })
          }
          return res.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: GET_BY_ID_AC_DIVIDEND_TRANSACTION_ERROR,
              error: error.response?.data?.detail?.message,
            })
            message.error(error.response?.data?.detail?.message)
          }
          return error?.response?.statusCode
        })
    }

export const findNavFinalForInvestTxn =
    (payload: {basedate: string, fundSeriesId: number }): unknown =>
      (dispatch: Dispatch) => {
        const accountTransaction = new AccountTransactionsApi(
          configHeader(),
          undefined,
          axiosInstance
        )
        dispatch({
          type: GET_NAV_FINAL_INVESTTXN,
        })
        return accountTransaction
        .findNavFinalWithInvestorTransaction(
          payload.basedate,
          payload.fundSeriesId
        ).then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: GET_NAV_FINAL_INVESTTXN_SUCCESS,
              payload: res.data,
            })
          }
        }).catch((error) => {
          dispatch({
            type: GET_NAV_FINAL_INVESTTXN,
          })
          return error?.response?.statusCode
        })
      }

export const getValueAccountInvest = (accountId: string | number | undefined): unknown => {
  return {
    type: GET_VALUE_ACCOUNT_INVESTOR,
    value: accountId,
  }
}
export const getValueBasedateInvest = (basedate: Moment | null): unknown => {
  return {
    type: GET_VALUE_BASE_DATE_INVESTOR,
    valueBasedate: basedate,
  }
}
