import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}

const Profile = ({ match }: iProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={`${match.url}/change-password`}
      component={asyncComponent(
        () => import('../../components/profile/ResetPassword')
      )}
    />{' '}
  </Switch>
)
export default Profile
