import { PortfolioStateDto } from './stateDtos'
import { PortfolioHoldingItem, PortfolioItem } from '../../dto'
import {
  CREATE_PORTFOLIO_SUCCESS,
  CREATE_PORTFOLIO_ERROR,
  UPDATE_PORTFOLIO,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLOO_ERROR,
  GET_BY_ID_PORTFOLIO_SUCCESS,
  GET_BY_ID_PORTFOLIO,
  GET_PORTFOLIO_BY_ACCOUNT_ID,
  GET_PORTFOLIO_BY_ACCOUNT_ID_SUCCESS,
  GET_LIST_PORTFOLIO_SUCCESS,
  GET_LIST_PORTFOLIO,
  GET_PORTFOLIO_BY_ACCOUNT_ID_AT_SUCCESS
} from '../../constants/ActionTypes'

interface ActionProps {
  id: number
  type: string
  payload: PortfolioItem
  data: PortfolioItem[]
  dataHolding: PortfolioHoldingItem[]
}
const INIT_STATE: PortfolioStateDto = {
  id: 0,
  portList: [],
  portListAT: [],
  dataHolding: [],
  CreateAlert: true,
  UpdateAlert: true,
  recordPortfolio:null
}
const PortfolioReducer = (
  state = INIT_STATE,
  action: ActionProps
): unknown => {
  switch (action.type) {
    case GET_LIST_PORTFOLIO: {
      return {
        ...state
      }
    }
    case GET_LIST_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        portList: action.data
      }
    }
    case CREATE_PORTFOLIO_SUCCESS: {
      const newList = [...state.portList]
      newList.push(action.payload)
      return {
        ...state,
        portList: newList,
        CreateAlert: true,
      }
    }
    case CREATE_PORTFOLIO_ERROR: {
      return {
        ...state,

        //recordPort: null,
      }
    }
    case UPDATE_PORTFOLIO: {
      return {
        ...state,
      }
    }
    case UPDATE_PORTFOLIO_SUCCESS: {
      const newList = state?.portList?.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        portList: newList,
        UpdateAlert: true,
      }
    }
    case UPDATE_PORTFOLOO_ERROR: {
      return {
        ...state,
        UpdateAlert: true,
      }
    }
    case GET_BY_ID_PORTFOLIO: {
      return {
        ...state,
        record: null
      }
    }

    case GET_BY_ID_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        recordPortfolio: action.payload,
        id: action.id,
      }
    }
    case GET_PORTFOLIO_BY_ACCOUNT_ID: {
      return {
        ...state,
        portList: [],
      }
    }
    case GET_PORTFOLIO_BY_ACCOUNT_ID_SUCCESS: {
      return {
        ...state,
        portList: action.data,
      }
    }
    case GET_PORTFOLIO_BY_ACCOUNT_ID_AT_SUCCESS: {
      return {
        ...state,
        portListAT: action.data,
      }
    }
    // case GET_LIST_PORTFOLIO_HOLDING_SUCCESS: {
    //   return {
    //     ...state,
    //     dataHolding: action.dataHolding
    //   }
    // }
    default:
      return state
  }
}
export default PortfolioReducer
