/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "../../constants/ActionTypes";
import {LAYOUT_TYPE, NAV_STYLE, THEME_COLOR, THEME_TYPE, UPDATE_RTL_STATUS} from "../../constants/ThemeSetting";

export function toggleCollapsedSideNav(navCollapsed: boolean) {
  return {type: TOGGLE_COLLAPSED_NAV, navCollapsed};
}

export function updateWindowWidth(width: number) {
  return (dispatch: (arg0: { type: string; width: any; }) => void) => {
    dispatch({type: WINDOW_WIDTH, width});
  }
}

export function setThemeType(themeType: any) {
  return (dispatch: (arg0: { type: string; themeType: any; }) => void) => {
    dispatch({type: THEME_TYPE, themeType});
  }
}

export function setThemeColor(themeColor: any) {
  return (dispatch: (arg0: { type: string; themeColor: any; }) => void) => {
    dispatch({type: THEME_COLOR, themeColor});
  }
}

export function setDirectionRTL(rtlStatus: any) {
  return (dispatch: (arg0: { type: string; rtlStatus: any; }) => void) => {
    dispatch({type: UPDATE_RTL_STATUS, rtlStatus});
  }
}

export function onNavStyleChange(navStyle: any) {
  return (dispatch: (arg0: { type: string; navStyle: any; }) => void) => {
    dispatch({type: NAV_STYLE, navStyle});
  }
}

export function onLayoutTypeChange(layoutType: any) {
  return (dispatch: (arg0: { type: string; layoutType: any; }) => void) => {
    dispatch({type: LAYOUT_TYPE, layoutType});
  }
}

export function switchLanguage(locale: { languageId: string; locale: string; name: string; icon: string; }) {
  return (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      payload: locale
    });
  }
}
