export default [
  //Master
  {
    path: '/main/master',
    name: 'Master Management',
    index: 0
  },
  {
    path: '/main/master/organization',
    name: 'Organization',

  },
  {
    path: '/main/master/organization/add',
    name: 'Add New',

  },
  {
    path: '/main/master/organization/edit/:id',
    name: 'Edit',

  },
  {
    path: '/main/master/hf',
    name: 'Hedge Fund',

  },
  {
    path: '/main/master/hf/add',
    name: 'Add New',
  },
  {
    path: '/main/master/hf/edit/:id',
    name: 'Edit',
    index: ''
  },
  {
    path: '/main/master/acct',
    name: 'Account',
    index: ''
  },
  {
    path: '/main/master/acct/add',
    name: 'Add New',
    index: ''
  },
  {
    path: '/main/master/acct/edit/:id',
    name: 'Edit',
    index: ''
  },
  {
    path: '/main/master/acct/:id/portfolio/add',
    name: 'Add New Portfolio',
    index: ''
  },
  {
    path: '/main/master/acct/:id/portfolio/edit/:portfolioId',
    name: 'Edit Portfolio',
    index: ''
  },
  {
    path: '/main/master/acct/:id/portfolio/:portfolioId/class/add',
    name: 'Add New Class',
    index: ''
  },
  {
    path: '/main/master/acct/:id/portfolio/:portfolioId/class/edit/:classId',
    name: 'Edit Class',
    index: ''
  },
  {
    path: '/main/master/acct/:id/class/add',
    name: 'Add New Class',
    index: ''
  },
  {
    path: '/main/master/acct/:id/class/edit/:classId',
    name: 'Edit Class',
    index: ''
  },
  {
    path: '/main/master/acct/:fundId/portfolio/class/:fundClassId/series/add',
    name: 'Add New',
    index: ''
  },
  {
    path: '/main/master/acct/:fundId/portfolio/class/:fundClassId/series/edit/:seriesId',
    name: 'Edit',
    index: ''
  },

  {
    path: '/main/master/investor',
    name: 'Investor',
    index: ''
  },
  {
    path: '/main/master/investor/add',
    name: 'Add New',
    index: ''
  },
  {
    path: '/main/master/investor/edit/:id',
    name: 'Edit',
    index: ''
  },
  {
    path: '/main/master/investor/:id/division/add',
    name: 'Add New Division',
    index: ''
  },
  {
    path: '/main/master/investor/:id/division/edit/:divisionId',
    name: 'Edit Division',
    index: ''
  },
  {
    path: '/main/master/hf/:id/class/:classId/roll-up/add',
    name: 'Add New Roll-up',
    index: ''
  },
  {
    path: '/main/master/hf/:id/class/:classId/roll-up/edit/:rollUpId',
    name: 'Edit Roll-up',
    index: ''
  },
  {
    path: '/main/master/hf/:id/class/:classId/series/add',
    name: 'Series',
    index: ''
  },
  {
    path: '/main/master/hf/:id/class/:classId/series/edit/:id',
    name: 'Series',
    index: ''
  },
  // {
  //   path: '/main/master/hf/:id/class',
  //   name: 'Class',
  // },
  {
    path: '/main/master/hf/:id/class/add',
    name: 'Add New Class',
  },
  {
    path: '/main/master/hf/:id/class/edit/:classId',
    name: 'Edit Class',
  },
  {
    path: '/main/master/hf/:fundId/history/add',
    name: 'Historical Data',
  },

  {
    path: '/main/master/hf/view/:fundId',
    name: 'HedgeFund View',
  },

  {
    path: '/main/master/hf/:fundId/class/view/:classId',
    name: 'HedgeFund-Class View',
  },

  {
    path: '/main/master/acct/view/:fundId',
    name: 'Account View',
  },

  {
    path: '/main/master/acct/:fundId/portfolio/view/:portfolioId',
    name: 'Portfolio View',
  },

  {
    path: '/main/master/acct/:fundId/portfolio/:portfolioId/class/view/:classId',
    name: 'Account-Class View',
  },

  {
    path: '/main/master/investor/view/:investorId',
    name: 'Investor View',
  },

  {
    path: '/main/master/investor/:investorId/division/view/:divisionId',
    name: 'Division View',
  },
  //Account
  {
    path: '/main/acct',
    name: 'Account',
  },
  {
    path: '/main/acct/nav',
    name: 'Account NAV',
  },
  {
    path: '/main/acct/doubleCheck',
    name: 'Account Double Check',
  },
  {
    path: '/main/acct/txn',
    name: 'Investor Transaction',
  },
  {
    path: '/main/acct/txn/invest/:basedate/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New Invest'
  },
  {
    path: '/main/acct/txn/invest/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New Invest'
  },
  {
    path: '/main/acct/txn/invest/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId',
    name: 'Edit Invest'
  },
  {
    path: '/main/acct/txn/invest/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId',
    name: 'Detail Invest'
  },
  {
    path: '/main/acct/txn/redeem/:basedate/:investorId/:divisionId/:fundTransactionId/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New Redeem',
  },
  {
    path: '/main/acct/txn/redeem/:basedate/:fundId/:fundClassId/:fundSeriesId/:fundTransactionId/edit/:fundTransactionItemId',
    name: 'Edit Redeem'
  },
  {
    path: '/main/acct/txn/redeem/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId',
    name: 'Detail Redeem'
  },
  {
    path: '/main/acct/txn/dividend/:basedate/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New Dividend',
  },
  {
    path: '/main/acct/txn/dividend/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId',
    name: 'Edit Dividend',
  },
  {
    path: '/main/acct/txn/dividend/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId',
    name: 'Detail Dividend',
  },
  {
    path: '/main/acct/txn/eqCrystallization/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New EQ Crystallization',
  },
  {
    path: '/main/acct/txn/eqCrystallization/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId',
    name: 'Edit EQ Crystallization',
  },
  {
    path: '/main/acct/txn/eqCrystallization/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId',
    name: 'Detail EQ Crystallization',
  },
  {
    path: '/main/acct/txn/other/:basdate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New Other',
  },
  {
    path: '/main/acct/txn/other/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId',
    name: 'Edit Other',
  },
  {
    path: '/main/acct/txn/other/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId',
    name: 'Detail Other',
  },
  {
    path: '/main/acct/txn/adjust/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New Adjustment for Performance Fee',
  },
  {
    path: '/main/acct/txn/adjust/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId',
    name: 'Edit Adjustment for Performance Fee',
  },
  {
    path: '/main/acct/txn/adjust/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId',
    name: 'Detail Adjustment for Performance Fee',
  },
  {
    path: '/main/acct/txn/extraDividend/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId',
    name: 'Add New Extra Dividend',
  },
  {
    path: '/main/acct/txn/extraDividend/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId',
    name: 'Edit Extra Dividend',
  },
  {
    path: '/main/acct/txn/extraDividend/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId',
    name: 'Detail Extra Dividend',
  },
  {
    path: '/main/acct/dataDuplication',
    name: 'Estimate Data Duplication'
  },
  //HedgeFund
  {
    path: '/main/hf',
    name: 'Hedge Fund'
  },
  {
    path: '/main/hf/view',
    name: 'Hedge Fund'
  },
  {
    path: '/main/hf/doubleCheck',
    name: 'Hedge Fund Double Check',
  },
  {
    path: '/main/hf/aum_exposure',
    name: 'AUM & Exposure',
  },
  {
    path: '/main/hf/return',
    name: 'Return',
  },
  {
    path: '/main/hf/return/dataEntry/:id/:basedate/add',
    name: 'HedgeFund Level - Add New',
  },
  {
    path: '/main/hf/return/dataEntry/:id/:basedate/edit',
    name: 'HedgeFund Level - Edit',
  },
  {
    path: '/main/hf/return/holdingLevel/:id/:basedate/add',
    name: 'Holding Level - Add New',
  },
  {
    path: '/main/hf/return/holdingLevel/:id/:basedate/edit',
    name: 'Holding Level - Edit',
  },
  {
    path: '/main/hf/port',
    name: 'Portfolio Management',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id',
    name: 'Portfolio Transaction',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:currency/class-series-change/:basedate/add',
    name: 'Add New Class Series Change',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/class-series-change/:basedate/edit/:mergeTxnItemIds',
    name: 'Edit Class Series Change',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/class-series-change/:basedate/detail/:fundTransactionItemId',
    name: 'Detail Class Series Change',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/:checkAccounting/invest/:basedate/add',
    name: 'Add New Invest',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/:fundTransId/:checkAccounting/invest/:basedate/edit/:fundTransactionItemId',
    name: 'Edit Invest',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/invest/:basedate/detail/:fundTransactionItemId',
    name: 'Detail Invest',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/:holdingId/redeem/:basedate/add',
    name: 'Add New Redeem',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/:holdingId/redeem/:basedate/edit/:fundTransactionItemId',
    name: 'Edit Redeem',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/:holdingId/redeem/:basedate/detail/:fundTransactionItemId',
    name: 'Detail Redeem',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/redeem/:basedate/detail/:fundTransactionItemId',
    name: 'Detail Redeem',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/other/:basedate/add',
    name: 'Add New Other',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/:fundTransactionId/other/:basedate/edit/:fundTransactionId',
    name: 'Edit Other',
  },
  {
    path: '/main/hf/port/txn/:accountId/:portfolio_id/:hedgeFundId/:fundClassId/other/:basedate/detail/:fundTransactionId',
    name: 'Detail Other',
  },
  {
    path: '/main/hf/port/portfolio-composition/:accountId/:portfolio_id',
    name: 'Portfolio Composition',
  },
  {
    path: '/main/hf/dataDuplication',
    name: 'Return Data Duplication'
  },
  {
    path: '/main/hf/nav',
    name: 'Hedge Fund NAV',
  },
  {
    path: '/main/hf/port/allocation/:accountId/:portfolio_id',
    name: 'Allocation Update'
  },
  //Analyze
  {
    path: '/main/analyze',
    name: 'Data Viewer'
  },
  {
    path: '/main/analyze/hfs',
    name: 'Hedge Fund'
  },
  {
    path: '/main/analyze/hf/:id',
    name: 'Hedge Fund',
  },
  {
    path: '/main/analyze/accts',
    name: 'Account'
  },
  {
    path: '/main/analyze/acct/:id',
    name: 'Account'
  },
  {
    path: '/main/analyze/investors',
    name: 'Investors'
  },
  {
    path: '/main/analyze/investor/:id',
    name: 'Investor'
  },
  {
    path: '/main/analyze/marketData',
    name: 'Market Data'
  },

  //Data Management
  {
    path: '/main/dataManagement',
    name: 'Data Management'
  },
  {
    path: '/main/dataManagement/marketData',
    name: 'Market Data'
  },
  {
    path: '/main/Account/Series',
    name: 'Account Series',
    index: ''
  },
  //Administration Menu
  {
    path: '/main/administration',
    name: 'Administration Menu'
  },
  {
    path: '/main/administration/marketData',
    name: 'Market Data'
  },
  {
    path: '/main/administration/users',
    name: 'List Users'
  },
    //AnalyticalTools
    {
      path: '/main/analytical',
      name: 'Analytical Tools'
    },
    {
      path: '/main/analytical/mtd_performance_report',
      name: 'MTD Performance Report'
    },
    {
      path: '/main/analytical/historical_download',
      name: 'Historical Data Download'
    },
    {
      path: '/main/analytical/monthly_fund_performance',
      name: 'Monthly Fund Performance Loader'
    },
    {
      path: '/main/analytical/strategy_allocation_contributions',
      name: 'Strategy Allocation WAR Contributions'
    },
    {
      path: '/main/analytical/monitoring_list',
      name: 'Monitoring List'
    },
    {
      path: '/main/analytical/historical_war',
      name: 'Historical WAR'
    },
    {
      path: '/main/analytical/allocation_map',
      name: 'Allocation Map'
    },
    {
      path: '/main/analytical/top_contributor',
      name: 'Top Contributor Detractor'
    },
    {
      path: '/main/analytical/redemption_schedule',
      name: 'Redemption Schedule'
    },
    {
      path: '/main/analytical/strategy_allocation_attribution',
      name: 'Strategy Allocation Attribution'
    },
]
