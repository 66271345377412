import {
  GET_LiST_ACCOUNT_NAV,
  // GET_LiST_ACCOUNT_NAV_FINAL_SUCCESS,
  GET_LiST_ACCOUNT_NAV_SUCCESS,
  GET_LIST_TRANSACTION_BY_AC_SERIES,
  BULK_SAVE_ACCOUNT_NAV_TOTAL,
  BULK_SAVE_ACCOUNT_NAV_TOTAL_SUCCESS,
  GET_EQ_CREDIT_ESTIMATE,
  GET_EQ_CREDIT_ESTIMATE_SUCCESS,
  GET_EQ_CREDIT_FINAL_SUCCESS,
  UPDATE_EQ_CREDIT_ESTIMATE_SUCCESS,
  UPDATE_EQ_CREDIT_ESTIMATE_ERROR,
  UPDATE_EQ_CREDIT_FINAL_ERROR,
  UPDATE_EQ_CREDIT_FINAL_SUCCESS,
  GET_VALUE_BASE_DATE_AC_NAV,
  GET_VALUE_ACCOUNT_AC_NAV,
  GET_LiST_DOUBLE_CHECK_NAV_SUCCESS,
  GET_VALUE_ACCOUNT_AC_DB_CHECK,
  GET_VALUE_BASE_DATE_AC_DB_CHECK,
  CHECK_LiST_DOUBLE_CHECK_NAV_SUCCESS,
  CHECK_LiST_DOUBLE_CHECK_NAV_ERROR,
  GET_DOUBLE_CHECK_TRACK_SUCCESS,
  GET_DOUBLE_CHECK_TRACK_ERROR,
  ALL_CHECK_DOUBLE_CHECK_TRACK_SUCCESS,
  ALL_CHECK_DOUBLE_CHECK_TRACK_ERROR,
  GET_EQ_CREDIT_FINAL,
  // GET_LIST_TRANSACTION_BY_AC_SERIES_SUCCESS
  // GET_LiST_ACCOUNT_NAV_ESTIMATE_SUCCESS
} from '../../constants/ActionTypes'
import { Ac_SeriesItem, EqCreditItem } from '../../dto'
import { AccountNAVTotalItem } from '../../dto'
import { AccountNAVStateDto } from './stateDtos'

interface ActionProps {
  type: string
  payload: AccountNAVTotalItem
  payloadEq: any
  valueBasedate: string
  valueAccount: any
  valueBasedateDc: string
  valueAccountDc: any
  data: AccountNAVTotalItem[]
  dataEqCreditEstimate: EqCreditItem[]
  dataEqCreditFinal: EqCreditItem[]
  dataTransaction: Ac_SeriesItem[]
}

const INIT_STATE: AccountNAVStateDto = {
  doubleCheckmounting: '',
  accountNAVtotal: [],
  eqCreditEstimate: [],
  eqCreditFinal: [],
  valueBasedate: '',
  valueAccount: null,
  valueBasedateDc: '',
  valueAccountDc: null,
}
const AccountNAVReducer = (
  state = INIT_STATE,
  action: ActionProps
): AccountNAVStateDto => {
  switch (action.type) {
    case GET_LiST_ACCOUNT_NAV: {
      return {
        ...state,
      }
    }
    case GET_LiST_ACCOUNT_NAV_SUCCESS: {
      return {
        ...state,
        accountNAVtotal: action.data,
      }
    }
    case GET_LiST_DOUBLE_CHECK_NAV_SUCCESS: {
      return {
        ...state,
        accountNAVtotal: action.data,
      }
    }
    case CHECK_LiST_DOUBLE_CHECK_NAV_SUCCESS: {
      return {
        ...state,
      }
    }
    case CHECK_LiST_DOUBLE_CHECK_NAV_ERROR: {
      return {
        ...state,
      }
    }
    case GET_DOUBLE_CHECK_TRACK_SUCCESS: {
      return {
        ...state,
        doubleCheckmounting: action.payload,
      }
    }
    case GET_DOUBLE_CHECK_TRACK_ERROR: {
      return {
        ...state,
      }
    }
    case ALL_CHECK_DOUBLE_CHECK_TRACK_SUCCESS: {
      return {
        ...state,
      }
    }
    case ALL_CHECK_DOUBLE_CHECK_TRACK_ERROR: {
      return {
        ...state,
      }
    }
    case GET_LIST_TRANSACTION_BY_AC_SERIES: {
      return {
        ...state,
      }
    }
    case GET_EQ_CREDIT_ESTIMATE: {
      return {
        ...state,
        eqCreditEstimate: []
      }
    }
    case GET_EQ_CREDIT_ESTIMATE_SUCCESS: {
      return {
        ...state,
        eqCreditEstimate: action.dataEqCreditEstimate,
      }
    }
    case GET_EQ_CREDIT_FINAL: {
      return {
        ...state,
        eqCreditEstimate: [],
      }
    }
    case GET_EQ_CREDIT_FINAL_SUCCESS: {
      return {
        ...state,
        eqCreditEstimate: action.dataEqCreditEstimate,
      }
    }
    case BULK_SAVE_ACCOUNT_NAV_TOTAL: {
      return {
        ...state,
      }
    }
    case BULK_SAVE_ACCOUNT_NAV_TOTAL_SUCCESS: {
      return {
        ...state,
      }
    }
    case UPDATE_EQ_CREDIT_ESTIMATE_SUCCESS: {
      return {
        ...state,
      }
    }
    case UPDATE_EQ_CREDIT_ESTIMATE_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_EQ_CREDIT_FINAL_SUCCESS: {
      return {
        ...state,
      }
    }
    case UPDATE_EQ_CREDIT_FINAL_ERROR: {
      return {
        ...state,
      }
    }
    case GET_VALUE_ACCOUNT_AC_NAV: {
      return {
        ...state,
        valueAccount: action.valueAccount,
      }
    }
    case GET_VALUE_BASE_DATE_AC_NAV: {
      return {
        ...state,
        valueBasedate: action.valueBasedate,
      }
    }
    case GET_VALUE_ACCOUNT_AC_DB_CHECK: {
      return {
        ...state,
        valueAccountDc: action.valueAccountDc,
      }
    }
    case GET_VALUE_BASE_DATE_AC_DB_CHECK: {
      return {
        ...state,
        valueBasedateDc: action.valueBasedateDc,
      }
    }
    default:
      return state
  }
}
export default AccountNAVReducer
