import {
  GET_BY_ID_DIVISION,
  GET_BY_ID_DIVISION_SUCCESS,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_DIVISION_ERROR,
  CREATE_DIVISION_SUCCESS,
  CREATE_DIVISION_ERROR,
  GET_LIST_DIVISION_SUCCESS,
  GET_LIST_DIVISION_BY_INVESTORID_SUCCESS,
  GET_LIST_DIVISION_BY_INVESTORID_ERROR,
} from '../../constants/ActionTypes'
import { DivisionItem } from '../../dto'
import { DivisionStateDto } from './stateDtos'

interface ActionProps {
  id: number
  type: string
  data: DivisionItem[]
  payload: DivisionItem
}
const INIT_STATE: DivisionStateDto = {
  id: 0,
  divisionList: [],
}
const DivisionReducer = (state = INIT_STATE, action: ActionProps): unknown => {
  switch (action.type) {
    case GET_LIST_DIVISION_SUCCESS: {
      return {
        ...state,
        divisionList: action.data,
      }
    }
    case GET_LIST_DIVISION_BY_INVESTORID_SUCCESS: {
      return {
        ...state,
        divisionList: action.data,
      }
    }
    case GET_LIST_DIVISION_BY_INVESTORID_ERROR: {
      return {
        ...state,
      }
    }
    case CREATE_DIVISION_SUCCESS: {
      const newList = [...state.divisionList]
      newList.push(action.payload)
      return {
        ...state,
        divisionList: newList,
      }
    }
    case CREATE_DIVISION_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_DIVISION_SUCCESS: {
      const newList = state.divisionList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        divisionList: newList,
        UpdateAlert: true,
      }
    }
    case UPDATE_DIVISION_ERROR: {
      return {
        ...state,
        UpdateAlert: true,
      }
    }
    case GET_BY_ID_DIVISION: {
      return {
        ...state,
        record: null,
      }
    }
    case GET_BY_ID_DIVISION_SUCCESS: {
      return {
        ...state,
        recordDivision: action.payload,
        id: action.id,
      }
    }
    default:
      return state
  }
}
export default DivisionReducer
