import {
  CREATE_WITH_INTEREST_RATE,
  CREATE_WITH_INTEREST_RATE_ERROR,
  CREATE_WITH_INTEREST_RATE_SUCCESS,
  GET_ALL_INTEREST_RATE,
  GET_ALL_INTEREST_RATE_AUD_SUCCESS,
  GET_ALL_INTEREST_RATE_ERROR,
  GET_ALL_INTEREST_RATE_EUR_SUCCESS,
  GET_ALL_INTEREST_RATE_GBP_SUCCESS,
  GET_ALL_INTEREST_RATE_HKD_SUCCESS,
  GET_ALL_INTEREST_RATE_JPY_SUCCESS,
  GET_ALL_INTEREST_RATE_SUCCESS,
  GET_ALL_INTEREST_RATE_USD_SUCCESS,
} from '../../constants/ActionTypes'
import { InterestRateItem } from '../../dto'
import { InterestRateDto } from './stateDtos'
interface ActionProps {
  type: string
  payload: InterestRateItem
  data: InterestRateItem[]
}

const INIT_STATE: InterestRateDto = {
  interestRateList: [],
  interestRateListUSD: [],
  interestRateListJPY: [],
  interestRateListEUR: [],
  interestRateListAUD: [],
  interestRateListGBP: [],
  interestRateListHKD: [],
}
const InterestRateReducer = (
  state = INIT_STATE,
  action: ActionProps
): InterestRateDto => {
  switch (action.type) {
    case CREATE_WITH_INTEREST_RATE: {
      return {
        ...state,
      }
    }
    case CREATE_WITH_INTEREST_RATE_SUCCESS: {
      const newList = [...state.interestRateList]
      newList.push(action.payload)
      return {
        ...state,
        interestRateList: newList,
      }
    }
    case CREATE_WITH_INTEREST_RATE_ERROR: {
      return {
        ...state,
      }
    }
    case GET_ALL_INTEREST_RATE: {
      return {
        ...state,
      }
    }
    case GET_ALL_INTEREST_RATE_SUCCESS: {
      return {
        ...state,
        interestRateList: action.data,
      }
    }
    case GET_ALL_INTEREST_RATE_USD_SUCCESS: {
      return {
        ...state,
        interestRateListUSD: action.data,
      }
    }
    case GET_ALL_INTEREST_RATE_JPY_SUCCESS: {
      return {
        ...state,
        interestRateListJPY: action.data,
      }
    }
    case GET_ALL_INTEREST_RATE_EUR_SUCCESS: {
      return {
        ...state,
        interestRateListEUR: action.data,
      }
    }
    case GET_ALL_INTEREST_RATE_GBP_SUCCESS: {
      return {
        ...state,
        interestRateListGBP: action.data,
      }
    }
    case GET_ALL_INTEREST_RATE_AUD_SUCCESS: {
      return {
        ...state,
        interestRateListAUD: action.data,
      }
    }
    case GET_ALL_INTEREST_RATE_HKD_SUCCESS: {
      return {
        ...state,
        interestRateListHKD: action.data,
      }
    }
    case GET_ALL_INTEREST_RATE_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default InterestRateReducer
