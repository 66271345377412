// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';
export const REQUIRED_PASS = 'REQUIRED_PASS';
export const LINK_SIGNIN_SUCCESS = 'LINK_SIGNIN_SUCCESS';
export const LINK_SIGNIN_FALSE = 'LINK_SIGNIN_FALSE';
export const GET_VALUE_ACCOUNT_INVESTOR = 'GET_VALUE_ACCOUNT_INVESTOR';
export const GET_VALUE_BASE_DATE_INVESTOR = 'GET_VALUE_BASEDATE_INVESTOR'
//Auth const
export const SIGNUP_USER = 'REGISTER SUCCESS, PLEASE CHECK EMAIL TO VERIFY!';
export const FORGOT_PASSWORD_SEND_CODE = 'SEND VERIFICATION CODE SUCCESS, PLEASE CHECK YOUR EMAIL!';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE PASSWORD SUCCESS';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';
export const SIGNIN_USER_ERROR = 'SIGNUP_USER_ERROR';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'LOGIN SUCCESS!';
export const SIGNIN_USER_INCORRECT = 'USERNAME OR PASSWORD INCORRECT!';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'LOGOUT SUCCESS!';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
//HF Return
export const PAGE_LIST_NUMBER = 'PAGE_LIST_NUMBER';
export const GET_LIST_HEDGE_FUND_RETURN = 'GET_LIST_HEDGE_FUND_RETURN';
export const GET_LIST_HEDGE_FUND_RETURN_SUCCESS = 'GET_LIST_HEDGE_FUND_RETURN_SUCCESS';
export const GET_LIST_HEDGE_FUND_RETURN_DEFAULT_SUCCESS = 'GET_LIST_HEDGE_FUND_RETURN_DEFAULT_SUCCESS';
export const GET_LIST_HEDGE_FUND_RETURN_DATA_INPUT_SUCCESS = 'GET_LIST_HEDGE_FUND_RETURN_DATA_INPUT_SUCCESS';
export const GET_LIST_HEDGE_FUND_RETURN_ERROR = 'GET_LIST_HEDGE_FUND_RETURN_ERROR';
export const GET_LIST_HF_HOLDING_LEVEL = 'GET_LIST_HF_HOLDING_LEVEL';
export const GET_LIST_HF_HOLDING_LEVEL_SUCCESS = 'GET_LIST_HF_HOLDING_LEVEL_SUCCESS';
export const GET_LIST_HF_HOLDING_LEVEL_ERROR = 'GET_LIST_HF_HOLDING_LEVEL_ERROR';
export const CREATE_INPUT_RETURN = 'CREATE_INPUT_RETURN';
export const CREATE_INPUT_RETURN_SUCCESS = 'CREATE_INPUT_RETURN_SUCCESS';
export const CREATE_INPUT_RETURN_ERROR = 'CREATE_INPUT_RETURN_ERROR';
export const BULK_SAVE_INPUT_RETURN = 'BULK_SAVE_INPUT_RETURN';
export const BULK_SAVE_INPUT_RETURN_SUCCESS = 'BULK_SAVE_INPUT_RETURN_SUCCESS';
export const BULK_SAVE_INPUT_RETURN_ERROR = 'BULK_SAVE_INPUT_RETURN_ERROR';
export const BULK_SAVE_HOLDING_LEVEL = 'BULK_SAVE_HOLDING_LEVEL';
export const BULK_SAVE_HOLDING_LEVEL_SUCCESS = 'BULK_SAVE_HOLDING_LEVEL_SUCCESS';
export const BULK_SAVE_HOLDING_LEVEL_ERROR = 'BULK_SAVE_HOLDING_LEVEL_ERROR';
export const GET_LIST_HEDGE_FUND_RETURN_DATA_CHECK_RESULT = 'GET_LIST_HEDGE_FUND_RETURN_DATA_CHECK_RESULT';
export const GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY = 'GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY';
export const GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY_SUCCESS = 'GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY_SUCCESS';
export const GET_VALUE_BASE_DATE_HF_RETURN = 'GET_VALUE_BASEDATE_HF_RETURN'

export const GET_LIST_HEDGE_FUND = 'GET_LIST_HEDGE_FUND';
export const GET_LIST_NAV_DOUBLE_CHECK = 'GET_LIST_NAV_DOUBLE_CHECK';
export const GET_LIST_INVESTOR_TRANSACTION = 'GET_LIST_INVESTOR_TRANSACTION';
export const GET_LIST_INVESTOR_TRANSACTION_ERROR = 'GET_LIST_INVESTOR_TRANSACTION_ERROR';
export const GET_LIST_INVESTOR_TRANSACTION_WITH_ID = 'GET_LIST_INVESTOR_TRANSACTION_WITH_ID';
export const GET_LIST_INVESTOR_TRANSACTION_WITH_ID_DEFAULT = 'GET_LIST_INVESTOR_TRANSACTION_WITH_ID_DEFAULT';
export const GET_LIST_INVESTOR_TRANSACTION_WITH_ID_ERROR = 'GET_LIST_INVESTOR_TRANSACTION_WITH_ID_ERROR';
export const GET_INVESTOR_WITH_TRANSACTION_ID = 'GET_INVESTOR_WITH_TRANSACTION_ID';
export const GET_INVESTOR_WITH_TRANSACTION_ID_SUCCESS = 'GET_INVESTOR_WITH_TRANSACTION_ID_SUCCESS';
export const GET_INVESTOR_WITH_TRANSACTION_ID_ERROR = 'GET_INVESTOR_WITH_TRANSACTION_ID_ERROR';
export const CREATE_AC_INVESTOR_TRANSACTION = 'CREATE_AC_INVESTOR_TRANSACTION';
export const CREATE_AC_INVESTOR_TRANSACTION_SUCCESS = 'CREATE_AC_INVESTOR_TRANSACTION_SUCCESS';
export const CREATE_AC_INVESTOR_TRANSACTION_ERROR = 'CREATE_AC_INVESTOR_TRANSACTION_ERROR';
export const UPDATE_AC_INVESTOR_TRANSACTION = 'UPDATE_AC_INVESTOR_TRANSACTION';
export const UPDATE_AC_INVESTOR_TRANSACTION_SUCCESS = 'UPDATE_AC_INVESTOR_TRANSACTION_SUCCESS';
export const UPDATE_AC_INVESTOR_TRANSACTION_ERROR = 'UPDATE_AC_INVESTOR_TRANSACTION_ERROR';
export const DELETE_AC_INVESTOR_TRANSACTION = 'DELETE_AC_INVESTOR_TRANSACTION';
export const DELETE_AC_INVESTOR_TRANSACTION_SUCCESS = 'DELETE_AC_INVESTOR_TRANSACTION_SUCCESS';
export const DELETE_AC_INVESTOR_TRANSACTION_ERROR = 'DELETE_AC_INVESTOR_TRANSACTION_ERROR';
export const CREATE_AC_REDEEM_TRANSACTION = 'CREATE_AC_REDEEM_TRANSACTION';
export const CREATE_AC_REDEEM_TRANSACTION_SUCCESS = 'CREATE_AC_REDEEM_TRANSACTION_SUCCESS';
export const CREATE_AC_REDEEM_TRANSACTION_ERROR = 'CREATE_AC_REDEEM_TRANSACTION_ERROR';
export const CREATE_AC_DIVIDEND_TRANSACTION_SUCCESS = 'CREATE_AC_DIVIDEND_TRANSACTION_SUCCESS';
export const CREATE_AC_DIVIDEND_TRANSACTION_ERROR = 'CREATE_AC_DIVIDEND_TRANSACTION_ERROR';
export const UPDATE_AC_DIVIDEND_TRANSACTION_SUCCESS = 'UPDATE_AC_DIVIDEND_TRANSACTION_SUCCESS';
export const UPDATE_AC_DIVIDEND_TRANSACTION_ERROR = 'UPDATE_AC_DIVIDEND_TRANSACTION_ERROR';
export const DELETE_AC_DIVIDEND_TRANSACTION_SUCCESS = 'DELETE_AC_DIVIDEND_TRANSACTION_SUCCESS';
export const DELETE_AC_DIVIDEND_TRANSACTION_ERROR = 'DELETE_AC_DIVIDEND_TRANSACTION_ERROR';
export const GET_ACCOUNT_TS_FUND_TXN = 'GET_ACCOUNT_TS_FUND_TXN';
export const GET_ACCOUNT_TS_FUND_TXN_SUCCESS = 'GET_ACCOUNT_TS_FUND_TXN_SUCCESS';
export const GET_ACCOUNT_TS_FUND_TXN_ERROR = 'GET_ACCOUNT_TS_FUND_TXN_ERROR';
export const GET_BY_ID_AC_DIVIDEND_TRANSACTION_SUCCESS = 'GET_BY_ID_AC_DIVIDEND_TRANSACTION_SUCCESS';
export const GET_BY_ID_AC_DIVIDEND_TRANSACTION_ERROR = 'GET_BY_ID_AC_DIVIDEND_TRANSACTION_ERROR';
export const GET_LIST_ACCOUNT_TRANSACTION = 'GET_LIST_ACCOUNT_TRANSACTION';
export const GET_NAV_FINAL_INVESTTXN = 'GET_NAV_FINAL_INVESTTXN';
export const GET_NAV_FINAL_INVESTTXN_SUCCESS = 'GET_NAV_FINAL_INVESTTXN_SUCCESS';
export const GET_WEIGHTEDAVERAGEDATA = 'GET_WEIGHTEDAVERAGEDATA';
export const GET_NAVDATA = 'GET_NAVDATA';
//Master Investor
export const GET_LiST_MASTER_INVESTOR = 'GET_LiST_MASTER_INVESTOR';
//Account NAV Final
export const GET_LiST_ACCOUNT_NAV = 'GET_LiST_ACCOUNT_NAV';
export const GET_LiST_ACCOUNT_NAV_FINAL_SUCCESS = 'GET_LiST_ACCOUNT_NAV_FINAL_SUCCESS';
export const GET_LiST_ACCOUNT_NAV_ESTIMATE_SUCCESS = 'GET_LiST_ACCOUNT_NAV_ESTIMATE_SUCCESS';
export const GET_LiST_ACCOUNT_NAV_SUCCESS = 'GET_LiST_ACCOUNT_NAV_SUCCESS';
export const GET_LiST_ACCOUNT_NAV_ERROR = 'GET_LiST_ACCOUNT_NAV_ERROR';
export const GET_LiST_DOUBLE_CHECK_NAV_SUCCESS = 'GET_LiST_DOUBLE_CHECK_NAV_SUCCESS';
export const GET_LiST_DOUBLE_CHECK_NAV_ERROR = 'GET_LiST_DOUBLE_CHECK_NAV_ERROR';
export const ALL_CHECK_DOUBLE_CHECK_TRACK_SUCCESS = 'ALL_CHECK_DOUBLE_CHECK_TRACK_SUCCESS';
export const ALL_CHECK_DOUBLE_CHECK_TRACK_ERROR = 'ALL_CHECK_DOUBLE_CHECK_TRACK_ERROR';
export const GET_DOUBLE_CHECK_TRACK_SUCCESS = 'GET_DOUBLE_CHECK_TRACK_SUCCESS';
export const GET_DOUBLE_CHECK_TRACK_ERROR = 'GET_DOUBLE_CHECK_TRACK_ERROR';
export const CHECK_LiST_DOUBLE_CHECK_NAV_SUCCESS = 'CHECK_LiST_DOUBLE_CHECK_NAV_SUCCESS';
export const CHECK_LiST_DOUBLE_CHECK_NAV_ERROR = 'CHECK_LiST_DOUBLE_CHECK_NAV_ERROR';
export const GET_VALUE_ACCOUNT_AC_NAV = 'GET_VALUE_ACCOUNT_AC_NAV';
export const GET_VALUE_BASE_DATE_AC_NAV = 'GET_VALUE_BASE_DATE_AC_NAV';
export const GET_VALUE_ACCOUNT_AC_DB_CHECK = 'GET_VALUE_ACCOUNT_AC_DB_CHECK';
export const GET_VALUE_BASE_DATE_AC_DB_CHECK = 'GET_VALUE_BASE_DATE_AC_DB_CHECK';
export const BULK_SAVE_ACCOUNT_NAV_TOTAL = 'BULK_SAVE_ACCOUNT_NAV_TOTAL';
export const BULK_SAVE_ACCOUNT_NAV_TOTAL_SUCCESS = 'BULK_SAVE_ACCOUNT_NAV_TOTAL_SUCCESS';
export const BULK_SAVE_ACCOUNT_NAV_TOTAL_ERROR = 'BULK_SAVE_ACCOUNT_NAV_TOTAL_ERROR';
export const GET_EQ_CREDIT_ESTIMATE = 'GET_EQ_CREDIT_ESTIMATE';
export const GET_EQ_CREDIT_ESTIMATE_SUCCESS = 'GET_EQ_CREDIT_ESTIMATE_SUCCESS';
export const GET_EQ_CREDIT_FINAL = 'GET_EQ_CREDIT_FINAL';
export const GET_EQ_CREDIT_FINAL_SUCCESS = 'GET_EQ_CREDIT_FINAL_SUCCESS';
export const UPDATE_EQ_CREDIT_FINAL_SUCCESS = 'UPDATE_EQ_CREDIT_FINAL_SUCCESS';
export const UPDATE_EQ_CREDIT_FINAL_ERROR = 'UPDATE_EQ_CREDIT_FINAL_ERROR';
export const UPDATE_EQ_CREDIT_ESTIMATE_SUCCESS = 'UPDATE_EQ_CREDIT_ESTIMATE_SUCCESS';
export const UPDATE_EQ_CREDIT_ESTIMATE_ERROR = 'UPDATE_EQ_CREDIT_ESTIMATE_ERROR';
//Organization Master
export const SAVE_LIST_ORGANIZATION = 'SAVE_LIST_ORGANIZATION';
export const GET_LIST_ORGANIZATION = 'GET_LIST_ORGANIZATION';
export const GET_LIST_ORGANIZATION_SUCCESS = 'GET_LIST_ORGANIZATION_SUCCESS';
export const GET_LIST_ORGANIZATION_ERROR = 'GET_LIST_ORGANIZATION_ERROR';
export const GET_BY_ID_ORGANIZATION = 'GET_BY_ID_ORGANIZATION';
export const GET_BY_ID_ORGANIZATION_SUCCESS = 'GET_BY_ID_ORGANIZATION_SUCCESS';
export const PAGE_NUMBER_ORGAN = 'PAGE_NUMBER_ORGAN';
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const GET_LIST_ORGANIZATION_DEFAULT = 'GET_LIST_ORGANIZATION_DEFAULT';
export const GET_LIST_ORGANIZATION_DEFAULT_SUCCESS = 'GET_LIST_ORGANIZATION_DEFAULT_SUCCESS';
//HedgeFund Master
export const PAGE_NUMBER_HF = 'PAGE_NUMBER_HF';
export const GET_LIST_HEDGEFUND = 'GET_LIST_HEDGEFUND';
export const GET_LIST_HEDGEFUND_SUCCESS = 'GET_LIST_HEDGEFUND_SUCCESS';
export const GET_LIST_HEDGEFUND_ACTIVE = 'GET_LIST_HEDGEFUND_ACTIVE';
export const GET_LIST_HEDGEFUND_DEFAULT = 'GET_LIST_HEDGEFUND_DEFAULT';
export const GET_LIST_HEDGEFUND_ERROR = 'GET_LIST_HEDGEFUND_ERROR';
export const CREATE_HEDGEFUND_SUCCESS = 'CREATE_HEDGEFUND_SUCCESS';
export const CREATE_HEDGEFUND_ERROR = 'CREATE_HEDGEFUND_ERROR'
export const GET_BY_ID_HEDGEFUND = 'GET_BY_ID_HEDGEFUND';
export const GET_BY_ID_HEDGEFUND_SUCCESS = 'GET_BY_ID_HEDGEFUND_SUCCESS';
export const GET_BY_ID_HEDGEFUND_ERROR = 'GET_BY_ID_HEDGEFUND_ERROR';
export const UPDATE_HEDGEFUND = 'UPDATE_HEDGEFUND'
export const UPDATE_HEDGEFUND_SUCCESS = 'UPDATE_HEDGEFUND_SUCCESS'
export const UPDATE_HEDGEFUND_ERROR = 'UPDATE_HEDGEFUND_ERROR'
export const SAVE_LIST_HEDGEFUND_CLASS = 'SAVE_LIST_HEDGEFUND_CLASS'
export const SAVE_LIST_HEDGEFUND_HISTORICAL = 'SAVE_LIST_HEDGEFUND_HISTORICAL'

//HF Historical
export const GET_BY_ID_HF_HISTORICAL = 'GET_BY_ID_HF_HISTORICAL'
export const GET_BY_ID_HF_HISTORICAL_SUCCESS = 'GET_BY_ID_HF_HISTORICAL_SUCCESS'
export const GET_BY_ID_HF_HISTORICAL_ERROR = 'GET_BY_ID_HF_HISTORICAL_ERROR'
export const UPDATE_BULK_HF_HISTORICAL = 'UPDATE_BULK_HF_HISTORICAL'
export const UPDATE_BULK_HF_HISTORICAL_SUCCESS = 'UPDATE_BULK_HF_HISTORICAL_SUCCESS'
export const UPDATE_BULK_HF_HISTORICAL_ERROR = 'UPDATE_BULK_HF_HISTORICAL_ERROR'
//Strategy
export const GET_LIST_STRATEGY = 'GET_LIST_STRATEGY'
export const GET_LIST_STRATEGY_SUCCESS = 'GET_LIST_STRATEGY_SUCCESS'
export const GET_LIST_STRATEGY_DEFAULT = 'GET_LIST_STRATEGY_DEFAULT'
export const GET_LIST_STRATEGY_ERROR = 'GET_LIST_STRATEGY_ERROR'
//Class
export const GET_LIST_HEDGEFUND_CLASS = 'GET_LIST_HEDGEFUND_CLASS'
export const GET_LIST_HEDGEFUND_CLASS_SUCCESS = 'GET_LIST_HEDGEFUND_CLASS_SUCCESS'
export const GET_LIST_HEDGEFUND_CLASS_ERROR = 'GET_LIST_HEDGEFUND_CLASS_ERROR'
export const GET_BY_ID_CLASS = 'GET_BY_ID_CLASS';
export const GET_BY_ID_CLASS_SUCCESS = 'GET_BY_ID_CLASS_SUCCESS';
export const GET_BY_ID_CLASS_ERROR = 'GET_BY_ID_CLASS_ERROR';
export const GET_SERIES_BY_HEDGEFUND_ID_SUCCESS = 'GET_SERIES_BY_HEDGEFUND_ID_SUCCESS';
export const GET_SERIES_BY_HEDGEFUND_ID_ERROR = 'GET_SERIES_BY_HEDGEFUND_ID_ERROR';
export const CREATE_CLASS = 'CREATE_CLASS';
export const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS';
export const CREATE_CLASS_ERROR = 'CREATE_CLASS_ERROR';
export const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCESS';
export const UPDATE_CLASS_ERROR = 'UPDATE_CLASS_ERROR';
export const GET_ALL_CLASS_BY_ACCOUNT_ID = 'GET_ALL_CLASS_BY_ACCOUNT_ID';
export const GET_ALL_CLASS_BY_HEDGEFUND_ID = 'GET_ALL_CLASS_BY_HEDGEFUND_ID';
export const GET_LIST_ACTIVE_ACCOUNTS = 'GET_LIST_ACTIVE_ACCOUNTS';
export const GET_LIST_ACTIVE_ACCOUNTS_SUCCESS = 'GET_LIST_ACTIVE_ACCOUNTS_SUCCESS';
export const GET_LIST_ACTIVE_ACCOUNTS_MASTER_SUCCESS = 'GET_LIST_ACTIVE_ACCOUNTS_MASTER_SUCCESS';
export const GET_LIST_ACTIVE_ACCOUNTS_ERROR = 'GET_LIST_ACTIVE_ACCOUNTS_ERROR';
export const DELETE_CLASS = 'DELETE_CLASS';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_ERROR = 'DELETE_CLASS_ERROR'
//HF Series
export const GET_LIST_HEDGEFUND_SERIES = 'GET_LIST_HEDGEFUND_SERIES';
export const GET_LIST_HEDGEFUND_SERIES_DEFAULT = 'GET_LIST_HEDGEFUND_SERIES_DEFAULT';
export const GET_LIST_HEDGEFUND_SERIES_SUCCESS = 'GET_LIST_HEDGEFUND_SERIES_SUCCESS';
export const GET_LIST_HEDGEFUND_SERIES_DEFAULT_SUCCESS = 'GET_LIST_HEDGEFUND_DEFAULT_SERIES_SUCCESS';
export const GET_LIST_HEDGEFUND_SERIES_ERROR = 'GET_LIST_HEDGEFUND_SERIES_ERROR';
export const GET_BY_ID_HEDGEFUND_SERIES = 'GET_BY_ID_HEDGEFUND_SERIES';
export const GET_BY_ID_HEDGEFUND_SERIES_SUCCESS = 'GET_BY_ID_HEDGEFUND_SERIES_SUCCESS';
export const GET_BY_ID_HEDGEFUND_SERIES_ERROR = 'GET_BY_ID_HEDGEFUND_SERIES_ERROR';
export const UPDATE_HEDGEFUND_SERIES_SUCCESS = 'UPDATE_HEDGEFUND_SERIES_SUCCESS';
export const UPDATE_HEDGEFUND_SERIES_ERROR = 'UPDATE_HEDGEFUND_SERIES_ERROR';
export const CREATE_HEDGEFUND_SERIES_SUCCESS = 'CREATE_HEDGEFUND_SERIES_SUCCESS';
export const CREATE_HEDGEFUND_SERIES_ERROR = 'CREATE_HEDGEFUND_SERIES_ERROR'
//Portfolio
export const UPDATE_PORTFOLOO_ERROR = 'UPDATE_PORTFOLIO_ERROR'
export const GET_BY_ID_PORTFOLIO = 'GET_BY_ID_PORTFOLIO';
export const GET_BY_ID_PORTFOLIO_SUCCESS = 'GET_BY_ID_PORTFOLIO_SUCCESS';
export const GET_PORTFOLIO_BY_ACCOUNT_ID = 'GET_PORTFOLIO_BY_ACCOUNT_ID';
export const GET_PORTFOLIO_BY_ACCOUNT_ID_SUCCESS = 'GET_PORTFOLIO_BY_ACCOUNT_ID_SUCCESS';
export const UPDATE_PORTFOLIO_HOLDING_SUCCESS = 'UPDATE_PORTFOLIO_HOLDING_SUCCESS';
export const UPDATE_PORTFOLIO_HOLDING_ERROR = 'UPDATE_PORTFOLIO_HOLDING_ERROR';
export const GET_BY_ID_PORTFOLIO_ERROR = 'GET_BY_ID_PORTFOLIO_ERROR';
export const GET_LIST_PORTFOLIO = 'GET_LIST_PORTFOLIO'
export const GET_LIST_PORTFOLIO_SUCCESS = 'GET_LIST_PORTFOLIO_SUCCESS'
export const CREATE_PORTFOLIO_SUCCESS = 'CREATE_PORTFOLIO_SUCCESS'
export const CREATE_PORTFOLIO_ERROR = 'CREATE_PORTFOLIO_ERROR'
export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO'
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_ERROR = 'UPDATE_PORTFOLIO_ERROR'
//Series
export const UPDATE_SERIES_SUCCESS = 'UPDATE_SERIES_SUCCESS'
export const UPDATE_SERIES_ERROR = 'UPDATE_SERIES_ERROR'
export const GET_BY_ID_SERIES = 'GET_BY_ID_SERIES';
export const GET_BY_ID_SERIES_SUCCESS = 'GET_BY_ID_SERIES_SUCCESS';
export const GET_BY_ID_SERIES_ERROR = 'GET_BY_ID_SERIES_ERROR';
export const GET_SERIES_BY_ACCOUNT_ID_SUCCESS = 'GET_SERIES_BY_ACCOUNT_ID_SUCCESS';
export const GET_SERIES_BY_ACCOUNT_ID = 'GET_SERIES_BY_ACCOUNT_ID';
export const GET_LIST_TRANSACTION_BY_AC_SERIES = 'GET_LIST_TRANSACTION_BY_AC_SERIES';
export const GET_LIST_TRANSACTION_BY_AC_SERIES_SUCCESS = 'GET_LIST_TRANSACTION_BY_AC_SERIES_SUCCESS';
export const GET_LIST_SERIES_BY_ACCT_CLASSES = 'GET_LIST_SERIES_BY_ACCT_CLASSES';
export const GET_LIST_SERIES_BY_ACCT_CLASSES_SUCCESS = 'GET_LIST_SERIES_BY_ACCT_CLASSES_SUCCESS';
export const GET_LIST_ACCOUNT_SERIES = 'GET_LIST_ACCOUNT_SERIES'
export const GET_LIST_ACCOUNT_SERIES_SUCCESS = 'GET_LIST_ACCOUNT_SERIES_SUCCESS'
export const GET_LIST_ACCOUNT_SERIES_ERROR = 'GET_LIST_ACCOUNT_SERIES_ERROR'
export const CREATE_SERIES_SUCCESS = ' CREATE_SERIES_SUCCESS'
export const CREATE_SERIES_ERROR = ' CREATE_SERIES_ERROR';
export const DELETE_SERIES = 'DELETE_SERIES';
export const DELETE_SERIES_SUCCESS = 'DELETE_SERIES_SUCCESS';
export const DELETE_SERIES_ERROR = 'DELETE_SERIES_ERROR';
//Account Class
export const GET_BY_ID_ACCOUNT_CLASS = 'GET_BY_ID_ACCOUNT_CLASS';
export const GET_BY_ID_ACCOUNT_CLASS_SUCCESS = 'GET_BY_ID_ACCOUNT_CLASS_SUCCESS';
export const GET_BY_ID_ACCOUNT_CLASS_ERROR = 'GET_BY_ID_ACCOUNT_CLASS_ERROR';
export const UPDATE_ACCOUNT_CLASS_SUCCESS = 'UPDATE_ACCOUNT_CLASS_SUCCESS';
export const UPDATE_ACCOUNT_CLASS_ERROR = 'UPDATE_ACCOUNT_CLASS_ERROR';
export const CREATE_ACCOUNT_CLASS_SUCCESS = 'CREATE_ACCOUNT_CLASS_SUCCESS';
export const CREATE_ACCOUNT_CLASS_ERROR = 'CREATE_ACCOUNT_CLASS_ERROR'
export const DELETE_ACCOUNT_CLASS = 'DELETE_ACCOUNT_CLASS'
export const DELETE_ACCOUNT_CLASS_ERROR = 'DELETE_ACCOUNT_CLASS_ERROR'
export const DELETE_ACCOUNT_CLASS_SUCCESS = 'DELETE_ACCOUNT_CLASS_SUCCESS'
//Account master
export const PAGE_NUMBER_ACC = 'PAGE_NUMBER_ACC';
export const GET_LIST_ACCOUNT = 'GET_LIST_ACCOUNT';
export const GET_LIST_ACCOUNT_SUCCESS = 'GET_LIST_ACCOUNT_SUCCESS';
export const GET_LIST_ACCOUNT_ERROR = 'GET_LIST_ACCOUNT_ERROR';
export const GET_LIST_ACCOUNT_DEFAULT_SUCCESS = 'GET_LIST_ACCOUNT_DEFAULT_SUCCESS';
export const SAVE_LIST_ACCOUNT = 'SAVE_LIST_ACCOUNT';
export const CREATE_ACCOUNT = ' CREATE_ACCOUT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const GET_BY_ID_ACCOUNT = 'GET_BY_ID_ACCOUNT';
export const GET_BY_ID_ACCOUNT_SUCCESS = 'GET_BY_ID_ACCOUNT_SUCCESS';
export const GET_BY_ID_ACCOUNT_ERROR = 'GET_BY_ID_ACCOUNT_ERROR';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';
//City
export const GET_LIST_CITY = 'GET_LIST_CITY'
export const GET_LIST_CITY_SUCCESS = 'GET_LIST_CITY_SUCCESS'
export const GET_LIST_CITY_ERROR = 'GET_LIST_CITY_ERROR'
//Investor Master
export const PAGE_NUMBER_INV = 'PAGE_NUMBER_INV'
export const GET_LIST_INVESTOR = 'GET_LIST_INVESTOR'
export const GET_LIST_INVESTOR_ACTIVE = 'GET_LIST_INVESTOR_ACTIVE'
export const GET_LIST_INVESTOR_SUCCESS = 'GET_LIST_INVESTOR_SUCCESS'
export const GET_LIST_INVESTOR_DEFAULT = 'GET_LIST_INVESTOR_DEFAULT'
export const GET_LIST_INVESTOR_ERROR = 'GET_LIST_INVESTOR_ERROR'
export const GET_BY_ID_INVESTOR = 'GET_BY_ID_INVESTOR'
export const GET_BY_ID_INVESTOR_SUCCESS = 'GET_BY_ID_INVESTOR_SUCCESS'
export const GET_BY_ID_INVESTOR_ERROR = 'GET_BY_ID_INVESTOR_ERROR'
export const CREATE_INVESTOR_SUCCESS = 'CREATE_INVESTOR_SUCCESS'
export const CREATE_INVESTOR_ERROR = 'CREATE_INVESTOR_ERROR'
export const UPDATE_INVESTOR = 'UPDATE_INVESTOR'
export const UPDATE_INVESTOR_SUCCESS = 'UPDATE_INVESTOR_SUCCESS'
export const UPDATE_INVESTOR_ERROR = 'UPDATE_INVESTOR_ERROR'
//Division Master
export const GET_BY_ID_DIVISION = 'GET_BY_ID_DIVISION'
export const GET_BY_ID_DIVISION_SUCCESS = 'GET_BY_ID_DIVISION_SUCCESS'
export const GET_BY_ID_DIVISION_ERROR = 'GET_BY_ID_DIVISION_ERROR'
export const GET_LIST_DIVISION_BY_INVESTORID_SUCCESS = 'GET_LIST_DIVISION_BY_INVESTORID_SUCCESS'
export const GET_LIST_DIVISION_BY_INVESTORID_ERROR = 'GET_LIST_DIVISION_BY_INVESTORID_ERROR'
export const GET_LIST_DIVISION_SUCCESS = 'GET_LIST_DIVISION_SUCCESS'
export const GET_LIST_DIVISION_ERROR = 'GET_LIST_DIVISION_ERROR'
export const CREATE_DIVISION_SUCCESS = 'CREATE_DIVISION_SUCCESS'
export const CREATE_DIVISION_ERROR = 'CREATE_DIVISION_ERROR'
export const UPDATE_DIVISION_SUCCESS = 'UPDATE_DIVISION_SUCCESS'
export const UPDATE_DIVISION_ERROR = 'UPDATE_DIVISION_ERROR'
//HedgeFund NAV
export const GET_VALUE_ACCOUNT_HF_NAV = 'GET_VALUE_ACCOUNT_HF_NAV';
export const GET_VALUE_PORTFOLIO_HF_NAV = 'GET_VALUE_PORTFOLIO_HF_NAV';
export const GET_VALUE_BASE_DATE_HF_NAV = 'GET_VALUE_BASE_DATE_HF_NAV';
export const GET_VALUE_ACCOUNT_HF_NAV_DC = 'GET_VALUE_ACCOUNT_HF_NAV_DC';
export const GET_VALUE_PORTFOLIO_HF_NAV_DC = 'GET_VALUE_PORTFOLIO_HF_NAV_DC';
export const GET_VALUE_BASE_DATE_HF_NAV_DC = 'GET_VALUE_BASE_DATE_HF_NAV_DC';
export const GET_LIST_HEDGEFUND_NAV_SUCCESS = 'GET_LIST_HEDGEFUND_NAV_SUCCESS';
export const GET_LIST_HEDGEFUND_NAV_ERROR = 'GET_LIST_HEDGEFUND_NAV_ERROR';
export const CHECK_LIST_HEDGEFUND_NAV_DC_SUCCESS = 'CHECK_LIST_HEDGEFUND_NAV_DC_SUCCESS';
export const CHECK_LIST_HEDGEFUND_NAV_DC_ERROR = 'CHECK_LIST_HEDGEFUND_NAV_DC_ERROR';
export const GET_LIST_HEDGEFUND_NAV_DC_SUCCESS = 'GET_LIST_HEDGEFUND_NAV_DC_SUCCESS';
export const GET_LIST_HEDGEFUND_NAV_DC_ERROR = 'GET_LIST_HEDGEFUND_NAV_DC_ERROR';
export const SAVE_LIST_HEDGEFUND_NAV_SUCCESS = 'SAVE_LIST_HEDGEFUND_NAV_SUCCESS';
export const SAVE_LIST_HEDGEFUND_NAV_ERROR = 'SAVE_LIST_HEDGEFUND_NAV_ERROR';
export const SAVE_LIST_HOLDING_FUND = 'SAVE_LIST_HOLDING_FUND'
//Allocation
export const SAVE_LIST_PORTFOLIO_ALLOCATION = 'SAVE_LIST_PORTFOLIO_ALLOCATION'

//Sysuser
export const GET_LIST_SYSUSER = 'GET_LIST_SYSUSER'
export const GET_LIST_SYSUSER_SUCCESS = 'GET_LIST_SYSUSER_SUCCESS'
export const GET_LIST_SYSUSER_DEFAULT_SUCCESS = 'GET_LIST_SYSUSER_DEFAULT_SUCCESS'
export const GET_LIST_SYSUSER_ERROR = 'GET_LIST_SYSUSER_ERROR'
export const CREATE_SYSUSER = 'CREATE_SYSUSER'
export const CREATE_SYSUSER_SUCCESS = 'CREATE_SYSUSER_SUCCESS'
export const CREATE_SYSUSER_ERROR = 'CREATE_SYSUSER_ERROR'
export const CREATE_LOGIN_SYSUSER_SUCCESS = 'CREATE_LOGIN_SYSUSER_SUCCESS'
export const CREATE_LOGIN_SYSUSER_ERROR = 'CREATE_SYSUSER_ERROR_ERROR'
export const UPDATE_SYSUSER_SUCCESS = 'UPDATE_SYSUSER_SUCCESS'
export const UPDATE_SYSUSER_ERROR = 'UPDATE_SYSUSER_ERROR'
export const SYSUSER_PROFILE_SUCCESS = 'SYSUSER_PROFILE_SUCCESS'
export const SYSUSER_PROFILE_ERROR = 'SYSUSER_PROFILE_ERROR'
export const GET_AVATAR_PROFILE_SUCCESS = 'GET_AVATAR_PROFILE_SUCCESS'
export const GET_AVATAR_PROFILE_ERROR = 'GET_AVATAR_PROFILE_ERROR'

//MarketData
export const GET_LIST_WITH_TICKER = 'GET_LIST_WITH_TICKER'
export const GET_LIST_WITH_TICKER_SUCCESS = 'GET_LIST_WITH_TICKER_SUCCESS'
export const GET_LIST_WITH_TICKER_ERROR = 'GET_LIST_WITH_TICKER_ERROR'
export const CREATE_WITH_TICKER = 'CREATE_WITH_TICKER'
export const CREATE_WITH_TICKER_SUCCESS = 'CREATE_WITH_TICKER_SUCCESS'
export const CREATE_WITH_TICKER_ERROR = 'CREATE_WITH_TICKER_ERROR'
export const UPDATE_WITH_TICKER = 'UPDATE_WITH_TICKER'
export const UPDATE_WITH_TICKER_SUCCESS = 'UPDATE_WITH_TICKER_SUCCESS'
export const UPDATE_WITH_TICKER_ERROR = 'UPDATE_WITH_TICKER_ERROR'
export const FIND_SYSTEM_CANDIDATE = 'FIND_SYSTEM_CANDIDATE'
export const FIND_SYSTEM_CANDIDATE_SUCCESS_USD = 'FIND_SYSTEM_CANDIDATE_SUCCESS_USD'
export const FIND_SYSTEM_CANDIDATE_SUCCESS_JPY = 'FIND_SYSTEM_CANDIDATE_SUCCESS_JPY'
export const FIND_SYSTEM_CANDIDATE_SUCCESS_EUR = 'FIND_SYSTEM_CANDIDATE_SUCCESS_EUR'
export const FIND_SYSTEM_CANDIDATE_SUCCESS_GBP = 'FIND_SYSTEM_CANDIDATE_SUCCESS_GBP'
export const FIND_SYSTEM_CANDIDATE_SUCCESS_AUD = 'FIND_SYSTEM_CANDIDATE_SUCCESS_AUD'
export const FIND_SYSTEM_CANDIDATE_SUCCESS_HKD = 'FIND_SYSTEM_CANDIDATE_SUCCESS_HKD'
export const FIND_SYSTEM_CANDIDATE_ERROR = 'FIND_SYSTEM_CANDIDATE_ERROR'

export const GET_ALL_INTEREST_RATE = 'GET_ALL_INTEREST_RATE'
export const GET_ALL_INTEREST_RATE_SUCCESS = 'GET_ALL_INTEREST_RATE_SUCCESS'
export const GET_ALL_INTEREST_RATE_USD_SUCCESS = 'GET_ALL_INTEREST_RATE_USD_SUCCESS'
export const GET_ALL_INTEREST_RATE_JPY_SUCCESS = 'GET_ALL_INTEREST_RATE_JPY_SUCCESS'
export const GET_ALL_INTEREST_RATE_EUR_SUCCESS = 'GET_ALL_INTEREST_RATE_EUR_SUCCESS'
export const GET_ALL_INTEREST_RATE_GBP_SUCCESS = 'GET_ALL_INTEREST_RATE_GBP_SUCCESS'
export const GET_ALL_INTEREST_RATE_AUD_SUCCESS = 'GET_ALL_INTEREST_RATE_AUD_SUCCESS'
export const GET_ALL_INTEREST_RATE_HKD_SUCCESS = 'GET_ALL_INTEREST_RATE_HKD_SUCCESS'
export const GET_ALL_INTEREST_RATE_ERROR = 'GET_ALL_INTEREST_RATE_ERROR'
export const CREATE_WITH_INTEREST_RATE = 'CREATE_WITH_INTEREST_RATE'
export const CREATE_WITH_INTEREST_RATE_SUCCESS = 'CREATE_WITH_INTEREST_RATE_SUCCESS'
export const CREATE_WITH_INTEREST_RATE_ERROR = 'CREATE_WITH_INTEREST_RATE_ERROR'

export const GET_ALL_FX_RATE = 'GET_ALL_FX_RATE'
export const GET_ALL_FX_RATE_SUCCESS = 'GET_ALL_FX_RATE_SUCCESS'
export const GET_ALL_FX_RATE_USD_SUCCESS = 'GET_ALL_FX_RATE_USD_SUCCESS'
export const GET_ALL_FX_RATE_JPY_SUCCESS = 'GET_ALL_FX_RATE_JPY_SUCCESS'
export const GET_ALL_FX_RATE_EUR_SUCCESS = 'GET_ALL_FX_RATE_EUR_SUCCESS'
export const GET_ALL_FX_RATE_AUD_SUCCESS = 'GET_ALL_FX_RATE_AUD_SUCCESS'
export const GET_ALL_FX_RATE_GBP_SUCCESS = 'GET_ALL_FX_RATE_GBP_SUCCESS'
export const GET_ALL_FX_RATE_HKD_SUCCESS = 'GET_ALL_FX_RATE_HKD_SUCCESS'
export const GET_ALL_FX_RATE_ERROR = 'GET_ALL_FX_RATE_ERROR'
export const CREATE_WITH_FX_RATE = 'CREATE_WITH_FX_RATE'
export const CREATE_WITH_FX_RATE_SUCCESS = 'CREATE_WITH_FX_RATE_SUCCESS'
export const CREATE_WITH_FX_RATE_ERROR = 'CREATE_WITH_FX_RATE_ERROR'
export const UPDATE_WITH_FX_RATE_SUCCESS = 'UPDATE_WITH_FX_RATE_SUCCESS'
export const UPDATE_WITH_FX_RATE_ERROR = 'UPDATE_WITH_FX_RATE_ERROR'

//Portfolio Transaction
export const GET_VALUE_BASE_DATE_PORT_TS = 'GET_VALUE_BASE_DATE_PORT_TS'
export const PAGE_NUMBER_PORT_TXN = 'PAGE_NUMBER_PORT_TXN'
export const GET_LIST_HF_TXN_WITH_BASE_DATE = 'GET_LIST_HF_TXN_WITH_BASE_DATE'
export const GET_LIST_HF_TXN_WITH_BASE_DATE_SUCCESS = 'GET_LIST_HF_TXN_WITH_BASE_DATE_SUCCESS'
export const GET_LIST_HF_TXN_WITH_BASE_DATE_ERROR = 'GET_LIST_HF_TXN_WITH_BASE_DATE_ERROR'
export const GET_LIST_HF_TXN_WITH_BASE_DATE_DEFAULT_SUCCESS = 'GET_LIST_HF_TXN_WITH_BASE_DATE_DEFAULT_SUCCESS'
export const CREATE_HEDGEFUND_TXN = 'CREATE_HEDGEFUND_TXN'
export const CREATE_HEDGEFUND_TXN_SUCCESS = 'CREATE_HEDGEFUND_TXN_SUCCESS'
export const BULK_UPDATE_HEDGEFUND_TXN_SUCCESS = 'BULK_UPDATE_HEDGEFUND_TXN_SUCCESS'
export const CREATE_HEDGEFUND_TXN_ERROR = 'CREATE_HEDGEFUND_TXN_ERROR'
export const BULK_UPDATE_HEDGEFUND_TXN_ERROR = 'BULK_UPDATE_HEDGEFUND_TXN_ERROR'
export const UPDATE_HEDGEFUND_TXN = 'UPDATE_HEDGEFUND_TXN'
export const UPDATE_HEDGEFUND_TXN_SUCCESS = 'UPDATE_HEDGEFUND_TXN_SUCCESS'
export const UPDATE_HEDGEFUND_TXN_ERROR = 'UPDATE_HEDGEFUND_TXN_ERROR'
export const DELETE_HEDGEFUND_TXN_SUCCESS = 'DELETE_HEDGEFUND_TXN_SUCCESS'
export const DELETE_HEDGEFUND_TXN_ERROR = 'DELETE_HEDGEFUND_TXN_ERROR'
export const GET_BY_ID_HEDGEFUND_TXN = 'GET_BY_ID_HEDGEFUND_TXN'
export const GET_BY_ID_HEDGEFUND_TXN_SUCCESS = 'GET_BY_ID_HEDGEFUND_TXN_SUCCESS'
export const GET_CLS_FUND_TXN_HEDGEFUND_TXN = 'GET_CLS_FUND_TXN_HEDGEFUND_TXN'
export const GET_CLS_FUND_TXN_HEDGEFUND_TXN_SUCCESS = 'GET_CLS_FUND_TXN_HEDGEFUND_TXN_SUCCESS'
export const GET_BY_ID_HEDGEFUND_TXN_ERROR = 'GET_BY_ID_HEDGEFUND_TXN_ERROR'
export const GET_REDEEM_MOUNTING_HEDGEFUND_TXN = 'GET_REDEEM_MOUNTING_HEDGEFUND_TXN'
export const GET_REDEEM_MOUNTING_HEDGEFUND_TXN_SUCCESS = 'GET_REDEEM_MOUNTING_HEDGEFUND_TXN_SUCCESS'
export const GET_CLS_MOUNTING_HEDGEFUND_TXN = 'GET_CLS_MOUNTING_HEDGEFUND_TXN'
export const GET_CLS_MOUNTING_HEDGEFUND_TXN_SUCCESS = 'GET_CLS_MOUNTING_HEDGEFUND_TXN_SUCCESS'
export const GET_REDEEM_MOUNTING_HEDGEFUND_TXN_ERROR = 'GET_REDEEM_MOUNTING_HEDGEFUND_TXN_ERROR'
export const GET_CLS_MOUNTING_HEDGEFUND_TXN_ERROR = 'GET_CLS_MOUNTING_HEDGEFUND_TXN_ERROR'
export const CALC_REDEEM_HEDGEFUND_TXN_SUCCESS = 'CALC_REDEEM_HEDGEFUND_TXN_SUCCESS'
export const CALC_REDEEM_HEDGEFUND_TXN_ERROR = 'CALC_REDEEM_HEDGEFUND_TXN_ERROR'
export const CREATE_HOLDINGS = 'CREATE_HOLDINGS'
export const CREATE_HOLDINGS_SUCCESS = 'CREATE_HOLDINGS_SUCCESS'
export const CREATE_HOLDINGS_ERROR = 'CREATE_HOLDINGS_ERROR'
export const GET_NAV_FINAL_HEDGEFUNDTXN = 'GET_NAV_FINAL_HEDGEFUNDTXN'
export const GET_NAV_FINAL_HEDGEFUNDTXN_SUCCESS = 'GET_NAV_FINAL_HEDGEFUNDTXN_SUCCESS'
//DataView
export const GET_LIST_DATA_VIEW_HEDGEFUND = 'GET_LIST_DATA_VIEW_HEDGEFUND'
export const GET_LIST_DATA_VIEW_HEDGEFUND_SUCCESS = 'GET_LIST_DATA_VIEW_HEDGEFUND_SUCCESS'
export const GET_LIST_DATA_VIEW_HEDGEFUND_ACTIVE = 'GET_LIST_DATA_VIEW_HEDGEFUND_ACTIVE'
export const GET_LIST_DATA_VIEW_HEDGEFUND_ERROR = 'GET_LIST_DATA_VIEW_HEDGEFUND_ERROR'
export const GET_LIST_DATA_VIEW_HEDGEFUND_DEFAULT = 'GET_LIST_DATA_VIEW_HEDGEFUND_DEFAULT'
export const GET_LIST_DATA_VIEW_ACCOUNT = 'GET_LIST_DATA_VIEW_ACCOUNT'
export const GET_LIST_DATA_VIEW_ACCOUNT_SUCCESS = 'GET_LIST_DATA_VIEW_ACCOUNT_SUCCESS'
export const GET_LIST_DATA_VIEW_ACCOUNT_ACTIVE = 'GET_LIST_DATA_VIEW_ACCOUNT_ACTIVE'
export const GET_LIST_DATA_VIEW_ACCOUNT_ERROR = 'GET_LIST_DATA_VIEW_ACCOUNT_ERROR'
export const GET_LIST_DATA_VIEW_ACCOUNT_DEFAULT = 'GET_LIST_DATA_VIEW_ACCOUNT_DEFAULT'
export const GET_LIST_DATA_VIEW_INVESTOR = 'GET_LIST_DATA_VIEW_INVESTOR'
export const GET_LIST_DATA_VIEW_INVESTOR_SUCCESS = 'GET_LIST_DATA_VIEW_INVESTOR_SUCCESS'
export const GET_LIST_DATA_VIEW_INVESTOR_ACTIVE = 'GET_LIST_DATA_VIEW_INVESTOR_ACTIVE'
export const GET_LIST_DATA_VIEW_INVESTOR_ERROR = 'GET_LIST_DATA_VIEW_INVESTOR_ERROR'
export const GET_LIST_DATA_VIEW_INVESTOR_DEFAULT = 'GET_LIST_DATA_VIEW_INVESTOR_DEFAULT'
export const GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND = 'GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND'
export const GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND_ERROR = 'GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND_ERROR'
export const GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND = 'GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND'
export const GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND_ERROR = 'GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND_ERROR'
export const GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND = 'GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND'
export const GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_DEFAULT = 'GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_DEFAULT'
export const GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_ERROR = 'GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_ERROR'
//InterestRatesTicker
export const GET_LIST_INTEREST_RATES_TICKER = 'GET_LIST_INTEREST_RATES_TICKER'
export const GET_LIST_INTEREST_RATES_TICKER_SUCCESS = 'GET_LIST_INTEREST_RATES_TICKER_SUCCESS'
export const GET_LIST_INTEREST_RATES_TICKER_ERROR = 'GET_LIST_INTEREST_RATES_TICKER_ERROR'
export const GET_DETAIL_DATA_WITH_TICKER = 'GET_DETAIL_DATA_WITH_TICKER'
export const GET_DETAIL_DATA_WITH_TICKER_SUCCESS = 'GET_DETAIL_DATA_WITH_TICKER_SUCCESS'
export const GET_DETAIL_DATA_WITH_TICKER_ERROR = 'GET_DETAIL_DATA_WITH_TICKER_ERROR'
//HedgeFundIndexTicker
export const GET_LIST_HEDGEFUND_INDEX_TICKER = 'GET_LIST_HEDGEFUND_INDEX_TICKER'
export const GET_LIST_HEDGEFUND_INDEX_TICKER_SUCCESS = 'GET_LIST_HEDGEFUND_INDEX_TICKER_SUCCESS'
export const GET_LIST_HEDGEFUND_INDEX_TICKER_ERROR = 'GET_LIST_HEDGEFUND_INDEX_TICKER_ERROR'
export const GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER = 'GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER'
export const GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_SUCCESS = 'GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_SUCCESS'
export const GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_ERROR = 'GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_ERROR'
//MarketIndex
export const GET_LIST_MARKET_INDEX_TICKER = 'GET_LIST_MARKET_INDEX_TICKER'
export const GET_LIST_MARKET_INDEX_TICKER_SUCCESS = 'GET_LIST_MARKET_INDEX_TICKER_SUCCESS'
export const GET_LIST_MARKET_INDEX_TICKER_ERROR = 'GET_LIST_MARKET_INDEX_TICKER_ERROR'
//FXTicker
export const GET_LIST_FX_TICKER = 'GET_LIST_FX_TICKER'
export const GET_LIST_FX_TICKER_SUCCESS = 'GET_LIST_FX_TICKER_SUCCESS'
export const GET_LIST_FX_TICKER_ERROR = 'GET_LIST_FX_TICKER_ERROR'
export const GET_DETAIL_CURRENCY_PAIR_FX = 'GET_DETAIL_CURRENCY_PAIR_FX'
export const GET_DETAIL_CURRENCY_PAIR_FX_SUCCESS = 'GET_DETAIL_CURRENCY_PAIR_FX_SUCCESS'
export const GET_DETAIL_CURRENCY_PAIR_FX_ERROR = 'GET_DETAIL_CURRENCY_PAIR_FX_ERROR'
//Analytical Tool
export const GET_PORTFOLIO_BY_ACCOUNT_ID_AT_SUCCESS = 'GET_PORTFOLIO_BY_ACCOUNT_ID_AT_SUCCESS';
