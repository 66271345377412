import { GET_LIST_NAV_DOUBLE_CHECK } from "../../constants/ActionTypes";
import {NAVDoubleCheckItem} from "../../dto"
const INIT_STATE : NAVDoubleCheckItem = {
    doubleCheckList: [{
        id: 1,
        basedate: "2020/12/31",
        accountname: "Asia-Pacific Rising Fund",
        name: "APRF",
        currency: "USD",
        abbreviation: "........",
        class: "USD Class A",
        series: "Initial",
        lastmonth: "99.854",
        isFinal: true,
        isEstimate: false,
        isTentative: false,
        totalNAV: "23,719",
        outstanding: "234,567",
        txnType: "Redeem",
        nav_share: "101.123",
        netMTD: "1.27",
        fundingMTD: "1.2",
        netWTD: "0.9",
        fundingWTD: "0.85",
        txnComment: "5M Redeem",
    }, {
        id: 2,
        basedate: "2020/12/31",
        accountname: "Asia-Pacific Rising Fund",
        name: "APRF",
        currency: "USD",
        abbreviation: "........",
        class: "USD Class B",
        series: "Initial",
        lastmonth: "99.854",
        isFinal: false,
        isEstimate: true,
        isTentative: false,
        totalNAV: "23,719",
        outstanding: "500.000",
        nav_share: "101.123",
        txnType: "Redeem",
        netMTD: "1.27",
        fundingMTD: "1.2",
        netWTD: "0.9",
        fundingWTD: "0.85",
        txnComment: "5M Redeem",
    }]
}
const DoubleCheckReducer = (state = INIT_STATE, action: { type: string; }):NAVDoubleCheckItem => {
    switch (action.type) {
        case GET_LIST_NAV_DOUBLE_CHECK:
            {
                return {
                    ...state,
                };
            }
        default:
            return state;
    }
};
export default DoubleCheckReducer;