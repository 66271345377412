import {
  GET_LIST_DATA_VIEW_HEDGEFUND,
  GET_LIST_DATA_VIEW_HEDGEFUND_ACTIVE,
  GET_LIST_DATA_VIEW_HEDGEFUND_DEFAULT,
  GET_LIST_DATA_VIEW_HEDGEFUND_ERROR,
  GET_LIST_DATA_VIEW_HEDGEFUND_SUCCESS,
  GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND,
  GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_DEFAULT,
  GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_ERROR,
  GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND,
  GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND_ERROR,
  GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND,
  GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND_ERROR,
} from '../../../constants/ActionTypes'
import { HedgeFundItem } from '../../../dto'
import { HoldingsDataViewHFItem, NavBvDataViewHFItem, PerformanceDataViewHFItem } from '../../../dto/DataView/HedgeFunds'
import { DataViewHedgeFundStateDto } from '../stateDtos/DataView/HedgeFunds'
interface ActionProps {
  data: HedgeFundItem[]
  type: string
  dataPerformance: PerformanceDataViewHFItem[]
  dataNav: NavBvDataViewHFItem
  dataHolding: HoldingsDataViewHFItem
  loading: boolean
  dataHoldingDefault: HoldingsDataViewHFItem
}
const INIT_STATE: DataViewHedgeFundStateDto = {
  loading: true,
  dataViewHFList: [],
  dataViewHFListDefault: [],
  dataViewHFActive: [],
  dataPerformance: [],
  dataNav: [],
  dataHolding: [],
  dataHoldingDefault: [],
}
const DataViewHedgeFundsReducer = (
  state = INIT_STATE,
  action: ActionProps
): any => {
  switch (action.type) {
    case GET_LIST_DATA_VIEW_HEDGEFUND: {
      return {
        ...state,
        loading: true,
        dataViewHFList: [],
        dataViewHFActive: [],
      }
    }
    case GET_LIST_DATA_VIEW_HEDGEFUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        dataViewHFList: action.data,
      }
    }
    case GET_LIST_DATA_VIEW_HEDGEFUND_DEFAULT: {
      return {
        ...state,
        loading: false,
        dataViewHFListDefault: action.data,
      }
    }
    case GET_LIST_DATA_VIEW_HEDGEFUND_ACTIVE: {
      const activeList = action.data.filter((x) => x.isActive == true)
      return {
        ...state,
        dataViewHFActive: activeList,
      }
    }
    case GET_LIST_DATA_VIEW_HEDGEFUND_ERROR: {
      return {
        ...state,
        loading: false,
      }
    }
    case GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND: {
      return {
        ...state,
        dataPerformance: action.dataPerformance,
      }
    }
    case GET_LIST_PERFORMANCE_DATA_VIEW_HEDGEFUND_ERROR: {
      return {
        ...state,
      }
    }
    case GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND: {
      return {
        ...state,
        dataNav: action.dataNav,
      }
    }
    case GET_LIST_NAVBV_DATA_VIEW_HEDGEFUND_ERROR: {
      return {
        ...state,
      }
    }
    case GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND: {
      return {
        ...state,
        dataHolding: action.dataHolding,
      }
    }
    case GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_DEFAULT: {
      return {
        ...state,
        dataHoldingDefault: action.dataHoldingDefault,
      }
    }
    case GET_LIST_HOLDING_DATA_VIEW_HEDGEFUND_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default DataViewHedgeFundsReducer
