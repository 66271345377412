import enLang from "./entries/en-US";
import zhLang from "./entries/zh-Hans-CN";
import jpLang from "./entries/jp-Kanji";

const AppLocale:any = {
  en: enLang,
  zh: zhLang,
  jp: jpLang,
};

export default AppLocale;
