export const CountryCode = [
  'JP',
  'US',
  'HK',
  'KR',
  'KY'
];

const region = new Intl.DisplayNames(
  ['en'], { type: 'region' }
);

export const regionNames = (code: string):any => {
  if (code) {
    return region.of(code);
  }
  return '';
}
